import {v4 as uuid} from 'uuid';
import {SAVE_REPLY} from "../constants/request-const";
import {toSaveReplyRequest} from "../converters/request-converter";
import {Reply, ReplyId} from "../entities/reply-entity";
import {ErrorCodeEnum, ErrorItemEnum} from "../enums/error-message-enum";
import {functions} from "../firebase";
import {isDefAndNotNull} from "../utils/common-util";
import {SaveReplyRequest} from "../vo/request-vo";

export default class ReplyService {
  public static getReplyId = (): ReplyId => uuid();

  public static saveReply = async (
    reply: Reply,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: SaveReplyRequest =
        toSaveReplyRequest(reply);
    
      const func = functions.httpsCallable(SAVE_REPLY);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };
}
