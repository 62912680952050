export enum Authority {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export enum UserActionEnum {
  SET = 'set_user',
  PURGE = 'persist/PURGE',
}

export enum AccountItemEnum {
  CARD = 'card',
  CONTACT = 'contact',
  BRIEF_HISTORY = 'brief_history',
  OWNER_COMMUNITY = 'owner_community',
  MEMBER_COMMUNITY = 'member_community',
  THANKS = 'thanks',
  OSHI = 'oshi',
  SPONSOR = 'sponsor',
}