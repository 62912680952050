import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import QRCode from '../../components/other/QRCode';
import Screen from '../../components/screen/Screen';
import {Connection} from '../../entities/connection-entity';
import {User} from '../../entities/user-entity';
import {CommunityMemberRole} from '../../enums/community-member-enum';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import ConnectionService from '../../services/connection-service';
import UserService from '../../services/user-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {embedIdInPath} from '../../utils/path-util';
import Community from './profile-item/Community';
import ConnectButton from './profile-item/ConnectButton';
import Contact from './profile-item/Contact';
import ProfilePhoto from './profile-item/ProfilePhoto';
import Questions from './profile-item/Questions';
import SelfIntroduction from './profile-item/SelfIntroduction';
import Topics from './profile-item/Topics';

interface P extends RouteComponentProps {}

const AccountScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params, openPath } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isMyAccount, setIsMyAccount] = useSafeState<boolean>(unmountRef, false);
  const [connection, setConnection] = useSafeState<Connection | undefined>(unmountRef);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const signInUser = await getUser() as User;
    const isMyAccount = params.userId === signInUser.userId;
 
    const [user, connection] = await Promise.all([
      isMyAccount ? signInUser
        : UserService.fetchUser(params.userId!),
      isMyAccount ? undefined
        : ConnectionService.fetchConnectionByUserIds(params.userId!, signInUser.userId),
    ]);

    if (!user) {
      openPath(Path.QUESTIONS);
      return;
    }

    setIsMyAccount(isMyAccount);
    setUser(user);
    setConnection(connection);
    setLoaded(true);
  }, [getUser, params.userId, openPath, setIsMyAccount, setConnection, setUser, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("アカウント画面");
  }, [initialize]);

  return (
    <Screen
      loading={!loaded}
      className="account-screen"
    >
      <Container>
        <Content>
          <ProfilePhoto
            isMyAccount={isMyAccount}
            user={user}
          />

          {isMyAccount &&
           !!user &&
            <QRCode
              url={window.location.href}
              path={embedIdInPath(Path.ACCOUNT, [user.userId])}
              message="URLもしくはQRにアクセスしてもらうことで、知り合いリストに追加できます。"
            />
          }

          {!isMyAccount &&
            <ConnectButton
              connection={connection}
              user={user}
            />
          }

          <SelfIntroduction
            user={user}
          />

          <Contact
            user={user}
          />
          
          <Questions
            user={user}
          />

          <Community
            role={CommunityMemberRole.OWNER}
            user={user}
          />

          <Community
            role={CommunityMemberRole.MEMBER}
            user={user}
          />

          <Topics
            user={user}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default AccountScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 9}px;
`;