import {useSnackbar} from 'notistack';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import Component from '../../../components/component/Component';
import {EMPTY} from '../../../constants/common-const';
import {SEND_CONNECTION_REQUEST, SUCCESS} from '../../../constants/snackbar-const';
import {toConnection} from '../../../converters/connection-converter';
import {Connection} from '../../../entities/connection-entity';
import {User} from '../../../entities/user-entity';
import {ConnectionType} from '../../../enums/connection-enum';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import ConnectionService from '../../../services/connection-service';

const ConnectionButtonLabel = {
  [ConnectionType.REQUESTED]: 'つながりリクエストを取り消す',
  [ConnectionType.CONNECTED]: 'つながりを削除する',
  [ConnectionType.REJECTED]: 'つながりリクエストを取り消す',
}

interface P {
  connection: Connection | undefined;
  user: User;
}

const ConnectButton: React.FC<P> = React.memo(props => {
  const { connection: initConnnection, user } = props;
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useSafeState<boolean>(unmountRef, false);
  const [connection, setConnection] = useSafeState<Connection | undefined>(unmountRef, initConnnection);

  const getNextConnectionType = useSafeCallback((
    prevConnection: Connection | undefined,
  ): ConnectionType | undefined => {
    if (!prevConnection) {
      enqueueSnackbar(SEND_CONNECTION_REQUEST, { variant: SUCCESS });
      return ConnectionType.REQUESTED;
    }

    switch (prevConnection.type) {
      case ConnectionType.REQUESTED:
      case ConnectionType.CONNECTED:
      case ConnectionType.REJECTED:
        return undefined;
        
      default:
        throw new Error(`${prevConnection.type} is out of target.`);
    }
  }, [enqueueSnackbar]);

  const onClickButton = useSafeCallback(async (): Promise<void> => {
    setDisabled(true);
    const signInUser = await getUser() as User;
    
    setConnection(connection => {
      const nextConnectionType = getNextConnectionType(connection);

      if (!!connection && !nextConnectionType) {
        ConnectionService.deleteConnection(connection.connectionPairId);
        setDisabled(false);
        return nextConnectionType;
      }
      
      const newConnection = !!connection
        ? { ...connection, type: nextConnectionType! }
        : toConnection(
            ConnectionService.getConnectionId(),
            ConnectionService.getConnectionPairId(),
            user,
            signInUser,
            nextConnectionType!,
            EMPTY
          );
      
      ConnectionService.saveConnection(newConnection);
      setDisabled(false);
      return newConnection;
    })
  }, [setDisabled, getUser, setConnection, getNextConnectionType, user]);

  return (
    <Component className="connect-button">
      <Container>
        <Content>
          <Button
            disabled={disabled}
            type={connection === undefined
              ? 'primary' : 'secondary'}
            label={connection === undefined
              ? 'つながる' : ConnectionButtonLabel[connection.type]}
            onClick={onClickButton}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default ConnectButton;

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: auto;
  height: auto;
`;