import {Typography} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CheckButton from '../../../components/button/CheckButton';
import Component from '../../../components/component/Component';
import ImageWithSkeleton from '../../../components/image/ImageWithSkeleton';
import {EMPTY, MINUS_ONE} from '../../../constants/common-const';
import {CommunityMember} from '../../../entities/community-member-entity';
import {Connection} from '../../../entities/connection-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {noop} from '../../../utils/common-util';
import {toFullName} from '../../../utils/user-util';
import {SelfIntroduction, URL} from '../../../vo/common-vo';

const IMG_SIZE = 72;

interface P {
  selected: CommunityMember[] | Connection[];
  member: CommunityMember | Connection;
  onSelect(member: CommunityMember | Connection): void;
}

const SelectableMemberCard: React.FC<P> = React.memo(props => {
  const { selected, member, onSelect } = props;
  const unmountRef = useUnmountRef();
  const [selcted, setSelected] = useSafeState<boolean>(unmountRef,
    member instanceof CommunityMember
      ? selected.findIndex(s => s.user.userId === member.user.userId) !== MINUS_ONE
      : selected.findIndex(s => s.connecting.userId === member.connecting.userId) !== MINUS_ONE);

  const userPhoto = useMemo<URL>(() => {
    return member instanceof CommunityMember
      ? member.user.photoURL
      : member.connecting.photoURL;
  }, [member]);

  const selfIntroduction = useMemo<SelfIntroduction>(() => {
    const selfIntroduction = member instanceof CommunityMember
      ? member.user.selfIntroduction
      : member.connecting.selfIntroduction;
    return !!selfIntroduction ? selfIntroduction : EMPTY;
  }, [member]);

  const toggleSelected = useSafeCallback((): void => {
    setSelected(selected => !selected);
    onSelect(member);
  }, [setSelected, onSelect, member]);

  return (
    <Component className="selectable-member-card">
      <Container>
        <Content
          onClick={toggleSelected}
        >
          <ImageWithSkeleton
            animation="wave"
            variant='circle'
            style={styleForUserPhoto}
            src={userPhoto}
          />

          <MemeberBox>
            <Label>
              <NameWrapper>
                <MemberName>
                  {toFullName(member instanceof CommunityMember
                    ? member.user : member.connecting)}
                </MemberName>
              </NameWrapper>
              
              <Comment>
                {selfIntroduction}
              </Comment>
            </Label>
          </MemeberBox>

          <ButtonWrapper>
            <CheckButton
              checked={selcted}
              onClick={noop}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default SelectableMemberCard;

const Container = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

const styleForUserPhoto: CSSProperties = {
  width: IMG_SIZE,
  minWidth: IMG_SIZE,
  height: IMG_SIZE,
  borderRadius: '50%',
  objectFit: 'cover',
  zIndex: 10,
};

const MemeberBox = styled.div`
  width: calc(100% - ${IMG_SIZE + 40}px);
  height: ${IMG_SIZE}px;
  border-radius: 16px;
  background: ${theme.mixins.background.white};
  margin-left: -${theme.mixins.spacing}px;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Label = styled.div`
  width: 100%;
  height: auto;
`;

const NameWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${theme.mixins.palette.gray};
`;

const MemberName = styled(Typography)`
  width: 100%;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Comment = styled(Typography)`
  width: 100%;
  color: ${theme.mixins.typography.fontColor.gray3};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
  width: 48px;
  min-width: 48px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;