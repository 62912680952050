import {FETCH_USER, FETCH_USER_BY_FIREBASE_USER_ID, SAVE_USER} from '../constants/request-const';
import {convert, toFetchUserByFirebaseUserIdRequest, toFetchUserRequest, toSaveUserRequest} from '../converters/request-converter';
import {FirebaseUserId} from '../entities/firebase-user-entity';
import {User, UserId} from '../entities/user-entity';
import {ErrorCodeEnum, ErrorItemEnum} from '../enums/error-message-enum';
import {functions} from '../firebase';
import {isDefAndNotNull} from '../utils/common-util';
import {FetchUserByFirebaseUserIdRequest, FetchUserRequest, SaveUserRequest} from '../vo/request-vo';

export default class UserService {
  public static saveUser = async (
    user: User,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: SaveUserRequest =
        toSaveUserRequest(user);
    
      const func = functions.httpsCallable(SAVE_USER);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  }

  public static fetchUser = async (
    userId: UserId,
  ): Promise<User | undefined> => {
    return await new Promise((resolve, reject) => {
      const request: FetchUserRequest =
        toFetchUserRequest(userId);
    
      const func = functions.httpsCallable(FETCH_USER);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
        .catch((error) => reject(error));
    });
  }

  public static fetchUserByFirebaseUserId = async (
    firebaseUserId: FirebaseUserId,
  ): Promise<User | undefined> => {
    return await new Promise((resolve, reject) => {
      const request: FetchUserByFirebaseUserIdRequest =
        toFetchUserByFirebaseUserIdRequest(firebaseUserId);
    
      const func = functions.httpsCallable(FETCH_USER_BY_FIREBASE_USER_ID);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
        .catch((error) => reject(error));
    });
  }
}
