import {Typography} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import ImageWithSkeleton from '../../../components/image/ImageWithSkeleton';
import Label from '../../../components/typography/Label';
import {CommunityMember} from '../../../entities/community-member-entity';
import {CommunityMemberStatus} from '../../../enums/community-member-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {embedIdInPath} from '../../../utils/path-util';
import {toFullName} from '../../../utils/user-util';

const IMG_SIZE = 72;

interface P {
  member: CommunityMember;
}

const MemberCard: React.FC<P> = React.memo(props => {
  const { member } = props;
  const { openPath } = usePath();

  const showLable = useMemo<boolean>(() => {
    return member.status === CommunityMemberStatus.REQUESTED
      || member.status === CommunityMemberStatus.INVITED;
  }, [member.status]);

  const openAccountScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT, [member.user.userId]));
  }, [openPath, member.user.userId]);

  return (
    <Component className="member-card">
      <Container>
        <Content
          onClick={openAccountScreen}
        >
          <ImageWithSkeleton
            animation="wave"
            variant='circle'
            style={styleForUserPhoto}
            src={member.user.photoURL}
          />

          <MemeberBox>
            <Wrapper
              showLabel={showLable}
            >
              <NameWrapper>
                <Name>
                  {toFullName(member.user)}
                </Name>
              </NameWrapper>
              
              <Comment>
                {member.user.selfIntroduction}
              </Comment>
            </Wrapper>

            {member.status === CommunityMemberStatus.REQUESTED &&
              <LabelWrapper>
                <Label
                  type="primary"
                  content="参加申請中"
                />
              </LabelWrapper>
            }

            {member.status === CommunityMemberStatus.INVITED &&
              <LabelWrapper>
                <Label
                  type="primary"
                  content="招待中"
                />
              </LabelWrapper>
            }
          </MemeberBox>
        </Content>
      </Container>
    </Component>
  );
});

export default MemberCard;

const Container = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

const styleForUserPhoto: CSSProperties = {
  width: IMG_SIZE,
  minWidth: IMG_SIZE,
  height: IMG_SIZE,
  borderRadius: '50%',
  objectFit: 'cover',
  zIndex: 10,
};

const MemeberBox = styled.div`
  width: calc(100% - ${IMG_SIZE}px + ${theme.mixins.spacing}px);
  height: ${IMG_SIZE}px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: ${theme.mixins.background.white};
  margin-left: -${theme.mixins.spacing}px;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Wrapper = styled.div<{ showLabel: boolean }>`
  width: calc(100% - ${props => props.showLabel ? 96 : 0}px);
  height: auto;
`;

const NameWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${theme.mixins.palette.gray};
`;

const Name = styled(Typography)`
  width: 100%;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Comment = styled(Typography)`
  width: 100%;
  height: 18px;
  color: ${theme.mixins.typography.fontColor.gray3};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LabelWrapper =styled.div`
  width: 96px;
  height: auto;
  margin: 0px ${theme.mixins.spacing}px;
`;