import {ErrorCodeEnum} from "../enums/error-message-enum";

export const ErrorMessages = {
  /** common */
  [ErrorCodeEnum.INVALID_EMAIL]: '正しいメールアドレスを入力してください',

  /** createUserWithEmailAndPassword */
  [ErrorCodeEnum.EMAIL_ALREADY_IN_USE]: 'このメールアドレスは登録されています',
  [ErrorCodeEnum.OPERATION_NOT_ALLOWED]: 'サポートチームに問い合わせください',
  [ErrorCodeEnum.WEAK_PASSWORD]: '強固なパスワードを入力してください',  

  /** signInWithEmailAndPassword */
  [ErrorCodeEnum.USER_DISABLED]: 'サポートチームに問い合わせください',
  [ErrorCodeEnum.USER_NOT_FOUND]: 'アカウントが見つかりませんでした',
  [ErrorCodeEnum.WRONG_PASSWORD]: 'パスワードが正しくありません',
}