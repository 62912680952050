import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../components/box/EditableBox';
import Component from '../../../components/component/Component';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import {URL} from '../../../vo/common-vo';

interface P {
  user: User;
  onChange(user: User): void;
}

const EditProfilePhoto: React.FC<P> = React.memo(props => {
  const { user, onChange } = props;
  const unmountRef = useUnmountRef();
  const [photoURL, setPhotoURL] = useSafeState<URL>(unmountRef, user.photoURL);

  const handlePhotoURLChanged = useSafeCallback((photoURL: URL): void => {
    setPhotoURL(photoURL);
    onChange({ ...user, photoURL });
  }, [setPhotoURL, onChange, user]);

  return (
    <Component className="edit-profile-photo">
      <Container>
        <Content>
          <EditableBox
            editable
            type="image"
            title="プロフィール写真"
            value={photoURL}
            onChange={handlePhotoURLChanged}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default EditProfilePhoto;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;