import {Typography} from '@material-ui/core';
import firebase from 'firebase';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import Box from '../../../components/box/Box';
import Button from '../../../components/button/Button';
import Component from '../../../components/component/Component';
import CheckBox from '../../../components/input/CheckBox';
import InputBox from '../../../components/input/InputBox';
import {EMPTY, MINUS_ONE} from '../../../constants/common-const';
import {ErrorMessages} from '../../../constants/error-message-const';
import {createUserWithFirebase} from '../../../converters/user-converter';
import {User} from '../../../entities/user-entity';
import {ProviderIdEnum} from '../../../enums/common-enum';
import {ErrorItemEnum} from '../../../enums/error-message-enum';
import {auth} from '../../../firebase';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import {theme} from '../../../styles/theme';
import {isEmpty, isNotEmpty} from '../../../utils/common-util';
import {Email, ErrorCode, ErrorMessage, Name, Password} from '../../../vo/common-vo';
import atomica from './../../../assets/atomica.gif';
import Remarks from './Remarks';

interface P {
  email: Email;
  onFinish(user: User | null): void;
}

const InputAccountWithPassword: React.FC<P> = React.memo(props => {
  const { email, onFinish } = props;
  const unmountRef = useUnmountRef();
  const { updateUser } = useUser();
  const [checked, setChecked] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [password, setPassword] = useSafeState<Password>(unmountRef, EMPTY);
  const [passwordError, setPasswordError] = useSafeState<ErrorMessage>(unmountRef, EMPTY);
  const [familyName, setFamilyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [familyNameError, setFamilyNameError] = useSafeState<ErrorMessage>(unmountRef, EMPTY);
  const [firstName, setFirstName] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameError, setFirstNameError] = useSafeState<ErrorMessage>(unmountRef, EMPTY);

  const disabled = useMemo<boolean>(() => {
   return !checked || isEmpty(password) || isEmpty(familyName) || isEmpty(firstName);
  }, [checked, password, familyName, firstName]);

  const constructErrorMessages = useSafeCallback((item: ErrorItemEnum, code: ErrorCode): void => {
    Object.entries(ErrorMessages)
      .forEach(([key, value]) => {
        if (code.toString().indexOf(key) === MINUS_ONE) return;

        switch (item) {
          case ErrorItemEnum.PASSWORD:
            setPasswordError(value);
            break;
          case ErrorItemEnum.FAMILY_NAME:
            setFamilyNameError(value);
            break;
          case ErrorItemEnum.FIRST_NAME:
            setFirstNameError(value);
            break;
          default:
            return;
        }
      });
  }, [setPasswordError, setFamilyNameError, setFirstNameError]);

  const createUser = useSafeCallback((): void => {
    setSaving(true);
    
    auth.createUserWithEmailAndPassword(email, password)
      .then(async (credential: firebase.auth.UserCredential): Promise<void> => {
        const user = createUserWithFirebase(
          credential.user!,
          true,
          familyName,
          firstName,
          email,
          ProviderIdEnum.EMAIL,
        );

        await updateUser(user);
        onFinish(user);
      })
      .catch(error => {
        constructErrorMessages(ErrorItemEnum.PASSWORD, error.code);
        setSaving(false);
      });
  }, [setSaving, email, password, familyName, firstName, updateUser, onFinish, constructErrorMessages]);

  return (
    <Component
      loading={saving}
      className="input-account-with-password"
    >
      <Container>
        <Content>
          <Logo
            src={atomica}
          />

          <InputWrapper>
            <Box
              disabled
            >
              <StyledEmail>
                {email}
              </StyledEmail>
            </Box>
          </InputWrapper>

          <PasswordWrapper>
            <InputBox
              type="password"
              label="パスワード"
              value={password}
              onChange={setPassword}
            />
            {isNotEmpty(passwordError) &&
              <Message>
                {passwordError}
              </Message>
            }
          </PasswordWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox
                maxLength={15}
                label="姓"
                value={familyName}
                onChange={setFamilyName}
              />
              {isNotEmpty(familyNameError) &&
                <Message>
                  {familyNameError}
                </Message>
              }
            </NameWrapper>

            <NameWrapper>
              <InputBox
                maxLength={15}
                label="名"
                value={firstName}
                onChange={setFirstName}
              />
              {isNotEmpty(firstNameError) &&
                <Message>
                  {firstNameError}
                </Message>
              }
            </NameWrapper>
          </FullNameWrapper>

          <Agreement>
            <CheckBox
              label="利用規約およびプライバシーポリシーに同意する"
              checked={checked}
              onChange={() => setChecked(checked => !checked)}
            />
          </Agreement>

          <ButtonWrapper>
            <Button
              type="primary"
              disabled={disabled}
              label="アカウント作成"
              onClick={createUser}
            />
          </ButtonWrapper>

          <Remarks />
        </Content>
      </Container>
    </Component>
  )
});

export default InputAccountWithPassword;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.mixins.spacing * 6}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: cover;
  padding: ${theme.mixins.spacing * 6}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const StyledEmail = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const PasswordWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Message = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing / 2}px 0px 0px ${theme.mixins.spacing * 2}px;
`;

const FullNameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const NameWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: 88px;
`;

const Agreement = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;