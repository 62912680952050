import React, {useEffect} from 'react';
import styled from 'styled-components';
import MultiButtons from '../../../../components/button/MultiButtons';
import Component from '../../../../components/component/Component';
import {toButtonsMessage} from '../../../../converters/message-converter';
import {ButtonMessage} from '../../../../entities/message-entity';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';

interface P {
  disabled: boolean;
  buttons: ButtonMessage[];
}

const FooterButtons: React.FC<P> = React.memo(props => {
  const { disabled: initDisabled, buttons } = props;
  const unmountRef = useUnmountRef();
  const { sendResponse } = useMessage();
  const [disabled, setDisabled] = useSafeState<boolean>(unmountRef, initDisabled);

  useEffect(() => {
    setDisabled(initDisabled);
  }, [setDisabled, initDisabled]);

  const handleButtonClicked = useSafeCallback((button: ButtonMessage): void => {
    setDisabled(true);
    sendResponse(toButtonsMessage([button]));
  }, [setDisabled, sendResponse]);

  return (
    <Component className="footer-buttons">
      <Container>
        <Content>
          <MultiButtons
            disabled={disabled}
            options={buttons}
            onClick={handleButtonClicked}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default FooterButtons;

const Container = styled.div`
  width: 100%;
  height: 72px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

const Content = styled.div`
  width: 280px;
  height: 56px;
`;