import {Connection, ConnectionId, ConnectionPairId} from "../entities/connection-entity";
import {User} from "../entities/user-entity";
import {ConnectionType} from "../enums/connection-enum";
import {Message} from "../vo/common-vo";

export const toConnection = (
  connectionId: ConnectionId,
  connectionPairId: ConnectionPairId,
  connecting: User,
  connecter: User,
  type: ConnectionType,
  message: Message,
): Connection => {
  return {
    connectionId,
    connectionPairId,
    connecting,
    connecter,
    type,
    message,
  };
}