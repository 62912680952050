import {CommunityMember} from "../entities/community-member-entity";
import {Name} from "../vo/common-vo";
import {toFullName} from "./user-util";

export const toMemberNames = (members: CommunityMember[]): Name => {
  return members.reduce((accum, current, index) => {
    switch (index) {
      case 0:
        return `${toFullName(current.user)}`;
      case 1:
        return `${accum}、${toFullName(current.user)}`;
      case 2:
        return `${accum}、他${members.length - 2}人`;
      default:
        return accum;
    }
  }, '');
}