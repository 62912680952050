import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Tips from '../../../components/typography/Tips';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import mojaco from './../../../assets/mojaco/mojaco_thanks2.png';

const ASK_LABEL = '相談\n！';

const ANSWER_LABEL = 'おかわり\n！';

interface P {
  onAnswerMore(): void;
}

const NoQuestion: React.FC<P> = React.memo(props => {
  const { onAnswerMore } = props;
  const { openPath } = usePath();

  const openAskScreen = useSafeCallback((): void => {
    openPath(Path.ASK_TO);
  }, [openPath]);

  return (
    <Component className="no-question">
      <Container>
        <Content>
          <MojacoWrapper>
            <Mojaco
              src={mojaco}
            />

            <AskButton
              onClick={openAskScreen}
            >
              {ASK_LABEL}
            </AskButton>

            <AnswerButton
              onClick={onAnswerMore}
            >
              {ANSWER_LABEL}
            </AnswerButton>
          </MojacoWrapper>

          <Tips
            message="まずは誘ってくれたコミュニティに参加をしてみましょう！招待は画面下の通知のベルマークから確認できますよ！"
          />
        </Content>
      </Container>
    </Component>
  );
});

export default NoQuestion;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${theme.mixins.spacing * 10}px 0px;
`;

const MojacoWrapper = styled.div`
  width: 296px;
  height: 296px;
  display: flex;
  margin-top: ${theme.mixins.spacing * 10}px;
`;

const Mojaco = styled.img`
  width: 296px;
  height: 296px;
  object-fit: cover;
`;

const AskButton = styled.div`
  width: 84px;
  min-width: 84px;
  height: 84px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  background: ${theme.mixins.background.orange};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -76px;
  left: -264px;
  border: 1px solid ${theme.mixins.palette.white};
  white-space: pre-wrap;
`;

const AnswerButton = styled.div`
  width: 84px;
  min-width: 84px;
  height: 84px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  background: ${theme.mixins.background.orange};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -64px;
  left: -156px;
  border: 1px solid ${theme.mixins.palette.white};
  white-space: pre-wrap;
`;