import {v4 as uuid} from 'uuid';
import {FETCH_COMMUNITIES_BY_QUESTION_ID, FETCH_COMMUNITIES_BY_ROLE, FETCH_COMMUNITIES_BY_USER_ID, FETCH_COMMUNITY, SAVE_COMMUNITY} from '../constants/request-const';
import {convert, convertMulti, toFetchCommunitiesByQuestionIdRequest, toFetchCommunitiesByRoleRequest, toFetchCommunitiesByUserIdRequest, toFetchCommunityRequest, toSaveCommunityRequest} from '../converters/request-converter';
import {Community, CommunityId} from '../entities/community-entity';
import {QuestionId} from '../entities/question-entity';
import {UserId} from '../entities/user-entity';
import {CommunitySortEnum} from '../enums/community-enum';
import {CommunityMemberRole} from '../enums/community-member-enum';
import {ErrorCodeEnum, ErrorItemEnum} from '../enums/error-message-enum';
import {functions} from '../firebase';
import {isDefAndNotNull} from '../utils/common-util';
import {Limit, Offset} from '../vo/common-vo';
import {FetchCommunitiesByQuestionIdRequest, FetchCommunitiesByRoleRequest, FetchCommunitiesByUserIdRequest, FetchCommunityRequest, SaveCommunityRequest} from '../vo/request-vo';

export default class CommunityService {
  public static getCommunityId = (): CommunityId => uuid();

  public static saveCommunity = async (
    community: Community,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
    const request: SaveCommunityRequest =
      toSaveCommunityRequest(community);
  
    const func = functions.httpsCallable(SAVE_COMMUNITY);
    func(request)
      .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
      .catch((error) => reject(error));
    });
  };

  public static fetchCommunity = async (
    communityId: CommunityId,
  ): Promise<Community | undefined> => {
    return await new Promise((resolve, reject) => {
    const request: FetchCommunityRequest =
      toFetchCommunityRequest(communityId);
  
    const func = functions.httpsCallable(FETCH_COMMUNITY);
    func(request)
      .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
      .catch((error) => reject(error));
    });
  };

  public static fetchCommunitiesByUserId = async (
    userId: UserId,
    sort: CommunitySortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Community[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchCommunitiesByUserIdRequest =
        toFetchCommunitiesByUserIdRequest(userId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_COMMUNITIES_BY_USER_ID);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };

  public static fetchCommunitiesByQuestionId = async (
    questionId: QuestionId,
    sort: CommunitySortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Community[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchCommunitiesByQuestionIdRequest =
        toFetchCommunitiesByQuestionIdRequest(questionId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_COMMUNITIES_BY_QUESTION_ID);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };

  public static fetchCommunitiesByRole = async (
    userId: UserId,
    role: CommunityMemberRole,
    sort: CommunitySortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Community[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchCommunitiesByRoleRequest =
        toFetchCommunitiesByRoleRequest(userId, role, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_COMMUNITIES_BY_ROLE);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };
}
