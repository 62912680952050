import {useEffect, useRef} from 'react';
import {Message} from '../../entities/message-entity';
import {EventTypeEnum} from '../../enums/event-enum';
import useSafeCallback from './useSafeCallback';

function useMessage() {
  const requestListener = useRef<EventListenerOrEventListenerObject | null>();
  const responseListener = useRef<EventListenerOrEventListenerObject | null>();

  const unsubscribeRequest = useSafeCallback((): void => {
    if (!requestListener.current) return;
    const root = document.getElementById('root')!;
    root.removeEventListener(EventTypeEnum.SEND_REQUEST, requestListener.current);
    requestListener.current = null;
  }, []);

  const unsubscribeResponse = useSafeCallback((): void => {
    if (!responseListener.current) return;
    const root = document.getElementById('root')!;
    root.removeEventListener(EventTypeEnum.SEND_RESPONSE, responseListener.current);
    responseListener.current = null;
  }, []);

  useEffect(() => {
    return () => {
      unsubscribeRequest();
      unsubscribeResponse();
    }
  }, [unsubscribeRequest, unsubscribeResponse]);

  const sendRequest = useSafeCallback((
    detail: Partial<Message>,
  ): boolean => {
    const root = document.getElementById('root')!;
    const event = new CustomEvent(EventTypeEnum.SEND_REQUEST, { detail });
    return root.dispatchEvent(event);
  }, []);

  const sendResponse = useSafeCallback((
    detail: Partial<Message>,
  ): boolean => {
    const root = document.getElementById('root')!;
    const event = new CustomEvent(EventTypeEnum.SEND_RESPONSE, { detail });
    return root.dispatchEvent(event);
  }, []);

  const subscribeRequest = useSafeCallback((
    newListener: EventListenerOrEventListenerObject,
  ): void => {
    if (!!requestListener.current) unsubscribeRequest();
    const root = document.getElementById('root')!;
    root.addEventListener(EventTypeEnum.SEND_REQUEST, newListener);
    requestListener.current = newListener;
  }, [unsubscribeRequest]);

  const subscribeResponse = useSafeCallback((
    newListener: EventListenerOrEventListenerObject,
  ): void => {
      if (!!responseListener.current) unsubscribeResponse();
      const root = document.getElementById('root')!;
      root.addEventListener(EventTypeEnum.SEND_RESPONSE, newListener);
      responseListener.current = newListener;  
  }, [unsubscribeResponse]);

  return {
    sendRequest,
    subscribeRequest,
    unsubscribeRequest,
    sendResponse,
    subscribeResponse,
    unsubscribeResponse,
  };
}

export default useMessage;
