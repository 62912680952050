
import {Button, Typography} from '@material-ui/core';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CommentBox from '../../../components/box/CommentBox';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {Community} from '../../../entities/community-entity';
import {Notification} from '../../../entities/notification-entity';
import {Question} from '../../../entities/question-entity';
import {Reply} from '../../../entities/reply-entity';
import {NotificationStatus} from '../../../enums/notification-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import NotificationService from '../../../services/notification-service';
import {theme} from '../../../styles/theme';
import {toDateTime} from '../../../utils/date-util';
import {embedIdInPath} from '../../../utils/path-util';
import {toFullName} from '../../../utils/user-util';
import clock from './../../../assets/icon/icon_clock_gray.png';

interface P {
  notification: Notification;
}

const ReplyNotificationCard: React.FC<P> = React.memo(props => {
  const { notification } = props;
  const { openPath } = usePath();
  const community = useMemo<Community>(() =>
    notification.reply!.answer!.question!.communities[0], [notification.reply]);
  const question = useMemo<Question>(() =>
    notification.reply!.answer!.question!, [notification.reply]);
  const reply = useMemo<Reply>(() =>
    notification.reply!, [notification.reply]);

  const openCommunityDetailsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [openPath, community.communityId]);

  const openCommunityQuestionDetailsScreen = useSafeCallback((): void => {
    const confirmed = { ...notification, status: NotificationStatus.CONFIRMED };
    NotificationService.saveNotification(confirmed);
    openPath(embedIdInPath(Path.COMMUNITY_QUESTION_DETAILS, [community.communityId, question.questionId]));
  }, [notification, openPath, community.communityId, question.questionId]);

  return (
    <Component className="reply-notification-card">
      <Container>
        <Content>
          <CommentBox
            rectPhoto={community.photoURL}
            circlePhoto={reply.user.photoURL}
            onClickRectPhoto={openCommunityDetailsScreen}
          >
            <Wrapper>
              <Title>
                {toFullName(reply.user)}
              </Title>

              <SubTitle>
                {community.name}
              </SubTitle>

              <Message>
                {reply.content}
              </Message>

              <FooterWrapper>
                <LinkButton
                  disableRipple
                  onClick={openCommunityQuestionDetailsScreen}
                >
                  相談を見る
                </LinkButton>

                <DatetimeWrapper>
                  <Icon
                    size="xsmall"
                    src={clock}
                  />
                  <Datetime>
                    {toDateTime(notification.createdAt!)}
                  </Datetime>
                </DatetimeWrapper>
              </FooterWrapper>
            </Wrapper>
          </CommentBox>
        </Content>
      </Container>
    </Component>
  );
});

export default ReplyNotificationCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Title = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkButton = styled(Button)`
  background: ${theme.mixins.background.white} !important;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  justify-content: flex-start;
  padding: 0px;
`;

const DatetimeWrapper = styled.div`
  color: ${theme.mixins.typography.fontColor.gray5};
  display: flex;
  align-items: center;
`;

const Datetime = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin-left: ${theme.mixins.spacing / 2}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;