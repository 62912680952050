import {Typography} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import firebase from 'firebase';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Component from '../../../../components/component/Component';
import InputBox from '../../../../components/input/InputBox';
import {EMPTY} from '../../../../constants/common-const';
import {ErrorMessages} from '../../../../constants/error-message-const';
import {ErrorCodeEnum} from '../../../../enums/error-message-enum';
import env from '../../../../env/env';
import {auth} from '../../../../firebase';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import {Path} from '../../../../router/Routes';
import {theme} from '../../../../styles/theme';
import {isEmpty} from '../../../../utils/common-util';
import {DOMAINS} from '../../../../utils/path-util';
import {Email, Message} from '../../../../vo/common-vo';

interface P {
  onSendEmail(email: Email): void;
}

const EmailSignUp: React.FC<P> = React.memo(props => {
  const { onSendEmail } = props;
  const unmountRef = useUnmountRef();
  const [disabled, setDisabled] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [message, setMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabled(isEmpty(email));
  }, [setDisabled, email]);

  const constructErrorMessage = useSafeCallback((code: ErrorCodeEnum): void => {
    if (isEmpty(code)) return;
    setMessage(ErrorMessages[code]);
  }, [setMessage]);

  const createUserWithEmail = useSafeCallback((): void => {
    setDisabled(true);
    
    if (isEmpty(email)) {
      setDisabled(false);
      constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      return;
    }

    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: `https://${DOMAINS[env]}${Path.REGISTER_NEW}?email=${email}`,
    };
    

    auth.sendSignInLinkToEmail(email, settings)
      .then(() => onSendEmail(email))
      .catch(() => {
        setDisabled(false);
        constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      });
  }, [setDisabled, email, constructErrorMessage, onSendEmail]);

  return (
    <Component className="email-sign-up">
      <Container>
        <Content>
          <InputWrapper>
            <InputBox
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
            />
    
            <ErrorMessage>
              {message}
            </ErrorMessage>
          </InputWrapper>
          
          <ButtonWrapper>
            <Button
              type="primary"
              disabled={disabled}
              icon={<MailIcon fontSize='small' />}
              label="メールアドレスで登録"
              onClick={createUserWithEmail}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  )
});

export default EmailSignUp;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-left: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;