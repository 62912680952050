import {animated} from '@react-spring/web';
import {useSnackbar} from 'notistack';
import React, {useEffect, useMemo, useRef} from 'react';
import {ReactEventHandlers} from 'react-use-gesture/dist/types';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Tips from '../../../components/typography/Tips';
import {EMPTY} from '../../../constants/common-const';
import {COPY_LINK, SUCCESS} from '../../../constants/snackbar-const';
import {toAnswer} from '../../../converters/answer-converter';
import {toInputMessage, toReplyMessage, toTabsMessage} from '../../../converters/message-converter';
import {toNextQuestionPath} from '../../../converters/path-converter';
import {toReply} from '../../../converters/reply-converter';
import {Answer, AnswerId} from '../../../entities/answer-entity';
import {Community} from '../../../entities/community-entity';
import {InputMessage, ReplyMessage} from '../../../entities/message-entity';
import {Question} from '../../../entities/question-entity';
import {Reply} from '../../../entities/reply-entity';
import {User} from '../../../entities/user-entity';
import {AnswerStatus} from '../../../enums/answer-enum';
import useMessage from '../../../redux/hooks/useMessage';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Path} from '../../../router/Routes';
import AnswerService from '../../../services/answer-service';
import QuestionService from '../../../services/question-service';
import ReplyService from '../../../services/reply-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {hasLength, isDefAndNotNull} from '../../../utils/common-util';
import {copyURL, embedIdInPath} from '../../../utils/path-util';
import {Content, ID, URL} from '../../../vo/common-vo';
import {DeckActionEnum} from '../../common/swipable-card/XYSwipableDeck';
import LeftComment from './parts/LeftComment';
import QuestionDetailsCard from './parts/QuestionDetailsCard';
import QuestionHeaderCard from './parts/QuestionHeaderCard';
import QuestionPhotoCard from './parts/QuestionPhotoCard';
import RightComment from './parts/RightComment';

interface P {
  isFocus: boolean;
  isFront: boolean;
  isOpen: boolean;
  showComplete:boolean;
  user: User;
  question: Question;
  bind: (id: ID) => ReactEventHandlers;
  onAction(action: DeckActionEnum): void;
}

const QuestionCard: React.FC<P> = React.memo(props => {
  const { isFocus, isFront, isOpen, user, question: initQuestion, bind, onAction } = props;
  const unmountRef = useUnmountRef();
  const { path, openPath, replacePath } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const { sendRequest, subscribeResponse, unsubscribeResponse } = useMessage();
  const prevIsOpen = useRef<boolean | undefined>(undefined);
  const isAskingUser = useMemo<boolean>(() => user.userId === initQuestion.user.userId, [user, initQuestion]);
  const community = useMemo<Community>(() => initQuestion.communities[0], [initQuestion]);
  const [question, setQuestion] = useSafeState<Question>(unmountRef, initQuestion);
  // const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [answers, setAnswers] = useSafeState<Answer[]>(unmountRef, initQuestion.answers);

  const saveNewReply = useSafeCallback((
    prevAnswer: Answer,
    content: Content,
    photoURL: URL,
  ): Answer => {
    const reply = toReply(
      ReplyService.getReplyId(),
      prevAnswer,
      content,
      photoURL,
      user,
    );

    ReplyService.saveReply(reply);

    const newReply = { ...reply, createdAt: new Date() };
    const newReplies = [ ...prevAnswer.replies!, newReply ];
    const newAnswer = { ...prevAnswer, replies: newReplies };
    return newAnswer;
  }, [user]);

  const saveAnswerAndShowRealName = useSafeCallback(async (newAnswer: Answer) => {
    await AnswerService.saveAnswer(newAnswer);

    if (question.user.isAnonymous) {
      const updatedQuestion = await QuestionService.fetchQuestion(question.questionId);
      setQuestion(updatedQuestion!);
    }
  }, [setQuestion]);

  const saveNewAnswer = useSafeCallback((
    question: Question,
    content: Content,
    photoURL: URL,
  ): Answer => {
    const newAnswer = toAnswer(
      AnswerService.getAnswerId(),
      question,
      AnswerStatus.ANSWERED,
      content,
      photoURL,
      user,
    );

    saveAnswerAndShowRealName(newAnswer);
    return newAnswer;
  }, [user, saveAnswerAndShowRealName]);

  const handleResponseSubmitted = useSafeCallback((
    event: Event
  ): void => {
    const input: InputMessage = event['detail'].input;
    if (!input.text && !input.photoURL) return;

    setAnswers(answers => {
      const reply: ReplyMessage | undefined = input.reply;

      const prevAnswer = !!reply
        ? answers.find(answer => answer.answerId === reply.id)
        : answers.find(answer => !!answer.user && answer.user.userId === user.userId);

      const shouldSaveNewAnswer = !prevAnswer || (isAskingUser && !reply);

      const newAnswer = shouldSaveNewAnswer
        ? saveNewAnswer(question, input.text, input.photoURL)
        : saveNewReply(prevAnswer, input.text, input.photoURL);

      return shouldSaveNewAnswer
        ? [ ...answers, newAnswer ]
        : answers.map(prevAnswer => prevAnswer.answerId === newAnswer.answerId ? newAnswer : prevAnswer);
    });
  }, [setAnswers, isAskingUser, saveNewAnswer, question, saveNewReply]);

  useEffect(() => {
    if (!isFront || isOpen === prevIsOpen.current) return;
    sendRequest(isOpen ? toInputMessage(EMPTY, EMPTY, undefined, isFocus, true) : toTabsMessage());
    isOpen ? subscribeResponse(handleResponseSubmitted) : unsubscribeResponse();

    if (isDefAndNotNull(prevIsOpen.current)
      && isDefAndNotNull(question)
      && hasLength(question.communities)) {
      const nextPath = toNextQuestionPath(
        path,
        community.communityId,
        question.questionId,
        question.user.userId,
      );
      replacePath(nextPath);
    }

    prevIsOpen.current = isOpen;
  }, [isFront, isOpen, isFocus, sendRequest, subscribeResponse, handleResponseSubmitted, unsubscribeResponse, path, community, question, replacePath]);

  const openCommunityDetailsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [openPath, community.communityId]);

  const toggleCardPosition = useSafeCallback((): void => {
    onAction(isOpen ? DeckActionEnum.MOVE_CENTER : DeckActionEnum.MOVE_TOP_AND_FOCUS_ON);
  }, [onAction, isOpen]);

  const openFooterInputBoxWithReply = useSafeCallback((
    answerId: AnswerId,
    content: Answer | Reply,
  ): void => {
    const message = toReplyMessage(answerId, content);
    sendRequest(toInputMessage(EMPTY, EMPTY, message, true));
  }, [sendRequest]);

  const openAccountScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT, [question.user.userId]));
  }, [openPath, question.user.userId]);

  const copyCommunityQuestionDetailsURL = useSafeCallback((): void => {
    copyURL(embedIdInPath(Path.COMMUNITY_QUESTION_DETAILS, [community.communityId, question.questionId]));
    enqueueSnackbar(COPY_LINK, { variant: SUCCESS });
  }, [community.communityId, question.questionId, enqueueSnackbar]);

  // const markAsCompleted = useSafeCallback(async (): Promise<void> => {
  //   setSaving(true);

  //   const answer = toAnswer(
  //     AnswerService.getAnswerId(),
  //     question,
  //     AnswerStatus.COMPLETED,
  //     EMPTY,
  //     user,
  //   );

  //   await AnswerService.saveAnswer(answer);

  //   openPath(toNextQuestionPath(
  //     path,
  //     community.communityId,
  //     question.questionId,
  //     user.userId,
  //   ));
  // }, [setSaving, question, user, openPath, path, community.communityId]);

  return (
    <Component
      className="question-card"
      // loading={saving}
    >
      <Container>
        <StyledContent>
          <HeaderWrapper
            {...bind(question.questionId)}
          >
            <QuestionHeaderCard
              community={community}
              question={question}
              onClickCommunity={openCommunityDetailsScreen}
              onClickReply={toggleCardPosition}
              onClickAccount={openAccountScreen}
              onClickCopy={copyCommunityQuestionDetailsURL}
            />
          </HeaderWrapper>

          {isOpen &&
            <BodyWrapper>
              <DetailsWrapper>
                <QuestionDetailsCard
                  level={question.level}
                  details={question.details}
                />
              </DetailsWrapper>

              {!!question.photoURL &&
                <PhotoWrapper>
                  <QuestionPhotoCard
                    photoURL={question.photoURL}
                  />
                </PhotoWrapper>
              }

              <CommentsWrapper>
                {answers.map((answer, index) =>
                  <CommentWrapper key={index}>
                    {answer.status === AnswerStatus.ANSWERED &&
                      <AnswerWrapper>
                        {question.user.userId === answer.user.userId
                          ? <LeftComment
                              showReply={isAskingUser && !hasLength(answer.replies!)}
                              content={answer.content}
                              photoURL={answer.photoURL}
                              user={answer.user}
                              createdAt={answer.createdAt!}
                              onReply={() => openFooterInputBoxWithReply(answer.answerId, answer)}
                            />
                          : <RightComment
                              showReply={isAskingUser && !hasLength(answer.replies!)}
                              content={answer.content}
                              photoURL={answer.photoURL}
                              user={answer.user}
                              createdAt={answer.createdAt!}
                              onReply={() => openFooterInputBoxWithReply(answer.answerId, answer)}
                            />
                        }
                      </AnswerWrapper>
                    }

                    {!!answer.replies
                      && hasLength(answer.replies)
                      && answer.replies.map((reply, index) =>
                      <ReplyWrapper key={index}>
                        {question.user.userId === reply.user.userId
                          ? <LeftComment
                              showReply={isAskingUser && answer.replies!.length === index + 1}
                              content={reply.content}
                              photoURL={reply.photoURL}
                              user={reply.user}
                              createdAt={reply.createdAt!}
                              onReply={() => openFooterInputBoxWithReply(answer.answerId, reply)}
                            />
                          : <RightComment
                              showReply={isAskingUser && answer.replies!.length === index + 1}
                              content={reply.content}
                              photoURL={reply.photoURL}
                              user={reply.user}
                              createdAt={reply.createdAt!}
                              onReply={() => openFooterInputBoxWithReply(answer.answerId, reply)}
                            />
                        }
                      </ReplyWrapper>
                    )}
                  </ CommentWrapper>
                )}
              </CommentsWrapper>

              {/* {showComplete &&
                <ButtonArea>
                  <ButtonWrapper>
                    <Button
                      type='primary'
                      label='回答を完了する'
                      onClick={markAsCompleted}
                    />              
                  </ButtonWrapper>
                </ButtonArea>            
              } */}

              <Tips
                message="相談に回答すると誰の相談か見ることができ、相談者と１対１でメッセージを送ることができます。"
              />
            </BodyWrapper>
          }
        </StyledContent>
      </Container>
    </Component>
  );
});

export default QuestionCard;

const Container = styled.div`
  width: calc(100vw - ${theme.mixins.spacing * 4}px);
  max-width: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  height: auto;
`;

const StyledContent = styled.div`
  width: 100%;
  height: auto;
`;

const HeaderWrapper = styled(animated.div)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: ${theme.mixins.spacing}px;
`;

const BodyWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 35}px;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const PhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const CommentsWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const CommentWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  padding-top: ${theme.mixins.spacing * 4}px;
`;

const AnswerWrapper = styled.div``;

const ReplyWrapper = styled.div``;

// const ButtonArea = styled.div`
//   width: 100%;
//   height: auto;
//   display: flex;
//   justify-content: center;
//   padding: ${theme.mixins.spacing * 2}px 0px;
//   margin-bottom: ${theme.mixins.spacing * 40}px;
// `;

// const ButtonWrapper = styled.div`
//   width: 168px;
//   height: auto;
// `;