import {v4 as uuid} from 'uuid';
import {SAVE_COMMUNITY_MEMBERS} from '../constants/request-const';
import {toSaveCommunityMembersRequest} from '../converters/request-converter';
import {CommunityId} from '../entities/community-entity';
import {CommunityMember, CommunityMemberId} from '../entities/community-member-entity';
import {User} from '../entities/user-entity';
import {ErrorCodeEnum, ErrorItemEnum} from '../enums/error-message-enum';
import {functions} from '../firebase';
import {isDefAndNotNull} from '../utils/common-util';
import {SaveCommunityMembersRequest} from '../vo/request-vo';

export default class CommunityMemberService {
  public static getCommunityMemberId = (): CommunityMemberId => uuid();

  public static saveCommunityMembers = async (
    communityId: CommunityId,
    members: CommunityMember[],
    editor: User,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
    const request: SaveCommunityMembersRequest =
      toSaveCommunityMembersRequest(communityId, members, editor);
  
    const func = functions.httpsCallable(SAVE_COMMUNITY_MEMBERS);
    func(request)
      .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
      .catch((error) => reject(error));
    });
  };
}
