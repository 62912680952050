import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import firebase from '../../../firebase';
import {theme} from '../../../styles/theme';
import miccca from './../../../assets/miccca.png';
import EmailSignIn from './parts/EmailSignIn';
import GoogleSignIn from './parts/GoogleSignIn';
import LineSignIn from './parts/LineSignIn';
import Remarks from './parts/Remarks';

interface P {
  signIn(res: firebase.auth.UserCredential): void;
  reset(): void;
}

const SignIn: React.FC<P> = React.memo(props => {
  const { signIn, reset } = props;

  return (
    <Component className="sign-in">
      <Container>
        <Content>
          <LogoWrapper>
            <Logo
              src={miccca}
            />

            <CatchCopy>
              ミッカでやってみっか
            </CatchCopy>
          </LogoWrapper>
          
          <EmailSignIn
            signIn={signIn}
            reset={reset}
          />
          
          <GoogleSignIn />

          <LineSignIn />

          <Remarks />
        </Content>
      </Container>
    </Component>
  )
});

export default SignIn;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.mixins.spacing * 6}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 4}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const CatchCopy = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;