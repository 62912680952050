import {CSSProperties} from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import Box from '../../../../components/box/Box';
import Component from '../../../../components/component/Component';
import Slider from '../../../../components/slider/Slider';
import Sentences from '../../../../components/typography/Sentences';
import {EMPTY} from '../../../../constants/common-const';
import {theme} from '../../../../styles/theme';
import {Details, Level} from '../../../../vo/common-vo';

interface P {
  level: Level;
  details: Details | undefined;
}

const QuestionDetailsCard: React.FC<P> = React.memo(props => {
  const { level, details = EMPTY } = props;

  return (
    <Component className="question-details-card">
      <Container>
        <Content>
          <Box>
            <SlideWrapper>
              <Slider
                disabled
                rightLabel="ガチめ"
                leftLabel="ユルめ"
                value={level}
              />
            </SlideWrapper>

            <Sentences
              style={textStyle}
              content={details}
            />
          </Box>
        </Content>
      </Container>
    </Component>
  );
});

export default QuestionDetailsCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const SlideWrapper = styled.div`
  margin: ${theme.mixins.spacing * 2}px 0px;
`;

const textStyle: CSSProperties = {
  color: theme.mixins.typography.fontColor.gray2,
  fontSize: theme.mixins.typography.fontSize.fourteen,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds as number,
  fontFamily: theme.mixins.typography.fontFamily,
  whiteSpace: 'pre-wrap',
};