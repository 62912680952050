import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../components/box/EditableBox';
import Component from '../../../components/component/Component';
import {Affiliation as AffiliationVo} from '../../../entities/affiliation-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import {Index, Name} from '../../../vo/common-vo';

interface P {
  index: Index;
  affiliation: AffiliationVo;
  onChange(affiliation: AffiliationVo): void;
}

const Affiliation: React.FC<P> = React.memo(props => {
  const { index, affiliation: initAffiliation, onChange } = props;
  const unmountRef = useUnmountRef();
  const [affiliation, setAffiliation] = useSafeState<AffiliationVo>(unmountRef, initAffiliation);

  const handleAffiliationChanged = useSafeCallback((name: Name): void => {
    setAffiliation(affiliation => {
      const newAffiliation = { ...affiliation, name };
      onChange(newAffiliation);
      return newAffiliation;
    });
  }, [setAffiliation, onChange]);

  return (
    <Component className="affiliation">
      <Container>
        <Content>
          <EditableBox
            editable
            type="text"
            placeholder={`所属している会社やコミュニティ${index + 1}`}
            value={affiliation.name}
            onChange={handleAffiliationChanged}
          />
        </Content>
      </Container>
    </Component>
  )
});

export default Affiliation;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;