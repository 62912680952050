import {User, UserAction} from '../../entities/user-entity';
import {UserActionEnum} from '../../enums/user-enum';

const userReducer = (user: User, action: UserAction): User | null => {
  if (user === undefined) return null;

  switch (action.type) {
    case UserActionEnum.SET:
      return action.user;
      
    case UserActionEnum.PURGE:
      return null;

    default:
      return user;
  }
};

export default userReducer;
