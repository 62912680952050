import {Button} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Component from '../component/Component';
import Icon from '../icon/Icon';
import mono from './../../assets/icon/icon_check_circle.png';
import color from './../../assets/icon/icon_check_circle_color.png';

interface P {
  disabled?: boolean;
  checked: boolean;
  onClick(): void;
}

const CheckButton: React.FC<P> = React.memo(props => {
  const { disabled = false, checked, onClick } = props;

  return (
    <Component className="check-button">
      <ToggleButton
        disableRipple
        disabled={disabled}
        onClick={onClick}
      >
        {checked
          ? <Icon size="medium" src={color} />
          : <Icon size="medium" src={mono} />
        }
      </ToggleButton>
    </Component>
  );
});

export default CheckButton;

const ToggleButton = styled(Button)`
  width: 40px;
  min-width: 40px;
  height: 40px;
`;