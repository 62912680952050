import {useCallback, useEffect, useRef} from 'react';
import {Params, QueryParams} from '../../entities/common-entity';
import {Path} from '../../router/Routes';
import {getParams, getPath, getQueryParams} from '../../utils/path-util';
import {PathName} from '../../vo/common-vo';
import {toPreviousURLAction} from '../actions/previous-url-action';
import store from '../store';
import useSafeCallback from './useSafeCallback';
import useSafeState from './useSafeState';
import useUnmountRef from './useUnmountRef';

const config: MutationObserverInit = {
  childList: true,
  attributes: true,
  characterData: true,
  subtree: true,
};

function usePath() {
  const unmountRef = useUnmountRef();
  const prevPath = useRef<PathName>(store.getState().previousURL);
  const currentPath = useRef<PathName>(window.location.pathname);
  const [path, setPath] = useSafeState<Path>(unmountRef, getPath(currentPath.current));
  const [params, setParams] = useSafeState<Params>(unmountRef, getParams(window.location.pathname));
  const [queryParams, setQueryParams] = useSafeState<QueryParams>(unmountRef, getQueryParams(window.location.search));

  const updatePathInfo = useCallback((): void => {
    const nextPath = window.location.pathname;
    if (nextPath === currentPath.current) return;
  
    const action = toPreviousURLAction(currentPath.current);
    store.dispatch(action);
    
    currentPath.current = nextPath;
    setPath(getPath(nextPath));

    const nextParams = getParams(window.location.pathname);
    setParams(nextParams);

    const nextQueryParams = getQueryParams(window.location.search);
    setQueryParams(nextQueryParams);
  }, [setPath, setParams, setQueryParams]);

  useEffect(() => {
    const observer = new MutationObserver(updatePathInfo);
    const root = document.getElementById('root')!;
    observer.observe(root, config);
    return () => observer.disconnect();
  }, [updatePathInfo]);

  const openPath = useSafeCallback((path: Path): void => {
    if (!path) return;
    const action = toPreviousURLAction(currentPath.current);
    store.dispatch(action);
    window.location.href = `${window.location.origin}${path}`;
  } , []);

  const openPathInNewTab = useSafeCallback((path: Path): void => {
    if (!path) return;
    window.open(`${window.location.origin}${path}`, 'newtab');
  } , []);

  const replacePath = useSafeCallback((path: Path): void => {
    if (!path) return;
    setPath(path);
    window.history.replaceState('', '', path);
  } , [setPath]);

  const goBack = useSafeCallback((): void => {
    openPath(prevPath.current ? prevPath.current : Path.QUESTIONS); // FIXME
  } , [openPath]);

  return {
    path,
    params,
    queryParams,
    openPath,
    openPathInNewTab,
    replacePath,
    goBack,
  };
}

export default usePath;
