import {Community} from '../entities/community-entity';
import {Question, QuestionId} from '../entities/question-entity';
import {User} from '../entities/user-entity';
import {QuestionStatus} from '../enums/question-enum';
import {Details, Level, Overview, URL} from '../vo/common-vo';

export const toQuestion = (
  questionId: QuestionId,
  communities: Community[],
  status: QuestionStatus,
  level: Level,
  overview: Overview,
  user: User,
  details?: Details,
  photoURL?: URL,
  due?: Date,
): Question => {
  return {
    questionId,
    communities,
    status,
    level,
    overview,
    details,
    photoURL,
    due,
    user,
  };
};