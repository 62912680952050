import {Badge, createStyles, Tab as MuiTab, Tabs as MuiTabs, Theme, withStyles} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {findIndex} from '../../utils/common-util';
import {Index} from '../../vo/common-vo';
import Component from '../component/Component';
import {ID_CONST} from './../../constants/common-const';

const constructBadge = (icon: React.ReactElement): React.ReactElement => {
  return (
    <Badge color="primary" variant='dot'>
      {icon}
    </Badge>
  );
}

export interface IconTab {
  id: number;
  showBadge?: boolean;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

interface P {
  selected: IconTab;
  tabs: IconTab[];
  onChange(tab: IconTab): void;
}

const IconTabs: React.FC<P> = React.memo(props => {
  const { selected, tabs, onChange } = props;
  const unmountRef = useUnmountRef();
  const [tabIndex, setTabIndex] = useSafeState<Index>(unmountRef, findIndex<IconTab>(tabs, ID_CONST, selected.id));

  useEffect(() => {
    setTabIndex(findIndex<IconTab>(tabs, ID_CONST, selected.id));
  }, [setTabIndex, tabs, selected.id]);

  const handleTabChange = useSafeCallback((
    event: React.ChangeEvent<{}>,
    tabIndex: Index,
  ) => {
    setTabIndex(tabIndex);
    onChange(tabs[tabIndex]);
  }, [setTabIndex, onChange, tabs]);

  return (
    <Component className="icon-tabs">
      <Container>
        <Content>
          <Tabs
            variant="fullWidth"
            value={tabIndex}
            onChange={handleTabChange}
          >
            {tabs.map((tab, index) =>
              <Tab
                key={index}
                disableRipple
                style={{
                  width: `calc((100% - ${theme.mixins.spacing * 4}px) / ${tabs.length})`,
                  maxWidth: `${(MOBILE_MAX_WIDTH - theme.mixins.spacing * 4) / tabs.length}px`,
                  minWidth: `${(MOBILE_MIN_WIDTH - theme.mixins.spacing * 4) / tabs.length}px`,
                }}
                icon={
                  tab.showBadge
                    ? constructBadge(tab.icon)
                    : tab.icon
                }
              />)
            }
          </Tabs>
        </Content>
      </Container>
    </Component>
  );
});

export default IconTabs;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

interface TabsProps {
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  value: Index;
  onChange(event: React.ChangeEvent<{}>, tabIndex: Index);
}

const Tabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% - ${theme.mixins.spacing * 4}px)`,
      maxWidth: `${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px`,
      minWidth: `${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px`,
      borderRadius: 24,
      backgroundColor: theme.mixins.background.white,
      boxShadow: 'rgb(0 0 0 / 15%) 0px 4px 4px',
    },
    indicator: {
      display: 'none',
    },
  }),
)((props: TabsProps) => <MuiTabs {...props} />);

interface TabProps {
  disableRipple: boolean;
  style: CSSProperties;
  icon: React.ReactElement;
}

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {      
      '&:focus': {
        filter: 'invert(61%) sepia(70%) saturate(1108%) hue-rotate(315deg) brightness(94%) contrast(113%)',
      },
    },
    selected: {
      filter: 'invert(61%) sepia(70%) saturate(1108%) hue-rotate(315deg) brightness(94%) contrast(113%)',
    },
  }),
)((props: TabProps) => <MuiTab {...props} />);