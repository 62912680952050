
import {Typography} from '@material-ui/core';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CommentBox from '../../../components/box/CommentBox';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {Announce} from '../../../entities/announce-entity';
import {Notification} from '../../../entities/notification-entity';
import {theme} from '../../../styles/theme';
import {noop} from '../../../utils/common-util';
import {toDateTime} from '../../../utils/date-util';
import clock from './../../../assets/icon/icon_clock_gray.png';

interface P {
  notification: Notification;
}

const AnnounceNotificationCard: React.FC<P> = React.memo(props => {
  const { notification } = props;
  const announce = useMemo<Announce>(() =>
    notification.announce!, [notification.announce]);

  return (
    <Component className="announce-notification-card">
      <Container>
        <Content>
          <CommentBox
            rectPhoto={announce.photoURL}
            onClickRectPhoto={noop}
          >
            <Wrapper>
              <Title>
                {announce.title}
              </Title>

              <Message>
                {announce.content}
              </Message>

              <FooterWrapper>
                <DatetimeWrapper>
                  <Icon
                    size="xsmall"
                    src={clock}
                  />
                  <Datetime>
                    {toDateTime(notification.createdAt!)}
                  </Datetime>
                </DatetimeWrapper>
              </FooterWrapper>
            </Wrapper>
          </CommentBox>
        </Content>
      </Container>
    </Component>
  );
});

export default AnnounceNotificationCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Title = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DatetimeWrapper = styled.div`
  color: ${theme.mixins.typography.fontColor.gray5};
  display: flex;
  align-items: center;
`;

const Datetime = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin-left: ${theme.mixins.spacing / 2}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;