import {IconButton as MuiIconButton, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {ID, Label, Source, URL} from '../../vo/common-vo';
import Component from '../component/Component';
import Icon from '../icon/Icon';

export interface IconData {
  id: ID;
  disabled: boolean;
  src: Source;
}

interface P {
  photoURL: URL;
  label: Label;
  icons: IconData[];
  onClick(icon: IconData): void;
}

const CardWithIcons: React.FC<P> = React.memo(props => {
  const { photoURL, label, icons, onClick } = props;

  return (
    <Component className="card-with-icons">
      <Container>
        <Content>
          <Photo
            src={photoURL}
          >              
            <Body>
              <StyledLabel>
                {label}
              </StyledLabel>

              <FooterArea>
                {icons.map((icon, index) =>
                  <IconButton
                    key={index}
                    disableRipple
                    disabled={icon.disabled}
                    onClick={() => onClick(icon)}
                  >
                    <Icon
                      size="small"
                      disabled={icon.disabled}
                      src={icon.src}
                    />
                  </IconButton>
                )}
              </FooterArea>
            </Body>
          </Photo>
        </Content>
      </Container>
    </Component>
  );
});

export default CardWithIcons;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px 0px;
`;

const Photo = styled.div<{ src: URL }>`
  width: calc(100vw - ${theme.mixins.spacing * 4}px);
  min-width: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  max-width: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  height: calc(100vw - ${theme.mixins.spacing * 4}px);
  min-height: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  max-height: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  background-color: ${theme.mixins.background.white};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 60%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${props => props.src});
  border-radius: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
`;

const Body = styled.div``;

const StyledLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  user-select:none;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.black};
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: space-around;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 2}px;
`;

const IconButton = styled(MuiIconButton)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: ${theme.mixins.typography.fontColor.white};
  background: ${theme.mixins.background.black} !important;

  .MuiIconButton-label {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;  
  }
`;