import {CSSProperties} from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {URL} from '../../vo/common-vo';
import Component from '../component/Component';
import ImageWithSkeleton from '../image/ImageWithSkeleton';

const IMG_SIZE = 64;

export type PhotoPosition = 'right' | 'left';

interface P {
  position?: PhotoPosition;
  circlePhoto?: URL;
  rectPhoto?: URL;
  children: React.ReactNode;
  onClickCirclePhoto?(): void;
  onClickRectPhoto?(): void;
}

const CommentBox: React.FC<P> = React.memo(props => {
  const {
    position = 'left',
    circlePhoto,
    rectPhoto,
    children,
    onClickCirclePhoto,
    onClickRectPhoto,
  } = props;

  return (
    <Component
      style={{ width: '100%' }}
      className="comment-box"
    >
      <Container>
        <Content>
          {position === 'left' &&
            <PhotoWrapper>
              {!!circlePhoto &&
                <ImageWithSkeleton
                  animation='wave'
                  variant='circle'
                  style={styleForCirclePhoto(position)}
                  src={circlePhoto}
                  onClick={() => !!onClickCirclePhoto && onClickCirclePhoto()}
                />          
              }
              {!!rectPhoto &&
                <ImageWithSkeleton
                  animation='wave'
                  variant='rect'
                  style={styleForRectPhoto(position)}
                  src={rectPhoto}
                  onClick={() => !!onClickRectPhoto && onClickRectPhoto()}
                />
              }
            </PhotoWrapper>
          }

          <Box
            position={position}
          >
            {children}
          </Box>

          {position === 'right' &&
            <PhotoWrapper>
              {!!circlePhoto &&
                <ImageWithSkeleton
                  animation='wave'
                  variant='circle'
                  style={styleForCirclePhoto(position)}
                  src={circlePhoto}
                  onClick={() => !!onClickCirclePhoto && onClickCirclePhoto()}
                />          
              }
              {!!rectPhoto &&
                <ImageWithSkeleton
                  animation='wave'
                  variant='rect'
                  style={styleForRectPhoto(position)}
                  src={rectPhoto}
                  onClick={() => !!onClickRectPhoto && onClickRectPhoto()}
                />          
              }
            </PhotoWrapper>
          }
        </Content>
      </Container>
    </Component>
  );
});

export default CommentBox;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

const PhotoWrapper = styled.div`
  z-index: 10;
`;

const styleForCirclePhoto = (position: PhotoPosition): CSSProperties => {
  return {
    width: `${IMG_SIZE}px`,
    minWidth: `${IMG_SIZE}px`,
    height: `${IMG_SIZE}px`,
    borderRadius: '50%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    objectFit: 'cover',
    marginLeft: position === 'right' ? theme.mixins.spacing * -1 : 0,
    zIndex: 10,
  };
}

const styleForRectPhoto = (position: PhotoPosition): CSSProperties => {
  return {
    width: `${IMG_SIZE}px`,
    minWidth: `${IMG_SIZE}px`,
    height: `${IMG_SIZE}px`,
    borderRadius: 8,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    objectFit: 'cover',
    marginTop: `-${theme.mixins.spacing}px`,
    marginLeft: position === 'right' ? theme.mixins.spacing * -1 : 0,
  };
}

const Box = styled.div<{ position: PhotoPosition }>`
  width: calc(100% - ${IMG_SIZE}px);
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 16px;
  margin-left: ${props => props.position === 'left'
    ? theme.mixins.spacing * -1 : 0}px;
`;