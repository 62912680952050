import React, {useEffect, useMemo} from 'react';
import {ReactEventHandlers} from 'react-use-gesture/dist/types';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {Question, QuestionId} from '../../../entities/question-entity';
import {User} from '../../../entities/user-entity';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {isEmpty} from '../../../utils/common-util';
import {isQuestionListView} from '../../../utils/path-util';
import {CardData, CardOptions} from '../../common/swipable-card/XYSwipableCard';
import XYSwipableDeck, {CardDeckRef, DeckActionEnum, PositionEnum} from '../../common/swipable-card/XYSwipableDeck';
import GuideModal from '../guide-modal/GuideModal';
import AllQuestionsConfirmed from '../no-question/AllQuestionsConfirmed';
import QuestionCard from './QuestionCard';

const toNode = (
  showComplete: boolean,
  user: User,
  question: Question,
) => (
  isFocus: boolean,
  isFront: boolean,
  isOpen: boolean,
  bind: (questionId: QuestionId) => ReactEventHandlers,
  onAction: (action: DeckActionEnum) => void,
): React.ReactNode => {
  return (
    <QuestionCard
      isFocus={isFocus}
      isFront={isFront}
      isOpen={isOpen}
      showComplete={showComplete}
      user={user}
      question={question}
      bind={bind}
      onAction={onAction}
    />
  );
}

interface P {
  showComplete: boolean;
  user: User;
  options: CardOptions;
  questions: Question[];
  onMove?(position: PositionEnum): void;
  onClickRight?(questionId: QuestionId): void;
  onClickLeft?(questionId: QuestionId): void;
  onClickReflesh?(): void;
}

const QuestionCards: React.ForwardRefExoticComponent<P & React.RefAttributes<CardDeckRef>> = React.forwardRef<CardDeckRef, P>((props, ref) => {
  const {
    showComplete,
    user,
    options,
    questions,
    onMove,
    onClickRight,
    onClickLeft,
    onClickReflesh,
  } = props;

  const unmountRef = useUnmountRef();
  const { path, params } = usePath();
  const questionId = useMemo<QuestionId>(() => params.questionId!, [params.questionId]);
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [allConfirmed, setAllConfirmed] = useSafeState<boolean>(unmountRef, false);
  const [cardDataList, setCardDataList] = useSafeState<CardData[]>(unmountRef, []);

  const moveOpeningQuestionToFront = useSafeCallback((): Question[] => {
    const question = questions.find(q => q.questionId === questionId);
    if (!question) return questions;
    return [question, ...questions.filter(q => q.questionId !== questionId)];
  }, [questions, questionId]);

  const initialize = useSafeCallback((): void => {
    const cards = moveOpeningQuestionToFront()
      .map((question, propsIndex) => {
      return {
        id: question.questionId,
        propsIndex,
        visible: isEmpty(questionId) || question.questionId === questionId,
        position: question.questionId === questionId ? PositionEnum.TOP : PositionEnum.CENTER,
        node: toNode(showComplete, user, question),
      };
    });
    
    setCardDataList(cards);
    setLoaded(true);
  }, [moveOpeningQuestionToFront, questionId, showComplete, user, setCardDataList, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!isQuestionListView(path)) return;
    !!(ref as React.RefObject<CardDeckRef>).current
      && (ref as React.RefObject<CardDeckRef>).current!.moveCard(PositionEnum.CENTER);
  }, [path, ref]);

  return (
    <Component
      loading={!loaded}
      className="question-cards"
    >
      <Container>
        <Content>
          {!allConfirmed &&
            <XYSwipableDeck
              ref={ref}
              cardOptions={options}
              cardDataList={cardDataList}
              onMove={onMove}
              onClickRight={onClickRight}
              onClickLeft={onClickLeft}
              onReachEnd={() => setAllConfirmed(true)}
            />
          }

          {allConfirmed &&
            <AllQuestionsConfirmed
              onAnswerMore={() => !!onClickReflesh && onClickReflesh()}
            />
          }
        </Content>

        <GuideModal
          user={user}
          questions={questions}
        />
      </Container>
    </Component>
  );
});

export default QuestionCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  top: 56px;
  left: 0px;
`;