import React, {useEffect} from 'react';
import styled from 'styled-components';
import AuthGuard from '../../../auth/AuthGuard';
import Modal from '../../../components/modal/Modal';
import {ZERO} from '../../../constants/common-const';
import {User} from '../../../entities/user-entity';
import {NotificationCategory} from '../../../enums/notification-enum';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import NotificationService from '../../../services/notification-service';
import {theme} from '../../../styles/theme';
import {isToday} from '../../../utils/date-util';
import NotificationList from '../../notifications/notification-list/NotificationList';

const NotificationModal: React.FC = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { getUser, updateUser } = useUser();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initilize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();
    if (!!user.confirmedAt && isToday(user.confirmedAt)) return;

    const userToBeUpdated = { ...user, confirmedAt: new Date() };
    const [updatedUser, count] = await Promise.all([
      updateUser(userToBeUpdated),
      NotificationService.countUnconfirmedNotifications(user.userId, NotificationCategory.ANNOUNCE)
    ]);

    setUser(updatedUser);
    setIsModalOpen(count > ZERO);
  }, [getUser, updateUser, setUser, setIsModalOpen]);

  useEffect(() => {
    initilize();
  }, [initilize]);
  
  return (
    <Modal
      isOpen={isModalOpen}
      label={'新着の通知'}
      onClose={() => setIsModalOpen(false)}
    >
      <Content>
        <NotificationList
          user={user}
          category={NotificationCategory.ANNOUNCE}
        />
      </Content>
    </Modal>
  );
});

export default AuthGuard(NotificationModal);

const Content = styled.div`
  width: 100%;
  height: ${window.innerHeight}px;
  padding: ${theme.mixins.spacing}px 0px ${theme.mixins.spacing * 8}px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;