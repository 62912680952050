import {Button, Typography} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {User} from '../../../entities/user-entity';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import {theme} from '../../../styles/theme';
import {embedIdInPath} from '../../../utils/path-util';

interface P {
  user: User;
}

const Questions: React.FC<P> = React.memo(props => {
  const { user } = props;
  const { openPath } = usePath();

  const openAccountQuestionsScreen = useSafeCallback(() => {
    openPath(embedIdInPath(Path.ACCOUNT_QUESTIONS, [user.userId]));
  }, [openPath, user.userId]);

  return (
    <Component className="questions">
      <Container>
        <Content>
          <ButtonWrapper
            disableRipple
            onClick={openAccountQuestionsScreen}
          >
            <Label>相談一覧</Label>
            <Icon fontSize="small" />
          </ButtonWrapper>
       </Content>
      </Container>
    </Component>
  );
});

export default Questions;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled(Button)`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white} !important;
  border-radius: 16px;
  padding: ${theme.mixins.spacing * 2}px;

  .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }
`;

const Label = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Icon = styled(ArrowForwardIosIcon)``;