import {Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import CommentBox from '../../../../components/box/CommentBox';
import Component from '../../../../components/component/Component';
import Icon from '../../../../components/icon/Icon';
import Sentences from '../../../../components/typography/Sentences';
import {User} from '../../../../entities/user-entity';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {Path} from '../../../../router/Routes';
import {theme} from '../../../../styles/theme';
import {toDateTime} from '../../../../utils/date-util';
import {embedIdInPath} from '../../../../utils/path-util';
import {toFullName} from '../../../../utils/user-util';
import {Content, CreatedAt, URL} from '../../../../vo/common-vo';
import reply from './../../../../assets/icon/icon_reply_black.png';

interface P {
  showReply: boolean;
  content: Content;
  photoURL: URL;
  user: User;
  createdAt: CreatedAt;
  onReply(): void;
}

const LeftComment: React.FC<P> = React.memo(props => {
  const { showReply = false, content, photoURL, user, createdAt, onReply } = props;
  const { openPath } = usePath();

  const openAccountScreen = useSafeCallback((): void => {
    if (user.isAnonymous) return;
    openPath(embedIdInPath(Path.ACCOUNT, [user.userId]));
  }, [openPath, user]);

  return (
    <Component className="left-comment">
      <Container>
        <StyledContent>
          <CommentBox
            circlePhoto={user.photoURL}
            onClickCirclePhoto={openAccountScreen}
          >
            <CommentWrapper>
              <UserName>
                {toFullName(user)}
              </UserName>

              <Sentences
                style={commentStyle}
                content={content}
              />

              <Photo
                src={photoURL}
              />
              
              <Footer>
                <CommentDate>
                  {toDateTime(createdAt)}
                </CommentDate>
                
                {showReply &&
                  <ReplyButton
                    disableRipple
                    onClick={onReply}
                  >
                    <Icon size="small" src={reply} />
                    <Label>ここに返信</Label>
                  </ReplyButton>              
                }
              </Footer>
            </CommentWrapper>
          </CommentBox>
        </StyledContent>
      </Container>
    </Component>
  );
});

export default LeftComment;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const StyledContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin: ${theme.mixins.spacing / 2}px 0px;
`;

const CommentWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const UserName = styled(Typography)`
  width: 100%;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  line-height: 32px;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
`;

const commentStyle: CSSProperties = {
  width: '100%',
  color: theme.mixins.typography.fontColor.gray2,
  fontSize: theme.mixins.typography.fontSize.fourteen,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds as number,
  fontFamily: theme.mixins.typography.fontFamily,
  whiteSpace: 'pre-wrap',
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CommentDate = styled(Typography)`
  width: calc(100% - 56px);
  color: ${theme.mixins.typography.fontColor.gray2};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  line-height: 32px;
  user-select: none;
`;

const ReplyButton = styled(IconButton)`
  width: 56px;
  height: 48px;
  background: ${theme.mixins.background.white} !important;

  .MuiIconButton-label {
    display: flex;
    flex-flow: column;
  }
`;

const Label = styled(Typography)`
  width: 56px;
  color: ${theme.mixins.typography.fontColor.gray4};
  font-size: ${theme.mixins.typography.fontSize.ten}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;