import {Community, CommunityId} from '../entities/community-entity';
import {CommunityStatus} from '../enums/community-enum';
import {Description, Name, URL} from '../vo/common-vo';

export const toCommunity = (
  communityId: CommunityId,
  isPinned: boolean,
  status: CommunityStatus,
  name: Name,
  description: Description,
  photoURL: URL,
  due?: Date,
): Community => {
  return {
    communityId,
    isPinned,
    status,
    name,
    description,
    photoURL,
    due,
  }
};