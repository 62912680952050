import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Message} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  message: Message;
}

const Tips: React.FC<P> = React.memo(props => {
  const { message } = props;

  return (
    <Component className="tips">
      <Container>
        <Content>
          <Label>
            TIPS
          </Label>

          <StyledMessage>
            {message}
          </StyledMessage>
        </Content>
      </Container>
    </Component>
  );
});

export default Tips;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const Label = styled(Typography)`
  width: 240px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration : underline;
  text-align: center;
  user-select: none;
`;

const StyledMessage = styled(Typography)`
  width: 240px;
  height: auto;
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: start;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  user-select: none;
`;