import {Button, Typography} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import {Community} from '../../../../entities/community-entity';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {Path} from '../../../../router/Routes';
import {theme} from '../../../../styles/theme';
import {embedIdInPath} from '../../../../utils/path-util';

interface P {
  community: Community;
}

const CommunityQuestions: React.FC<P> = React.memo(props => {
  const { community } = props;
  const { openPath } = usePath();

  const openCommunityQuestionsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.COMMUNITY_QUESTIONS, [community.communityId]));
  }, [openPath, community.communityId]);

  return (
    <Component className="community-questions">
      <Container>
        <Content>
          <ButtonWrapper
            disableRipple
            onClick={openCommunityQuestionsScreen}
          >
            <Label>このコミュニティの相談一覧</Label>
            <Icon fontSize="small" />
          </ButtonWrapper>
       </Content>
      </Container>
    </Component>
  );
});

export default CommunityQuestions;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled(Button)`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white} !important;
  border-radius: 16px;
  padding: ${theme.mixins.spacing * 2}px;

  .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }
`;

const Label = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Icon = styled(ArrowForwardIosIcon)``;