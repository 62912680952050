import {TextField} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {MAX_LENGTH} from '../../constants/common-const';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import {theme} from '../../styles/theme';
import {Label, Value} from '../../vo/common-vo';
import Component from '../component/Component';

type Type = 'text' | 'email' | 'password';

interface P {
  type?: Type;
  maxLength?: number;
  label: Label;
  value: Value;
  onChange: (value: Value) => void;
}

const InputBox: React.FC<P> = React.memo(props => {
  const {
    type = 'text',
    maxLength = MAX_LENGTH,
    label,
    value,
    onChange
  } = props;

  const handleValueChanged = useSafeCallback((value: Value) => {
    if (!!value && value.length > maxLength) return;
    onChange(value);
  }, [maxLength, onChange]);

  return (
    <Component className="input-box">
      <Container>
        <Content>
          <Input
            variant="filled"
            type={type}
            label={label}
            value={value}
            onChange={(e) => handleValueChanged(e.target.value)}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default InputBox;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Input = styled(TextField)`
  width: 100%;
  height: 56px;

  .MuiInputLabel-root {
    padding-left: 8px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiInputBase-root {
    background: none;
  }

  .MuiInputBase-input {
    background: ${theme.mixins.background.white};
    border-radius: 16px;
    padding-left: 16px;
  }

  .MuiFilledInput-underline:before {
    border: none;
  }

  .MuiFilledInput-underline:after {
    border: none;
  }
`;
