import {v4 as uuid} from 'uuid';
import {DELETE_CONNECTION, FETCH_CONNECTION, FETCH_CONNECTIONS, FETCH_CONNECTIONS_USER_IDS, SAVE_CONNECTION} from "../constants/request-const";
import {convert, convertMulti, toDeleteConnectionRequest, toFetchConnectionByUserIdsRequest, toFetchConnectionRequest, toFetchConnectionsRequest, toSaveConnectionRequest} from "../converters/request-converter";
import {Connection, ConnectionId, ConnectionPairId} from "../entities/connection-entity";
import {UserId} from "../entities/user-entity";
import {ConnectionSortEnum} from "../enums/connection-enum";
import {ErrorCodeEnum, ErrorItemEnum} from "../enums/error-message-enum";
import {functions} from "../firebase";
import {isDefAndNotNull} from "../utils/common-util";
import {Limit, Offset} from "../vo/common-vo";
import {DeleteConnectionRequest, FetchConnectionByUserIdsRequest, FetchConnectionRequest, FetchConnectionsRequest, SaveConnectionRequest} from "../vo/request-vo";

export default class ConnectionService {
  public static getConnectionId = (): ConnectionId => uuid();

  public static getConnectionPairId = (): ConnectionId => uuid();

  public static saveConnection = async (
    connection: Connection,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: SaveConnectionRequest =
        toSaveConnectionRequest(connection);
    
      const func = functions.httpsCallable(SAVE_CONNECTION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static deleteConnection = async (
    connectionPairId: ConnectionPairId,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: DeleteConnectionRequest =
        toDeleteConnectionRequest(connectionPairId);
    
      const func = functions.httpsCallable(DELETE_CONNECTION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static fetchConnection = async (
    connectionId: ConnectionId,
  ): Promise<Connection | undefined> => {
    return await new Promise((resolve, reject) => {
      const request: FetchConnectionRequest =
        toFetchConnectionRequest(connectionId);
    
      const func = functions.httpsCallable(FETCH_CONNECTION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
        .catch((error) => reject(error));
    });
  };

  public static fetchConnectionByUserIds = async (
    connectingId: UserId,
    connecterId: UserId,
  ): Promise<Connection | undefined> => {
    return await new Promise((resolve, reject) => {
      const request: FetchConnectionByUserIdsRequest =
        toFetchConnectionByUserIdsRequest(connectingId, connecterId);
    
      const func = functions.httpsCallable(FETCH_CONNECTIONS_USER_IDS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
        .catch((error) => reject(error));
    });
  };
 
  public static fetchConnections = async (
    userId: UserId,
    sort: ConnectionSortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Connection[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchConnectionsRequest =
        toFetchConnectionsRequest(userId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_CONNECTIONS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };
}
