export enum Process {
  INITIAL = 'initial',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export enum ProcessActionEnum {
  SET = 'set_process',
  PURGE = 'persist/PURGE',
}

export enum PreviousURLActionEnum {
  SET = 'set_previous_url',
  PURGE = 'persist/PURGE',
}

export enum ReservedURLActionEnum {
  SET = 'set_reserved_url',
  PURGE = 'persist/PURGE',
}