import {Button as MuiButton} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import Component from '../component/Component';

interface P {
  onClick(): void;
}

const BackButton: React.FC<P> = React.memo(props => {
  const { onClick } = props;

  return (
    <Component className="back-button">
      <Container>
        <Content>
          <Button
            disableRipple
            onClick={onClick}
          >
            <Icon />
          </Button>
        </Content>
      </Container>
    </Component>
  );
});

export default BackButton;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Button = styled(MuiButton)`
  background: ${theme.mixins.background.white} !important;
`;

const Icon = styled(ArrowBackIosIcon)`
  width: 16px;
  height: 16px;
  color: ${theme.mixins.typography.fontColor.gray5};
`;