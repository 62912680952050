import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import CommentBox from '../../../components/box/CommentBox';
import Component from '../../../components/component/Component';
import {Connection} from '../../../entities/connection-entity';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import {theme} from '../../../styles/theme';
import {noop} from '../../../utils/common-util';
import {embedIdInPath} from '../../../utils/path-util';
import {toFullName} from '../../../utils/user-util';

interface P {
  connection: Connection;
}

const ConnectionCard: React.FC<P> = React.memo(props => {
  const { connection } = props;
  const { openPath } = usePath();

  const openAccountScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT, [connection.connecting.userId]));
  }, [openPath, connection.connecting.userId]);

  return (
    <Component className="connection-card">
      <Container>
        <Content
          onClick={openAccountScreen}
        >
          <CommentBox
            circlePhoto={connection.connecting.photoURL}
            onClickCirclePhoto={noop}
          >
            <Wrapper>
              <UserName>
                {toFullName(connection.connecting)}
              </UserName>

              <Comment>
                {connection.connecting.selfIntroduction}
              </Comment>
            </Wrapper>
          </CommentBox>
        </Content>
      </Container>
    </Component>
  );
});

export default ConnectionCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const Wrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const UserName = styled(Typography)`
  width: 100%;
  height: 40px;
  border-bottom: solid 1px ${theme.mixins.palette.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Comment = styled(Typography)`
  width: 100%;
  height: 18px;
  color: ${theme.mixins.typography.fontColor.gray3};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;