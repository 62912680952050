import {DependencyList, useCallback} from 'react';
import {isAsync} from '../../utils/common-util';
import useSafeState from './useSafeState';
import useUnmountRef from './useUnmountRef';

function useSafeCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
): (...args: any[]) => any {
  const unmountRef = useUnmountRef();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, setError] = useSafeState(unmountRef);

  const wrapCallBack = useCallback((...args: any[]): any => {
    try {
      return isAsync(callback)
        ? new Promise(async (resolve) => resolve(await callback(...args)))
        : callback(...args);
    } catch (e: any) {
      setError(() => {
        throw new Error(e);
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, deps);

  return wrapCallBack;
}

export default useSafeCallback;