import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {URL} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  src: URL;
}

const PhotoBox: React.FC<P> = React.memo(props => {
  const { src } = props;

  return (
    <Component className="photo-box">
      <Container>
        <Content>
          <Box>
            <Photo
              src={src}
            />
          </Box>
        </Content>
      </Container>
    </Component>
  );
});

export default PhotoBox;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Box = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 16px;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
`;