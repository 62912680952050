export const COMMUNITY_ID = 'communityId';

export const COMMUNITY_MEMBER_ID = 'communityMemberId';

export const IDEA_ID = 'ideaId';

export const NOTIFICATION_ID = 'notificationId';

export const QUESTION_ID = 'questionId';

export const USER_ID = 'userId';

export const TOPIC_ID = 'topicId';