import {EMPTY} from '../../constants/common-const';
import {createUserWithFirebase, setFirebaseUser} from '../../converters/user-converter';
import {User, UserId} from '../../entities/user-entity';
import firebase, {analytics, auth} from '../../firebase/index';
import UserService from '../../services/user-service';
import {getProviderId} from '../../utils/user-util';
import {Email} from '../../vo/common-vo';
import useSafeCallback from './useSafeCallback';

function useUser() {
  const getFirebaseUser = useSafeCallback(async (): Promise<firebase.User | null> => {
    return await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged(firebaseUser => {
        resolve(firebaseUser);
        unsubscribe();
      });
    });
  }, []);

  const getUser = useSafeCallback(async (): Promise<User | undefined> => {
    const firebaseUser = await getFirebaseUser();
    const user = !!firebaseUser ? await UserService.fetchUserByFirebaseUserId(firebaseUser.uid) : undefined;
    !!user && analytics.setUserId(user.userId);
    return user;
  }, [getFirebaseUser]);

  const createUser = useSafeCallback(async (
    firebaseUser: firebase.User,
    email: Email,
  ): Promise<User> => {
    const providerId = getProviderId(firebaseUser);
    const newUser = createUserWithFirebase(firebaseUser, false, EMPTY, EMPTY, email, providerId);
    await UserService.saveUser(newUser);
    return newUser;
  }, []);

  const updateUser = async (
    micccaUser: User,
  ): Promise<User> => {
    await UserService.saveUser(micccaUser);
    return micccaUser;
  };

  const initUser = async (
    firebaseUser: firebase.User,
    email: Email,
  ): Promise<User> => {
    const micccaUser = await UserService.fetchUserByFirebaseUserId(firebaseUser.uid);
    const providerId = getProviderId(firebaseUser);
    const userToUpdate = !!micccaUser ? setFirebaseUser(micccaUser, firebaseUser, providerId) : undefined;
    const signInUser = !!userToUpdate
      ? await updateUser(userToUpdate)
      : await createUser(firebaseUser, email);
    return signInUser;
  };

  const existUser = useSafeCallback(async (): Promise<boolean> => {
    const firebaseUser = await getFirebaseUser();
    return !!firebaseUser;
  }, [getFirebaseUser]);

  const isSignInUser = useSafeCallback(async (userId: UserId): Promise<boolean> => {
    const micccaUser = await getUser();
    return !!micccaUser && micccaUser.userId === userId;
  }, [getUser]);

  return {
    initUser,
    updateUser,
    getUser,
    existUser,
    isSignInUser,
  };
}

export default useUser;
