import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Message} from '../../vo/common-vo';
import Component from '../component/Component';
import loading from './../../assets/miccca.gif';

interface P {
  message?: Message;
}

const Loading: React.FC<P> = React.memo(props => {
  const { message } = props;

  return (
    <Component className="loading" >
      <LoadingWrapper>
        <StyledLoading
          src={loading}
        />

        <StyledMessage>
          {message}
        </StyledMessage>
      </LoadingWrapper>
    </Component>
  );
});

export default Loading;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
`;

const StyledLoading = styled.img`
  width: 120px;
  height: 120px;
  user-select: none;
`;

const StyledMessage = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select: none;
`;