import React, {useMemo} from 'react';
import styled from 'styled-components';
import Bar from '../../../../components/bar/Bar';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import usePath from '../../../../redux/hooks/usePath';
import {Path} from '../../../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {Label} from '../../../../vo/common-vo';

interface LabelBarOption {
  label: Label;
}

const OPTIONS: { [path: string]: LabelBarOption } = {
  [Path.QUESTIONS]: {
    label: 'あなたへの相談',
  },
  [Path.COMMUNITY_EDIT]: {
    label: 'コミュニティの設定',
  },
  [Path.ACCOUNT_EDIT]: {
    label: 'アカウントの設定',
  },
};

interface P {}

const LabelBar: React.FC<P> = React.memo(() => {
  const { path } = usePath();
  const option = useMemo<LabelBarOption>(() => OPTIONS[path], [path]);

  return (
    <Component className="label-bar">
      <Container>
        <Content>
          <Bar
            label={!!option ? option.label : EMPTY}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default LabelBar;

const Container = styled.div`
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px;
  z-index: 1000;
`;

const Content = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
