import {FirebaseUser, FirebaseUserId} from '../entities/firebase-user-entity';
import {ProviderIdEnum} from '../enums/common-enum';

export const toFirebaseUser = (
  firebaseUserId: FirebaseUserId,
  providerId: ProviderIdEnum,
): FirebaseUser => {
  return {
    firebaseUserId,
    providerId,
  };
};