import {Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CommentBox from '../../../components/box/CommentBox';
import MultiButtons, {ButtonOption} from '../../../components/button/MultiButtons';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {ACCEPT_CONNECTION_REQUEST, SKIP_CONNECTION_REQUEST, SUCCESS} from '../../../constants/snackbar-const';
import {Connection} from '../../../entities/connection-entity';
import {Notification, NotificationId} from '../../../entities/notification-entity';
import {ConnectionType} from '../../../enums/connection-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import ConnectionService from '../../../services/connection-service';
import {theme} from '../../../styles/theme';
import {hasSize} from '../../../utils/common-util';
import {toDateTime} from '../../../utils/date-util';
import {embedIdInPath} from '../../../utils/path-util';
import {toFullName} from '../../../utils/user-util';
import clock from './../../../assets/icon/icon_clock_gray.png';

enum ButtonType {
  ACCEPT_CONNECTION = 'accept_connection',
  SKIP_CONNECTION = 'skip_connection',
}

const CONNECT_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.ACCEPT_CONNECTION,
    primary: true,
    color: theme.mixins.typography.fontColor.black,
    background: theme.mixins.background.orange,
    label: 'つながる',
  },
  {
    id: ButtonType.SKIP_CONNECTION,
    primary: false,
    color: theme.mixins.typography.fontColor.black,
    background: theme.mixins.background.gray4,
    label: '見送る',
  },
];

interface P {
  notification: Notification;
  onRemove(notificationId: NotificationId): void;
}

const ConnectNotificationCard: React.FC<P> = React.memo(props => {
  const { notification, onRemove } = props;
  const { openPath } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const connection = useMemo<Connection>(() =>
    notification.connection!, [notification.connection]);

  const openAccountScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT, [connection.connecting.userId]));
  }, [openPath, connection.connecting.userId]);

  const handleButtonClicked = useSafeCallback(async (option: ButtonOption): Promise<void> => {
    onRemove(notification.notificationId);

    switch (option.id) {
      case ButtonType.ACCEPT_CONNECTION:
        const connected = { ...connection, type: ConnectionType.CONNECTED };
        const connectedErrors = await ConnectionService.saveConnection(connected);
        if (!hasSize(connectedErrors)) enqueueSnackbar(ACCEPT_CONNECTION_REQUEST, { variant: SUCCESS });
        break;

      case ButtonType.SKIP_CONNECTION:
        const skipped = { ...connection, type: ConnectionType.REJECTED };
        const skippedErrors = await ConnectionService.saveConnection(skipped);
        if (!hasSize(skippedErrors)) enqueueSnackbar(SKIP_CONNECTION_REQUEST, { variant: SUCCESS });
        break;
        
      default:
        throw new Error(`${option.id} is out of target.`);
    }
  }, [onRemove, notification, connection, enqueueSnackbar]);

  return (
    <Component className="connect-notification-card">
      <Container>
        <Content>
          <CommentBox
            circlePhoto={connection.connecting.photoURL}
            onClickCirclePhoto={openAccountScreen}
          >
            <Wrapper>
              <Title>
                {toFullName(connection.connecting)}
              </Title>

              <SubTitle>
                つながり申請がきました。
              </SubTitle>

              <Message>
                {connection.message}
              </Message>

              <ConnectButtons>
                <MultiButtons
                  options={CONNECT_BUTTONS}
                  onClick={handleButtonClicked}
                />
              </ConnectButtons>

              <FooterWrapper>
                <DatetimeWrapper>
                  <Icon
                    size="xsmall"
                    src={clock}
                  />
                  <Datetime>
                    {toDateTime(notification.createdAt!)}
                  </Datetime>
                </DatetimeWrapper>
              </FooterWrapper>
            </Wrapper>
          </CommentBox>
        </Content>
      </Container>
    </Component>
  );
});

export default ConnectNotificationCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Title = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const ConnectButtons = styled.div`
  margin-bottom: ${theme.mixins.spacing}px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DatetimeWrapper = styled.div`
  color: ${theme.mixins.typography.fontColor.gray5};
  display: flex;
  align-items: center;
`;

const Datetime = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin-left: ${theme.mixins.spacing / 2}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;