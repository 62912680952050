import {FETCH_TOPIC} from "../constants/request-const";
import {convert, toFetchTopicRequest} from "../converters/request-converter";
import {Topic, TopicId} from "../entities/topic-entity";
import {UserId} from "../entities/user-entity";
import {functions} from "../firebase";
import {isDefAndNotNull} from "../utils/common-util";
import {FetchTopicRequest} from "../vo/request-vo";

export default class TopicService {
  public static fetchTopic = async (
    topicId: TopicId,
    userId: UserId,
  ): Promise<Topic | undefined> => {
    return await new Promise((resolve, reject) => {
      const request: FetchTopicRequest =
        toFetchTopicRequest(topicId, userId);
    
      const func = functions.httpsCallable(FETCH_TOPIC);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
        .catch((error) => reject(error));
    });
  };
}
