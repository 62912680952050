import {Button, Button as MuiButton, Typography} from '@material-ui/core';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ButtonOption} from '../../../../components/button/MultiButtons';
import Component from '../../../../components/component/Component';
import Icon from '../../../../components/icon/Icon';
import Tips from '../../../../components/typography/Tips';
import {toButtonsMessage, toTabsMessage} from '../../../../converters/message-converter';
import {Community} from '../../../../entities/community-entity';
import {CommunityMember} from '../../../../entities/community-member-entity';
import {ButtonMessage} from '../../../../entities/message-entity';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import {noop} from '../../../../utils/common-util';
import {CardOptions, CardRef} from '../../../common/swipable-card/XSwipableCard';
import plus from './../../../../assets/icon/icon_plus_black.png';
import SwipableMemberCards from './../../member-card/SwipableMemberCards';
import EditCommunityDescription from './EditCommunityDescription';
import EditCommunityName from './EditCommunityName';
import EditCommunityPhoto from './EditCommunityPhoto';

const OWNER_OPTIONS: CardOptions = {
  rightButton: {
    color: theme.mixins.typography.fontColor.black,
    label: 'コミュニティオーナーから外す',
    fontSize: theme.mixins.typography.fontSize.fourteen,
    background: theme.mixins.background.pink,
  },
};

const MEMBER_OPTIONS: CardOptions = {
  rightButton: {
    color: theme.mixins.typography.fontColor.black,
    label: 'コミュニティメンバーから外す',
    fontSize: theme.mixins.typography.fontSize.fourteen,
    background: theme.mixins.background.pink,
  },
  leftButton: {
    color: theme.mixins.typography.fontColor.black,
    label: 'コミュニティオーナーにする',
    fontSize: theme.mixins.typography.fontSize.fourteen,
    background: theme.mixins.background.blue,
  },
};

enum ButtonType {
  SAVE_COMMUNITY = 'save_community',
  GO_BACK = 'go_back',
}

const EDIT_COMMUNITY_INFO_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.GO_BACK,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: '戻る',
  },
  {
    id: ButtonType.SAVE_COMMUNITY,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '保存する',
  },
];

interface P {
  community: Community;
  owners: CommunityMember[];
  members: CommunityMember[];
  setHeader(community: Community): void;
  removeFromCommunityMember(member: CommunityMember): void;
  assignOwnerRole(member: CommunityMember): void;
  assignMemberRole(member: CommunityMember): void;
  addNewMembers(): void;
  leftCommunity(): void;
  saveUpdatedCommunity(): void;
  goBack(): void;
}

const EditCommunityInfo: React.ForwardRefExoticComponent<P & React.RefAttributes<CardRef>> = React.forwardRef<CardRef, P>((props, ref) => {
  const {
    community,
    owners,
    members,
    setHeader,
    removeFromCommunityMember,
    assignOwnerRole,
    assignMemberRole,
    addNewMembers,
    leftCommunity,
    saveUpdatedCommunity,
    goBack,
  } = props;

  const { sendRequest, subscribeResponse } = useMessage();

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const button: ButtonMessage = event['detail'].buttons[0];
    sendRequest(toTabsMessage());

    switch (button.id) {
      case ButtonType.SAVE_COMMUNITY:
        saveUpdatedCommunity();
        break;

      case ButtonType.GO_BACK:
        goBack();
        break;

      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [sendRequest, saveUpdatedCommunity, goBack]);

  const setupMessage = useSafeCallback((): void => {
    sendRequest(toButtonsMessage(EDIT_COMMUNITY_INFO_BUTTONS));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
  }, [setupMessage]);

  return (
    <Component className="edit-community-info">
      <Container>
        <Content>
          <EditCommunityName
            community={community}
            onChange={setHeader}
          />

          <EditCommunityPhoto
            community={community}
            onChange={setHeader}
          />

          <EditCommunityDescription
            community={community}
            onChange={setHeader}
          />

          <Label>
            オーナー
          </Label>

          <SwipableMemberCards
            ref={ref}
            options={OWNER_OPTIONS}
            members={owners}
            onClickRight={assignMemberRole}
            onClickLeft={noop}
          />

          <Label>
            メンバー
          </Label>

          <SwipableMemberCards
            ref={ref}
            options={MEMBER_OPTIONS}
            members={members}
            onClickRight={removeFromCommunityMember}
            onClickLeft={assignOwnerRole}
          />

          <ButtonWrapper>
            <AddButton
              disableRipple
              onClick={addNewMembers}
            >
              <Icon
                size="xsmall"
                src={plus}
              />

              <ButtonLabel>
                メンバーを追加
              </ButtonLabel>
            </AddButton>
          </ButtonWrapper>

          <Tips
            message="参加メンバーを左右スワイプすることでコミュニティオーナーにしたり、脱退させたりすることができます。"
          />

          <IconButtonWrapper>
            <IconButton
              disableRipple
              onClick={leftCommunity}
            >
              <StyledMeetingRoomIcon />
              <StyledLabel>
                退出する
              </StyledLabel>
            </IconButton>
          </IconButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default EditCommunityInfo;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Label = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration-line: underline;
  user-select: none;
  padding: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const AddButton = styled(MuiButton)`
  width: 200px;
  height: 56px;
  background: ${theme.mixins.background.gray4} !important;
  border-radius: 28px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
`;

const ButtonLabel = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  margin-left: ${theme.mixins.spacing / 2}px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${theme.mixins.spacing * 2}px;
`;

const IconButton = styled(Button)`
  .MuiButton-label {
    display: flex;
    flex-flow: column;  
  }
`;

const StyledMeetingRoomIcon = styled(MeetingRoomIcon)`
  width: 64px;
  height: 64px
`;

const StyledLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;