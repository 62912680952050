import {Typography} from '@material-ui/core';
import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import MultiButtons, {ButtonOption} from '../../../components/button/MultiButtons';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import Modal from '../../../components/modal/Modal';
import Tips from '../../../components/typography/Tips';
import {Community} from '../../../entities/community-entity';
import {User} from '../../../entities/user-entity';
import {CommunitySortEnum} from '../../../enums/community-enum';
import {Authority} from '../../../enums/user-enum';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import CommunityService from '../../../services/community-service';
import {theme} from '../../../styles/theme';
import {hasLength} from '../../../utils/common-util';
import CreateCommunity from '../../communities/create-community/CreateCommunity';
import CommunityCards from '../community-card/CommunityCards';
import plus from './../../../assets/icon/icon_plus_black.png';

enum ButtonType {
  INPUT_QUESTION = 'input_question',
}

const COMMUNITY_SELECT_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.INPUT_QUESTION,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '次へ',
  },
];

interface P {
  user: User,
  selected: Community[];
  onChange(community: Community): void;
  goNext(): void;
}

const SelectCommunities: React.FC<P> = React.memo(props => {
  const { user, selected, onChange, goNext } = props;
  const unmountRef = useUnmountRef();
  const disabled = useMemo(() => !hasLength(selected), [selected]);
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [communities, setCommunities] = useSafeState<Community[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setLoaded(false);

    const communities = await CommunityService.fetchCommunitiesByUserId(
      user.userId,
      CommunitySortEnum.CREATED_AT_DESC
    );
  
    setCommunities(communities);
    setLoaded(true);
  }, [setLoaded, user.userId, setCommunities]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const refreshCommunities = useSafeCallback((): void => {
    initialize();
    setIsModalOpen(false);
  }, [initialize, setIsModalOpen]);

  return (
    <Component
      loading={!loaded}
      className="select-community"
    >
      <Container>
        <Content>
          {user.authority === Authority.ADMIN &&
            <ButtonWrapper
              onClick={() => setIsModalOpen(true)}
            >
              <OpenModalButton>
                <Icon size='xsmall' src={plus} />
              </OpenModalButton>
  
              <ButtonLabel>
                新しいコミュニティをつくる
              </ButtonLabel>
            </ButtonWrapper>        
          }

          <CommunityCards
            selected={selected}
            communities={communities}
            onClickCommunity={onChange}
          />

          <Tips
            message="新しいコミュニティは、オーナーのみが作れます。作成したいときは管理者もしくはATOMicaまでお問い合わせください。"
          />

          <FooterArea>
            <ButtonsWrapper>
              <MultiButtons
                disabled={disabled}
                options={COMMUNITY_SELECT_BUTTONS}
                onClick={goNext}
              />
            </ButtonsWrapper>
          </FooterArea>

          <Modal
            isOpen={isModalOpen}
            label="コミュニティを作成する"
            onClose={refreshCommunities}
          >
            <CreateCommunity />
          </Modal>
        </Content>
      </Container>
    </Component>
  );
});

export default SelectCommunities;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const OpenModalButton = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  border-radius: 50%;
  margin-right: ${theme.mixins.spacing / 2}px;
`;

const ButtonLabel = styled(Typography)`
  width: 100%;
  height: 40px;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  background: ${theme.mixins.background.white};
  border-radius: 8px;
  user-select: none;
  padding-left: ${theme.mixins.spacing * 2}px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  left: 0px;
  bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ButtonsWrapper = styled.div`
  width: 280px;
  height: 48px;
`;