import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import Icon from '../../../../components/icon/Icon';
import IconTabs, {IconTab} from '../../../../components/tab/IconTabs';
import {ZERO} from '../../../../constants/common-const';
import {fromFooterTab, toFooterTab} from '../../../../converters/path-converter';
import {User} from '../../../../entities/user-entity';
import {NotificationCategory} from '../../../../enums/notification-enum';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import NotificationService from '../../../../services/notification-service';
import {Index} from '../../../../vo/common-vo';
import account from './../../../../assets/icon/icon_account_black.png';
import connection from './../../../../assets/icon/icon_connection_black.png';
import conversation from './../../../../assets/icon/icon_conversation_black.png';
import ear from './../../../../assets/icon/icon_ear_black.png';
import notification from './../../../../assets/icon/icon_notification_black.png';

export enum FooterTabEnum {
  QUESTIONS,
  ASK,
  RELATIONS,
  NOTIFICATIONS,
  ACCOUNT,
  OUT_OF_TARGET,
}

const getTabs = (showBadge: boolean): IconTab[] => [
  {
    id: FooterTabEnum.QUESTIONS,
    icon: <Icon size="small" src={ear} />,
  },
  {
    id: FooterTabEnum.ASK,
    icon: <Icon size="small" src={conversation} />,
  },
  {
    id: FooterTabEnum.RELATIONS,
    icon: <Icon size="small" src={connection} />,
  },
  {
    id: FooterTabEnum.NOTIFICATIONS,
    showBadge,
    icon: <Icon size="small" src={notification} />,
  },
  {
    id: FooterTabEnum.ACCOUNT,
    icon: <Icon size="small" src={account} />,
  },
];

interface P {
  user: User;
}

const FooterTabs: React.FC<P> = React.memo(props => {
  const { user } = props;
  const unmountRef = useUnmountRef();
  const { path, replacePath } = usePath();
  const index = useMemo<Index>(() => toFooterTab(path), [path]);
  const [tabs, setTabs] = useSafeState<IconTab[]>(unmountRef, getTabs(false));

  const initialize = useSafeCallback(async () => {
    if (!user) return;
    
    const count = await NotificationService.countUnconfirmedNotifications(
      user.userId,
      NotificationCategory.ANNOUNCE,
    );
    
    const showBadge = count > ZERO;
    setTabs(getTabs(showBadge));
  }, [user, setTabs]);

  useEffect(() => {
    initialize();
  }, [initialize]);


  const handleTabChanged = useSafeCallback(async (
    tab: IconTab,
  ): Promise<void> => {
    replacePath(fromFooterTab(tab.id, user));
  }, [replacePath, user]);

  return (
    <Component
      loadable={index !== FooterTabEnum.OUT_OF_TARGET}
      className="footer-tabs"
    >
      <Container id="footer-tabs">
        <Content>
          <IconTabs
            selected={tabs[index]}
            tabs={tabs}
            onChange={handleTabChanged}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default FooterTabs;

const Container = styled.div`
  width: 100vw;
  height: 72px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

const Content = styled.div`
  width: 288px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
