import React, {useEffect, useRef} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Icon from '../../components/icon/Icon';
import Screen from '../../components/screen/Screen';
import {QUESTION_ID} from '../../constants/id-const';
import {toConfirmMessage, toTabsMessage} from '../../converters/message-converter';
import {ConfirmMessage} from '../../entities/message-entity';
import {Question, QuestionId} from '../../entities/question-entity';
import {User} from '../../entities/user-entity';
import {QuestionSortEnum} from '../../enums/question-enum';
import {analytics} from '../../firebase';
import useMessage from '../../redux/hooks/useMessage';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import QuestionService from '../../services/question-service';
import {theme} from '../../styles/theme';
import {isNotEmpty} from '../../utils/common-util';
import {CardOptions} from '../common/swipable-card/XYSwipableCard';
import {CardDeckRef} from '../common/swipable-card/XYSwipableDeck';
import conversation from './../../assets/icon/icon_conversation_white.png';
import loading from './../../assets/icon/icon_loading2_white.png';
import QuestionCards from './question-card/QuestionCards';

const CARD_OPTIONS: CardOptions = {
  rightButton: {
    color: theme.mixins.typography.fontColor.white,
    label: 'もう一度\n相談する',
    icon: <Icon size="large" src={conversation} />,
    fontSize: theme.mixins.typography.fontSize.twenty,
    background: theme.mixins.background.pink,
  },
  leftButton: {
    color: theme.mixins.typography.fontColor.white,
    label: '削除',
    icon: <Icon size="large" src={loading} />,
    fontSize: theme.mixins.typography.fontSize.twenty,
    background: theme.mixins.background.gray2,
  },
};

interface P extends RouteComponentProps {}

const AccountQuestionsScreen: React.FC<P> = React.memo(() => {
  const ref = useRef<CardDeckRef>(null);
  const unmountRef = useUnmountRef();
  const { params, openPath } = usePath();
  const { getUser } = useUser();
  const { sendRequest, subscribeResponse } = useMessage();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isMyAccount, setIsMyAccount] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [questions, setQuestions] = useSafeState<Question[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const signInUser = await getUser();

    const questions = await QuestionService.fetchQuestionsByUserId(
      params.userId!,
      signInUser.userId,
      QuestionSortEnum.CREATED_AT_DESC,
    )
    
    setIsMyAccount(params.userId === signInUser.userId);
    setUser(signInUser);
    setQuestions(questions);
    setLoaded(true);
  }, [getUser, params.userId, setIsMyAccount, setUser, setQuestions, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("ユーザー毎相談一覧画面");
  }, [initialize]);

  const openAskScreen = useSafeCallback((questionId: QuestionId): void => {
    openPath(`${Path.ASK_TO}?${QUESTION_ID}=${questionId}` as Path);
  }, [openPath]);

  const deleteQuestion = useSafeCallback(async (questionId: QuestionId): Promise<void> => {
    ref.current && ref.current.removeCard(questionId);
    await QuestionService.deleteQuestion(questionId);
  }, []);

  const handleResponseSubmitted = useSafeCallback((event: Event, questionId: QuestionId): void => {
    const confirm: ConfirmMessage = event['detail'].confirm;
    sendRequest(toTabsMessage());
    
    if (isNotEmpty(confirm.value)) {
      deleteQuestion(questionId);
    }
  }, [sendRequest, deleteQuestion]);

  const confirmAndDeleteQuestion = useSafeCallback((questionId: QuestionId): void => {
    sendRequest(toConfirmMessage('削除する', '一度削除すると回答は完全に削除され、復元することはできなくなります'));
    subscribeResponse((event: Event) => handleResponseSubmitted(event, questionId));
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  return (
    <Screen
      loading={!loaded}
      className="account-questions-screen"
    >
      <Container>
        <Content>
          <QuestionCards
            ref={ref}
            showComplete={false}
            user={user}
            options={isMyAccount ? CARD_OPTIONS : {}}
            questions={questions}
            onClickRight={openAskScreen}
            onClickLeft={confirmAndDeleteQuestion}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default AccountQuestionsScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
`;

const Content = styled.div`
  width: 100vw;
  height: auto;
`;