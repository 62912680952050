import {InputBase, Typography} from '@material-ui/core';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import React, {useRef} from 'react';
import styled from 'styled-components';
import {EMPTY, MAX_LENGTH} from '../../constants/common-const';
import {User} from '../../entities/user-entity';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import PhotoService from '../../services/photo-service';
import {theme} from '../../styles/theme';
import {isEmpty, isNotEmpty} from '../../utils/common-util';
import {Placeholder, Title, Value} from '../../vo/common-vo';
import Component from '../component/Component';
import Icon from '../icon/Icon';
import Sentences from '../typography/Sentences';
import pen from './../../assets/icon/icon_pen_black.png';

type Type = 'text' | 'image';

interface P {
  multiline?: boolean;
  editable?: boolean;
  maxLength?: number;
  title?: Title;
  placeholder?: Placeholder;
  type: Type;
  value: Value;
  onChange?(value: Value): void;
  onBlur?(): void;
}

const EditableBox: React.FC<P> = React.memo(props => {
  const {
    multiline = false,
    editable = false,
    maxLength = MAX_LENGTH,
    title = EMPTY,
    placeholder = EMPTY,
    type,
    value: initValue,
    onChange,
    onBlur,
  } = props;

  const inputRef = useRef<any>();
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const [uploading, setUploading] = useSafeState<boolean>(unmountRef, false);
  const [value, setValue] = useSafeState<Value>(unmountRef, initValue);

  const focusOnInputArea = useSafeCallback((): void => {
    inputRef.current && inputRef.current.focus();
  }, []);

  const handleValueChanged = useSafeCallback((text: Text): void => {
    if (!!text && text.length > maxLength) return;
    setValue(text);
    !!onChange && onChange(text);
  }, [maxLength, setValue, onChange]);

  const uploadPhoto = useSafeCallback(async (image): Promise<void> => {
    setUploading(true);
    const user = await getUser() as User;
    const photoURL = await PhotoService.upload(image, user.userId);
    setValue(photoURL);
    !!onChange && onChange(photoURL);
    setUploading(false);
  }, [setUploading, getUser, setValue, onChange]);

  return (
    <Component
      loading={uploading}
      className="editable-box"
    >
      <Container>
        <Content>
          {type === 'text' &&
            <ButtonArea
              onClick={focusOnInputArea}
            >
              {isNotEmpty(title) &&
                <TitleWrapper>
                  <StyledTitle>
                    {title}
                  </StyledTitle>

                  {editable &&
                    <Icon
                      size="small"
                      src={pen}
                    />              
                  }
                </TitleWrapper>            
              }

              <TextBody>
                {editable &&                 
                  <Input
                    multiline={multiline}
                    inputRef={inputRef}
                    inputProps={{ style: inputProps }}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => handleValueChanged(e.target.value)}
                    onBlur={(e) => !!onBlur && onBlur()}
                  />
                }

                {!editable &&
                  <Sentences
                    style={textStyle}
                    content={value}
                  />
                }
              </TextBody>
            </ButtonArea>
          }

          {type === 'image' &&
            <UploadArea>
              <UploadPhoto
                type="file"
                accept="image/*"
                onChange={(e) => uploadPhoto(e.target.files[0])}
              />

              <TitleWrapper>
                <StyledTitle>
                  {title}
                </StyledTitle>
                
                {editable &&
                  <Icon
                    size="small"
                    src={pen}
                  />                
                }
              </TitleWrapper>

              <ImageBody>
                {isEmpty(value) &&
                  <NoImage>
                    <AddAPhotoOutlinedIcon fontSize="large" />
                  </NoImage>            
                }

                {isNotEmpty(value) &&
                  <StyledImage
                    src={value}
                  />                        
                }
              </ImageBody>
            </UploadArea>
          }
        </Content>
      </Container>
    </Component>
  );
});

export default EditableBox;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 16px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
`;

const UploadArea = styled.div`
  width: 100%;
  height: auto;
`;

const UploadPhoto = styled.input`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: 196px;
  opacity: 0;
  appearance: none;
  position: absolute;
`;

const TitleWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: 40px;
  border-bottom: 1px solid ${theme.mixins.palette.gray2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px 0px;
`;

const StyledTitle = styled(Typography)`
  width: calc(100% - 40px);
  height: auto;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

const ImageBody = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Input = styled(InputBase)`
  width: 100%;
  height: auto;
`;

const inputProps = {
  fontSize: theme.mixins.typography.fontSize.sixteen,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  lineHeight: 2,
  whiteSpace: 'pre-wrap',
};

const textStyle: CSSProperties = {
  width: '100%',
  height: 'auto',
  minHeight: 32,
  fontSize: theme.mixins.typography.fontSize.sixteen,
  fontWight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  whiteSpace: 'pre-wrap',
};

const TextBody = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const NoImage = styled.div`
  width: 132px;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: ${theme.mixins.background.gray5};
`;

const StyledImage = styled.img`
  width: 132px;
  height: 132px;
  border-radius: 16px;
  background: ${theme.mixins.background.white};
  object-fit: cover;
  margin: ${theme.mixins.spacing}px;
`;