import {Typography} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import styled from 'styled-components';
import CommentBox from '../../../components/box/CommentBox';
import Component from '../../../components/component/Component';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {noop} from '../../../utils/common-util';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting.png';
import mojaco_thanks from './../../../assets/mojaco/mojaco_thanks.png';

interface P {}

const AccountCreated: React.FC<P> = React.memo(() => {

  return (
    <Component className="account-created">
      <Container>
        <Content>
          <MessageWrapper>
            <Icon>
              <CheckIcon />
            </Icon>
            <Message>
              アカウントの作成ができました
            </Message>
          </MessageWrapper>

          <BoxWrapper>
            <CommentBox
              circlePhoto={mojaco_greeting}
              onClickCirclePhoto={noop}
            >
              <Greeting>
                ご登録ありがとうございます！ 僕は皆様の相談役モジャ公と言います。ヨロシクです！
              </Greeting>
            </CommentBox>
          </BoxWrapper>

          <Mojaco
            src={mojaco_thanks}
          />
        </Content>
      </Container>
    </Component>
  )
});

export default AccountCreated;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  padding-top: ${theme.mixins.spacing * 3}px;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray7};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const MessageWrapper =styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const Icon = styled(Typography)`
  width: 40px;
  height: 40px;
  color: ${theme.mixins.palette.pink};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray7};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Mojaco = styled.img`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  position: fixed;
  bottom: 0px;
  object-fit: cover;
`;