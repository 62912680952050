import React from 'react';
import styled from 'styled-components';
import AuthGuard from '../../../auth/AuthGuard';
import usePath from '../../../redux/hooks/usePath';
import {Path} from '../../../router/Routes';
import AccountEditScreen from '../../account/AccountEditScreen';
import AccountScreen from '../../account/AccountScreen';
import AskScreen from '../../ask/AskScreen';
import CommunitiesScreen from '../../communities/CommunitiesScreen';
import CommunityDetailsScreen from '../../communities/CommunityDetailsScreen';
import CommunityEditScreen from '../../communities/CommunityEditScreen';
import ConnectionsScreen from '../../connections/ConnectionsScreen';
import NotificationsActivityScreen from '../../notifications/NotificationsActivityScreen';
import NotificationsAnnounceScreen from '../../notifications/NotificationsAnnounceScreen';
import NotificationsCommunityScreen from '../../notifications/NotificationsCommunityScreen';
import AccountQuestionsScreen from '../../questions/AccountQuestionsScreen';
import CommunityQuestionsScreen from '../../questions/CommunityQuestionsScreen';
import QuestionsScreen from '../../questions/QuestionsScreen';
import RegisterIntroScreen from '../../register/RegisterIntroScreen';
import AccountTopicsEditScreen from '../../topic/AccountTopicsEditScreen';
import AccountTopicsScreen from '../../topic/AccountTopicsScreen';
import TopicNewScreen from '../../topic/TopicNewScreen';

const GuardedSwitcher: React.FC = React.memo(() => {
  const { path } = usePath();
  
  return (
    <Switcher>
      {path === Path.REGISTER_INTRO &&
        <RegisterIntroScreen />
      }

      {(path === Path.QUESTIONS ||
        path === Path.QUESTION_DETAILS) &&
        <QuestionsScreen />
      }

      {(path === Path.ASK_TO ||
        path === Path.ASK_INPUT ||
        path === Path.ASK_DONE) &&
        <AskScreen />
      }

      {path === Path.COMMUNITIES &&
        <CommunitiesScreen />
      }

      {path === Path.COMMUNITY_DETAILS &&
        <CommunityDetailsScreen />
      }

      {path === Path.COMMUNITY_EDIT &&
        <CommunityEditScreen />
      }

      {(path === Path.COMMUNITY_QUESTIONS ||
        path === Path.COMMUNITY_QUESTION_DETAILS) &&
        <CommunityQuestionsScreen />
      }

      {path === Path.CONNECTIONS &&
        <ConnectionsScreen />
      }

      {path === Path.NOTIFICATIONS_ANNOUNCE &&
        <NotificationsAnnounceScreen />
      }

      {path === Path.NOTIFICATIONS_COMMUNITY &&
        <NotificationsCommunityScreen />
      }

      {path === Path.NOTIFICATIONS_ACTIVITY &&
        <NotificationsActivityScreen />
      }

      {path === Path.ACCOUNT &&
        <AccountScreen />
      }

      {path === Path.ACCOUNT_EDIT &&
        <AccountEditScreen />
      }

      {(path === Path.ACCOUNT_QUESTIONS ||
        path === Path.ACCOUNT_QUESTION_DETAILS) &&
        <AccountQuestionsScreen />
      }

      {path === Path.TOPIC_NEW &&
        <TopicNewScreen />
      }

      {path === Path.ACCOUNT_TOPICS &&
        <AccountTopicsScreen />
      }

      {path === Path.ACCOUNT_TOPICS_EDIT &&
        <AccountTopicsEditScreen />
      }
    </Switcher>
  );
});

export default AuthGuard(GuardedSwitcher);

const Switcher = styled.div`
  width: 100%;
  height: auto;
`;