import {EMPTY, MINUS_ONE, REDIRECT_PATH} from "../constants/common-const";
import {Params, QueryParams} from "../entities/common-entity";
import env, {EnvDef} from "../env/env";
import {Path, PATH_IDS} from "../router/Routes";
import {ID, QueryParam} from "../vo/common-vo";
import {isDefAndNotNull, isExisted, isNotEmpty} from "./common-util";

const DEVELOPMENT_DOMAIN = 'dev.miccca.jp';

const STAGING_DOMAIN = 'staging.miccca.jp';

const PRODUCTION_DOMAIN = 'miccca.jp';

export const DOMAINS = {
  [EnvDef.DEVELOPMENT]: DEVELOPMENT_DOMAIN,
  [EnvDef.STAGING]: STAGING_DOMAIN,
  [EnvDef.PRODUCTION]: PRODUCTION_DOMAIN,
}

const DEVELOPMENT_LIFF_URL = 'line://app/1656260711-xMp3e4me';
// const DEVELOPMENT_LIFF_URL = 'https://liff.line.me/1656260711-xMp3e4me';

const STAGING_LIFF_URL = 'line://app/1656260711-Q20DMoqM';
// const STAGING_LIFF_URL = 'https://liff.line.me/1656260711-Q20DMoqM';

const PRODUCTION_LIFF_URL = 'line://app/1656260711-AvgxO3VO';
// const PRODUCTION_LIFF_URL = 'https://liff.line.me/1656260711-AvgxO3VO';

export const LIFF_URLS = {
  [EnvDef.DEVELOPMENT]: DEVELOPMENT_LIFF_URL,
  [EnvDef.STAGING]: STAGING_LIFF_URL,
  [EnvDef.PRODUCTION]: PRODUCTION_LIFF_URL,
}

export const removeColon = (pathStr: string): ID =>
  isNotEmpty(pathStr) ? pathStr.replace(':', '') : '';

export const getPath = (pathStr: string): Path => {
  if (pathStr === Path.QUESTIONS) return Path.QUESTIONS;

  const index = pathStr.indexOf('?');
  const dirsStr = index === MINUS_ONE ? pathStr : pathStr.substr(0, index);
  const tgtDirs = dirsStr.split('/');

  const path: Path | undefined = Object.values(Path)
    .find(path => {
      if (path === Path.QUESTIONS) return false;

      const srcDirs = path.split('/');
      if (tgtDirs.length !== srcDirs.length) return false;

      for (let i = 0; i < srcDirs.length; i++) {
        const srcDir = removeColon(srcDirs[i]);
        if (isExisted<ID>(PATH_IDS, srcDir)) continue;
        if (tgtDirs[i] !== srcDir) return false;
      }

      return true;
    });

  return !!path ? path : Path.ACCOUNT;
}

export const getParams = (pathStr: string): Params => {
  if (pathStr === Path.QUESTIONS) return {};

  const params = {};
  const index = pathStr.indexOf('?');
  const dirsStr = index === MINUS_ONE ? pathStr : pathStr.substr(0, index);
  const tgtDirs = dirsStr.split('/');

  Object.values(Path)
    .filter(path => {
      if (path === Path.QUESTIONS) return false;

      const srcDirs = path.split('/');
      if (tgtDirs.length !== srcDirs.length) return false;

      for (let i = 0; i < srcDirs.length; i++) {
        const srcDir = removeColon(srcDirs[i]);
        if (isExisted<ID>(PATH_IDS, srcDir)) continue;
        if (tgtDirs[i] !== srcDir) return false;
      }

      return true;
    })
    .forEach(path => {
      const srcDirs = path.split('/');

      for (let i = 0; i < srcDirs.length; i++) {
        const srcDir = removeColon(srcDirs[i]);
        if (!isExisted<ID>(PATH_IDS, srcDir)) continue;
        params[srcDir] = tgtDirs[i];
      }
    });

  return isDefAndNotNull(params) ? params : {};
}

export const getQueryParams = (search: string): QueryParams => {
  const params = search.substring(1);

  let paramsObj: { [key: string]: string } = {};
  params.split('&').forEach((param: string) => {
    const keyValArray = param.split('=');
    paramsObj = {
      ...paramsObj,
      [keyValArray[0]]: keyValArray[1]
    }
  })
  return paramsObj;
}

export const embedIdInPath = (path: Path, ids: ID[]): Path => {
  const srcDirs: string[] = path.split('/');
  srcDirs.shift();

  const tgtDirs: string[] = [];
  for (let i = 0; i < srcDirs.length; i++) {
    const srcDir = removeColon(srcDirs[i]);

    if (isExisted<ID>(PATH_IDS, srcDir)) {
      tgtDirs.push(`/${ids[0]}`);
      ids.shift();
      continue;
    }

    tgtDirs.push(`/${srcDir}`);
  }

  return tgtDirs.join('') as Path;
}

export const copyURL = async (path: Path): Promise<void> => {
  const url = `${LIFF_URLS[env]}?${REDIRECT_PATH}=${encodeURIComponent(path)}`;
  return navigator.clipboard.writeText(url);
}

export const isQuestionListView = (path: Path): boolean =>
  path === Path.QUESTIONS
    || path === Path.COMMUNITY_QUESTIONS
    || path === Path.ACCOUNT_QUESTIONS;

    export const decode = (
      param: QueryParam | undefined,
    ): QueryParam => {
      if (!param) return EMPTY;
      const decoded = decodeURIComponent(param);
      const doubleDecoded = decodeURIComponent(decoded);
      return doubleDecoded;
    }