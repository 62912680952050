import React from 'react';
import styled from 'styled-components';
import {Path} from '../../../router/Routes';
import FooterBar from '../../common/footer-bar/FooterBar';
import HeaderBar from '../../common/header-bar/HeaderBar';
import NotificationModal from '../notification-modal/NotificationModal';
import GuardedSwitcher from './GuardedSwitcher';

interface P {
  path: Path;
}

const GuardedScreens: React.FC<P> = React.memo(props => {
  const { path } = props;
  
  return (
    <Screen>
      <HeaderBar
        path={path}
      />
      
      <GuardedSwitcher />

      <FooterBar />

      <NotificationModal />
    </Screen>
  );
});

export default GuardedScreens;

const Screen = styled.div`
  width: 100%;
  height: auto;
`;