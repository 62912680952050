import {EMPTY} from '../../constants/common-const';
import {PreviousURLAction} from '../../entities/action-entity';
import {PreviousURLActionEnum} from '../../enums/action-enum';
import {URL} from '../../vo/common-vo';

const previousURLReducer = (previousURL: URL, action: PreviousURLAction): URL => {
  if (previousURL === undefined) return EMPTY;

  switch (action.type) {
    case PreviousURLActionEnum.SET:
      return action.previousURL;

    case PreviousURLActionEnum.PURGE:
      return EMPTY;
      
    default:
      return previousURL;
  }
};

export default previousURLReducer;
