import {Answer, AnswerId} from '../entities/answer-entity';
import {Question} from '../entities/question-entity';
import {User} from '../entities/user-entity';
import {AnswerStatus} from '../enums/answer-enum';
import {Content, URL} from '../vo/common-vo';

export const toAnswer = (
  answerId: AnswerId,
  question: Question,
  status: AnswerStatus,
  content: Content,
  photoURL: URL,
  user: User,
): Answer => {
  return {
    answerId,
    question,
    replies: [],
    status,
    content,
    photoURL,
    user,
    createdAt: new Date(),
  };
};