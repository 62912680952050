import {SpringValues, to} from '@react-spring/core';
import {animated} from '@react-spring/web';
import React, {useEffect, useMemo, useRef} from 'react';
import {ReactEventHandlers} from 'react-use-gesture/dist/types';
import styled from 'styled-components';
import {ZERO} from '../../../constants/common-const';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {ID, Index} from '../../../vo/common-vo';
import CardButton, {Button} from './CardButton';
import {DeckActionEnum, PositionEnum} from './XYSwipableDeck';

export const PROPS_INDEX = 'propsIndex';

const trans = (x: number, y: number): string =>
  `translate3d(${x}px, ${y}px, 0px)`;

const animate = (p: number, r: number, s: number): string =>
  `perspective(${p}px) rotateX(${r}deg) rotateY(${r}deg) rotateZ(${r}deg) scale(${s})`;

export interface CardOptions {
  rightButton?: Button;
  leftButton?: Button;
}

export interface CardProps {
  id: ID;
  x: number;
  y: number;
  zIndex: number;
  opacity: number;
  scale: number;
  delay?: number;
}

type CardNode = (
  isFocus: boolean,
  isFront: boolean,
  isTarget: boolean,
  bind: (id: ID) => ReactEventHandlers,
  onClick: (action: DeckActionEnum,
) => void) => React.ReactNode;

export interface CardData {
  id: ID;
  propsIndex: Index;
  visible: boolean;
  position: PositionEnum;
  node: CardNode;
}

interface P {
  isFocus: boolean;
  isFront: boolean;
  cardOptions?: CardOptions;
  cardProps: SpringValues<CardProps>;
  cardData: CardData;
  bind: (...args: any[]) => ReactEventHandlers;
  onClickRight(id: ID): void;
  onClickLeft(id: ID): void;
  onAction(action: DeckActionEnum): void;
}

const XYSwipableCard: React.FC<P> = React.memo(props => {
  const {
    isFocus,
    isFront,
    cardOptions,
    cardProps,
    cardData,
    bind,
    onClickRight,
    onClickLeft,
    onAction,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const rightId = useRef<NodeJS.Timeout>();
  const leftId = useRef<NodeJS.Timeout>();
  const unmountRef = useUnmountRef();
  const [showRightButton, setShowRightButton] = useSafeState<boolean>(unmountRef, false);
  const [showLeftButton, setShowLeftButton] = useSafeState<boolean>(unmountRef, false);
  const [cardWidth, setCardWidth] = useSafeState<number>(unmountRef, ZERO);
  const [cardHieght, setCardHeight] = useSafeState<number>(unmountRef, ZERO);

  const isOpen = useMemo<boolean>(() => {
    return isFront && cardData.position === PositionEnum.TOP;
  }, [isFront, cardData.position]);

  const updateShowButton = useSafeCallback((): void => {
    rightId.current && clearTimeout(rightId.current);
    leftId.current && clearTimeout(leftId.current);

    switch (cardData.position) {
      case PositionEnum.CENTER:
        rightId.current = setTimeout(() => setShowRightButton(false), 400);
        leftId.current = setTimeout(() => setShowLeftButton(false), 400);
        break;

      case PositionEnum.RIGHT:
        setShowLeftButton(true);
        break;
      
      case PositionEnum.LEFT:
        setShowRightButton(true);
        break;

      case PositionEnum.TOP:
      case PositionEnum.BOTTOM:
        setShowRightButton(false);
        setShowLeftButton(false);
        break;
      
      default:
        throw new Error(`${cardData.position} is out of target.`);
    }
  }, [cardData.position, setShowRightButton, setShowLeftButton]);

  const getCardWidth = useSafeCallback((): number => {
    return ref.current ? ref.current.getBoundingClientRect().width : ZERO;
  }, []);

  const getCardHeight = useSafeCallback((): number => {
    return ref.current ? ref.current.getBoundingClientRect().height : ZERO;
  }, []);

  const initialize = useSafeCallback((): void => {
    updateShowButton();
    setCardWidth(getCardWidth());
    setCardHeight(getCardHeight());
  }, [updateShowButton, setCardWidth, getCardWidth, setCardHeight, getCardHeight]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Container
      style={{
        width: cardWidth,
        opacity: cardProps.opacity,
        zIndex: cardProps.zIndex.to(zIndex => Math.floor(zIndex)),
        transform: to([0, cardProps.y], trans),
      }}
    >
      {showRightButton &&
        !!cardOptions &&
        !!cardOptions.rightButton &&
        <CardButton
          position="right"
          width={cardWidth / 2}
          height={cardHieght}
          button={cardOptions.rightButton}
          onClick={() => onClickRight(cardData.id)}
        />
      }
    
      {showLeftButton &&
        !!cardOptions &&
        !!cardOptions.leftButton &&
        <CardButton
          position="left"
          width={cardWidth / 2}
          height={cardHieght}
          button={cardOptions.leftButton}
          onClick={() => onClickLeft(cardData.id)}
        />
      }
      
      <Content
        style={{
          transform: to([cardProps.x, 0], trans)
        }}
      >
        <Card
          ref={ref}
          style={{
            transform: to([0, 0, cardProps.scale], animate)
          }}
        >
          {cardData.node(isFocus, isFront, isOpen, bind, onAction)}
        </Card>
      </Content>
    </Container>
  );
});

export default XYSwipableCard;

const Container = styled(animated.div)`
  height: auto;
  position: absolute;
  will-change: transform;
`;

const Content = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  will-change: transform;
`;

const Card = styled(animated.div)`
  will-change: transform;
`;