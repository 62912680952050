import {Button} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import ClickableBox from '../../../components/box/ClickableBox';
import Component from '../../../components/component/Component';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {theme} from '../../../styles/theme';
import {isEmpty, isNotEmpty} from '../../../utils/common-util';

export enum ContactOptionEnum {
  HOME = 'homePage',
  TWITTER = 'twitterId',
  INSTAGRAM = 'instagramId',
  FACEBOOK = 'facebookId',
}

export const CONTACTS = [
  {
    id: ContactOptionEnum.HOME,
    icon: <HomeIcon fontSize="large" />,
    background: '#39475d',
  },
  {
    id: ContactOptionEnum.TWITTER,
    icon: <TwitterIcon fontSize="large" />,
    background: '#1da1f2',
  },
  {
    id: ContactOptionEnum.INSTAGRAM,
    icon: <InstagramIcon fontSize="large" />,
    background: '#cf2e92',
  },
  {
    id: ContactOptionEnum.FACEBOOK,
    icon: <FacebookIcon fontSize="large" />,
    background: '#3b5998',
  },
]

interface P {
  user: User;
}

const Contact: React.FC<P> = React.memo(props => {
  const { user } = props;

  const isOneOfContactsExisted = useMemo<boolean>(() => {
    return isNotEmpty(user.homePage)
      || isNotEmpty(user.twitterId)
      || isNotEmpty(user.instagramId)
      || isNotEmpty(user.facebookId);
  }, [user]);

  const openContactPage = useSafeCallback((option: ContactOptionEnum): void => {
    switch (option) {
      case ContactOptionEnum.HOME:
        window.open(user.homePage, 'newtab');
        break;
      case ContactOptionEnum.TWITTER:
        window.open(`https://twitter.com/${user.twitterId}`, 'newtab');
        break;
      case ContactOptionEnum.INSTAGRAM:
        window.open(`https://www.instagram.com/${user.instagramId}`, 'newtab');
        break;
      case ContactOptionEnum.FACEBOOK:
        window.open(`https://www.facebook.com/${user.facebookId}`, 'newtab');
        break;
      default:
        throw new Error(`${option} is out of target.`);
    }
  }, [user]);

  return (
    <Component
      loadable={isOneOfContactsExisted}
      className="contact"
    >
      <Container>
        <Content>
          <ClickableBox
            label="連絡先"
          >
            <Wrapper>
              {CONTACTS.map((contact, index) =>
                <ButtonWrapper
                  key={index}
                  hidden={isEmpty(user[contact.id])}
                >
                  <IconButton
                    disableRipple
                    background={contact.background}
                    onClick={() => openContactPage(contact.id)}
                  >
                    {contact.icon}
                  </IconButton>
                </ButtonWrapper>
              )}
            </Wrapper>
          </ClickableBox>
        </Content>
      </Container>
    </Component>
  );
});

export default Contact;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  padding-top: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div<{ hidden: boolean }>`
  display: ${props => props.hidden ? 'none' : 'block'}
`;

const IconButton = styled(Button)<{ background: string }>`
  width: 56px;
  min-width: 56px;
  height: 56px;
  color: ${theme.mixins.typography.fontColor.white};
  background: ${props => props.background} !important;
  border-radius: 50%;
  margin: ${theme.mixins.spacing / 2}px;
`;