import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../../components/box/EditableBox';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import {Community} from '../../../../entities/community-entity';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import {Description} from '../../../../vo/common-vo';

interface P {
  community: Community;
  onChange(community: Community): void;
}

const EditCommunityDescription: React.FC<P> = React.memo(props => {
  const { community, onChange } = props;

  const updateCommunityDescription = useSafeCallback((
    description: Description,
  ): void => {
    onChange({ ...community, description });
  }, [onChange, community]);

  return (
    <Component className="edit-community-description">
      <Container>
        <Content>
          <EditableBox
            multiline
            editable
            type="text"
            title={'コミュニティの説明'}
            value={!!community.description
              ? community.description : EMPTY}
            onChange={updateCommunityDescription}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default EditCommunityDescription;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;