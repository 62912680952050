import {Typography} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import ClickableBox from '../../../components/box/ClickableBox';
import Component from '../../../components/component/Component';
import ImageWithSkeleton from '../../../components/image/ImageWithSkeleton';
import {Community} from '../../../entities/community-entity';
import {User} from '../../../entities/user-entity';
import {CommunitySortEnum} from '../../../enums/community-enum';
import {CommunityMemberRole} from '../../../enums/community-member-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Path} from '../../../router/Routes';
import CommunityService from '../../../services/community-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {hasLength} from '../../../utils/common-util';
import {embedIdInPath} from '../../../utils/path-util';

interface P {
  user: User
}

const EditOwnerCommunity: React.FC<P> = React.memo(props => {
  const { user } = props;
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);  
  const [communities, setCommunities] = useSafeState<Community[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const communities = await CommunityService.fetchCommunitiesByRole(
      user.userId,
      CommunityMemberRole.OWNER,
      CommunitySortEnum.CREATED_AT_DESC,
    );

    setCommunities(communities);
    setLoaded(true);
  }, [user.userId, setCommunities, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openEditCommunityScreen = useSafeCallback((community: Community) => {
    openPath(embedIdInPath(Path.COMMUNITY_EDIT, [community.communityId]));
  }, [openPath]); 

  return (
    <Component
      loadable={hasLength(communities)}
      loading={!loaded}
      className="edit-community"
    >
      <Container>
        <Content>
          <ClickableBox
            label='オーナーコミュニティ'
          >
            <CommunitiesWrapper>
              {communities.map((community, index) =>
                <CommunityWrapper
                  key={index}
                  onClick={() => openEditCommunityScreen(community)}
                >
                  <ImageWithSkeleton
                    animation="wave"
                    variant='rect'
                    style={styleForCommunityPhoto}
                    src={community.photoURL}
                  />
                  
                  <CommunityName>
                    {community.name}
                  </CommunityName>
                </CommunityWrapper>
              )}
            </CommunitiesWrapper>
          </ClickableBox>
        </Content>
      </Container>
    </Component>
  );
});

export default EditOwnerCommunity;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const CommunitiesWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CommunityWrapper = styled.div`
  width: calc(100vw / 2 - ${theme.mixins.spacing * 8}px);
  max-width: calc(${MOBILE_MAX_WIDTH / 2 - theme.mixins.spacing * 8}px);
  min-width: calc(${MOBILE_MIN_WIDTH / 2 - theme.mixins.spacing * 8}px);
  display: flex;
  flex-flow: column;
  margin: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const styleForCommunityPhoto: CSSProperties = {
  width: '100%',
  height: `calc(100vw / 2 - ${theme.mixins.spacing * 8}px)`,
  maxHeight: `calc(${MOBILE_MAX_WIDTH / 2 - theme.mixins.spacing * 8}px)`,
  minHeight: `calc(${MOBILE_MIN_WIDTH / 2 - theme.mixins.spacing * 8}px)`,
  borderRadius: '8px',
  objectFit: 'cover',
};

const CommunityName = styled(Typography)`
  width: 100%;
  height: auto;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing / 2}px;
`;