import {CSSProperties} from '@material-ui/core/styles/withStyles';
import anchorme from 'anchorme';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Content} from '../../vo/common-vo';
import Component from '../component/Component';

const getInnterHTML = (content: Content): { __html: string } => {
  const options = {
    attributes: {
      target: "_blank",
    }
  };

  return {
    __html: anchorme({ input: content, options })
  };
}

interface P {
  style: CSSProperties;
  content: Content;
}

const Sentences: React.FC<P> = React.memo(props => {
  const { style, content } = props;

  return (
    <Component className="sentences">
      <Container>
        <StyledContent>
          <Text
            style={style}
            dangerouslySetInnerHTML={getInnterHTML(content)}
          />
        </StyledContent>
      </Container>
    </Component>
  );
});

export default Sentences;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const StyledContent = styled.div`
  width: 100%;
  height: auto;
`;

const Text = styled.div`
  color: ${theme.mixins.typography.fontColor.gray2};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  word-break: break-all;
  white-space: pre-wrap;
  ${theme.mixins.underline};
`;