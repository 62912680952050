import {MINUS_ONE, ZERO} from "../constants/common-const";
import {Index} from "../vo/common-vo";

export const noop = () => {};

export const isZero = (num: number): boolean => num === ZERO;

export const asc = (a: number, b: number): number => a - b;

export const desc = (a: number, b: number): number => b - a;

export const isDefAndNotNull = (value: any): boolean =>
  value !== undefined && value !== null;

export const isUndefOrNull = (value: any): boolean =>
  value === undefined || value === null;

export const isNotEmpty = (str: string | undefined | null): boolean =>
  !!str && str.length > ZERO;

export const isEmpty = (str: string | undefined | null): boolean =>
  !str || isZero(str.length);

export const hasLength = (arr: any[]): boolean => 
  isDefAndNotNull(arr) && arr.length > ZERO;

export const hasSize = (map: Map<any, any>): boolean => 
  isDefAndNotNull(map) && map.size > ZERO;

export const isExisted = <T>(srcList: T[], tgtVal: T): boolean => 
  srcList.findIndex(s => s === tgtVal) !== MINUS_ONE;

export const findIndex = <T>(srcList: T[], tgtKey: string, tgtVal: any): Index =>
  srcList.findIndex((s) => s[tgtKey] === tgtVal);

export const isExistedIn = <T>(srcList: T[], tgtKey: string, tgtVal: any): boolean =>
  findIndex(srcList, tgtKey, tgtVal) !== MINUS_ONE;

export const findIn = <T>(srcList: T[], tgtKey: string, tgtVal: any): T | undefined =>
  srcList.find((s) => s[tgtKey] === tgtVal);

export const filter = <T>(srcList: T[], tgtKey: string, tgtVal: any): T[] =>
  srcList.filter((s) => s[tgtKey] === tgtVal);

export const removeFrom = <T>(srcList: T[], tgtKey: string, tgtVal: any): T[] =>
  srcList.filter((s) => s[tgtKey] !== tgtVal);

export const moveToHead = <T>(srcList: T[], tgtKey: string, tgtVal: any): T[] => {
  const src = findIn(srcList, tgtKey, tgtVal);
  return !!src ? [src, ...removeFrom(srcList, tgtKey, tgtVal)] : srcList;
}

export const scrollTop = (): void => window.scrollTo(0, 0);

export const getRandomInt = (max: number): number =>
  Math.floor(Math.random() * max);

export const isAsync = (func: Function) =>
  func.constructor.name === "AsyncFunction";