import {Community} from '../entities/community-entity';
import {CommunityMember, CommunityMemberId} from '../entities/community-member-entity';
import {User} from '../entities/user-entity';
import {CommunityMemberRole, CommunityMemberStatus} from '../enums/community-member-enum';
import {Message} from '../vo/common-vo';

export const toMember = (
  communityMemberId: CommunityMemberId,
  community: Community | undefined,
  isPinned: boolean,
  status: CommunityMemberStatus,
  role: CommunityMemberRole,
  message: Message,
  user: User,
): CommunityMember => {
  return {
    communityMemberId,
    community,
    isPinned,
    status,
    role,
    message,
    user,
  }
};