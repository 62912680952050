import {Typography} from '@material-ui/core';
import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {ButtonOption} from '../../../components/button/MultiButtons';
import {IconData} from '../../../components/card/CardWithIcons';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import QRCode from '../../../components/other/QRCode';
import {EMPTY} from '../../../constants/common-const';
import {toMember} from '../../../converters/community-member-converter';
import {toButtonsMessage, toTabsMessage} from '../../../converters/message-converter';
import {Community} from '../../../entities/community-entity';
import {CommunityMember} from '../../../entities/community-member-entity';
import {ButtonMessage} from '../../../entities/message-entity';
import {User} from '../../../entities/user-entity';
import {CommunityMemberRole, CommunityMemberStatus} from '../../../enums/community-member-enum';
import useMessage from '../../../redux/hooks/useMessage';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Path} from '../../../router/Routes';
import CommunityMemberService from '../../../services/community-member-service';
import {theme} from '../../../styles/theme';
import {hasLength, hasSize} from '../../../utils/common-util';
import {embedIdInPath} from '../../../utils/path-util';
import askBlack from './../../../assets/icon/icon_balloon_black.png';
import askWhite from './../../../assets/icon/icon_balloon_white.png';
import settingBlack from './../../../assets/icon/icon_setting_black.png';
import settingWhite from './../../../assets/icon/icon_setting_white.png';
import shareBlack from './../../../assets/icon/icon_share_black.png';
import shareWhite from './../../../assets/icon/icon_share_white.png';
import MemberCards from './../member-card/MemberCards';
import CommunityDescription from './parts/CommunityDescription';
import CommunityPhoto, {CommunityPhotoIconEnum} from './parts/CommunityPhoto';
import CommunityQuestions from './parts/CommunityQuestions';

const getIcons = (member: CommunityMember | undefined): IconData[] => [
  {
    id: CommunityPhotoIconEnum.ASK,
    disabled: !member || member.status !== CommunityMemberStatus.JOINED,
    src: askWhite,
  },
  {
    id: CommunityPhotoIconEnum.SETTING,
    disabled: !member || member.role !== CommunityMemberRole.OWNER,
    src: settingWhite,
  },
  {
    id: CommunityPhotoIconEnum.SHARE,
    disabled: false,
    src: shareWhite,
  },
];

enum ButtonType {
  REQUEST_TO_JOIN_COMMUNITY = 'request_to_join_community',
  JOIN_COMMUNITY = 'join_community',
}

const getButtons = (member: CommunityMember | undefined): ButtonOption[] => {
  if (!!member && member.status === CommunityMemberStatus.INVITED) {
    return [
      {
        id: ButtonType.JOIN_COMMUNITY,
        label: '参加する',
        primary: true,
        color: theme.mixins.typography.fontColor.white,
        background: theme.mixins.background.pink,
      }
    ];
  }

  if (!member
    || member.status === CommunityMemberStatus.SKIPPED
    || member.status === CommunityMemberStatus.LEFT) {
    return [
      {
        id: ButtonType.REQUEST_TO_JOIN_COMMUNITY,
        label: '参加申請する',
        primary: true,
        color: theme.mixins.typography.fontColor.white,
        background: theme.mixins.background.pink,
      }
    ];
  }

  return [];
};

interface P {
  user: User;
  community: Community;
}

const CommunityDetails: React.FC<P> = React.memo(props => {
  const { user, community } = props;
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const { sendRequest, subscribeResponse } = useMessage();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const owners = useMemo<CommunityMember[]>(() =>
    community.members!.filter(m => m.role === CommunityMemberRole.OWNER), [community.members]);
  const members = useMemo<CommunityMember[]>(() =>
    community.members!.filter(m => m.role === CommunityMemberRole.MEMBER), [community.members]);
  const member = useMemo<CommunityMember | undefined>(() =>
    community.members!.find(m => m.user.userId === user.userId), [community.members, user.userId]);

  const requestToJoinCommunity = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    
    const newMember = toMember(
      !!member
        ? member.communityMemberId
        : CommunityMemberService.getCommunityMemberId(),
      community,
      false,
      CommunityMemberStatus.REQUESTED,
      CommunityMemberRole.MEMBER,
      EMPTY,
      user,
    );

    const errors = await CommunityMemberService.saveCommunityMembers(community.communityId, [newMember], user);
    if (!hasSize(errors)) openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [setSaving, community, member, user, openPath]);

  const joinCommunity = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    
    const newMember = toMember(
      member!.communityMemberId,
      community,
      false,
      CommunityMemberStatus.JOINED,
      CommunityMemberRole.MEMBER,
      EMPTY,
      user,
    );

    const errors = await CommunityMemberService.saveCommunityMembers(community.communityId, [newMember], user);
    if (!hasSize(errors)) openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [setSaving, member, community, user, openPath]);

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const button: ButtonMessage = event['detail'].buttons[0];
    sendRequest(toTabsMessage());
    
    switch (button.id) {
      case ButtonType.REQUEST_TO_JOIN_COMMUNITY:
        requestToJoinCommunity();
        break;

      case ButtonType.JOIN_COMMUNITY:
        joinCommunity();
        break;

      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [sendRequest, requestToJoinCommunity, joinCommunity]);

  const setupMessage = useSafeCallback((): void => {
    const buttons = getButtons(member);
    if (!hasLength(buttons)) return;
    sendRequest(toButtonsMessage(buttons));
    subscribeResponse(handleResponseSubmitted);
  }, [member, sendRequest, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
  }, [setupMessage]);

  return (
    <Component
      loading={saving}
      className="community-details"
    >
      <Container>
        <Content>
          <CommunityPhoto
            icons={getIcons(member)}
            community={community}
          />

          {!!community &&
           !!member &&
           member.status === CommunityMemberStatus.JOINED &&
            <QRCode
              url={window.location.href}
              path={embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId])}
              message="URLもしくはQRにアクセスしてもらうことで、コミュニティに参加できます。"
            />
          }

          <CommunityDescription
            community={community}
          />

          <CommunityQuestions
            community={community}
          />

          <Label>
            オーナー
          </Label>

          <MemberCards
            members={owners}
          />

          <Label>
            参加者リスト
          </Label>

          <MemberCards
            members={members}
          />

          <TipsWrapper>
            <TipsLabel>
              TIPS
            </TipsLabel>

            <TipsMessageWrapper>
              <Icon size="small" src={askBlack} />
              <TipsMessage>
                ←を押すとこのコミュニティへの相談を 作成することができます。
              </TipsMessage>
            </TipsMessageWrapper>

            <TipsMessageWrapper>
              <Icon size="small" src={settingBlack} />
              <TipsMessage>
                ←を押すとこのコミュニティの設定画面 に移ります。（オーナーのみ）
              </TipsMessage>
            </TipsMessageWrapper>

            <TipsMessageWrapper>
              <Icon size="small" src={shareBlack} />
              <TipsMessage>
                ←を押すと相談カードへの招待URLを コピーできます。
              </TipsMessage>
            </TipsMessageWrapper>
          </TipsWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityDetails;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 8}px;
`;

const Label = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration-line: underline;
  user-select: none;
  padding: ${theme.mixins.spacing * 2}px;
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const TipsLabel = styled(Typography)`
  width: 240px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration : underline;
  text-align: center;
  user-select: none;
`;

const TipsMessageWrapper = styled.div`
  width: 240px;
  height: auto;
  display: flex;
  align-items: center;
  color: ${theme.mixins.typography.fontColor.gray};
  padding: ${theme.mixins.spacing}px 0px;
`;

const TipsMessage = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-left: ${theme.mixins.spacing}px;
  user-select: none;
`;