import {Button as MuiButton, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

type Type = 'default' | 'primary' | 'secondary';

interface P {
  disabled?: boolean;
  type?: Type;
  icon?: React.ReactNode;
  label?: Label;
  onClick(): void;
}

const Button: React.FC<P> = React.memo(props => {
  const {
    disabled = false,
    type = 'default',
    icon,
    label = '',
    onClick
  } = props;

  return (
    <Component className="button">
      <Container>
        <Content>
          <StyledButton
            disableRipple
            disabled={disabled}
            type={type}
            onClick={onClick}
          >
            <StyledIcon>
              {icon}
            </StyledIcon>
            <StyledLabel>
              {label}
            </StyledLabel>
          </StyledButton>
        </Content>
      </Container>
    </Component>
  );
});

export default Button;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const StyledButton = styled(MuiButton)<{ disabled: boolean, type: Type }>`
  width: 100%;
  height: 48px;
  color: ${props => props.disabled
    ? 'rgba(0, 0, 0, 0.26)'
    : props.type === 'default'
      ? theme.mixins.typography.fontColor.black
      : theme.mixins.typography.fontColor.white};
  background: ${props => props.type === 'primary'
    ? theme.mixins.background.pink
    : props => props.type === 'secondary'
      ? theme.mixins.background.gray3
      : theme.mixins.background.white} !important;
  border-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-start;
  padding: 0px ${theme.mixins.spacing * 3}px;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLabel = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: 0px ${theme.mixins.spacing}px;
`;