import React from 'react';
import styled from 'styled-components';
import {Path} from '../../../router/Routes';
import NoGuardedSwicher from './NoGuardedSwicher';

interface P {
  path: Path;
}

const NoGuardedScreens: React.FC<P> = React.memo(props => {
  const { path } = props;

  return (
    <Screen>
      <NoGuardedSwicher
        path={path}
      />
    </ Screen>
  );
});

export default NoGuardedScreens;

const Screen = styled.div`
  width: 100%;
  height: auto;
`;