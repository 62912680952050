import {Button, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {CSSProperties} from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import ImageWithSkeleton from '../../../../components/image/ImageWithSkeleton';
import {ReplyMessage} from '../../../../entities/message-entity';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';

const IMG_SIZE = 48;

interface P {
  reply: ReplyMessage;
  onClose(): void;
}

const Reply: React.FC<P> = React.memo(props => {
  const { reply, onClose } = props;

  return (
    <Component className="reply">
      <Container>
        <Content>        
          <ImageWithSkeleton
            variant='circle'
            style={styleForUserPhoto}
            src={reply.photoURL}
          />

          <Wrapper>
            <TitleArea>
              <Title>
                {reply.title}
              </Title>

              <CloseButton
                disableRipple
                onClick={onClose}
              >
                <CloseIcon />
              </CloseButton>
            </TitleArea>

            <Details>
              {!!reply.details ? reply.details : '画像'}
            </Details>
          </Wrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default Reply;

const Container = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;

  &::before {
    width: 100vw;
    height: 72px;
    position: absolute;
    content: '';
    background: ${theme.mixins.background.black2};
    opacity: 0.5;
    z-index: -1;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  display: flex;
  align-items:center;
  padding: ${theme.mixins.spacing}px;
`;

const styleForUserPhoto: CSSProperties = {
  width: `${IMG_SIZE}px`,
  minWidth: `${IMG_SIZE}px`,
  height: `${IMG_SIZE}px`,
  borderRadius: '50%',
  objectFit: 'contain',
};

const Wrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin-left: ${theme.mixins.spacing}px;
`;

const TitleArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

const Title = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CloseButton = styled(Button)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent !important;
  color: ${theme.mixins.typography.fontColor.white};
`;


const Details = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;