import {Button, IconButton as MuiIconButton, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import Icon from '../../../../components/icon/Icon';
import {Community} from '../../../../entities/community-entity';
import {Question} from '../../../../entities/question-entity';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {toFullName} from '../../../../utils/user-util';
import {URL} from '../../../../vo/common-vo';
import account from './../../../../assets/icon/icon_account_white.png';
import balloon from './../../../../assets/icon/icon_balloon_white.png';
import share from './../../../../assets/icon/icon_share_white.png';

interface P {
  community: Community;
  question: Question;
  onClickCommunity(): void;
  onClickReply(): void;
  onClickAccount(): void;
  onClickCopy(): void;
}

const QuestionHeaderCard: React.FC<P> = React.memo(props => {
  const {
    community,
    question,
    onClickCommunity,
    onClickReply,
    onClickAccount,
    onClickCopy,
  } = props;

  return (
    <Component className="question-header-card" >
      <Container>
        <Content>
          <QuestionPhoto
              src={question.user.photoURL}
              alt={toFullName(question.user)}
            >
              <UpperArea>
                <CommunityButton
                  disableRipple
                  onClick={onClickCommunity}
                >
                  <CommunityPhoto
                    src={community.photoURL}
                  />                
                </CommunityButton>
                
                {/* {!!question.due &&
                  <RemainingDays
                    due={question.due}
                  />
                } */}
              </UpperArea>
              
              <Body>
                <Overview>
                  {question.overview}
                </Overview>

                <FooterArea>
                  {!question.user.isAnonymous &&
                    <Questioner>
                      {toFullName(question.user)}
                    </Questioner>
                  }

                  <IconButtonWrapper>
                    <IconButton
                      disableRipple
                      onClick={onClickReply}
                    >
                      <Icon
                        size="small"
                        src={balloon}
                      />
                    </IconButton>

                    {!question.user.isAnonymous &&
                      <IconButton
                        disableRipple
                        onClick={onClickAccount}
                      >
                        <Icon
                          size="small"
                          src={account}
                        />
                      </IconButton>
                    }
                    
                    <IconButton
                      disableRipple
                      onClick={onClickCopy}
                    >
                      <Icon
                        size="small"
                        src={share}
                      />
                    </IconButton>
                  </IconButtonWrapper>
                </FooterArea>
              </Body>
            </QuestionPhoto>
        </Content>
      </Container>
    </Component>
  );
});

export default QuestionHeaderCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const QuestionPhoto = styled.div<{ src: URL }>`
  width: calc(100vw - ${theme.mixins.spacing * 4}px);
  min-width: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  max-width: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  height: calc(100vw - ${theme.mixins.spacing * 4}px);
  min-height: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  max-height: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  background-color: ${theme.mixins.background.white};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 60%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${props => props.src});
  border-radius: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const UpperArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px 0px;
`;

const CommunityButton = styled(Button)`
  width: 80px;
  height: 80px;
`;

const CommunityPhoto = styled.div<{ src: URL }>`
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: ${theme.mixins.background.white};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const Body = styled.div`
`;

const Overview = styled(Typography)`
  max-height: 176px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin-bottom: ${theme.mixins.spacing}px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  user-select:none;
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

const FooterArea = styled.div`
  background: ${theme.mixins.background.black};
  border-radius: 0px 0px 16px 16px;
  display: flex;
  flex-flow: column;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
`;

const Questioner = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select:none;
  padding: ${theme.mixins.spacing / 2}px 0px;
`;

const IconButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
`;

const IconButton = styled(MuiIconButton)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: ${theme.mixins.typography.fontColor.white};
  background: ${theme.mixins.background.black} !important;

  .MuiIconButton-label {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;  
  }
`;