import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {COMMUNITY_ID} from '../../../constants/id-const';
import {Community} from '../../../entities/community-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {theme} from '../../../styles/theme';
import {isExistedIn} from '../../../utils/common-util';
import CommunityCard from './CommunityCard';

interface P {
  loaded?: boolean;
  selected?: Community[];
  communities: Community[];
  onClickCommunity(community: Community): void;
}

const CommunityCards: React.FC<P> = React.memo(props => {
  const { loaded = true, selected = [], communities, onClickCommunity } = props;

  const isSelected = useSafeCallback((community: Community): boolean => {
    return isExistedIn(selected, COMMUNITY_ID, community.communityId);
  }, [selected]);

  return (
    <Component
      loading={!loaded}
      style={{ width: '100%' }}
      className="community-cards"
    >
      <Container>
        <Content>
          {communities.map((community: Community, index) =>
            <CommunityCard
              key={index}
              selected={isSelected(community)}
              community={community}
              onClick={onClickCommunity}
            />)}
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing}px;
`;