import {v4 as uuid} from 'uuid';
import {ZERO} from '../constants/common-const';
import {COUNT_QUESTIONS, DELETE_QUESTION, FETCH_QUESTION, FETCH_QUESTIONS, FETCH_QUESTIONS_BY_COMMUNITY_ID, FETCH_QUESTIONS_BY_USER_ID, SAVE_QUESTION} from '../constants/request-const';
import {convert, convertMulti, toCountQuestionsRequest, toDeleteQuestionRequest, toFetchQuestionRequest, toFetchQuestionsByCommunityIdRequest, toFetchQuestionsByUserIdRequest, toFetchQuestionsRequest, toSaveQuestionRequest} from '../converters/request-converter';
import {CommunityId} from '../entities/community-entity';
import {Question, QuestionId} from '../entities/question-entity';
import {UserId} from '../entities/user-entity';
import {ErrorCodeEnum, ErrorItemEnum} from '../enums/error-message-enum';
import {QuestionSortEnum} from '../enums/question-enum';
import {functions} from '../firebase';
import {isDefAndNotNull} from '../utils/common-util';
import {Count, Limit, Offset} from '../vo/common-vo';
import {CountQuestionsRequest, DeleteQuestionRequest, FetchQuestionRequest, FetchQuestionsByCommunityIdRequest, FetchQuestionsByUserIdRequest, FetchQuestionsRequest, SaveQuestionRequest} from '../vo/request-vo';

export default class QuestionService {
  public static getQuestionId = (): QuestionId => uuid();

  public static saveQuestion = async (
    question: Question,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: SaveQuestionRequest =
        toSaveQuestionRequest(question);
    
      const func = functions.httpsCallable(SAVE_QUESTION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static deleteQuestion = async (
    questionId: QuestionId,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: DeleteQuestionRequest =
        toDeleteQuestionRequest(questionId);
    
      const func = functions.httpsCallable(DELETE_QUESTION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static fetchQuestion = async (
    questionId: QuestionId,
  ): Promise<Question | undefined> => {
    return await new Promise((resolve, reject) => {
      const request: FetchQuestionRequest =
        toFetchQuestionRequest(questionId);
    
      const func = functions.httpsCallable(FETCH_QUESTION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convert(res.data) : undefined))
        .catch((error) => reject(error));
    });
  };

  public static fetchQuestions = async (
    signInUserId: UserId,
    sort: QuestionSortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Question[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchQuestionsRequest =
        toFetchQuestionsRequest(signInUserId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_QUESTIONS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };

  public static fetchQuestionsByUserId = async (
    askingUserId: UserId,
    signInUserId: UserId,
    sort: QuestionSortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Question[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchQuestionsByUserIdRequest =
        toFetchQuestionsByUserIdRequest(askingUserId, signInUserId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_QUESTIONS_BY_USER_ID);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };

  public static fetchQuestionsByCommunityId = async (
    communityId: CommunityId,
    signInUserId: UserId,
    sort: QuestionSortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Question[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchQuestionsByCommunityIdRequest =
        toFetchQuestionsByCommunityIdRequest(communityId, signInUserId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_QUESTIONS_BY_COMMUNITY_ID);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };

  public static countUnconfirmedQuestions = async (
    userId: UserId,
    communityId: CommunityId,
  ): Promise<Count> => {
    return await new Promise((resolve, reject) => {
      const request: CountQuestionsRequest =
        toCountQuestionsRequest(userId, communityId);
    
      const func = functions.httpsCallable(COUNT_QUESTIONS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : ZERO))
        .catch((error) => reject(error));
    });
  };
}