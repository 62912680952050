import React, {useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {MINUS_ONE} from '../../../constants/common-const';
import {COMMUNITY_MEMBER_ID} from '../../../constants/id-const';
import {toConfirmMessage, toTabsMessage} from '../../../converters/message-converter';
import {Community} from '../../../entities/community-entity';
import {CommunityMember} from '../../../entities/community-member-entity';
import {ConfirmMessage} from '../../../entities/message-entity';
import {User} from '../../../entities/user-entity';
import {CommunityMemberRole, CommunityMemberStatus} from '../../../enums/community-member-enum';
import useMessage from '../../../redux/hooks/useMessage';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Path} from '../../../router/Routes';
import CommunityMemberService from '../../../services/community-member-service';
import CommunityService from '../../../services/community-service';
import {setSettingStyle} from '../../../styles/theme';
import {hasSize, isExistedIn} from '../../../utils/common-util';
import {embedIdInPath} from '../../../utils/path-util';
import {CardRef, PositionEnum} from '../../common/swipable-card/XSwipableCard';
import EditCommunityInfo from './parts/EditCommunityInfo';
import EditCommunityMembers from './parts/EditCommunityMembers';

const removeMembers = (
  oldMembers: CommunityMember[],
  newMembers: CommunityMember[],
): CommunityMember[] => {
  return oldMembers.filter(o =>
    !isExistedIn(newMembers, COMMUNITY_MEMBER_ID, o.communityMemberId));
}

const addMembers = (
  oldMembers: CommunityMember[],
  newMembers: CommunityMember[],
): CommunityMember[] => {
  const filteredMembers = removeMembers(oldMembers, newMembers);
  return [ ...filteredMembers, ...newMembers ];
}

export enum EditCommunityEnum {
  EDIT_COMMUNITY_INFO,
  SELECT_CONNECTIONS,
}

interface P {
  user: User;
  community: Community;
}

const EditCommunity: React.FC<P> = React.memo(props => {
  const { user, community: initCommunity } = props;
  const ref = useRef<CardRef>(null);
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const { sendRequest, subscribeResponse } = useMessage();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [index, setIndex] = useSafeState<EditCommunityEnum>(unmountRef, EditCommunityEnum.EDIT_COMMUNITY_INFO);
  const [community, setCommunity] = useSafeState<Community>(unmountRef, initCommunity);
  const [owners, setOwners] = useSafeState<CommunityMember[]>(unmountRef,
    !!initCommunity.members
      ? initCommunity.members.filter(m => m.role === CommunityMemberRole.OWNER) : []);
  const [members, setMembers] = useSafeState<CommunityMember[]>(unmountRef,
    !!initCommunity.members
      ? initCommunity.members.filter(m => m.role === CommunityMemberRole.MEMBER): []);
  const [updatedMembers, setUpdatedMembers] = useSafeState<CommunityMember[]>(unmountRef, []);

  const isOwner = useMemo<boolean>(() => {
    return owners.findIndex(o => o.user.userId === user.userId) !== MINUS_ONE;
  }, [owners, user.userId]);

  useEffect(() => {
    if (!isOwner) {
      openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [initCommunity.communityId]));
      return;
    }

    setSettingStyle();
    setLoaded(true);
  }, [isOwner, openPath, initCommunity.communityId, setLoaded]);

  const openCommunitiesScreen = useSafeCallback((): void => {
    openPath(Path.COMMUNITIES);
  }, [openPath]);

  const goBackToCommunityDetailsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [openPath, community.communityId]);

  const goBackToCommuntyEditInfoScreen = useSafeCallback((): void => {
    setIndex(EditCommunityEnum.EDIT_COMMUNITY_INFO);
  }, [setIndex]);

  const removeCommunityMember = useSafeCallback((member: CommunityMember): void => {
    const removedMember = { ...member, status: CommunityMemberStatus.LEFT };
    setMembers(members => removeMembers(members, [removedMember]));
    setUpdatedMembers(members => addMembers(members, [removedMember]));
    ref.current && ref.current.moveCard(PositionEnum.CENTER);
  }, [setMembers, setUpdatedMembers]);

  const assignOwnerRole = useSafeCallback((member: CommunityMember): void => {
    ref.current && ref.current.moveCard(PositionEnum.CENTER);
    const newOwner = { ...member, role: CommunityMemberRole.OWNER };
    setOwners(owners => addMembers(owners, [newOwner]));
    setMembers(members => removeMembers(members, [newOwner]));
    setUpdatedMembers(members => addMembers(members, [newOwner]));
  }, [setOwners, setMembers, setUpdatedMembers]);

  const assignMemberRole = useSafeCallback((member: CommunityMember): void => {
    ref.current && ref.current.moveCard(PositionEnum.CENTER);
    const newMember = { ...member, role: CommunityMemberRole.MEMBER };
    setOwners(owners => removeMembers(owners, [newMember]));
    setMembers(members => addMembers(members, [newMember]));
    setUpdatedMembers(members => addMembers(members, [newMember]));
  }, [setOwners, setMembers, setUpdatedMembers]);

  const showConnections = useSafeCallback((): void => {
    setIndex(EditCommunityEnum.SELECT_CONNECTIONS);
  }, [setIndex]);

  const leftCommunity = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    const me = [ ...owners, ...members ].find(m => m.user.userId === user.userId)!;
    const updatedMe = { ...me, status: CommunityMemberStatus.LEFT };
    const errors = await CommunityMemberService.saveCommunityMembers(community.communityId, [updatedMe], user);
    if (!hasSize(errors)) openCommunitiesScreen();
  }, [setSaving, owners, members, user, community.communityId, openCommunitiesScreen]);

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const confirm: ConfirmMessage = event['detail'].confirm;
    sendRequest(toTabsMessage());
    if (!!confirm.value) leftCommunity();
  }, [sendRequest, leftCommunity]);

  const confirmAndLeftCommunity = useSafeCallback((): void => {
    sendRequest(toConfirmMessage('退出', 'コミュニティを退出してもよろしいですか'));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  const saveUpdatedCommunity = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const [communityErrors, membersErrors] = await Promise.all([
      CommunityService.saveCommunity(community),
      CommunityMemberService.saveCommunityMembers(community.communityId, updatedMembers, user),
    ]);
    
    if (!hasSize(communityErrors) && !hasSize(membersErrors))
      goBackToCommunityDetailsScreen();
  }, [setSaving, community, updatedMembers, user, goBackToCommunityDetailsScreen]);

  const addNewMembers = useSafeCallback((newMembers: CommunityMember[]): void => {
    setMembers(members => addMembers(members, newMembers));
    setUpdatedMembers(members => addMembers(members, newMembers));
    setIndex(EditCommunityEnum.EDIT_COMMUNITY_INFO);
  }, [setMembers, setUpdatedMembers, setIndex]);

  return (
    <Component
      loading={!loaded || saving}
      className="edit-community"
    >
      <Container>
        <Content>
          {index === EditCommunityEnum.EDIT_COMMUNITY_INFO &&
            <EditCommunityInfo
              ref={ref}
              community={community}
              owners={owners}
              members={members}
              setHeader={setCommunity}
              removeFromCommunityMember={removeCommunityMember}
              assignOwnerRole={assignOwnerRole}
              assignMemberRole={assignMemberRole}
              addNewMembers={showConnections}
              leftCommunity={confirmAndLeftCommunity}
              saveUpdatedCommunity={saveUpdatedCommunity}
              goBack={goBackToCommunityDetailsScreen}
            />
          }

          {index === EditCommunityEnum.SELECT_CONNECTIONS &&
            <EditCommunityMembers
              community={community}
              members={[...owners, ...members]}
              onClickAdd={addNewMembers}
              goBack={goBackToCommuntyEditInfoScreen}
            />
          }
        </Content>
      </Container>
    </Component>
  );
});

export default EditCommunity;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;