import env, {EnvDef} from '../env/env';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const developConfig: FirebaseConfig = {
  apiKey: "AIzaSyDd_K0YiOAU-VmgA02odzqR0ByHkHHkTqk",
  authDomain: "atomica-apps-develop.firebaseapp.com",
  databaseURL: "https://atomica-apps-develop.firebaseio.com",
  projectId: "atomica-apps-develop",
  storageBucket: "atomica-apps-develop.appspot.com",
  messagingSenderId: "1047437103844",
  appId: "1:1047437103844:web:2ba8f80c850544714b52f1",
  measurementId: "G-GK79CH2KWV"
};

const stagingConfig: FirebaseConfig = {
  apiKey: "AIzaSyChLIa7E6kGCE13O3PczxxbAsTqIb-YsIg",
  authDomain: "atomica-apps-staging.firebaseapp.com",
  databaseURL: "https://atomica-apps-staging.firebaseio.com",
  projectId: "atomica-apps-staging",
  storageBucket: "atomica-apps-staging.appspot.com",
  messagingSenderId: "178268720541",
  appId: "1:178268720541:web:b3ce2adef511ee06127374",
  measurementId: "G-E6Z8R38VYK"
};

const productionConfig: FirebaseConfig = {
  apiKey: "AIzaSyBR6es_LS65GLFYp87hzGb8YVYkApp2ras",
  authDomain: "atomica-apps-production.firebaseapp.com",
  databaseURL: "https://atomica-apps-production.firebaseio.com",
  projectId: "atomica-apps-production",
  storageBucket: "atomica-apps-production.appspot.com",
  messagingSenderId: "97850186953",
  appId: "1:97850186953:web:40627c8dbaca0d2e314118",
  measurementId: "G-YDWNEKD60B"
};

export const getFirebaseConfig = (): FirebaseConfig => {
  switch (String(env)) {
    case EnvDef.DEVELOPMENT:
      return developConfig;

    case EnvDef.STAGING:
      return stagingConfig;

    case EnvDef.PRODUCTION:
      return productionConfig;

    default:
      throw new Error('EnvDef is out of target.');
  }
}
