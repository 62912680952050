import {Skeleton} from '@material-ui/lab';
import {CSSProperties} from '@material-ui/styles';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import {isEmpty} from '../../utils/common-util';
import {URL} from '../../vo/common-vo';
import Component from '../component/Component';

type Animation = 'pulse' | 'wave' | false;

type Variant = 'text' | 'rect' | 'circle';

interface P {
  animation?: Animation;
  variant?: Variant;
  style: CSSProperties;
  src: URL;
  onClick?(): void;
}

const ImageWithSkeleton: React.FC<P> = React.memo((props) => {
  const { animation, variant, style, src, onClick } = props;
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, isEmpty(src));

  const initialize = useSafeCallback((): void => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
    }
  }, [src, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);
  
  return (
    <Component className="image-with-skeleton">
      <Container>
        <Content
          onClick={() => !!onClick && onClick()}
        >
          {!loaded &&
            <Skeleton
              style={style}
              animation={animation}
              variant={variant}
            />
          }

          {loaded &&
            <StyledImage
              style={style}
              src={src}
            />
          }
        </Content>
      </Container>
    </Component>
  );
});

export default ImageWithSkeleton;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

const StyledImage = styled.div<{ style: CSSProperties, src: URL }>`
  ${props => props.style}
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;