import {Button as MuiButton, Typography} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {FallbackProps} from 'react-error-boundary';
import styled from 'styled-components';
import usePath from '../../redux/hooks/usePath';
import {Path} from '../../router/Routes';
import ErrorService from '../../services/error-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import Component from '../component/Component';
import mojaco from './../../assets/mojaco/mojaco_sorry.png';

const Error: React.FC<FallbackProps> = React.memo(props => {
  const { error } = props;
  const { openPath } = usePath();

  useEffect(() => {
    const stack = !!error.stack ? error.stack : error.message;
    ErrorService.throwError(stack);
  }, [error]);

  const openQuestionsScreen = useCallback((): void => {
    openPath(Path.QUESTIONS);
  }, [openPath]);

  return (
    <Component className="error">
      <Container>
        <Content>
          <MessageWrapper>
            <Message>
              エラーが発生しました…！
            </Message>
            <Message>
              しばらくしてから
            </Message>
            <Message>
              もう一度お試しください。
            </Message>
          </MessageWrapper>

          <Mojaco
            src={mojaco}
          />

          <ButtonWrapper>
            <Button
              disableRipple
              onClick={openQuestionsScreen}
            >
              <Label>
                戻る
              </Label>
            </Button>
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default Error;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const Button = styled(MuiButton)`
  width: 96px;
  height: 48px;
  color: ${theme.mixins.typography.fontColor.white};
  background: ${theme.mixins.background.gray3} !important;
  border-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-start;
  padding: 0px ${theme.mixins.spacing * 3}px;
`;

const Label = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: 0px ${theme.mixins.spacing}px;
`;