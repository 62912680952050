export const SUCCESS = 'success';

export const ERROR = 'error';

export const COPY_LINK = 'URLをコピーしました';

export const UPDATE_PROFILE = 'プロフィールを更新しました';

export const SEND_CONNECTION_REQUEST = 'つながりリクエストを送りました';

export const ACCEPT_CONNECTION_REQUEST = 'つながりリクエストを承認しました';

export const SKIP_CONNECTION_REQUEST = 'つながりリクエストを見送りました';

export const ACCEPT_COMMUNITY_INVITATION = 'コミュニティに参加しました';

export const SKIP_COMMUNITY_INVITATION = 'コミュニティの招待を見送りました';

export const ACCEPT_COMMUNITY_REQUEST = 'コミュニティへの参加申請を承認しました';

export const SKIP_COMMUNITY_REQUEST = 'コミュニティへの参加申請を見送りました';