import {CREATED_AT, DUE, UPDATED_AT} from "../constants/common-const";
import {Answer} from "../entities/answer-entity";
import {Community, CommunityId} from "../entities/community-entity";
import {CommunityMember} from "../entities/community-member-entity";
import {Connection, ConnectionId, ConnectionPairId} from "../entities/connection-entity";
import {FirebaseUserId} from "../entities/firebase-user-entity";
import {Idea, IdeaId} from "../entities/idea-entity";
import {Notification} from "../entities/notification-entity";
import {Question, QuestionId} from "../entities/question-entity";
import {Reply} from "../entities/reply-entity";
import {TopicId} from "../entities/topic-entity";
import {User, UserId} from "../entities/user-entity";
import {ProviderIdEnum} from "../enums/common-enum";
import {CommunitySortEnum} from "../enums/community-enum";
import {CommunityMemberRole} from "../enums/community-member-enum";
import {ConnectionSortEnum} from "../enums/connection-enum";
import {IdeaSortEnum} from "../enums/idea-enum";
import {NotificationCategory, NotificationSortEnum} from "../enums/notification-enum";
import {QuestionSortEnum} from "../enums/question-enum";
import {isDefAndNotNull} from "../utils/common-util";
import {Email, IdToken, Limit, Offset, Stack} from "../vo/common-vo";
import {CatchFrontendErrorRequest, CountNotificationsRequest, CountQuestionsRequest, CreateUserWithEmailRequest, DeleteConnectionRequest, DeleteIdeaRequest, DeleteQuestionRequest, FetchCommunitiesByQuestionIdRequest, FetchCommunitiesByRoleRequest, FetchCommunitiesByUserIdRequest, FetchCommunityRequest, FetchConnectionByUserIdsRequest, FetchConnectionRequest, FetchConnectionsRequest, FetchCustomTokenRequest, FetchIdeasRequest, FetchNotificationsRequest, FetchQuestionRequest, FetchQuestionsByCommunityIdRequest, FetchQuestionsByUserIdRequest, FetchQuestionsRequest, FetchTopicRequest, FetchUserByFirebaseUserIdRequest, FetchUserRequest, SaveAnswerRequest, SaveCommunityMembersRequest, SaveCommunityRequest, SaveConnectionRequest, SaveIdeaRequest, SaveNotificationRequest, SaveQuestionRequest, SaveReplyRequest, SaveUserRequest, VerifyUserRequest} from "../vo/request-vo";

export const convert = <T>(object: T | undefined): T | undefined => {
  if (!object) return undefined;

  const newObject = {} as T;
  Object.entries(object).forEach(field => {
    const [key, value] = field;

    switch (key) {
      case DUE:
      case CREATED_AT:
      case UPDATED_AT:
        newObject[key] = isDefAndNotNull(value)
          ? new Date(value) : undefined;
        return;
    
      default:
        newObject[key] = value;
    }

    if (Array.isArray(value)) {
      newObject[key] = value.map(convert);
      return 
    }

    if (typeof value === 'object') {
      newObject[key] = convert(value);
      return 
    }
  })

  return newObject;
}

export const convertMulti = <T>(array: T[]): T[] => {
  return array.map(item => convert<T>(item) as T);
}

export const toSaveAnswerRequest = (
  answer: Answer,
): SaveAnswerRequest => {
  return {
    answer,
  }
};

export const toFetchCustomTokenRequest = (
  idToken: any,
  providerId: ProviderIdEnum,
): FetchCustomTokenRequest => {
  return {
    uid: idToken.sub,
    displayName: idToken.name,
    email: idToken.email,
    photoURL: idToken.picture,
    providerId,
  }
};

export const toSaveCommunityRequest = (
  community: Community,
): SaveCommunityRequest => {
  return {
    community,
  }
};

export const toSaveCommunityMembersRequest = (
  communityId: CommunityId,
  members: CommunityMember[],
  editor: User,
): SaveCommunityMembersRequest => {
  return {
    communityId,
    members,
    editor,
  }
};

export const toFetchCommunityRequest = (
  communityId: CommunityId,
): FetchCommunityRequest => {
  return {
    communityId,
  }
};

export const toFetchCommunitiesByUserIdRequest = (
  userId: UserId,
  sort: CommunitySortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchCommunitiesByUserIdRequest => {
  return {
    userId,
    sort,
    limit,
    offset,
  }
};

export const toFetchCommunitiesByQuestionIdRequest = (
  questionId: QuestionId,
  sort: CommunitySortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchCommunitiesByQuestionIdRequest => {
  return {
    questionId,
    sort,
    limit,
    offset,
  }
};

export const toFetchCommunitiesByRoleRequest = (
  userId: UserId,
  role: CommunityMemberRole,
  sort: CommunitySortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchCommunitiesByRoleRequest => {
  return {
    userId,
    role,
    sort,
    limit,
    offset,
  }
};

export const toCountQuestionsRequest = (
  userId: UserId,
  communityId: CommunityId,
): CountQuestionsRequest => {
  return {
    userId,
    communityId,
  }
};

export const toSaveConnectionRequest = (
  connection: Connection,
): SaveConnectionRequest => {
  return {
    connection,
  }
};

export const toDeleteConnectionRequest = (
  connectionPairId: ConnectionPairId,
): DeleteConnectionRequest => {
  return {
    connectionPairId,
  }
};

export const toFetchConnectionRequest = (
  connectionId: ConnectionId,
): FetchConnectionRequest => {
  return {
    connectionId,
  }
};

export const toFetchConnectionByUserIdsRequest = (
  connectingId: UserId,
  connecterId: UserId,
): FetchConnectionByUserIdsRequest => {
  return {
    connectingId,
    connecterId,
  }
};

export const toFetchConnectionsRequest = (
  userId: UserId,
  sort: ConnectionSortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchConnectionsRequest => {
  return {
    userId,
    sort,
    limit,
    offset,
  }
};

export const toCatchFrontendErrorRequest = (
  stack: Stack,
): CatchFrontendErrorRequest => {
  return {
    stack,
  }
};

export const toSaveNotificationRequest = (
  notification: Notification,
): SaveNotificationRequest => {
  return {
    notification,
  }
};

export const toFetchNotificationsRequest = (
  userId: UserId,
  category: NotificationCategory,
  sort: NotificationSortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchNotificationsRequest => {
  return {
    userId,
    category,
    sort,
    limit,
    offset,
  }
};

export const toCountNotificationsRequest = (
  userId: UserId,
  category: NotificationCategory,
): CountNotificationsRequest => {
  return {
    userId,
    category,
  }
};

export const toSaveQuestionRequest = (
  question: Question,
): SaveQuestionRequest => {
  return {
    question,
  }
};

export const toDeleteQuestionRequest = (
  questionId: QuestionId,
): DeleteQuestionRequest => {
  return {
    questionId,
  }
};

export const toFetchQuestionRequest = (
  questionId: QuestionId,
): FetchQuestionRequest => {
  return {
    questionId,
  }
};

export const toFetchQuestionsRequest = (
  signInUserId: UserId,
  sort: QuestionSortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchQuestionsRequest => {
  return {
    signInUserId,
    sort,
    limit,
    offset,
  }
};

export const toFetchQuestionsByUserIdRequest = (
  askingUserId: UserId,
  signInUserId: UserId,
  sort: QuestionSortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchQuestionsByUserIdRequest => {
  return {
    askingUserId,
    signInUserId,
    sort,
    limit,
    offset,
  }
};

export const toFetchQuestionsByCommunityIdRequest = (
  communityId: CommunityId,
  signInUserId: UserId,
  sort: QuestionSortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchQuestionsByCommunityIdRequest => {
  return {
    communityId,
    signInUserId,
    sort,
    limit,
    offset,
  }
};

export const toSaveReplyRequest = (
  reply: Reply,
): SaveReplyRequest => {
  return {
    reply,
  }
};

export const toSaveIdeaRequest = (
  idea: Idea,
): SaveIdeaRequest => {
  return {
    idea,
  }
};

export const toDeleteIdeaRequest = (
  ideaId: IdeaId,
): DeleteIdeaRequest => {
  return {
    ideaId,
  }
};

export const toFetchIdeasRequest = (
  userId: UserId,
  sort: IdeaSortEnum,
  limit?: Limit,
  offset?: Offset,
): FetchIdeasRequest => {
  return {
    userId,
    sort,
    limit,
    offset,
  }
};

export const toFetchTopicRequest = (
  topicId: TopicId,
  userId: UserId,
): FetchTopicRequest => {
  return {
    topicId,
    userId,
  }
};

export const toCreateUserWithEmailRequest = (
  email: Email,
): CreateUserWithEmailRequest => {
  return {
    email,
  }
};

export const toSaveUserRequest = (
  user: User,
): SaveUserRequest => {
  return {
    user,
  }
};

export const toFetchUserRequest = (
  userId: UserId,
): FetchUserRequest => {
  return {
    userId,
  }
};

export const toFetchUserByFirebaseUserIdRequest = (
  firebaseUserId: FirebaseUserId,
): FetchUserByFirebaseUserIdRequest => {
  return {
    firebaseUserId,
  }
};

export const toVerifyUserRequest = (
  idToken: IdToken,
  providerId: ProviderIdEnum,
): VerifyUserRequest => {
  return {
    idToken,
    providerId,
  }
};