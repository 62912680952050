import React from 'react';
import styled from 'styled-components';
import {Path} from '../../../router/Routes';
import LiffScreen from '../../liff/LiffScreen';
import RegisterNewScreen from '../../register/RegisterNewScreen';
import SignOutScreen from '../../sign-out/SignOutScreen';
import SignUpInScreen from '../../sign-up-in/SignUpInScreen';

interface P {
  path: Path;
}

const NoGuardedSwicher: React.FC<P> = React.memo(props => {
  const { path } = props;

  return (
    <Switcher>
      {(path === Path.SIGN_UP ||
        path === Path.SIGN_IN) &&
        <SignUpInScreen />
      }

      {path === Path.REGISTER_NEW &&
        <RegisterNewScreen />
      }

      {path === Path.SIGN_OUT &&
        <SignOutScreen />
      }

      {path === Path.LIFF &&
        <LiffScreen />
      }
    </ Switcher>
  );
});

export default NoGuardedSwicher;

const Switcher = styled.div`
  width: 100%;
  height: auto;
`;