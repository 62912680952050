import {Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ButtonOption} from '../../../../components/button/MultiButtons';
import CardWithIcons, {IconData} from '../../../../components/card/CardWithIcons';
import Component from '../../../../components/component/Component';
import Tips from '../../../../components/typography/Tips';
import {COMMUNITY_ID} from '../../../../constants/id-const';
import {COPY_LINK, SUCCESS} from '../../../../constants/snackbar-const';
import {toButtonsMessage, toTabsMessage} from '../../../../converters/message-converter';
import {Community} from '../../../../entities/community-entity';
import {ButtonMessage} from '../../../../entities/message-entity';
import useMessage from '../../../../redux/hooks/useMessage';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {Path} from '../../../../router/Routes';
import {theme} from '../../../../styles/theme';
import {noop} from '../../../../utils/common-util';
import {copyURL, embedIdInPath} from '../../../../utils/path-util';
import {CommunityPhotoIconEnum} from '../../community-details/parts/CommunityPhoto';
import ask from './../../../../assets/icon/icon_balloon_white.png';
import setting from './../../../../assets/icon/icon_setting_white.png';
import share from './../../../../assets/icon/icon_share_white.png';
import mojaco from './../../../../assets/mojaco/mojaco_wipe_sweat.png';

export const COMMUNITY_CREATED_ICONS: IconData[] = [
  {
    id: CommunityPhotoIconEnum.ASK,
    disabled: true,
    src: ask,
  },
  {
    id: CommunityPhotoIconEnum.SETTING,
    disabled: true,
    src: setting,
  },
  {
    id: CommunityPhotoIconEnum.SHARE,
    disabled: false,
    src: share,
  },
];

enum ButtonType {
  ASK_QUESTION = 'ask_question',
  LOOK_COMMUNITY = 'look_community',
}

const COMMUNITY_CREATED_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.LOOK_COMMUNITY,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: 'あとで',
  },
  {
    id: ButtonType.ASK_QUESTION,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '相談する',
  },
];

interface P {
  community: Community;
}

const CommunityCreated: React.FC<P> = React.memo((props: any) => {
  const { community } = props;
  const { openPath } = usePath();
  const { sendRequest, subscribeResponse } = useMessage();
  const { enqueueSnackbar } = useSnackbar();

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const button: ButtonMessage = event['detail'].buttons[0];

    switch (button.id) {
      case ButtonType.ASK_QUESTION:
        openPath(`${Path.ASK_TO}?${COMMUNITY_ID}=${community.communityId}` as Path);
        break;

      case ButtonType.LOOK_COMMUNITY:
        openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
        break;
        
      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [openPath, community.communityId]);

  const setupMessage = useSafeCallback(async (): Promise<void> => {
    sendRequest(toButtonsMessage(COMMUNITY_CREATED_BUTTONS));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
    
    return () => {
      sendRequest(toTabsMessage());
    };
  }, [setupMessage, sendRequest]);

  const copyCommunityDetailsURL = useSafeCallback((): void => {
    copyURL(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
    enqueueSnackbar(COPY_LINK, { variant: SUCCESS });
  }, [community.communityId, enqueueSnackbar]);

  return (
    <Component className="community-created">
      <Container>
        <Content>
          <MessageWrapper>
            <Message>
              作成できました…！
            </Message>
            <Message>
              早速相談してみましょう。
            </Message>
          </MessageWrapper>

          <Mojaco
            src={mojaco}
          />

          <Tips
            message="さらにメンバーを増やしたいときは、招待リンクを送って招待することもできます。"
          />

          <CardWrapper>
            <CardWithIcons
              photoURL={community.photoURL}
              label={community.name}
              icons={COMMUNITY_CREATED_ICONS}
              onClick={noop}
            />

            <CircleWrapper
              onClick={copyCommunityDetailsURL}
            >
              <Circle />
              <Label>ここ</Label>
            </CircleWrapper>
          </CardWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityCreated;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: ${window.innerHeight - 56}px;
  padding-bottom: ${theme.mixins.spacing * 10}px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing}px;
`;

const CardWrapper =styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
`;

const CircleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: relative;
  top: -54px;
  left: calc(((100% - 160px) / 6 + 20px) * -1);
`;

const Circle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 5px solid ${theme.mixins.palette.pink};
`;

const Label = styled(Typography)`
  width: 56px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;