import {Typography} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CheckButton from '../../../components/button/CheckButton';
import Component from '../../../components/component/Component';
import ImageWithSkeleton from '../../../components/image/ImageWithSkeleton';
import {EMPTY, MINUS_ONE} from '../../../constants/common-const';
import {Community} from '../../../entities/community-entity';
import {CommunityMember} from '../../../entities/community-member-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import {hasLength, noop} from '../../../utils/common-util';
import {toMemberNames} from '../../../utils/community-member-util';
import {toDayDiff} from '../../../utils/date-util';

const IMG_SIZE = 132;

interface P {
  selected?: boolean;
  community: Community;
  onClick(community: Community): void;
}

const CommunityCard: React.FC<P> = React.memo(props => {
  const { selected = false, community, onClick } = props;
  const unmountRef = useUnmountRef();
  const [selcted, setSelected] = useSafeState<boolean>(unmountRef, selected);

  const members = useMemo<CommunityMember[]>(() => {
    return !!community.members ? community.members : [];
  }, [community.members]);

  const memberNames = useMemo<string>(() => {
    return hasLength(members) ? toMemberNames(members) : EMPTY;
  }, [members]);

  const remainingDays = useMemo<number | null>(() => {
    return !!community.due ? toDayDiff(community.due) * MINUS_ONE : null;
  }, [community.due]);

  const toggleSelected = useSafeCallback((): void => {
    setSelected(selected => !selected);
    onClick(community);
  }, [setSelected, onClick, community]);

  return (
    <Component className="community-card">
      <Container>
        <Content
          onClick={toggleSelected}
        >
          <CommunityWrapper>
            <ButtonWrapper>
              <CheckButton
                checked={selcted}
                onClick={noop}
              />
            </ButtonWrapper>

            <ImageWithSkeleton
              animation="wave"
              variant='rect'
              style={styleForCommunityPhoto}
              src={community.photoURL}
            />

            <CommunityBox
              disableRipple
              onClick={noop}
            >
              <Title>
                {community.name}
              </Title>

              <MemberNames>
                {memberNames}
              </MemberNames>

              <Wrapper>
                <MembersWrapper>
                  {hasLength(members) &&
                    <Avatar
                      isHead
                      src={members[0].user.photoURL}
                    />
                  }
                  {members.length > 1 &&
                    <Avatar
                      isHead={false}
                      src={members[1].user.photoURL}
                    />
                  }
                </MembersWrapper>

                {remainingDays !== null &&
                  <DaysWrapper
                    limited={remainingDays < 2}
                  >
                    <Border>
                      <Remaining>
                        あと
                      </Remaining>
                      <Days>
                        {remainingDays}
                      </Days>
                      <Remaining>
                        日
                      </Remaining>
                    </Border>
                  </DaysWrapper>
                }
              </Wrapper>
            </CommunityBox>
          </CommunityWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityCard;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const CommunityWrapper = styled.div`
  width: 100%;
  height: ${IMG_SIZE}px;
  display: flex;
  align-items: flex-end;
`;

const ButtonWrapper = styled.div`
  width: 56px;
  min-width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const styleForCommunityPhoto: CSSProperties = {
  width: IMG_SIZE,
  minWidth: IMG_SIZE,
  height: IMG_SIZE,
  borderRadius: 10,
  objectFit: 'cover',
  marginLeft: -56,
};

const CommunityBox = styled.div`
  width: calc(100% - ${IMG_SIZE}px);
  height: ${IMG_SIZE}px;
  border-radius: 10px;
  background: ${theme.mixins.background.white};
`;

const Title = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 32px;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  border-bottom: solid 1px ${theme.mixins.palette.gray};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select:none;
  margin: ${theme.mixins.spacing}px;
`;

const MemberNames = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 24px;
  color: ${theme.mixins.typography.fontColor.gray2};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select:none;
  margin: ${theme.mixins.spacing}px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MembersWrapper = styled.div`
  max-width: calc(100% - 300px);
  display: flex;
  margin: 0px ${theme.mixins.spacing}px;
`;

const Avatar = styled.img<{ isHead: boolean }>`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 20;
  background: ${theme.mixins.background.white};
  object-fit: cover;
  margin-left: ${props => props.isHead ? 0 : theme.mixins.spacing * -1}px;
`;

const DaysWrapper = styled.div<{ limited: boolean }>`
  width: 96px;
  height: 44px;
  background: ${props => props.limited
    ? theme.mixins.background.pink
    : theme.mixins.background.gray6};
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Border = styled.div`
  width: 94px;
  height: 40px;
  border-radius: 4px 0px 0px 4px;
  border-top: solid 2px ${theme.mixins.palette.white};
  border-left: solid 2px ${theme.mixins.palette.white};
  border-bottom: solid 2px ${theme.mixins.palette.white};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const Remaining = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select:none;
`;

const Days = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select:none;
  margin: 0px ${theme.mixins.spacing / 2}px;
`;
