import React, {useMemo} from 'react';
import styled from 'styled-components';
import Bar from '../../../../components/bar/Bar';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import usePath from '../../../../redux/hooks/usePath';
import {Path} from '../../../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {Label} from '../../../../vo/common-vo';

interface AskBarOption {
  label: Label;
}

const OPTIONS: { [path: string]: AskBarOption } = {
  [Path.ASK_TO]: {
    label: '相談先を選択する',
  },
  [Path.ASK_INPUT]: {
    label: '相談内容を入力する',
  },
  [Path.ASK_DONE]: {
    label: '相談が完了しました',
  },
};

interface P {}

const AskBar: React.FC<P> = React.memo(() => {
  const { path } = usePath();
  const option = useMemo<AskBarOption>(() => OPTIONS[path], [path]);

  return (
    <Component className="ask-bar">
      <Container>
        <Content>          
          <BarWrapper>
            <Bar
              label={!!option ? option.label : EMPTY}
            />
          </BarWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default AskBar;

const Container = styled.div`
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px;
  z-index: 1000;
`;

const Content = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${theme.mixins.spacing}px;
`;

const BarWrapper = styled.div`
  width: calc(100% - 120px);
  height: auto;
`;