import {ID} from '../vo/common-vo';

export const PATH_IDS: ID[] = [
  'communityId',
  'questionId',
  'topicId',
  'userId',
];

export enum Path {
  /** sign-up */
  SIGN_UP = '/signup',

  /** register */
  REGISTER_NEW = '/register/new',
  REGISTER_INTRO = '/register/intro',

  /** sign-in */
  SIGN_IN = '/signin',

  /** sign-out */
  SIGN_OUT = '/signout',

  /** liff */
  LIFF = '/liff',

  /** questions */
  QUESTIONS = '/',
  QUESTION_DETAILS = '/question/:questionId',

  /** ask */
  ASK_TO = '/ask',
  ASK_INPUT = '/ask/new',
  ASK_DONE = '/ask/done',

  /** communities */
  COMMUNITIES = '/community',
  COMMUNITY_DETAILS = '/community/:communityId',
  COMMUNITY_EDIT = '/community/:communityId/edit',
  COMMUNITY_QUESTIONS = '/community/:communityId/questions',
  COMMUNITY_QUESTION_DETAILS = '/community/:communityId/question/:questionId',

  /** connections */
  CONNECTIONS = '/connections',

  /** notifications */
  NOTIFICATIONS_ANNOUNCE = '/notifications',
  NOTIFICATIONS_COMMUNITY = '/notifications/replies',
  NOTIFICATIONS_ACTIVITY = '/notifications/updates',  

  /** topics */
  TOPIC_NEW = '/topic/:topicId/new',

  /** account */
  ACCOUNT = '/:userId',
  ACCOUNT_EDIT = '/:userId/edit',
  ACCOUNT_QUESTIONS = '/:userId/questions',
  ACCOUNT_QUESTION_DETAILS = '/:userId/question/:questionId',
  ACCOUNT_TOPICS = '/:userId/topics',
  ACCOUNT_TOPICS_EDIT = '/:userId/topics/edit',
}