import {PreviousURLAction} from '../../entities/action-entity';
import {PreviousURLActionEnum} from '../../enums/action-enum';
import {URL} from '../../vo/common-vo';

export const toPreviousURLAction = (
  previousURL: URL,
): PreviousURLAction => {
  return {
    type: PreviousURLActionEnum.SET,
    previousURL,
  };
};
