import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Content} from '../../vo/common-vo';
import Component from '../component/Component';

type Type = 'primary';

interface P {
  type: Type;
  content: Content;
}

const Label: React.FC<P> = React.memo(props => {
  const { type, content } = props;

  return (
    <Component className="label">
      <Container>
        <StyledContent>
          <StyledLabel
            type={type}
          >
            {content}
          </StyledLabel>
        </StyledContent>
      </Container>
    </Component>
  );
});

export default Label;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const StyledContent = styled.div`
  width: 100%;
  height: auto;
`;

const StyledLabel = styled(Typography)<{ type: Type }>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: ${props => props.type === 'primary'
    ? theme.mixins.background.pink
    : theme.mixins.background.gray};
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;