import React, {useEffect} from 'react';
import usePath from '../redux/hooks/usePath';
import useReservedURL from '../redux/hooks/useReservedURL';
import useSafeCallback from '../redux/hooks/useSafeCallback';
import useSafeState from '../redux/hooks/useSafeState';
import useUnmountRef from '../redux/hooks/useUnmountRef';
import useUser from '../redux/hooks/useUser';
import {Path} from '../router/Routes';

const AuthGuard = (Component: React.FC<any>): React.FC<any> => React.memo(() => {
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const { openPath } = usePath();
  const { saveCurrentURL } = useReservedURL();
  const [ready, setReady] = useSafeState<boolean>(unmountRef, false);

  const initialize = useSafeCallback(async () => {
    const user = await getUser();
    
    if (!user) {
      saveCurrentURL();
      openPath(Path.SIGN_IN);
      return;
    }

    if (!user.isActivated) {
      saveCurrentURL();
      openPath(Path.REGISTER_NEW);
      return;
    }

    setReady(true);
  }, [getUser, saveCurrentURL, openPath, setReady]);

  useEffect(() => {    
    initialize();
  }, [initialize]);

  return (
    ready ? <Component /> : null
  );
});

export default AuthGuard;