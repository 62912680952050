import {IconButton} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {v4 as uuid} from 'uuid';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {EMPTY} from '../../../constants/common-const';
import {toAffiliation} from '../../../converters/affiliation-converter';
import {Affiliation as AffiliationVo} from '../../../entities/affiliation-entity';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import plus from './../../../assets/icon/icon_plus_black.png';
import Affiliation from './Affiliation';

const INITIAL_AFFILIATION = toAffiliation(
  uuid(),
  EMPTY,
);

interface P {
  user: User;
  onChange(affiliation: AffiliationVo): void;
}

const Affiliations: React.FC<P> = React.memo(props => {
  const { user, onChange } = props;
  const unmountRef = useUnmountRef();
  const [affiliations, setAffiliations] = useSafeState<AffiliationVo[]>(unmountRef,
    [INITIAL_AFFILIATION, INITIAL_AFFILIATION]);

  const addNewAffiliation = useSafeCallback((): void => {
    setAffiliations(affiliations => {
      affiliations.push(INITIAL_AFFILIATION);
     return [ ...affiliations ]; 
    })
  }, [setAffiliations, user]);

  return (
    <Component className="affiliations">
      <Container>
        <Content>
          {affiliations.map((affiliation, index) =>
            <Affiliation
              key={index}
              index={index}
              affiliation={affiliation}
              onChange={onChange}
            />
          )}
          
          <ButtonWrapper>
            <AddButton
              disableRipple
              onClick={addNewAffiliation}
            >
              <Icon size='xsmall' src={plus} />
            </AddButton>
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  )
});

export default Affiliations;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const AddButton = styled(IconButton)`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${theme.mixins.background.white} !important;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
`;