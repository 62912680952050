import {default as React, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {EMAIL} from '../../constants/common-const';
import {toSignUpInTab} from '../../converters/path-converter';
import {Process} from '../../enums/action-enum';
import {ProviderIdEnum} from '../../enums/common-enum';
import firebase, {analytics, auth} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useProcess from '../../redux/hooks/useProcess';
import useReservedURL from '../../redux/hooks/useReservedURL';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import AuthService from '../../services/auth-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {Code} from '../../vo/common-vo';
import ResetPassword from './reset-password/ResetPassword';
import SignIn from './sign-in/SignIn';
import SignUp from './sign-up/SignUp';
import SignUpInTabs, {SignUpInTabEnum} from './tabs/SignUpInTabs';

interface P extends RouteComponentProps {}

const SignUpInScreen: React.FC<P> = React.memo(() => {
  const { path, queryParams, openPath } = usePath();
  const { initUser } = useUser();
  const { process, setProcess } = useProcess();
  const { hasReservedURL, openReservedURL } = useReservedURL();
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<SignUpInTabEnum>(unmountRef, toSignUpInTab(path));

  const succeededToSignIn = useSafeCallback(async (res: firebase.auth.UserCredential): Promise<void> => {
    if (!res.user) {
      setProcess(Process.INITIAL);
      return;
    }

    setProcess(Process.COMPLETED);
    const email = !!res.additionalUserInfo && !!res.additionalUserInfo.profile
      ? res.additionalUserInfo.profile[EMAIL]
      : res.user.email;
    await initUser(res.user, email);

    hasReservedURL()
      ? openReservedURL()
      : openPath(Path.QUESTIONS);
  }, [setProcess, initUser, openPath, hasReservedURL, openReservedURL]);

  const failedToSignIn = useSafeCallback((): void => {
    setProcess(Process.INITIAL);
  }, [setProcess]);

  const signInWithRedirect = useSafeCallback((): void => {
    auth.getRedirectResult()
      .then(succeededToSignIn)
      .catch(failedToSignIn);
  }, []);

  const signInWithLine = useSafeCallback(async (code: Code): Promise<void> => {
    const idToken = await AuthService
      .fetchIdToken(code);

    const customToken = await AuthService
      .verifyUser(idToken, ProviderIdEnum.LINE);

    auth.signInWithCustomToken(customToken)
      .then(succeededToSignIn)
      .catch((err) => setProcess(Process.INITIAL));
  }, [setProcess]);

  useEffect(() => {
    const code = queryParams['code'];
    analytics.setCurrentScreen("ログイン画面");

    if (!!code) {
      signInWithLine(code);
      return;
    }

    signInWithRedirect();
  }, [queryParams, signInWithLine, signInWithRedirect]);

  useEffect(() => {
    setIndex(toSignUpInTab(path))
  }, [setIndex, path]);

  const showResetScreen = useSafeCallback((): void => {
    setIndex(SignUpInTabEnum.RESET_PASSWORD);
  }, [setIndex]);

  return (
    <Screen
      loading={process !== Process.INITIAL}
      className="sign-up-in-screen"
    >
      <Container>
        <Content>
          <SignUpInTabs />

          {index === SignUpInTabEnum.SIGN_UP &&
            <SignUp />
          }

          {index === SignUpInTabEnum.SIGN_IN &&
            <SignIn
              signIn={succeededToSignIn}
              reset={showResetScreen}
            />
          }

          {index === SignUpInTabEnum.RESET_PASSWORD &&
            <ResetPassword />
          }
        </Content>
      </Container>
    </Screen>
  );
});

export default SignUpInScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
`;