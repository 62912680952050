
import {Typography} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {ZERO} from '../../../constants/common-const';
import {NOTIFICATION_ID} from '../../../constants/id-const';
import {Notification, NotificationId} from '../../../entities/notification-entity';
import {User} from '../../../entities/user-entity';
import {NotificationCategory, NotificationSortEnum, NotificationType} from '../../../enums/notification-enum';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import NotificationService from '../../../services/notification-service';
import {theme} from '../../../styles/theme';
import {hasLength, removeFrom} from '../../../utils/common-util';
import {Offset} from '../../../vo/common-vo';
import AnnounceNotificationCard from '../notification-card/AnnounceNotificationCard';
import AnswerNotificationCard from '../notification-card/AnswerNotificationCard';
import ConnectNotificationCard from '../notification-card/ConnectNotificationCard';
import IdeaNotificationCard from '../notification-card/IdeaNotificationCard';
import InviteNotificationCard from '../notification-card/InviteNotificationCard';
import ReplyNotificationCard from '../notification-card/ReplyNotificationCard';
import RequestNotificationCard from '../notification-card/RequestNotificationCard';
import mojaco from './../../../assets/mojaco/mojaco_thanks3.png';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: "0px 0px 300px 0px",
};

interface P {
  user: User;
  category: NotificationCategory;
}

const NotificationList: React.FC<P> = React.memo(props => {
  const { user, category } = props;
  const ref = useRef<HTMLDivElement>();
  const offset = useRef<Offset>(ZERO);
  const hasMore = useRef<boolean>(true);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [notifications, setNotifications] = useSafeState<Notification[]>(unmountRef, []);

  const loadNotifications = useSafeCallback(async (): Promise<void> => {
    if (!hasMore) return;
    
    const newNotifications = await NotificationService.fetchNotifications(
      user.userId,
      category,
      NotificationSortEnum.CREATED_AT_DESC,
      LIMIT,
      offset.current,
    );

    offset.current += LIMIT;
    hasMore.current = newNotifications.length === LIMIT;
    setNotifications(prevNotifications =>
      [ ...prevNotifications, ...newNotifications ]);
    setLoaded(true);
  }, [user, category, setNotifications, setLoaded]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const onScroll = useSafeCallback((
    entries: IntersectionObserverEntry[],
  ): void => {
    for (const entry of entries) {
      if (!entry.isIntersecting) return;
      loadNotifications();
    }
  }, [loadNotifications]);

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => onScroll(entries),
      OPTIONS,
    );

    ref.current && observer.observe(ref.current);
    
    return () => {
      observer.disconnect();
     }
  }, [loaded, onScroll]);

  const removeNotification = useSafeCallback((notificationId: NotificationId): void => {
    setNotifications(notifications => removeFrom(notifications, NOTIFICATION_ID, notificationId))
  }, [setNotifications]);

  return (
    <Component
      loading={!loaded}
      className="notification-list"
    >
      <Container>
        <Content>
          {notifications.map((notification, index) =>
            <NotificationCardWrapper
              key={index}
            >
              {notification.srcType === NotificationType.ANNOUNCE && 
                <AnnounceNotificationCard
                  notification={notification}
                />
              }
              {notification.srcType === NotificationType.REQUEST_MEMBER && 
                <RequestNotificationCard
                  notification={notification}
                  user={user}
                  onRemove={removeNotification}
                />
              }
              {notification.srcType === NotificationType.INVITE_MEMBER && 
                <InviteNotificationCard
                  notification={notification}
                  user={user}
                  onRemove={removeNotification}
                />
              }
              {notification.srcType === NotificationType.REQUEST_CONNECTION && 
                <ConnectNotificationCard
                  notification={notification}
                  onRemove={removeNotification}
                />
              }
              {notification.srcType === NotificationType.RECEIVE_ANSWER && 
                <AnswerNotificationCard
                  notification={notification}
                />
              }
              {notification.srcType === NotificationType.RECEIVE_REPLY && 
                <ReplyNotificationCard
                  notification={notification}
                />
              }
              {notification.srcType === NotificationType.ADD_IDEA && 
                <IdeaNotificationCard
                  notification={notification}
                />
              }
            </NotificationCardWrapper>
          )}

          {!hasLength(notifications) &&
            <Wrapper>
              <Mojaco
                src={mojaco}
              />

              <Message>
                すべて確認済みです
              </Message>
            </Wrapper>
          }

          <Bottom ref={ref} />
        </Content>
      </Container>
    </Component>
  );
});

export default NotificationList;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 8px;
`;

const NotificationCardWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 136px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Message = styled(Typography)`
  width: 100%;
  height: auto;
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Bottom = styled.div``;