import {to, useSpring} from '@react-spring/core';
import {animated} from '@react-spring/web';
import React, {useEffect, useImperativeHandle, useRef} from 'react';
import {useGesture} from 'react-use-gesture';
import {FullGestureState} from 'react-use-gesture/dist/types';
import styled from 'styled-components';
import {ZERO} from '../../../constants/common-const';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import {isDefAndNotNull} from '../../../utils/common-util';
import CardButton, {Button} from './CardButton';

const trans = (x: number, y: number): string =>
  `translate3d(${x}px, ${y}px, 0px)`;

const animate = (p: number, r: number, s: number): string =>
  `perspective(${p}px) rotateX(${r}deg) rotateY(${r}deg) rotateZ(${r}deg) scale(${s})`;

type Axis = 'x' | 'y' | undefined;

enum DirectionEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum PositionEnum {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left',
}

export interface CardOptions {
  rightButton?: Button;
  leftButton?: Button;
}

interface CardProps {
  x: number;
  delay?: number;
}

const DECK_SETTINGS: { [key: string]: number } = {
  /** position */
  DEFAULT_X_POSITION: 0,

  /** distance */
  MOVING_DISTANCE: 100,

  /** delay */
  TRANSITION_DELAY: 40,
}

const toX = (p: PositionEnum): Partial<CardProps> => {
  return {
    x: p === PositionEnum.RIGHT
      ? DECK_SETTINGS.DEFAULT_X_POSITION + DECK_SETTINGS.MOVING_DISTANCE
      : p === PositionEnum.LEFT
        ? DECK_SETTINGS.DEFAULT_X_POSITION - DECK_SETTINGS.MOVING_DISTANCE
        : DECK_SETTINGS.DEFAULT_X_POSITION,
    delay: DECK_SETTINGS.TRANSITION_DELAY,
  }
};

const isTargetGesture = (
  state: Omit<FullGestureState<"drag">, "event">,
): boolean => {
  const isDefinedDirection = isDefAndNotNull(state.axis);
  const finishedDragging = !state.dragging;
  return isDefinedDirection && finishedDragging;
}

const toDirection = (
  axis: Axis,
  xDir: number,
  yDir: number,
): DirectionEnum => {
  switch (axis) {
    case 'x':
      return xDir > 0 ? DirectionEnum.RIGHT : DirectionEnum.LEFT;

    case 'y':
      return yDir > 0 ? DirectionEnum.BOTTOM : DirectionEnum.TOP;
      
    default:
      throw new Error(`${axis} is out of target.`);
  }
}

const toNextPosition = (
  position: PositionEnum,
  direction: DirectionEnum,
  options?: CardOptions,
): PositionEnum => {
  switch (position) {
    case PositionEnum.RIGHT:
      return direction === DirectionEnum.LEFT
        ? PositionEnum.CENTER : PositionEnum.RIGHT;

    case PositionEnum.LEFT:
      return direction === DirectionEnum.RIGHT
        ? PositionEnum.CENTER : PositionEnum.LEFT;
  }

  switch (direction) {
    case DirectionEnum.RIGHT:
      return !!options && !!options.leftButton
        ? PositionEnum.RIGHT
        : PositionEnum.CENTER;

    case DirectionEnum.LEFT:
      return !!options && !!options.rightButton
        ? PositionEnum.LEFT
        : PositionEnum.CENTER;

    default:
      return PositionEnum.CENTER;
  }
}

export interface CardRef {
  moveCard(position: PositionEnum): void;
}

interface P {
  cardOptions?: CardOptions;
  children: React.ReactNode;
  onClickRight(): void;
  onClickLeft(): void;
}

const XSwipableCard: React.ForwardRefExoticComponent<P & React.RefAttributes<CardRef>> = React.forwardRef<CardRef, P>((props, cardRef) => {
  const {
    cardOptions,
    children,
    onClickRight,
    onClickLeft
  } = props;

  const ref = useRef<HTMLDivElement>();
  const rightId = useRef<NodeJS.Timeout>();
  const leftId = useRef<NodeJS.Timeout>();
  const unmountRef = useUnmountRef();
  const [cardProps, setCardProps] = useSpring<CardProps>(() => toX(PositionEnum.CENTER));
  const [showRightButton, setShowRightButton] = useSafeState<boolean>(unmountRef, false);
  const [showLeftButton, setShowLeftButton] = useSafeState<boolean>(unmountRef, false);
  const [cardWidth, setCardWidth] = useSafeState<number>(unmountRef, ZERO);
  const [cardHieght, setCardHeight] = useSafeState<number>(unmountRef, ZERO);
  const [position, setPosition] = useSafeState<PositionEnum>(unmountRef, PositionEnum.CENTER);
 
  const updateShowButton = useSafeCallback((): void => {
    switch (position) {
      case PositionEnum.CENTER:
        rightId.current = setTimeout(() => setShowRightButton(false), 400);
        leftId.current = setTimeout(() => setShowLeftButton(false), 400);
        break;

      case PositionEnum.RIGHT:
        leftId.current && clearTimeout(leftId.current);
        setShowLeftButton(true);
        break;
      
      case PositionEnum.LEFT:
        rightId.current && clearTimeout(rightId.current);
        setShowRightButton(true);
        break;
      
      default:
        throw new Error(`${position} is out of target.`);
    }
  }, [position, setShowRightButton, setShowLeftButton]);

  const getCardWidth = useSafeCallback((): number => {
    return ref.current ? ref.current.getBoundingClientRect().width : ZERO;
  }, []);

  const getCardHeight = useSafeCallback((): number => {
    return ref.current ? ref.current.getBoundingClientRect().height : ZERO;
  }, []);

  const initialize = useSafeCallback((): void => {
    updateShowButton();
    setCardWidth(getCardWidth());
    setCardHeight(getCardHeight());
  }, [updateShowButton, setCardWidth, getCardWidth, setCardHeight, getCardHeight]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleDragGestureChanged = useSafeCallback((
    state: Omit<FullGestureState<"drag">, "event">,
  ): void => {
    if (!isTargetGesture(state)) return;
    
    setPosition(prevPosition => {
      const { axis, direction: [xDir, yDir] } = state;
      const direction = toDirection(axis, xDir, yDir);
      const nextPosition = toNextPosition(prevPosition, direction, cardOptions);
      setCardProps(toX(nextPosition));
      return nextPosition;
    });
  }, [setPosition, cardOptions, setCardProps])

  const bind = useGesture({
      onDrag: handleDragGestureChanged,
  });

  const moveCard = useSafeCallback((nextPosition: PositionEnum): void => {
    setPosition(nextPosition);
    setCardProps(toX(nextPosition));
  }, [setPosition, setCardProps]);

  useImperativeHandle(cardRef, () => ({
    moveCard: (position: PositionEnum) => moveCard(position),
  }));

  return (
    <Container
      style={{
        height: cardHieght + theme.mixins.spacing,
      }}
    >
      <Content
        style={{
          width: cardWidth,
          height: cardHieght,
          transform: to([0, 0], trans),
        }}
      >
        {showRightButton &&
          !!cardOptions &&
          !!cardOptions.rightButton &&
          <CardButton
            position="right"
            width={cardWidth / 2}
            height={cardHieght}
            button={cardOptions.rightButton}
            onClick={() => onClickRight()}
          />
        }
      
        {showLeftButton &&
          !!cardOptions &&
          !!cardOptions.leftButton &&
          <CardButton
            position="left"
            width={cardWidth / 2}
            height={cardHieght}
            button={cardOptions.leftButton}
            onClick={() => onClickLeft()}
          />
        }
          
        <CardWrapper
          style={{
            transform: to([cardProps.x, 0], trans)
          }}
        >
          <Card
            {...bind()}
            ref={ref}
            style={{
              transform: to([0, 0, 1], animate)
            }}
          >
            {children}
          </Card>
        </CardWrapper>
      </Content>
    </Container>
  );
});

export default XSwipableCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
`;

const Content = styled(animated.div)`
  position: absolute;
  will-change: transform;
`;

const CardWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  will-change: transform;
`;

const Card = styled(animated.div)`
  will-change: transform;
`;