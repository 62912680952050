import {useCallback, useState} from 'react';

function useSafeState<S>(
  unmountRef: React.MutableRefObject<boolean>,
  initialState?: S | (() => S),
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState<S | undefined>(initialState);

  const wrapSetState: React.Dispatch<React.SetStateAction<S>> = useCallback((
    value: React.SetStateAction<S>,
  ): void => {
    if (unmountRef.current) return;
    setState(value as S);
  }, [unmountRef, setState]);

  return [
    state,
    wrapSetState,
  ] as [
    S,
    React.Dispatch<React.SetStateAction<S>>,
  ];
}

export default useSafeState;
