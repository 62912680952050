import {Button, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {to, useSpring} from '@react-spring/core';
import {animated} from '@react-spring/web';
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

const trans = (x: number, y: number): string =>
  `translate3d(${x}px, ${y}px, 0px)`;

interface ModalProps {
  y: number;
  delay?: number;
}

const MODAL_SETTINGS: { [key: string]: number } = {
  /** position */
  DEFAULT_Y_POSITION: 0,
  TOP_Y_POSITION: -window.innerHeight,

  /** delay */
  TRANSITION_DELAY: 0,
}

const toY = (isOpen: boolean): Partial<ModalProps> => {
  return {
    y: isOpen
      ? MODAL_SETTINGS.TOP_Y_POSITION
      : MODAL_SETTINGS.DEFAULT_Y_POSITION,
    delay: MODAL_SETTINGS.TRANSITION_DELAY,
  }
};

interface P {
  isOpen: boolean;
  label: Label;
  children: React.ReactNode;
  onClose(): void;
}

const Modal: React.FC<P> = React.memo((props) => {
  const { isOpen, label, children, onClose } = props;
  const unmountRef = useUnmountRef();
  const id = useRef<NodeJS.Timeout | void>();
  const [modalProps, setModalProps] = useSpring<ModalProps>(() => toY(isOpen));
  const [showModal, setShowModal] = useSafeState(unmountRef, false);

  useEffect(() => {
    !!id.current && clearTimeout(id.current);
    id.current = isOpen ? setShowModal(true) : setTimeout(() => setShowModal(false), 400);
    setModalProps(toY(isOpen));
  }, [isOpen, setShowModal, setModalProps]);

  return (
    <Component
      className="modal"
      loadable={showModal}
    >
      <Container>
        <Content>
          <Wrapper
            style={{
              transform: to([0, modalProps.y], trans),
            }}
          >
            <Header>
              <ButtonWrapper>
                <CloseButton
                  disableRipple
                  onClick={onClose}
                >
                  <CloseIcon />
                </CloseButton>
              </ButtonWrapper>

              <StyledLabel>
                {label}
              </StyledLabel>

              <ButtonWrapper />
            </Header>
              
            {children}
          </Wrapper>
        </Content>
      </Container>
    </Component> 
  );
});

export default Modal;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  position: fixed;
  top: ${window.innerHeight}px;
  left: ${window.innerWidth > MOBILE_MAX_WIDTH
    ? (window.innerWidth - MOBILE_MAX_WIDTH) / 2 : 0}px;
  z-index: 1300;
`;

const Wrapper = styled(animated.div)`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  min-height: 100vh;
  background: ${theme.mixins.background.yellow};
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  background: ${theme.mixins.background.white};
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray5};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select: none;
`;

const ButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

const CloseButton = styled(Button)`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent !important;
  color: ${theme.mixins.typography.fontColor.gray5};
`;
