import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {CommunityMember} from '../../../entities/community-member-entity';
import MemberCard from './MemberCard';

interface P {
  members: CommunityMember[];
}

const MemberCards: React.FC<P> = React.memo(props => {
  const { members } = props;

  return (
    <Component className="member-cards">
      <Container>
        <Content>
          {members.map((member, index) =>
            <MemberCard
              key={index}
              member={member}
            />)}
        </Content>
      </Container>
    </Component>
  );
});

export default MemberCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;