import {Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import CommentBox from '../../../components/box/CommentBox';
import MultiButtons, {ButtonOption} from '../../../components/button/MultiButtons';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {ACCEPT_COMMUNITY_INVITATION, SKIP_COMMUNITY_INVITATION, SUCCESS} from '../../../constants/snackbar-const';
import {Community} from '../../../entities/community-entity';
import {CommunityMember} from '../../../entities/community-member-entity';
import {Notification, NotificationId} from '../../../entities/notification-entity';
import {User} from '../../../entities/user-entity';
import {CommunityMemberStatus} from '../../../enums/community-member-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import CommunityMemberService from '../../../services/community-member-service';
import {theme} from '../../../styles/theme';
import {hasSize} from '../../../utils/common-util';
import {toDateTime} from '../../../utils/date-util';
import {embedIdInPath} from '../../../utils/path-util';
import clock from './../../../assets/icon/icon_clock_gray.png';

enum ButtonType {
  ACCEPT_INVITATION = 'accept_invitation',
  SKIP_INVITATION = 'skip_invitation',
}

const INVITE_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.ACCEPT_INVITATION,
    primary: true,
    color: theme.mixins.typography.fontColor.black,
    background: theme.mixins.background.orange,
    label: '参加する',
  },
  {
    id: ButtonType.SKIP_INVITATION,
    primary: false,
    color: theme.mixins.typography.fontColor.black,
    background: theme.mixins.background.gray4,
    label: '見送る',
  },
];

interface P {
  notification: Notification;
  user: User;
  onRemove(notificationId: NotificationId): void;
}

const InviteNotificationCard: React.FC<P> = React.memo(props => {
  const { notification, user, onRemove } = props;
  const { openPath } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const community = useMemo<Community>(() =>
    notification.member!.community!, [notification.member]);
  const member = useMemo<CommunityMember>(() =>
    notification.member!, [notification.member]);

  const openCommunityDetailsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [openPath, community.communityId]);

  const handleButtonClicked = useSafeCallback(async (option: ButtonOption): Promise<void> => {
    onRemove(notification.notificationId);

    switch (option.id) {
      case ButtonType.ACCEPT_INVITATION:
        const joined = { ...member, status: CommunityMemberStatus.JOINED };
        const joinedErrors = await CommunityMemberService.saveCommunityMembers(community.communityId, [joined], user);
        if (!hasSize(joinedErrors)) enqueueSnackbar(ACCEPT_COMMUNITY_INVITATION, { variant: SUCCESS });
        break;

      case ButtonType.SKIP_INVITATION:
        const skipped = { ...member, status: CommunityMemberStatus.SKIPPED };
        const skippedErrors = await CommunityMemberService.saveCommunityMembers(community.communityId, [skipped], user);
        if (!hasSize(skippedErrors)) enqueueSnackbar(SKIP_COMMUNITY_INVITATION, { variant: SUCCESS });
        break;
        
      default:
        throw new Error(`${option.id} is out of target.`);
    }
  }, [onRemove, member, community.communityId, user, enqueueSnackbar, notification]);

  return (
    <Component className="invite-notification-card">
      <Container>
        <Content>
          <CommentBox
            rectPhoto={community.photoURL}
            onClickRectPhoto={openCommunityDetailsScreen}
          >
            <Wrapper>
              <Title>
                {community.name}
              </Title>

              <SubTitle>
                {`${community.name}への招待が来ています`}
              </SubTitle>

              <ConnectButtons>
                <MultiButtons
                  options={INVITE_BUTTONS}
                  onClick={handleButtonClicked}
                />
              </ConnectButtons>

              <FooterWrapper>
                <DatetimeWrapper>
                  <Icon
                    size="xsmall"
                    src={clock}
                  />
                  <Datetime>
                    {toDateTime(notification.createdAt!)}
                  </Datetime>
                </DatetimeWrapper>
              </FooterWrapper>
            </Wrapper>
          </CommentBox>
        </Content>
      </Container>
    </Component>
  );
});

export default InviteNotificationCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Title = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const ConnectButtons = styled.div`
  margin-bottom: ${theme.mixins.spacing}px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DatetimeWrapper = styled.div`
  color: ${theme.mixins.typography.fontColor.gray5};
  display: flex;
  align-items: center;
`;

const Datetime = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin-left: ${theme.mixins.spacing / 2}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;