import React, {useEffect} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {Message} from '../../../entities/message-entity';
import {User} from '../../../entities/user-entity';
import {MessageType} from '../../../enums/message-enum';
import useMessage from '../../../redux/hooks/useMessage';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import FooterButtons from './parts/FooterButtons';
import FooterConfirmation from './parts/FooterConfirmation';
import FooterInputBox from './parts/FooterInputBox';
import FooterTabs from './parts/FooterTabs';

interface P {}

const FooterBar: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { subscribeRequest } = useMessage();
  const { getUser } = useUser();
  const [index, setIndex] = useSafeState<MessageType>(unmountRef, MessageType.TABS);
  const [message, setMessage] = useSafeState<Message>(unmountRef);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    setUser(user);
  }, [getUser, setUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleRequestSubmitted = useSafeCallback((event: Event): void => {
    const message: Message = event['detail'];
    setMessage(message);
    setIndex(message.type);
  }, [setMessage, setIndex]);

  useEffect(() => {
    subscribeRequest(handleRequestSubmitted);
  }, [subscribeRequest, handleRequestSubmitted]);

  return (
    <Component className="footer-bar">
      <Container>
        <Content>
          {index === MessageType.TABS &&
            <FooterTabs
              user={user}
            />
          }

          {index === MessageType.INPUT &&
            <FooterInputBox
              user={user}
              input={message.input!}
            />
          }

          {index === MessageType.BUTTONS &&
            <FooterButtons
              disabled={message.disabled!}
              buttons={message.buttons!}
            />
          }

          {index === MessageType.CONFIRM &&
            <FooterConfirmation
              confirm={message.confirm!}
            />
          }
        </Content>
      </Container>
    </Component>
  );
});

export default FooterBar;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;