import {Button as MuiButton, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  label: Label;
  onClick(): void;
}

const TextButton: React.FC<P> = React.memo(props => {
  const { label, onClick } = props;

  return (
    <Component className="text-button">
      <Container>
        <Content>
          <StyledButton
            disableRipple
            onClick={onClick}
          >
            <StyledLabel>
              {label}
            </StyledLabel>
          </StyledButton>
        </Content>
      </Container>
    </Component>
  );
});

export default TextButton;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const StyledButton = styled(MuiButton)`
  width: 100%;
  height: auto;
  background: none !important;
  padding: 0px;
`;

const StyledLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;