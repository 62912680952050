import {Typography} from '@material-ui/core';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import Box from '../../../components/box/Box';
import Button from '../../../components/button/Button';
import Component from '../../../components/component/Component';
import CheckBox from '../../../components/input/CheckBox';
import InputBox from '../../../components/input/InputBox';
import {EMPTY} from '../../../constants/common-const';
import {setFullName} from '../../../converters/user-converter';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import {theme} from '../../../styles/theme';
import {isEmpty} from '../../../utils/common-util';
import {Name} from '../../../vo/common-vo';
import atomica from './../../../assets/atomica.gif';
import Remarks from './Remarks';

interface P {
  user: User;
  onFinish(fbUser: User | null): void;
}

const InputAccountWithoutPassword: React.FC<P> = React.memo(props => {
  const { user, onFinish } = props;
  const unmountRef = useUnmountRef();
  const { updateUser } = useUser();
  const [checked, setChecked] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [familyName, setFamilyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstName, setFirstName] = useSafeState<Name>(unmountRef, EMPTY);

  const disabled = useMemo<boolean>(() => {
   return !checked || isEmpty(familyName) || isEmpty(firstName);
  }, [checked, familyName, firstName]);

  const saveFullName = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    const fullNameUser = setFullName(user, familyName, firstName);
    await updateUser(fullNameUser);
    onFinish(fullNameUser);
  }, [setSaving, user, familyName, firstName, updateUser, onFinish]);

  return (
    <Component
      loading={saving}
      className="input-account-without-password"
    >
      <Container>
        <Content>
          <Logo
            src={atomica}
          />

          <InputWrapper>
            <Box
              disabled
            >
              <Email>
                {user.email}
              </Email>
            </Box>
          </InputWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox
                maxLength={15}
                label="姓"
                value={familyName}
                onChange={setFamilyName}
              />
            </NameWrapper>

            <NameWrapper>
              <InputBox
                maxLength={15}
                label="名"
                value={firstName}
                onChange={setFirstName}
              />
            </NameWrapper>
          </FullNameWrapper>

          <Agreement>
            <CheckBox
              label="利用規約およびプライバシーポリシーに同意する"
              checked={checked}
              onChange={() => setChecked(checked => !checked)}
            />
          </Agreement>

          <ButtonWrapper>
            <Button
              type="primary"
              disabled={disabled}
              label="アカウント作成"
              onClick={saveFullName}
            />
          </ButtonWrapper>
          <Remarks />
        </Content>
      </Container>
    </Component>
  )
});

export default InputAccountWithoutPassword;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.mixins.spacing * 6}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: cover;
  padding: ${theme.mixins.spacing * 6}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Email = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const FullNameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const NameWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: 88px;
`;

const Agreement = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;