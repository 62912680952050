import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import {ButtonOption} from '../../components/button/MultiButtons';
import Screen from '../../components/screen/Screen';
import {toButtonsMessage, toTabsMessage} from '../../converters/message-converter';
import {ButtonMessage} from '../../entities/message-entity';
import {User} from '../../entities/user-entity';
import {analytics} from '../../firebase';
import useMessage from '../../redux/hooks/useMessage';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {setSettingStyle, theme} from '../../styles/theme';
import {hasLength} from '../../utils/common-util';
import {embedIdInPath} from '../../utils/path-util';
import EditContact from './edit-profile-item/EditContact';
import EditFamilyName from './edit-profile-item/EditFamilyName';
import EditFirstName from './edit-profile-item/EditFirstName';
import EditOwnerCommunity from './edit-profile-item/EditOwnerCommunity';
import EditProfilePhoto from './edit-profile-item/EditProfilePhoto';
import EditSelfIntroduction from './edit-profile-item/EditSelfIntroduction';
import EditTopics from './edit-profile-item/EditTopics';

enum ButtonType {
  SAVE_ACCOUNT = 'save_account',
  GO_BACK = 'go_back',
}

export const ACCOUNT_EDIT_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.GO_BACK,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: '戻る',
  },
  {
    id: ButtonType.SAVE_ACCOUNT,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '保存する',
  },
];

interface P extends RouteComponentProps {}

const AccountEditScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params, openPath } = usePath();
  const { getUser, updateUser, isSignInUser } = useUser();
  const { sendRequest, subscribeResponse } = useMessage();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const goBackToAccountScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT, [params.userId!]));
  }, [openPath, params.userId]);

  const saveUpdatedAccount = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    await updateUser(user);
    goBackToAccountScreen();
  }, [setSaving, updateUser, user, goBackToAccountScreen]);

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    if (!event['detail'] || !hasLength(event['detail'].buttons)) return;
    const button: ButtonMessage = event['detail'].buttons[0];
    sendRequest(toTabsMessage());

    switch (button.id) {
      case ButtonType.SAVE_ACCOUNT:
        saveUpdatedAccount();
        break;

      case ButtonType.GO_BACK:
        goBackToAccountScreen();
        break;

      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [sendRequest, saveUpdatedAccount, goBackToAccountScreen]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setSettingStyle();
    
    const user = await getUser() as User;
    setUser(user);
    setLoaded(true);
  }, [getUser, setUser, setLoaded]);

  useEffect(() => {
    if (!isSignInUser(params.userId!)) {
      openPath(embedIdInPath(Path.ACCOUNT, [params.userId!]));
      return;
    }

    initialize();
  }, [isSignInUser, params.userId, openPath, initialize]);

  const setupMessage = useSafeCallback((): void => {
    sendRequest(toButtonsMessage(ACCOUNT_EDIT_BUTTONS));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
    analytics.setCurrentScreen("アカウント編集画面");
  }, [setupMessage]);

  return (
    <Screen
      loading={!loaded || saving}
      className="account-edit-screen"
    >
      <Container>
        <Content>
          <EditFamilyName
            user={user}
            onChange={setUser}
          />

          <EditFirstName
            user={user}
            onChange={setUser}
          />
          
          <EditProfilePhoto
            user={user}
            onChange={setUser}
          />

          <EditSelfIntroduction
            user={user}
            onChange={setUser}
          />

          <EditContact
            user={user}
            onChange={setUser}
          />

          <EditOwnerCommunity
            user={user}
          />

          <EditTopics
            user={user}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default AccountEditScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 9}px;
`;
