import {Typography} from '@material-ui/core';
import {animated} from '@react-spring/web';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../../styles/theme';
import {Background, Color, Height, ID, Label, Size, Width} from '../../../vo/common-vo';

type Position = 'right' | 'left';

export interface Button {
  color: Color;
  icon?: React.ReactNode;
  label: Label;
  fontSize: Size;
  background: Background;
}

interface P {
  position: Position;
  width: Width;
  height: Height;
  button: Button;
  onClick(id: ID): void;
}

const CardButton: React.FC<P> = React.memo(props => {
  const { position, button, width, height, onClick } = props;

  return (
    <AmimatedButton
      position={position}
      width={width}
      height={height}
      button={button}
      onClick={onClick}
    >
      <LabelWrapper>
        <ButtonIcon>
          {button.icon}
        </ButtonIcon>

        <ButtonLabel
          fontSize={button.fontSize}
        >
          {button.label}
        </ButtonLabel>
      </LabelWrapper>
    </AmimatedButton>
  );
});

export default CardButton;

const AmimatedButton = styled(animated.div)<{
    position: Position,
    width: number,
    height: number,
    button: Button,
  }>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  color: ${props => props.button.color};
  border-radius: 8px;
  background: ${props => props.button.background};
  display: flex;
  justify-content: ${props => props.position === 'left' ? 'flex-start' : 'flex-end'};
  position: absolute;
  top: 0px;
  left: ${props => props.position === 'left' ? 0 : props.width}px;
`;

const LabelWrapper = styled.div`
  width: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonIcon = styled.div`
  padding-bottom: ${theme.mixins.spacing}px;
`;

const ButtonLabel = styled(Typography)<{ fontSize: Size }>`
  font-size: ${props => props.fontSize}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
`;