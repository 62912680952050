import React, {useEffect, useRef} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {IDEA_ID} from '../../constants/id-const';
import {toConfirmMessage, toTabsMessage} from '../../converters/message-converter';
import {Idea} from '../../entities/idea-entity';
import {ConfirmMessage} from '../../entities/message-entity';
import {IdeaSortEnum} from '../../enums/idea-enum';
import {analytics} from '../../firebase';
import useMessage from '../../redux/hooks/useMessage';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import IdeaService from '../../services/idea-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {setSettingStyle, theme} from '../../styles/theme';
import {removeFrom} from '../../utils/common-util';
import {embedIdInPath} from '../../utils/path-util';
import {CardRef, PositionEnum} from '../common/swipable-card/XSwipableCard';
import EditTopic from './edit-topic/EditTopic';

interface P extends RouteComponentProps {}

const AccountTopicsEditScreen: React.FC<P> = React.memo(() => {
  const ref = useRef<CardRef>(null);
  const unmountRef = useUnmountRef();
  const { isSignInUser } = useUser();
  const { params, openPath } = usePath();
  const { sendRequest, subscribeResponse } = useMessage();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);  
  const [ideas, setIdeas] = useSafeState<Idea[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setSettingStyle();

    const ideas = await IdeaService.fetchIdeas(
      params.userId!,
      IdeaSortEnum.CREATED_AT_DESC,
    );

    setIdeas(ideas);
    setLoaded(true);
  }, [params.userId, setIdeas, setLoaded]);

  useEffect(() => {
    if (!isSignInUser(params.userId!)) {
      openPath(embedIdInPath(Path.ACCOUNT, [params.userId!]));
      return;
    }

    initialize();
    analytics.setCurrentScreen("一問一答編集画面");
  }, [isSignInUser, params.userId, openPath, initialize]);

  const deleteIdea = useSafeCallback(async (idea: Idea): Promise<void> => {
    ref.current && ref.current.moveCard(PositionEnum.CENTER);
    setIdeas(ideas => removeFrom(ideas, IDEA_ID, idea.ideaId));
    await IdeaService.deleteIdea(idea.ideaId);
  }, [setIdeas]);

  const handleResponseSubmitted = useSafeCallback((event: Event, idea: Idea): void => {
    const confirm: ConfirmMessage = event['detail'].confirm;
    sendRequest(toTabsMessage());
    if (!!confirm.value) deleteIdea(idea);
  }, [sendRequest, deleteIdea]);

  const confirmAndDeleteIdea = useSafeCallback((idea: Idea): void => {
    sendRequest(toConfirmMessage('削除', '削除してもよろしいですか'));
    subscribeResponse(event => handleResponseSubmitted(event, idea));
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  return (
    <Screen
      loading={!loaded}
      className="account-topics-edit-screen"
    >
      <Container>
        <Content>
          {ideas.map((idea, index) =>
            <EditTopic
              ref={ref}
              key={index}
              idea={idea}
              deleteIdea={confirmAndDeleteIdea}
            />              
          )}
        </Content>
      </Container>
    </Screen>
  );
});

export default AccountTopicsEditScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 9}px;
`;