import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import {hasLength} from '../utils/common-util';
import {ASIA_NORTHEAST1} from './../constants/common-const';
import {getFirebaseConfig} from './config';

!hasLength(firebase.apps)
  && firebase.initializeApp(getFirebaseConfig());

export default firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const storage = firebase.storage();

export const functions = firebase.app().functions(ASIA_NORTHEAST1);

export const performance = firebase.performance();

export const analytics = firebase.analytics();