import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import Component from '../component/Component';

interface P {
  disabled?: boolean;
  children: React.ReactNode;
}

const Box: React.FC<P> = React.memo(props => {
  const { disabled = false, children } = props;

  return (
    <Component className="box">
      <Container>
        <Content>
          <StyledBox
            disabled={disabled}
          >
            {children}
          </StyledBox>
        </Content>
      </Container>
    </Component>
  );
});

export default Box;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const StyledBox = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: auto;
  background: ${props => props.disabled
    ? theme.mixins.background.gray5
    : theme.mixins.background.white};
  border-radius: 16px;
  padding: ${theme.mixins.spacing * 2}px;
`;