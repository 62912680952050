import {CommunityId} from '../entities/community-entity';
import {QuestionId} from '../entities/question-entity';
import {User, UserId} from '../entities/user-entity';
import {Path} from '../router/Routes';
import {FooterTabEnum} from '../screens/common/footer-bar/parts/FooterTabs';
import {HeaderBarEnum} from '../screens/common/header-bar/HeaderBar';
import {NotificationsTabEnum} from '../screens/common/header-bar/parts/NotificationsTabs';
import {RelationsTabEnum} from '../screens/common/header-bar/parts/RelationsTabs';
import {SignUpInTabEnum} from '../screens/sign-up-in/tabs/SignUpInTabs';
import {embedIdInPath} from '../utils/path-util';

export const isAuthGuarded = (path: Path): boolean => {
  switch (path) {
    case Path.SIGN_UP:
    case Path.SIGN_IN:
    case Path.SIGN_OUT:
    case Path.LIFF:
    case Path.REGISTER_NEW:
      return false;

    case Path.REGISTER_INTRO:
    case Path.QUESTIONS:
    case Path.QUESTION_DETAILS:
    case Path.COMMUNITIES:
    case Path.COMMUNITY_DETAILS:
    case Path.COMMUNITY_EDIT:
    case Path.COMMUNITY_QUESTIONS:
    case Path.COMMUNITY_QUESTION_DETAILS:
    case Path.ASK_TO:
    case Path.ASK_INPUT:
    case Path.ASK_DONE:
    case Path.TOPIC_NEW:
    case Path.NOTIFICATIONS_ANNOUNCE:
    case Path.NOTIFICATIONS_COMMUNITY:
    case Path.NOTIFICATIONS_ACTIVITY:
    case Path.CONNECTIONS:
    case Path.ACCOUNT:
    case Path.ACCOUNT_EDIT:
    case Path.ACCOUNT_QUESTIONS:
    case Path.ACCOUNT_QUESTION_DETAILS:
    case Path.ACCOUNT_TOPICS:
    case Path.ACCOUNT_TOPICS_EDIT:
      return true;
      
    default:
      throw new Error(`${path} is out of target.`);
  }
}

export const toSignUpInTab = (path: Path): SignUpInTabEnum => {
  switch (path) {
    case Path.SIGN_UP:
      return SignUpInTabEnum.SIGN_UP;

    case Path.SIGN_IN:
      return SignUpInTabEnum.SIGN_IN;
      
    default:
      return SignUpInTabEnum.OUT_OF_TARGET;
  }
}

export const fromSignUpInTab = (tab: SignUpInTabEnum): Path | undefined => {
  switch (tab) {
    case SignUpInTabEnum.SIGN_UP:
      return Path.SIGN_UP;

    case SignUpInTabEnum.SIGN_IN:
      return Path.SIGN_IN;
  }
}

export const toHeaderBar = (path: Path): HeaderBarEnum => {
  switch (path) {    
    case Path.ASK_TO:
    case Path.ASK_INPUT:
    case Path.ASK_DONE:
      return HeaderBarEnum.ASK;

    case Path.COMMUNITIES:
    case Path.CONNECTIONS:
      return HeaderBarEnum.RELATIONS;

    case Path.NOTIFICATIONS_ACTIVITY:
    case Path.NOTIFICATIONS_COMMUNITY:
    case Path.NOTIFICATIONS_ANNOUNCE:
      return HeaderBarEnum.NOTIFICATIONS;

    case Path.ACCOUNT:
      return HeaderBarEnum.ACCOUNT;

    case Path.QUESTIONS:
    case Path.COMMUNITY_EDIT:
    case Path.ACCOUNT_EDIT:
      return HeaderBarEnum.Label;

    case Path.QUESTION_DETAILS:
    case Path.COMMUNITY_DETAILS:
    case Path.COMMUNITY_QUESTIONS:
    case Path.COMMUNITY_QUESTION_DETAILS:
    case Path.TOPIC_NEW:
    case Path.ACCOUNT_QUESTIONS:
    case Path.ACCOUNT_QUESTION_DETAILS:
    case Path.ACCOUNT_TOPICS:
    case Path.ACCOUNT_TOPICS_EDIT:
      return HeaderBarEnum.BACK;

    default:
      return HeaderBarEnum.OUT_OF_TARGET;
  }
}

export const toNextQuestionPath = (
  path: Path,
  communityId: CommunityId,
  questionId: QuestionId,
  userId: UserId,
): Path | undefined => {
  switch(path) {
    case Path.QUESTIONS:
      return embedIdInPath(Path.QUESTION_DETAILS, [questionId]);

    case Path.QUESTION_DETAILS:
      return Path.QUESTIONS;

    case Path.COMMUNITY_QUESTIONS:
      return embedIdInPath(Path.COMMUNITY_QUESTION_DETAILS, [communityId, questionId]);
  
    case Path.COMMUNITY_QUESTION_DETAILS:
      return embedIdInPath(Path.COMMUNITY_QUESTIONS, [communityId]);

    case Path.ACCOUNT_QUESTIONS:
      return embedIdInPath(Path.ACCOUNT_QUESTION_DETAILS, [userId, questionId]);

    case Path.ACCOUNT_QUESTION_DETAILS:
      return embedIdInPath(Path.ACCOUNT_QUESTIONS, [userId]);
  }
}

export const toRelationsTab = (path: Path): RelationsTabEnum  => {
  switch (path) {
    case Path.COMMUNITIES:
      return RelationsTabEnum.COMMUNITIES;
      
    case Path.CONNECTIONS:
      return RelationsTabEnum.CONNECTIONS;
      
    default:
      return RelationsTabEnum.OUT_OF_TARGET;
  }
}

export const fromRelationsTab = (tab: RelationsTabEnum): Path | undefined => {
  switch (tab) {
    case RelationsTabEnum.COMMUNITIES:
      return Path.COMMUNITIES;

    case RelationsTabEnum.CONNECTIONS:
      return Path.CONNECTIONS;
  }
}

export const toNotificationsTab = (path: Path): NotificationsTabEnum  => {
  switch (path) {
    case Path.NOTIFICATIONS_ANNOUNCE:
      return NotificationsTabEnum.ANNOUNCE;
      
    case Path.NOTIFICATIONS_COMMUNITY:
      return NotificationsTabEnum.REPLIES;
      
    case Path.NOTIFICATIONS_ACTIVITY:
      return NotificationsTabEnum.UPDATES;
  
    default:
      return NotificationsTabEnum.OUT_OF_TARGET;
  }
}

export const fromNotificationsTab = (tab: NotificationsTabEnum): Path | undefined => {
  switch (tab) {
    case NotificationsTabEnum.ANNOUNCE:
      return Path.NOTIFICATIONS_ANNOUNCE;

    case NotificationsTabEnum.REPLIES:
      return Path.NOTIFICATIONS_COMMUNITY;

    case NotificationsTabEnum.UPDATES:
      return Path.NOTIFICATIONS_ACTIVITY;
  }
}

export const toFooterTab = (path: Path): FooterTabEnum => {
  switch (path) {
    case Path.QUESTIONS:
    case Path.QUESTION_DETAILS:
      return FooterTabEnum.QUESTIONS;

    case Path.ASK_TO:
    case Path.ASK_INPUT:
    case Path.ASK_DONE:
      return FooterTabEnum.ASK;

    case Path.COMMUNITIES:
    case Path.COMMUNITY_DETAILS:
    case Path.COMMUNITY_QUESTIONS:
    case Path.COMMUNITY_QUESTION_DETAILS:
    case Path.CONNECTIONS:
      return FooterTabEnum.RELATIONS;
  
    case Path.NOTIFICATIONS_ANNOUNCE:
    case Path.NOTIFICATIONS_COMMUNITY:
    case Path.NOTIFICATIONS_ACTIVITY:
        return FooterTabEnum.NOTIFICATIONS;

    case Path.ACCOUNT:
    case Path.ACCOUNT_EDIT:
    case Path.ACCOUNT_QUESTIONS:
    case Path.ACCOUNT_QUESTION_DETAILS:
    case Path.ACCOUNT_TOPICS:
    case Path.ACCOUNT_TOPICS_EDIT:
      return FooterTabEnum.ACCOUNT;
      
    default:
      return FooterTabEnum.OUT_OF_TARGET;
  }
}

export const fromFooterTab = (tab: FooterTabEnum, user: User): Path | undefined => {
  switch (tab) {
    case FooterTabEnum.QUESTIONS:
      return Path.QUESTIONS;

    case FooterTabEnum.ASK:
      return Path.ASK_TO;

    case FooterTabEnum.RELATIONS:
      return Path.COMMUNITIES;

    case FooterTabEnum.NOTIFICATIONS:
      return Path.NOTIFICATIONS_ANNOUNCE;

    case FooterTabEnum.ACCOUNT:
      return user && embedIdInPath(Path.ACCOUNT, [user.userId]);
  }
}