import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {User} from '../../../entities/user-entity';
import {Email, Name} from '../../../vo/common-vo';
import InputAccountWithoutPassword from './InputAccountWithoutPassword';
import InputAccountWithPassword from './InputAccountWithPassword';

export interface FullName {
  familyName: Name;
  firstName: Name;
}

interface P {
  email: Email;
  user: User | null;
  onFinish(user: User | null): void;
}

const InputAccount: React.FC<P> = React.memo(props => {
  const { email, user, onFinish } = props;

  return (
    <Component className="input-account">
      <Container>
        <Content>
          {!user &&
            <InputAccountWithPassword
              email={email}
              onFinish={onFinish}
            />
          }

          {!!user &&
            <InputAccountWithoutPassword
              user={user}
              onFinish={onFinish}
            />
          }
        </Content>
      </Container>
    </Component>
  )
});

export default InputAccount;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;