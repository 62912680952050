import {makeStyles} from "@material-ui/core";
import {StylesProvider, ThemeProvider as MuiThemeProvider} from "@material-ui/styles";
import {SnackbarOrigin, SnackbarProvider} from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import {ErrorBoundary} from "react-error-boundary";
import {hot} from 'react-hot-loader/root';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import Error from "./components/other/Error";
import env, {EnvDef} from "./env/env";
import './index.css';
import store, {persistor} from './redux/store';
import Root from "./screens/root/Root";
import * as serviceWorker from './serviceWorker';
import {theme} from './styles/theme';

if (String(env) === EnvDef.DEVELOPMENT) {
  const {whyDidYouUpdate} = require('why-did-you-update');
  whyDidYouUpdate(React);
}

const snackbarOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

const useStyles = makeStyles(() => {
  return {
    success: {
      fontSize: theme.mixins.typography.fontSize.sixteen,
      fontWeight: theme.mixins.typography.fontWeight.sevenHundreds as number,
      fontFamily: theme.mixins.typography.fontFamily,
      backgroundColor: `${theme.mixins.background.green} !important`,
      borderRadius: 16,
    },
    error: {
      backgroundColor: `${theme.mixins.background.pink} !important`,
      borderRadius: 16,
    },
  }
});

const App: React.FC = hot(() => {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <StyledThemeProvider theme={theme}>
              <SnackbarProvider
                maxSnack={1}
                autoHideDuration={1000}
                classes={{
                  variantSuccess: classes.success,
                  variantError: classes.error,
                }}
                anchorOrigin={snackbarOrigin}
              >
                <ErrorBoundary FallbackComponent={Error}>
                  <Root />
                </ErrorBoundary>
              </SnackbarProvider>
            </StyledThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();