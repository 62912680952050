import React, {useEffect} from 'react';
import styled from 'styled-components';
import Bar from '../../../../components/bar/Bar';
import BackButton from '../../../../components/button/BackButton';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import {User} from '../../../../entities/user-entity';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import {Path} from '../../../../router/Routes';
import UserService from '../../../../services/user-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {toFullName} from '../../../../utils/user-util';
import {Label} from '../../../../vo/common-vo';

interface BackBarOption {
  label: Label;
}

const getOption = (user?: User): { [path: string]: BackBarOption } => {
  return {
    [Path.QUESTION_DETAILS]: {
      label: '相談の詳細',
    },
    [Path.COMMUNITY_DETAILS]: {
      label: 'コミュニティの詳細',
    },
    [Path.COMMUNITY_QUESTIONS]: {
      label: 'コミュニティの相談',
    },
    [Path.COMMUNITY_QUESTION_DETAILS]: {
      label: 'コミュニティの相談の詳細',
    },
    [Path.ACCOUNT_QUESTIONS]: {
      label: `${toFullName(user!)}の相談`,
    },
    [Path.ACCOUNT_QUESTION_DETAILS]: {
      label: `${toFullName(user!)}の相談の詳細`,
    },
    [Path.TOPIC_NEW]: {
      label: '一問一答の回答',
    },
    [Path.ACCOUNT_TOPICS_EDIT]: {
      label: '一問一答の編集',
    },
    [Path.ACCOUNT_TOPICS]: {
      label: '一問一答の一覧',
    },
  }
};

interface P {}

const BackBar: React.FC<P> = React.memo(() => {
  const { path, params, goBack } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [option, setOption] = useSafeState<BackBarOption>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = !!params.userId
      ? await UserService.fetchUser(params.userId)
      : undefined;
    
    setOption(getOption(user)[path]);
    setLoaded(true);
  }, [params.userId, setOption, path, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component
      loadable={loaded && !!option}
      className="back-bar"
    >
      <Container>
        <Content>
          <ButtonWrapper>
            <BackButton
              onClick={goBack}
            />
          </ButtonWrapper>
          
          <BarWrapper>
            <Bar
              label={!!option ? option.label : EMPTY}
            />
          </BarWrapper>

          <ButtonWrapper />
        </Content>
      </Container>
    </Component>
  );
});

export default BackBar;

const Container = styled.div`
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px;
  z-index: 1000;
`;

const Content = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${theme.mixins.spacing}px;
`;

const BarWrapper = styled.div`
  width: calc(100% - 120px);
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 80px;
  height: auto;
`;