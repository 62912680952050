import {EMPTY} from '../../constants/common-const';
import {isNotEmpty} from '../../utils/common-util';
import {URL} from '../../vo/common-vo';
import {toReservedURLAction} from '../actions/reserved-url-action';
import store from '../store';
import useSafeCallback from './useSafeCallback';
import useSafeState from './useSafeState';
import useUnmountRef from './useUnmountRef';

function useReservedURL() {
  const unmountRef = useUnmountRef();
  const [reservedURL, setReservedURL] = useSafeState<URL>(unmountRef, store.getState().reservedURL);

  const saveCurrentURL = useSafeCallback((): void => {
    const currentURL = window.location.href;
    setReservedURL(currentURL);
    const action = toReservedURLAction(currentURL);
    store.dispatch(action);
  }, [setReservedURL]);

  const hasReservedURL = useSafeCallback((): boolean => {
    return isNotEmpty(reservedURL);
  }, [reservedURL]);

  const openReservedURL = useSafeCallback((): void => {
    window.location.href = reservedURL;
  }, [reservedURL]);

  const removeReservedURL = useSafeCallback((): void => {
    setReservedURL(EMPTY);
  }, [setReservedURL]);

  return {
    saveCurrentURL,
    hasReservedURL,
    openReservedURL,
    removeReservedURL,
  };
}

export default useReservedURL;
