import {v4 as uuid} from 'uuid';
import {DELETE_IDEA, FETCH_IDEAS, SAVE_IDEA} from "../constants/request-const";
import {convertMulti, toDeleteIdeaRequest, toFetchIdeasRequest, toSaveIdeaRequest} from "../converters/request-converter";
import {Idea, IdeaId} from "../entities/idea-entity";
import {UserId} from "../entities/user-entity";
import {ErrorCodeEnum, ErrorItemEnum} from "../enums/error-message-enum";
import {IdeaSortEnum} from '../enums/idea-enum';
import {functions} from "../firebase";
import {isDefAndNotNull} from "../utils/common-util";
import {Limit, Offset} from "../vo/common-vo";
import {DeleteIdeaRequest, FetchIdeasRequest, SaveIdeaRequest} from "../vo/request-vo";

export default class IdeaService {
  public static getIdeaId = (): IdeaId => uuid();

  public static saveIdea = async (
    idea: Idea,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: SaveIdeaRequest =
        toSaveIdeaRequest(idea);
    
      const func = functions.httpsCallable(SAVE_IDEA);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static deleteIdea = async (
    ideaId: IdeaId,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: DeleteIdeaRequest =
        toDeleteIdeaRequest(ideaId);
    
      const func = functions.httpsCallable(DELETE_IDEA);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static fetchIdeas = async (
    userId: UserId,
    sort: IdeaSortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Idea[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchIdeasRequest =
        toFetchIdeasRequest(userId, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_IDEAS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };
}
