import React, {useMemo} from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import MainTabs, {MainTab} from '../../../../components/tab/MainTabs';
import {SubTab} from '../../../../components/tab/SubTabs';
import {fromNotificationsTab, toNotificationsTab} from '../../../../converters/path-converter';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {Index} from '../../../../vo/common-vo';

export enum NotificationsTabEnum {
  ANNOUNCE,
  REPLIES,
  UPDATES,
  OUT_OF_TARGET,
}

const tabs: MainTab[] = [
  {
    id: NotificationsTabEnum.ANNOUNCE,
    showBadge: false,
    label: '通知',
  },
  {
    id: NotificationsTabEnum.REPLIES,
    label: 'リアクション',
  },
  {
    id: NotificationsTabEnum.UPDATES,
    label: '更新',
  },
];

interface P {}

const NotificationsTabs: React.FC<P> = React.memo(() => {
  const { path, replacePath } = usePath();

  const index = useMemo<Index>(() => {
    return toNotificationsTab(path);
  }, [path]);

  const handleTabChanged = useSafeCallback((
    tab: SubTab,
  ): void => {
    replacePath(fromNotificationsTab(tab.id));
  }, [replacePath]);

  return (
    <Component
      loadable={index !== NotificationsTabEnum.OUT_OF_TARGET}
      className="notifications-tabs"
    >
      <Container>
        <Content>
          <MainTabs
            selected={tabs[index]}
            tabs={tabs}
            onChange={handleTabChanged}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default NotificationsTabs;

const Container = styled.div`
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px;
  z-index: 1000;
`;

const Content = styled.div`
  width: calc(100vw - ${theme.mixins.spacing * 5}px);
  max-width: calc(${MOBILE_MAX_WIDTH}px - ${theme.mixins.spacing * 5}px);
  min-width: calc(${MOBILE_MIN_WIDTH}px - ${theme.mixins.spacing * 5}px);
  height: 40px;
`;
