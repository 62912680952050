import React, {useEffect} from 'react';
import styled from 'styled-components';
import {EventTypeEnum} from '../../enums/event-enum';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import {Message} from '../../vo/common-vo';
import Loading from '../loading/Loading';

interface P {
  loading?: boolean;
  className: string;
  message?: Message;
  children?: React.ReactNode;
}

const Screen: React.FC<P> = React.memo(props => {
  const { loading = false, className, message, children } = props;

  const handleDisablePinchIn = useSafeCallback((e) => {
    e.touches.length >= 2 && e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener(
      EventTypeEnum.TOUCH,
      handleDisablePinchIn,
      { passive: false }
    );

    return () => {
      window.removeEventListener(
        EventTypeEnum.TOUCH,
        handleDisablePinchIn,
        { passive: false } as any,
      );
    }  
  }, [handleDisablePinchIn]);

  return (
    <Base className={className}>
      {loading
        ? <Loading
            message={message}
          />
        : children
      }
    </Base>
  );
});

export default Screen;

const Base = styled.div``;