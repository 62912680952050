import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import ClickableBox from '../../../components/box/ClickableBox';
import Component from '../../../components/component/Component';
import {Idea} from '../../../entities/idea-entity';
import {User} from '../../../entities/user-entity';
import {IdeaSortEnum} from '../../../enums/idea-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Path} from '../../../router/Routes';
import IdeaService from '../../../services/idea-service';
import {theme} from '../../../styles/theme';
import {hasLength} from '../../../utils/common-util';
import {embedIdInPath} from '../../../utils/path-util';

interface P {
  user: User;
}

const Topics: React.FC<P> = React.memo(props => {
  const { user } = props;
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);  
  const [ideas, setIdeas] = useSafeState<Idea[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const ideas = await IdeaService.fetchIdeas(
      user.userId,
      IdeaSortEnum.CREATED_AT_DESC,
    );
    setIdeas(ideas);
    setLoaded(true);
  }, [user.userId, setIdeas, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openAccountTopicsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT_TOPICS, [user.userId]));
  }, [openPath, user.userId]);

  return (
    <Component
      loadable={hasLength(ideas)}
      loading={!loaded}
      className="topics"
    >
      <Container>
        <Content>
          <ClickableBox
            label="あなたへの一問一答"
            onClick={openAccountTopicsScreen}
          >
            <TopicsWrapper>
              {ideas.map((idea, index) =>
                <StyledTopic
                  key={index}
                >
                  Q : {idea.topic.content}
                </StyledTopic>
              )}
            </TopicsWrapper>
          </ClickableBox>
        </Content>
      </Container>
    </Component>
  );
});

export default Topics;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const TopicsWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const StyledTopic = styled(Typography)`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.orange};
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px;
  margin: ${theme.mixins.spacing}px 0px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;