import {AxiosRequestConfig} from 'axios';
import querystring from 'querystring';
import {v4 as uuid} from 'uuid';

export const MICCCA_CLIENT_ID = 1656260711;

export const MICCCA_CLIENT_SECRET = 'a36410724d05a838453af81c4ede4f97';

export const NONCE = '09876xyz';

export const LINE_AUTHORIZE_URL = 'https://access.line.me/oauth2/v2.1/authorize';

export const LINE_AUTHORIZE_PARAMS: querystring.ParsedUrlQueryInput = {
  response_type: 'code',
  client_id: MICCCA_CLIENT_ID,
  state: uuid(),
  scope: 'profile openid email',
  nonce: NONCE,
  prompt: 'consent',
  max_age: 120,
  bot_prompt: 'normal'
}

export const LINE_TOKEN_URL = 'https://api.line.me/oauth2/v2.1/token';

export const LINE_TOKEN_CONFIG: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const LIFF_ID = '1656260711-AvgxO3VO';