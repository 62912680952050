import {IconButton as MuiIconButton, Input} from '@material-ui/core';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import SendIcon from '@material-ui/icons/Send';
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import {EMPTY, MAX_LENGTH} from '../../../../constants/common-const';
import {toInputMessage} from '../../../../converters/message-converter';
import {InputMessage, ReplyMessage} from '../../../../entities/message-entity';
import {User} from '../../../../entities/user-entity';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import PhotoService from '../../../../services/photo-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {isDefAndNotNull} from '../../../../utils/common-util';
import {Content} from '../../../../vo/common-vo';
import Reply from './Reply';

interface P {
  user: User;
  input: InputMessage;
}

const FooterInputBox: React.FC<P> = React.memo(props => {
  const { user, input } = props;
  const unmountRef = useUnmountRef();
  const inputRef = useRef<any>();
  const { sendResponse } = useMessage();
  const [reply, setReply] = useSafeState<ReplyMessage | undefined>(unmountRef,
    isDefAndNotNull(input) ? input.reply : undefined);
  const [content, setContent] = useSafeState<Content>(unmountRef,
    isDefAndNotNull(input) ? input.text : EMPTY);

  useEffect(() => {
    setReply(input.reply);
    setContent(input.text);
    input.initFocus && inputRef.current && inputRef.current.focus();
  }, [setReply, setContent, input]);

  const handleContentChanged = (content: Content): void => {
    if (!!content && content.length > MAX_LENGTH) return;
    setContent(content);
  }

  const closeReplyMessage = useSafeCallback((): void => {
    setReply(undefined);
  }, [setReply]);

  const handleUploadPhotoButtonClicked = useSafeCallback(async (image): Promise<void> => {
    const photoURL = await PhotoService.upload(image, user.userId);
    sendResponse(toInputMessage(EMPTY, photoURL, reply));
  }, [user, sendResponse, reply]);

  const handleSendButtonClicked = useSafeCallback((): void => {
    setContent(content => {
      sendResponse(toInputMessage(content, EMPTY, reply));
      return EMPTY;
    });
  }, [setContent, sendResponse, reply]);

  return (
    <Component className="footer-input-box">
      <Container>
        {!!reply &&
          <Reply
            reply={reply}
            onClose={closeReplyMessage}
          />
        }

        <StyledContent>
          <InputBoxWrapper>
            <ButtonWrapper>
              <IconButton>
                <PhotoOutlinedIcon />
              </IconButton>

              <UploadPhoto
                type="file"
                accept="image/*"
                onChange={(e) => handleUploadPhotoButtonClicked(e.target.files[0])}
              />
            </ButtonWrapper>

            <InputBox
              multiline
              disableUnderline
              maxRows={10}
              variant="filled"
              type='text'
              placeholder=""
              inputRef={inputRef}
              value={content}
              onChange={(e) => handleContentChanged(e.target.value)}
            />

            <ButtonWrapper>
              <IconButton
                disableRipple
                onClick={handleSendButtonClicked}
              >
                <SendIcon />
              </IconButton>
            </ButtonWrapper>
          </InputBoxWrapper>
        </StyledContent>
      </Container>
    </Component>
  );
});

export default FooterInputBox;

const Container = styled.div`
  width: 100vw;
  height: auto;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 1000;
`;

const StyledContent = styled.div`
  width: 100vw;
  height: auto;
  background: ${theme.mixins.background.white};
  display: flex;
  justify-content: center;
`;

const InputBoxWrapper = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  display: flex;
  padding: 0px ${theme.mixins.spacing}px;
`;

const InputBox = styled(Input)`
  width: calc(100% - 80px);
  height: auto;
  padding: ${theme.mixins.spacing}px;

  .MuiInputLabel-root {
    padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  }

  .MuiInputBase-root {
    background: none;
  }

  .MuiInputBase-input {
    border-radius: 32px;
    background: ${theme.mixins.background.gray8};
    padding: ${theme.mixins.spacing * 1.5}px ${theme.mixins.spacing * 2}px;
  }
`;

const UploadPhoto = styled.input`
  width: 40px;
  height: 40px;
  opacity: 0;
  appearance: none;
  position: absolute;
`;

const ButtonWrapper = styled.div`
  width: 40px;
  height: auto;
  display: flex;
  align-items: center;
`;

const IconButton = styled(MuiIconButton)`
  width: 40px;
  height: 40px;
  color: ${theme.mixins.palette.blue};
  background: ${theme.mixins.background.white} !important;
`;