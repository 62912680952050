export enum ConnectionSortEnum {
  NAME_ASC,
  CREATED_AT_ASC,
  CREATED_AT_DESC,
}

export enum ConnectionType {
  REQUESTED = 'requested',
  CONNECTED = 'connected',
  REJECTED = 'rejected',
}