import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../../components/box/EditableBox';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import {Community} from '../../../../entities/community-entity';
import {theme} from '../../../../styles/theme';

interface P {
  community: Community;
}

const CommunityDescription: React.FC<P> = React.memo(props => {
  const { community } = props;

  return (
    <Component className="community-description">
      <Container>
        <Content>
          <EditableBox
            multiline
            type="text"
            title={'コミュニティの説明'}
            value={!!community.description
              ? community.description : EMPTY}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityDescription;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;