import {CSSProperties} from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import {Source} from '../../vo/common-vo';
import Component from '../component/Component';

type Size = 'xsmall' | 'small' | 'medium' | 'large';

interface P {
  disabled?: boolean
  size?: Size;
  style?: CSSProperties;
  src: Source;
}

const Icon: React.FC<P> = React.memo(props => {
  const {
    disabled = false,
    size = 'medium',
    style,
    src,
  } = props;

  return (
    <Component className="icon">
      <Container>
        <Content>
          <Image
            style={style}
            disabled={disabled}
            size={size}
            src={src}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default Icon;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img<{
  disabled: boolean,
  size: Size,
}>`
  width: ${props => props.size === 'xsmall' ? 12
    : props.size === 'small' ? 24
    : props.size === 'large' ? 56 : 40}px;
  height: ${props => props.size === 'xsmall' ? 12
    : props.size === 'small' ? 24
    : props.size === 'large' ? 56 : 40}px;
  filter: ${props => props.disabled
    ? 'invert(40%) sepia(0%) saturate(1%) hue-rotate(270deg) brightness(105%) contrast(85%)'
    : 'none'};
  object-fit: cover;
  user-select: none;
`;