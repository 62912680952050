import {Typography} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import {theme} from '../../../../styles/theme';
import {Email} from '../../../../vo/common-vo';
import puzzles from './../../../../assets/puzzles.png';

interface P {
  email: Email;
}

const EmailSent: React.FC<P> = React.memo(props => {
  const { email } = props;

  return (
    <Component className="email-sent">
      <Container>
        <Content>
          <Logo
            src={puzzles}
          />

          <BoxWrapper>
            <Box>
              <Icon><CheckIcon /></Icon>
              <Message>仮登録が完了しました</Message>
            </Box>
          </BoxWrapper>

          <Remarks>
            <Row>仮登録ありがとうございます。</Row>
            <Row>{email}</Row>
            <Row>宛にメールを送信しました。</Row>
          </Remarks>

          <Remarks>
            <Row>本文内のURLにアクセスし、</Row>
            <Row>アカウントを有効化してください。</Row>
          </Remarks>
        </Content>
      </Container>
    </Component>
  )
});

export default EmailSent;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: cover;
`;

const BoxWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  width: 280px;
  height: 48px;
  background: ${theme.mixins.background.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  display: flex;
  align-items: center;
`;

const Icon = styled(Typography)`
  width: 40px;
  height: 40px;
  color: ${theme.mixins.palette.pink};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled(Typography)`
  width: calc(100% - 40px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray7};
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Remarks = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const Row = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray7};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;