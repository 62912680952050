import React, {ReactElement} from 'react';
import {useMediaQuery} from 'react-responsive';

export const MOBILE_MIN_WIDTH = 320;

export const MOBILE_MAX_WIDTH = 428;

interface P {
  children: ReactElement<any, any> | null;
}

export const Desktop = React.memo((props: P) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? props.children : null;
});

export const Tablet = React.memo((props: P) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? props.children : null;
});

export const Mobile = React.memo((props: P) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? props.children : null;
});

export const Default = React.memo((props: P) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? props.children : null;
});

export default Default;
