import {useEffect, useRef} from 'react';

function useUnmountRef() {
  const unmountRef = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      unmountRef.current = true;
    };
  }, []);

  return unmountRef;
}

export default useUnmountRef;
