import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {Connection} from '../../../entities/connection-entity';
import ConnectionCard from './ConnectionCard';

interface P {
  connections: Connection[];
}

const ConnectionCards: React.FC<P> = React.memo(props => {
  const { connections } = props;

  return (
    <Component className="connection-cards">
      <Container>
        <Content>
          {connections.map((connection, index) =>
            <ConnectionCard
              key={index}
              connection={connection}
            />)}
        </Content>
      </Container>
    </Component>
  );
});

export default ConnectionCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;