import {Button as MuiButton} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {toConfirmMessage, toTabsMessage} from '../../converters/message-converter';
import {ConfirmMessage} from '../../entities/message-entity';
import {auth} from '../../firebase';
import useMessage from '../../redux/hooks/useMessage';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import {persistor} from '../../redux/store';
import {Path} from '../../router/Routes';
import {theme} from '../../styles/theme';
import Component from '../component/Component';

interface P {}

const SignOutButton: React.FC<P> = React.memo(() => {
  const { openPath } = usePath();
  const { sendRequest, subscribeResponse } = useMessage();

  const signOut = useSafeCallback(async () => {
    await auth.signOut();
    await persistor.purge();
    openPath(Path.SIGN_IN);
  }, [openPath]);

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const confirm: ConfirmMessage = event['detail'].confirm;

    if (!confirm.value) {
      sendRequest(toTabsMessage());
      return;
    }

    signOut();
  }, [sendRequest, signOut]);

  const confirmAndSignOut = useSafeCallback((): void => {
    sendRequest(toConfirmMessage('ログアウト', 'ログアウトしてもよろしいですか'));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  return (
    <Component className="sign-out-button">
      <Container>
        <Content>
          <Button
            disableRipple
            onClick={confirmAndSignOut}
          >
            ログアウト
          </Button>
        </Content>
      </Container>
    </Component>
  );
});

export default SignOutButton;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Button = styled(MuiButton)`
  width: 80px;
  height: 40px;
  background: ${theme.mixins.background.pink} !important;
  border-radius: 8px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;