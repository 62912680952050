import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import {theme} from '../../../../styles/theme';
import {Email} from '../../../../vo/common-vo';
import atomica from './../../../../assets/atomica.gif';
import EmailSignUp from './EmailSignUp';
import GoogleSignUp from './GoogleSignUp';
import LineSignUp from './LineSignUp';

export enum SignUpEnum {
  INPUT,
  FINISH,
}

interface P {
  onSendEmail(email: Email): void;
}

const SignUpOptions: React.FC<P> = React.memo(props => {
  const { onSendEmail } = props;

  return (
    <Component className="sign-up-options">
      <Container>
        <Content>
          <Logo
            src={atomica}
          />

          <BoxWrapper>
            <Box>
              <Title>micccaは、相談をきっかけに</Title>
              <Title>知り合いの輪を広げる&深める</Title>
              <Title>コミュニティアプリです。</Title>
            </Box>
          </BoxWrapper>

          <List>
            <Item>✔ 知人の力を借りたい</Item>
            <Item>✔ 困っている知人を助けたい</Item>
            <Item>✔ 新しい知り合いを作りたい</Item>
            <Item>　そんな方にオススメです。</Item>
          </List>
          
          <EmailSignUp
            onSendEmail={onSendEmail}
          />
          
          <GoogleSignUp />

          <LineSignUp />
        </Content>
      </Container>
    </Component>
  )
});

export default SignUpOptions;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.mixins.spacing * 6}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: cover;
  padding: ${theme.mixins.spacing}px;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Box = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 16px;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Title = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const List = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const Item = styled(Typography)`
  width: 100%;
  height: auto;
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;