import {combineReducers} from 'redux';
import previousURLReducer from './previous-url-reducer';
import processReducer from './process-reducer';
import reservedURLReducer from './reserved-url-reducer';
import userReducer from './user-reducer';

export default combineReducers({
  process: processReducer,
  previousURL: previousURLReducer,
  reservedURL: reservedURLReducer,
  user: userReducer,
});
