import {Typography} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Component from '../../../../components/component/Component';
import InputBox from '../../../../components/input/InputBox';
import {EMPTY, MINUS_ONE} from '../../../../constants/common-const';
import {ErrorMessages} from '../../../../constants/error-message-const';
import {Process} from '../../../../enums/action-enum';
import firebase, {auth} from '../../../../firebase';
import useProcess from '../../../../redux/hooks/useProcess';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import {theme} from '../../../../styles/theme';
import {isEmpty} from '../../../../utils/common-util';
import {Email, Message, Password} from '../../../../vo/common-vo';
import TextButton from './../../../../components/button/TextButton';

interface P {
  signIn(res: firebase.auth.UserCredential): void;
  reset(): void;
}

const EmailSignIn: React.FC<P> = React.memo(props => {
  const { signIn, reset } = props;
  const unmountRef = useUnmountRef();
  const { setProcess } = useProcess();
  const [disabled, setDisabled] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [password, setPassword] = useSafeState<Password>(unmountRef, EMPTY);
  const [message, setMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabled(isEmpty(email) || isEmpty(password));
  }, [setDisabled, email, password]);

  const constructErrorMessage = useSafeCallback((message: Message): void => {
    if (isEmpty(message)) return;
    
    Object.entries(ErrorMessages)
      .forEach(([key, value]) => {
        if (message.toString().indexOf(key) === MINUS_ONE) return;
        setMessage(value);
      });
  }, [setMessage]);

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    setDisabled(true);
    setProcess(Process.PROCESSING);

    auth.signInWithEmailAndPassword(email, password)
      .then(signIn)
      .catch((err) => {
        setDisabled(false);
        setProcess(Process.INITIAL);
        constructErrorMessage(err.code);
      });
  }, [setDisabled, setProcess, email, password, signIn, constructErrorMessage]);

  return (
    <Component className="email-sign-in">
      <Container>
        <Content>
          <InputWrapper>
            <InputBox
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
            />
          </InputWrapper>
          <InputWrapper>
            <InputBox
              type="password"
              label="パスワード"
              value={password}
              onChange={setPassword}
            />

            <LinkWrapper>
              <TextButton
                label="パスワードを忘れた場合"
                onClick={reset}
              />
            </LinkWrapper>
 
            <ErrorMessage>
              {message}
            </ErrorMessage>
          </InputWrapper>
            
          <ButtonWrapper>
            <Button
              type="primary"
              disabled={disabled}
              icon={<MailIcon fontSize='small' />}
              label="メールアドレスでログイン"
              onClick={signInWithEmailAndPassword}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  )
});

export default EmailSignIn;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${theme.mixins.spacing * 2}px;
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-left: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;