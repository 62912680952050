import firebase from 'firebase';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Component from '../../../../components/component/Component';
import {EMAIL} from '../../../../constants/common-const';
import {Process} from '../../../../enums/action-enum';
import {auth} from '../../../../firebase';
import useProcess from '../../../../redux/hooks/useProcess';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import google from './../../../../assets/icon/icon_google.png';

interface P {}

const GoogleSignIn: React.FC<P> = React.memo(() => {
  const { setProcess } = useProcess();

  const signInWithRedirect = useSafeCallback((): void => {
    setProcess(Process.PROCESSING);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope(EMAIL);
    auth.signInWithRedirect(provider);
  }, [setProcess]);

  return (
    <Component className="google-sign-in">
      <Container>
        <Content>
          <Button
            type="default"
            icon={<Icon src={google} />}
            label="Googleでログイン"
            onClick={signInWithRedirect}
          />
        </Content>
      </Container>
    </Component>
  )
});

export default GoogleSignIn;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;