import React, {useEffect} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {EMPTY, MINUS_ONE} from '../../../constants/common-const';
import {COMMUNITY_PHOTO} from '../../../constants/community-const';
import {toCommunity} from '../../../converters/community-converter';
import {toMember} from '../../../converters/community-member-converter';
import {Community} from '../../../entities/community-entity';
import {Connection} from '../../../entities/connection-entity';
import {User} from '../../../entities/user-entity';
import {CommunityStatus} from '../../../enums/community-enum';
import {CommunityMemberRole, CommunityMemberStatus} from '../../../enums/community-member-enum';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import CommunityMemberService from '../../../services/community-member-service';
import CommunityService from '../../../services/community-service';
import {hasSize} from '../../../utils/common-util';
import {Description, Name, URL} from '../../../vo/common-vo';
import CommunityCreated from './parts/CommunityCreated';
import InputCommunity from './parts/InputCommunity';
import SelectCommunityMember from './parts/SelectCommunityMember';

enum CreateCommunityStep {
  SELECT_COMMUNITY_MEMBER,
  INPUT_COMMUNITY,
  COMMUNITY_CREATED,
}

interface P {}

const CreateCommunity: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [step, setStep] = useSafeState<CreateCommunityStep>(unmountRef, CreateCommunityStep.SELECT_COMMUNITY_MEMBER);
  const [name, setName] = useSafeState<Name>(unmountRef, EMPTY);
  const [description, setDescription] = useSafeState<Description>(unmountRef, EMPTY);
  const [photoURL, setPhotoURL] = useSafeState<URL>(unmountRef, EMPTY);
  const [newCommunity, setNewCommunity] = useSafeState<Community>(unmountRef);
  const [connections, setConnections] = useSafeState<Connection[]>(unmountRef, []);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    setUser(user);
    setLoaded(true);
  }, [getUser, setUser, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const updateCommunityMembers = useSafeCallback((connection: Connection): void => {
    setConnections(connections => {
      const isSelected = connections.findIndex(c =>
        c.connecting.userId === connection.connecting.userId) !== MINUS_ONE;

      return isSelected
        ? connections.filter(c => c.connecting.userId !== connection.connecting.userId)
        : [ ...connections, connection ];
    })
  }, [setConnections]);

  const saveNewCommunity = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    
    const community = toCommunity(
      CommunityService.getCommunityId(),
      false,
      CommunityStatus.ACTIVE,
      name,
      description,
      !!photoURL ? photoURL : COMMUNITY_PHOTO,
      undefined,
    );

    const owner = toMember(
      CommunityMemberService.getCommunityMemberId(),
      community,
      false,
      CommunityMemberStatus.JOINED,
      CommunityMemberRole.OWNER,
      EMPTY,
      user,
    );

    const members = connections.map(connection => toMember(
      CommunityMemberService.getCommunityMemberId(),
      community,
      false,
      CommunityMemberStatus.INVITED,
      CommunityMemberRole.MEMBER,
      EMPTY,
      connection.connecting,
    ));

    const errors = await CommunityService.saveCommunity(community)
      && await CommunityMemberService.saveCommunityMembers(
          community.communityId,
          [ owner, ...members ],
          user,
        );

    if (!hasSize(errors)) {
      setNewCommunity(community);
      setStep(CreateCommunityStep.COMMUNITY_CREATED);
      setSaving(false);
    }
  }, [setSaving, name, description, photoURL, user, connections, setNewCommunity, setStep]);

  const goToCommunityInfoInput = useSafeCallback((): void => {
    setStep(CreateCommunityStep.INPUT_COMMUNITY);
  }, [setStep]);

  const goBackToCommunityMemberSelect = useSafeCallback((): void => {
    setStep(CreateCommunityStep.SELECT_COMMUNITY_MEMBER);
  }, [setStep]);

  return (
    <Component
      loading={!loaded || saving}
      className="create-community"
    >
      <Container>
        <Content>
          {step === CreateCommunityStep.SELECT_COMMUNITY_MEMBER &&
            <SelectCommunityMember
              user={user}
              selected={connections}
              onChange={updateCommunityMembers}
              goNext={goToCommunityInfoInput}
            />
          }

          {step === CreateCommunityStep.INPUT_COMMUNITY &&
            <InputCommunity
              name={name}
              description={description}
              photoURL={photoURL}
              onChangeName={setName}
              onChangeDescription={setDescription}
              onChangePhotoURL={setPhotoURL}
              goNext={saveNewCommunity}
              goBack={goBackToCommunityMemberSelect}
            />
          }

          {step === CreateCommunityStep.COMMUNITY_CREATED &&
            <CommunityCreated
              community={newCommunity}
            />
          }
        </Content>
      </Container>
    </Component>
  );
});

export default CreateCommunity;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;