import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../../components/box/EditableBox';
import Component from '../../../../components/component/Component';
import {Community} from '../../../../entities/community-entity';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import {Name} from '../../../../vo/common-vo';

interface P {
  community: Community;
  onChange(community: Community): void;
}

const EditCommunityName: React.FC<P> = React.memo(props => {
  const { community, onChange } = props;

  const updateCommunityName = useSafeCallback((name: Name): void => {
    onChange({ ...community, name });
  }, [onChange, community]);

  return (
    <Component className="edit-community-name">
      <Container>
        <Content>
          <EditableBox
            editable
            maxLength={30}
            type="text"
            title="コミュニティの名前"
            value={community.name!}
            onChange={updateCommunityName}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default EditCommunityName;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;