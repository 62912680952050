import React, {useMemo} from 'react';
import styled from 'styled-components';
import ScrollTop from '../../components/other/ScrollTop';
import {isAuthGuarded as guarded} from '../../converters/path-converter';
import usePath from '../../redux/hooks/usePath';
import GuardedScreens from './guarded-screens/GuardedScreens';
import NoGuardedScreens from './no-guarded-screens/NoGuardedScreens';

const Root: React.FC = React.memo(() => {
  const { path } = usePath();
  const isAuthGuard = useMemo<boolean>(() => guarded(path), [path]);

  return (
    <StyledRoot>
      <ScrollTop />
      
      {!isAuthGuard &&
        <NoGuardedScreens
          path={path}
        />
      }

      {isAuthGuard &&
        <GuardedScreens
          path={path}
        />
      }
    </StyledRoot>
  );
});

export default Root;

const StyledRoot = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
`;