export enum CommunityMemberRole {
  OWNER = 'owner',
  MEMBER = 'member',
  SPONSOR = 'sponsor',
}

export enum CommunityMemberStatus {
  REQUESTED = 'requested',
  INVITED = 'invited',
  JOINED = 'joined',
  SKIPPED = 'skipped',
  LEFT = 'left',
}