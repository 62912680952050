import React, {useEffect} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Modal from '../../../components/modal/Modal';
import {Question} from '../../../entities/question-entity';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import {theme} from '../../../styles/theme';
import {hasLength} from '../../../utils/common-util';
import guide from './../../../assets/guide/question_guide.png';

interface P {
  user: User;
  questions: Question[];
}

const GuideModal: React.FC<P> = React.memo(props => {
  const {
    user: initUser,
    questions,
  } = props;

  const unmountRef = useUnmountRef();
  const { updateUser } = useUser();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef, initUser);

  const initialize = useSafeCallback((): void => {
    if (user.isGuided || !hasLength(questions)) return;
    setIsModalOpen(true);
  }, [user.isGuided, questions, setIsModalOpen]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveAsGuideShown = useSafeCallback((): void => {
    setIsModalOpen(false);
    const userToBeUpdated = { ...initUser, isGuided: true };
    setUser(userToBeUpdated);
    updateUser(userToBeUpdated);
  }, [setIsModalOpen, initUser, setUser, updateUser]);

  return (
    <Component className="guide-modal">
      <Container>
        <Content>
          <Modal
            isOpen={isModalOpen}
            label={'スタートアップガイド'}
            onClose={saveAsGuideShown}
          >
            <Wrapper>
              <Photo
                src={guide}
              />
            </Wrapper>
          </Modal>
        </Content>
      </Container>
    </Component>
  );
});

export default GuideModal;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${window.innerHeight}px;
  padding-bottom: ${theme.mixins.spacing * 10}px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;