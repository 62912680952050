import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ButtonOption} from '../../../../components/button/MultiButtons';
import Component from '../../../../components/component/Component';
import {toButtonsMessage, toTabsMessage} from '../../../../converters/message-converter';
import {Connection} from '../../../../entities/connection-entity';
import {ButtonMessage} from '../../../../entities/message-entity';
import {User} from '../../../../entities/user-entity';
import {ConnectionSortEnum} from '../../../../enums/connection-enum';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import ConnectionService from '../../../../services/connection-service';
import {theme} from '../../../../styles/theme';
import SelectableMemberCards from '../../member-card/SelectableMemberCards';

enum ButtonType {
  INPUT_COMMUNITY_INFO = 'input_community_info',
}

const SELECT_COMMUNITY_MEMBER_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.INPUT_COMMUNITY_INFO,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '次へ',
  },
];

interface P {
  user: User;
  selected: Connection[];
  onChange(connection: Connection): void;
  goNext(): void;
}

const SelectCommunityMember: React.FC<P> = React.memo((props: any) => {
  const { user, selected, onChange, goNext } = props;
  const unmountRef = useUnmountRef();
  const { sendRequest, subscribeResponse } = useMessage();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<Connection[]>(unmountRef, []);

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const button: ButtonMessage = event['detail'].buttons[0];

    switch (button.id) {
      case ButtonType.INPUT_COMMUNITY_INFO:
        goNext();
        break;
        
      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [goNext]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const connections = await ConnectionService.fetchConnections(
      user.userId,
      ConnectionSortEnum.NAME_ASC,
    );

    setConnections(connections);
    setLoaded(true);
  }, [user, setConnections, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const setupMessage = useSafeCallback((): void => {
    sendRequest(toButtonsMessage(SELECT_COMMUNITY_MEMBER_BUTTONS));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
    
    return () => {
      sendRequest(toTabsMessage());
    };
  }, [setupMessage, sendRequest]);

  return (
    <Component
      loading={!loaded}
      className="select-community-member"
    >
      <Container>
        <Content>
          <Wrapper>
            <Supplement>
              メンバーを招待（作成後URLで招待も可能です）
            </Supplement>
          </Wrapper>

          <SelectableMemberCards
            selected={selected}
            members={connections}
            onSelect={onChange}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default SelectCommunityMember;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: ${window.innerHeight - 56}px;
  padding-bottom: ${theme.mixins.spacing * 10}px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Supplement = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration-line: underline;
  user-select: none;
`;