import {CSSProperties} from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Loading from '../loading/Loading';

interface P {
  loadable?: boolean;
  loading?: boolean;
  show?: boolean;
  style?: CSSProperties;
  className: string;
  children?: React.ReactNode;
}

const Component: React.FC<P> = React.memo(props => {
  const {
    loadable = true,
    loading = false,
    show = true,
    style,
    className,
    children,
  } = props;

  return (
    <Base
      show={show}
      style={style}
      className={className}
    >
      {loadable &&
        (loading
          ? <Loading />
          : children
        )
      }
    </Base>
  );
});

export default Component;

const Base = styled.div<{ show: boolean }>`
  visibility: ${props => props.show ? 'inherit' : 'hidden'};
`;