import {CATCH_FRONTEND_ERROR} from '../constants/request-const';
import {toCatchFrontendErrorRequest} from '../converters/request-converter';
import {functions} from '../firebase';
import {Stack} from '../vo/common-vo';
import {CatchFrontendErrorRequest} from '../vo/request-vo';

export default class ErrorService {
  public static throwError = async (
    stack: Stack,
  ): Promise<void> => {
    return await new Promise((resolve, reject) => {
      const request: CatchFrontendErrorRequest =
        toCatchFrontendErrorRequest(stack);
    
      const func = functions.httpsCallable(CATCH_FRONTEND_ERROR);
      func(request)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }
}
