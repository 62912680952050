import React, {useMemo} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {toHeaderBar} from '../../../converters/path-converter';
import {Path} from '../../../router/Routes';
import AccountBar from './parts/AccountBar';
import AskBar from './parts/AskBar';
import BackBar from './parts/BackBar';
import LabelBar from './parts/LabelBar';
import NotificationsTabs from './parts/NotificationsTabs';
import RelationsTabs from './parts/RelationsTabs';

export enum HeaderBarEnum {
  ASK,
  RELATIONS,
  NOTIFICATIONS,
  ACCOUNT,
  Label,
  BACK,
  OUT_OF_TARGET,
}

interface P {
  path: Path;
}

const HeaderBar: React.FC<P> = React.memo(props => {
  const { path } = props;
  const bar = useMemo<HeaderBarEnum>(() => toHeaderBar(path), [path]);

  return (
    <Component
      loadable={bar !== HeaderBarEnum.OUT_OF_TARGET}
      className="header-bar"
    >
      <Container>
        <Content>
          {bar === HeaderBarEnum.ASK &&
            <AskBar />
          }

          {bar === HeaderBarEnum.RELATIONS &&
            <RelationsTabs />
          }

          {bar === HeaderBarEnum.NOTIFICATIONS &&
            <NotificationsTabs />
          }
          
          {bar === HeaderBarEnum.ACCOUNT &&
            <AccountBar />
          }

          {bar === HeaderBarEnum.Label &&
            <LabelBar />
          }

          {bar === HeaderBarEnum.BACK &&
            <BackBar />
          }
        </Content>
      </Container>
    </Component>
  );
});

export default HeaderBar;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;