import {createMuiTheme} from '@material-ui/core/styles';
import createMixins from '@material-ui/core/styles/createMixins';

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    spacing: number;
    palette: { [color: string]: string };
    background: { [color: string]: string };
    typography: {
      fontFamily: string;
      fontWeight: { [weight: string]: number | string };
      fontSize: { [size: string]: number };
      fontColor: { [color: string]: string };
    };
    underline: string;
  }
}

const { breakpoints, spacing } = createMuiTheme();
const mixins = createMixins(breakpoints, spacing, {
  spacing: 8,
  palette: {
    black: '#000',
    blue: '#459CFF',
    gray: '#AEAEAE',
    gray2: '#EAEAEA',
    pink: '#FF7165',
    white: '#fff',
  },
  background: {
    black: '#000',
    black2: '#333',
    blue: '#36C9C9',
    gray: '#3E3E3E',
    gray2: '#AEAEAE',
    gray3: '#C4C4C4',
    gray4: '#D4D4D4',
    gray5: '#EAEAEA',
    gray6: '#EBEBEB',
    gray7: '#ECECEC',
    gray8: '#F4F4F4',
    green: '#9DDE22',
    orange: '#EB6D27',
    orange2: '#FFA143',
    pink: '#FF7165',
    white: '#fff',
    yellow: '#FFE474',
    yellow2: '#FFEEAA',
  },
  typography: {
    fontColor: {
      black: '#000',
      blue: '#459CFF',
      gray: '#4F4F4F',
      gray2: '#656565',
      gray3: '#898989',
      gray4: '#8F8F8F',
      gray5: '#AEAEAE',
      gray6: '#C4C4C4',
      pink: '#FF7165',
      white: '#fff',
      yellow: '#FFE474',
    },
    fontSize: {
      eight: 8,
      ten: 10,
      twelve: 12,
      fourteen: 14,
      sixteen: 16,
      eighteen: 18,
      twenty: 20,
      twentyFour: 24,
    },
    fontWeight: {
      fourHundreds: 400,
      sevenHundreds: 700,
      nineHundreds: 900,
    },
    fontFamily: [
      'Noto Sans CJK JP',
      'Noto Sans JP',
      'Yu Gothic',
    ].join(','),
  },
  underline: `
    background-image:
      linear-gradient(
        180deg,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) 97%,
        #EAEAEA 97%,
        #EAEAEA 100%
      );
    background-size:100% 2em;
    line-height:2;
  `,
});

export const theme = createMuiTheme({
  mixins,
  palette: {
    primary: {
      main: '#f08080',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  props: {
    MuiCheckbox: { 
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          display: 'none',
        },
        "&:after": {
          display: 'none',
        }
      },
    },
  },
});

export const setBlindStyle = (): void => {
  const body = document.querySelector('body')!;
  body.classList.add('body-blind');
}

export const setSettingStyle = (): void => {
  const body = document.querySelector('body')!;
  body.classList.add('body-setting');
}