import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Component from '../../../../components/component/Component';
import {Process} from '../../../../enums/action-enum';
import {constructLineLoginURL} from '../../../../line';
import useProcess from '../../../../redux/hooks/useProcess';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import line from './../../../../assets/icon/icon_line.png';

interface P {}

const LineSignUp: React.FC<P> = React.memo(() => {
  const { setProcess } = useProcess();
  
  const openLineLoginPage = useSafeCallback((): void => {
    setProcess(Process.PROCESSING);
    const lineLoginURL = constructLineLoginURL();
    window.location.href = lineLoginURL;
  }, [setProcess]);

  return (
    <Component className="line-sign-up">
      <Container>
        <Content>
          <Button
            type="default"
            icon={<Icon src={line} />}
            label="LINEで登録"
            onClick={openLineLoginPage}
          />
        </Content>
      </Container>
    </Component>
  )
});

export default LineSignUp;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;