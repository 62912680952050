import firebase from 'firebase';
import {v4 as uuid} from 'uuid';
import {EMPTY} from '../constants/common-const';
import {User} from '../entities/user-entity';
import {ProviderIdEnum} from '../enums/common-enum';
import {Authority} from '../enums/user-enum';
import {Email, Name} from '../vo/common-vo';
import {toFirebaseUser} from './firebase-user-converter';

export const createUserWithFirebase = (
  firebaseUser: firebase.User,
  isActivated: boolean,
  familyName: Name,
  firstName: Name,
  email: Email,
  providerId: ProviderIdEnum,
): User => {
  return {
    userId: uuid(),
    isActivated,
    isGuided: false,
    isAnonymous: false,
    authority: Authority.MEMBER,
    familyName,
    firstName,
    email,
    phone: firebaseUser.phoneNumber || EMPTY,
    photoURL: firebaseUser.photoURL || EMPTY,
    selfIntroduction: EMPTY,
    homePage: EMPTY,
    twitterId: EMPTY,
    instagramId: EMPTY,
    facebookId: EMPTY,
    affiliations: [],
    firebaseUsers: [toFirebaseUser(firebaseUser.uid, providerId)],
  };
};

export const setFirebaseUser = (
  micccaUser: User,
  firebaseUser: firebase.User,
  providerId: ProviderIdEnum,
): User => {
  return {
    ...micccaUser,
    firebaseUsers: [toFirebaseUser(firebaseUser.uid, providerId)]
  };
};

export const setFullName = (
  micccaUser: User,
  familyName: Name,
  firstName: Name,
): User => {
  return {
    ...micccaUser,
    isActivated: true,
    familyName,
    firstName,
  };
};