import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {EMPTY} from '../../constants/common-const';
import {COMMUNITY_ID} from '../../constants/id-const';
import {toQuestion} from '../../converters/question-converter';
import {Community, CommunityId} from '../../entities/community-entity';
import {Question, QuestionId} from '../../entities/question-entity';
import {User} from '../../entities/user-entity';
import {QuestionStatus} from '../../enums/question-enum';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import CommunityService from '../../services/community-service';
import QuestionService from '../../services/question-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {hasLength, hasSize, isExistedIn, isUndefOrNull, removeFrom} from '../../utils/common-util';
import {getDate} from '../../utils/date-util';
import {Details, Level, Overview, URL} from '../../vo/common-vo';
import InputQuestion from './input-question/InputQuestion';
import QuestionCreated from './question-created/QuestionCreated';
import SelectCommunities from './select-communities/SelectCommunities';

const QUESTION_DUE = 14;

interface P extends RouteComponentProps {}

const AskScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { path, queryParams, replacePath } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [question, setQuestion] = useSafeState<Question>(unmountRef);
  const [communities, setCommunities] = useSafeState<Community[]>(unmountRef, []);
  const [level, setLevel] = useSafeState<Level>(unmountRef, 50);
  const [overview, setOverview] = useSafeState<Overview>(unmountRef, EMPTY);
  const [details, setDetails] = useSafeState<Details>(unmountRef, EMPTY);
  const [photoURL, setPhotoURL] = useSafeState<URL>(unmountRef, EMPTY);

  const setPreviousQuestion = useSafeCallback(async (
    questionId: QuestionId | undefined
  ): Promise<void> => {
    if (!questionId) return;
    
    const question = await QuestionService.fetchQuestion(questionId);
    if (!question) return;

    replacePath(Path.ASK_INPUT);
    setCommunities(question.communities);
    setLevel(question.level);
    setOverview(question.overview);
    setDetails(!!question.details ? question.details : EMPTY);
  }, [replacePath, setCommunities, setLevel, setOverview, setDetails]);

  const setInitialCommunity = useSafeCallback(async (
    communityId: CommunityId | undefined,
  ): Promise<void> => {
    if (!communityId) return;

    const community = await CommunityService.fetchCommunity(communityId);
    if (!community) return;

    replacePath(Path.ASK_INPUT);
    setCommunities([ community ]);
  }, [replacePath, setCommunities]);

  const initialize = useSafeCallback(async (
    communityId: CommunityId | undefined,
    questionId: QuestionId | undefined,
  ): Promise<void> => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [user, _, __] = await Promise.all([
      getUser() as Promise<User>,
      setInitialCommunity(communityId),
      setPreviousQuestion(questionId),
    ]);

    setUser(user);
    setLoaded(true);
  }, [setPreviousQuestion, setInitialCommunity, getUser, setUser, setLoaded]);

  useEffect(() => {
    if ((path === Path.ASK_INPUT || path === Path.ASK_DONE)
      && !hasLength(communities)) {
      replacePath(Path.ASK_TO);
    }

    if (path === Path.ASK_DONE && isUndefOrNull(question)) {
      replacePath(Path.ASK_INPUT);
    }

    const { communityId, questionId } = queryParams;
    initialize(communityId, questionId);
    analytics.setCurrentScreen("相談画面");
  }, [path, communities, replacePath, question, queryParams, initialize]);

  const updateSelectedCommunities = useSafeCallback((community: Community): void => {
    setCommunities(communities => {
      const isSelected = isExistedIn(communities, COMMUNITY_ID, community.communityId);
      return isSelected
        ?  removeFrom(communities, COMMUNITY_ID, community.communityId)
        : [ ...communities, community ];
    })
  }, [setCommunities]);

  const saveNewQuestion = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const question = toQuestion(
      QuestionService.getQuestionId(),
      communities,
      QuestionStatus.OPEN,
      level,
      overview,
      user,
      details,
      photoURL,
      getDate(QUESTION_DUE)
    );
    
    const errors = await QuestionService.saveQuestion(question);

    if (!hasSize(errors)) {
      setQuestion(question);
      replacePath(Path.ASK_DONE);
      setSaving(false);
    }
  }, [setSaving, user, communities, level, overview, details, photoURL, setQuestion, replacePath]);

  return (
    <Screen
      loading={!loaded || saving}
      className="ask-screen"
    >
      <Container>
        <StyledContent>
          {path === Path.ASK_TO &&
            <SelectCommunities
              user={user}
              selected={communities}
              onChange={updateSelectedCommunities}
              goNext={() => replacePath(Path.ASK_INPUT)}
            />
          }

          {path === Path.ASK_INPUT &&
            <InputQuestion
              level={level}
              title={overview}
              content={details}
              photoURL={photoURL}
              onChangeLevel={setLevel}
              onChangeTitle={setOverview}
              onChangeContent={setDetails}
              onChangePhotoURL={setPhotoURL}
              goNext={saveNewQuestion}
              goBack={() => replacePath(Path.ASK_TO)}
            />
          }

          {path === Path.ASK_DONE &&
            <QuestionCreated
              question={question}
            />
          }
        </StyledContent>
      </Container>
    </Screen>
  );
});

export default AskScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const StyledContent = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 16}px;
`;