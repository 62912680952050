
import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import Modal from '../../../components/modal/Modal';
import Tips from '../../../components/typography/Tips';
import {Community} from '../../../entities/community-entity';
import {User} from '../../../entities/user-entity';
import {CommunitySortEnum} from '../../../enums/community-enum';
import {Authority} from '../../../enums/user-enum';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import useUser from '../../../redux/hooks/useUser';
import {Path} from '../../../router/Routes';
import CommunityService from '../../../services/community-service';
import {theme} from '../../../styles/theme';
import {embedIdInPath} from '../../../utils/path-util';
import CommunityCards from '../community-card/CommunityCards';
import CreateCommunity from '../create-community/CreateCommunity';
import plus from './../../../assets/icon/icon_plus_black.png';

interface P {}

const CommunityList: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [communities, setCommunities] = useSafeState<Community[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setLoaded(false);

    const user = await getUser() as User;
    const communities = await CommunityService.fetchCommunitiesByUserId(
      user.userId,
      CommunitySortEnum.CREATED_AT_DESC,
    );
    
    setUser(user);
    setCommunities(communities);
    setLoaded(true);
  }, [setLoaded, getUser, setUser, setCommunities]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openCommunityDetailsScreen = useSafeCallback((community: Community): void => {
    openPath(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
  }, [openPath]);

  const refreshCommunities = useSafeCallback((): void => {
    initialize();
    setIsModalOpen(false);
  }, [initialize, setIsModalOpen]);

  return (
    <Component
      loading={!loaded}
      className="community-list"
    >
      <Container>
        <Content>
          {!!user && user.authority === Authority.ADMIN &&
            <ButtonWrapper
              onClick={() => setIsModalOpen(true)}
            >
              <OpenModalButton>
                <Icon size='xsmall' src={plus} />
              </OpenModalButton>

              <ButtonLabel>
                新しいコミュニティをつくる
              </ButtonLabel>
            </ButtonWrapper>        
          }

          <CommunityCards
            user={user}
            communities={communities}
            onClick={openCommunityDetailsScreen}
          />

          <Tips
            message="新しいコミュニティは、オーナーのみが作れます。作成したいときは管理者もしくはATOMicaまでお問い合わせください。"
          />

          <Modal
            isOpen={isModalOpen}
            label={'コミュニティを作成する'}
            onClose={refreshCommunities}
          >
            <CreateCommunity />
          </Modal>
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityList;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const OpenModalButton = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  border-radius: 50%;
  margin-right: ${theme.mixins.spacing / 2}px;
`;

const ButtonLabel = styled(Typography)`
  width: 100%;
  height: 40px;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  background: ${theme.mixins.background.white};
  border-radius: 8px;
  user-select: none;
  padding-left: ${theme.mixins.spacing * 2}px;
`;
