const FUNCTION_GROUP = 'miccca';

export const CATCH_FRONTEND_ERROR = `${FUNCTION_GROUP}-CatchFrontendError`;

export const COUNT_NOTIFICATIONS = `${FUNCTION_GROUP}-CountNotifications`;

export const COUNT_QUESTIONS = `${FUNCTION_GROUP}-CountQuestions`;

export const DELETE_CONNECTION = `${FUNCTION_GROUP}-DeleteConnection`;

export const DELETE_IDEA = `${FUNCTION_GROUP}-DeleteIdea`;

export const DELETE_QUESTION = `${FUNCTION_GROUP}-DeleteQuestion`;

export const FETCH_COMMUNITIES_BY_QUESTION_ID = `${FUNCTION_GROUP}-FetchCommunitiesByQuestionId`;

export const FETCH_COMMUNITIES_BY_ROLE = `${FUNCTION_GROUP}-FetchCommunitiesByRole`;

export const FETCH_COMMUNITIES_BY_USER_ID = `${FUNCTION_GROUP}-FetchCommunitiesByUserId`;

export const FETCH_COMMUNITY = `${FUNCTION_GROUP}-FetchCommunity`;

export const FETCH_CONNECTION = `${FUNCTION_GROUP}-FetchConnection`;

export const FETCH_CONNECTIONS_USER_IDS = `${FUNCTION_GROUP}-FetchConnectionByUserIds`;

export const FETCH_CONNECTIONS = `${FUNCTION_GROUP}-FetchConnections`;

export const FETCH_IDEAS = `${FUNCTION_GROUP}-FetchIdeas`;

export const FETCH_NOTIFICATIONS = `${FUNCTION_GROUP}-FetchNotifications`;

export const FETCH_QUESTION = `${FUNCTION_GROUP}-FetchQuestion`;

export const FETCH_QUESTIONS = `${FUNCTION_GROUP}-FetchQuestions`;

export const FETCH_QUESTIONS_BY_COMMUNITY_ID = `${FUNCTION_GROUP}-FetchQuestionsByCommunityId`;

export const FETCH_QUESTIONS_BY_USER_ID = `${FUNCTION_GROUP}-FetchQuestionsByUserId`;

export const FETCH_TOPIC = `${FUNCTION_GROUP}-FetchTopic`;

export const FETCH_USER = `${FUNCTION_GROUP}-FetchUser`;

export const FETCH_USER_BY_FIREBASE_USER_ID = `${FUNCTION_GROUP}-FetchUserByFirebaseUserId`;

export const SAVE_ANSWER = `${FUNCTION_GROUP}-SaveAnswer`;

export const SAVE_COMMUNITY = `${FUNCTION_GROUP}-SaveCommunity`;

export const SAVE_COMMUNITY_MEMBERS = `${FUNCTION_GROUP}-SaveCommunityMembers`;

export const SAVE_CONNECTION = `${FUNCTION_GROUP}-SaveConnection`;

export const SAVE_IDEA = `${FUNCTION_GROUP}-SaveIdea`;

export const SAVE_NOTIFICATION = `${FUNCTION_GROUP}-SaveNotification`;

export const SAVE_QUESTION = `${FUNCTION_GROUP}-SaveQuestion`;

export const SAVE_REPLY = `${FUNCTION_GROUP}-SaveReply`;

export const SAVE_TOPIC = `${FUNCTION_GROUP}-SaveTopic`;

export const SAVE_USER = `${FUNCTION_GROUP}-SaveUser`;

export const VERIFY_USER = `${FUNCTION_GROUP}-VerifyUser`;