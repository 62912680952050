import React, {useEffect} from 'react';
import styled from 'styled-components';
import Bar from '../../../../components/bar/Bar';
import BackButton from '../../../../components/button/BackButton';
import SignOutButton from '../../../../components/button/SignOutButton';
import Component from '../../../../components/component/Component';
import {User} from '../../../../entities/user-entity';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import useUser from '../../../../redux/hooks/useUser';
import {Path} from '../../../../router/Routes';
import UserService from '../../../../services/user-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';
import {toFullName} from '../../../../utils/user-util';

interface P {}

const AccountBar: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params, openPath, goBack } = usePath();
  const { getUser, isSignInUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isMyAccount, setIsMyAccount] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const isMyAccount = await isSignInUser(params.userId!);
    const user = isMyAccount
      ? await getUser()
      : await UserService.fetchUser(params.userId!);

    if (!user) {
      openPath(Path.QUESTIONS);
      return;
    }

    setIsMyAccount(isMyAccount);
    setUser(user);
    setLoaded(true);
  }, [isSignInUser, params.userId, getUser, openPath, setIsMyAccount, setUser, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component
      loading={!loaded} 
      className="account-bar"
    >
      <Container>
        <Content>
          <ButtonWrapper>
            {!isMyAccount &&
              <BackButton
                onClick={goBack}
              />
            }
          </ButtonWrapper>
          
          <BarWrapper>
            <Bar
              label={toFullName(user)}
            />
          </BarWrapper>

          <ButtonWrapper>
            {isMyAccount &&
              <SignOutButton />
            }
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default AccountBar;

const Container = styled.div`
  width: 100vw;
  height: 56px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.mixins.background.white};
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px;
  z-index: 1000;
`;

const Content = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${theme.mixins.spacing}px;
`;

const BarWrapper = styled.div`
  width: calc(100% - 176px);
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 80px;
  height: auto;
`;