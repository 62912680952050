import {Button} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ClickableBox from '../../../components/box/ClickableBox';
import Component from '../../../components/component/Component';
import {toButtonsMessage, toInputMessage} from '../../../converters/message-converter';
import {InputMessage} from '../../../entities/message-entity';
import {User} from '../../../entities/user-entity';
import useMessage from '../../../redux/hooks/useMessage';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {theme} from '../../../styles/theme';
import {URL} from '../../../vo/common-vo';
import {ACCOUNT_EDIT_BUTTONS} from '../AccountEditScreen';
import {ContactOptionEnum, CONTACTS} from '../profile-item/Contact';

interface P {
  user: User;
  onChange(user: User): void;
}

const EditContact: React.FC<P> = React.memo(props => {
  const { user, onChange } = props;
  const { sendRequest, subscribeResponse, unsubscribeResponse } = useMessage();

  const handleContactChanged = useSafeCallback(async (
    URL: URL,
    option: ContactOptionEnum,
  ): Promise<void> => {    
    switch (option) {
      case ContactOptionEnum.HOME:
        onChange({ ...user, homePage: URL });
        break;

      case ContactOptionEnum.TWITTER:
        onChange({ ...user, twitterId: URL });
        break;

      case ContactOptionEnum.INSTAGRAM:
        onChange({ ...user, instagramId: URL });
        break;

      case ContactOptionEnum.FACEBOOK:
        onChange({ ...user, facebookId: URL });
        break;
        
      default:
        throw new Error(`${option} is out of target.`);
    }
  }, [onChange, user]);

  const handleResponseSubmitted = useSafeCallback((
    event: Event,
    option: ContactOptionEnum,
  ): void => {
    const input: InputMessage = event['detail'].input!;
    handleContactChanged(input.text, option);
    sendRequest(toButtonsMessage(ACCOUNT_EDIT_BUTTONS));
    unsubscribeResponse();
  }, [handleContactChanged, sendRequest, unsubscribeResponse]);

  const openFooterInputBox = useSafeCallback((
    URL: URL,
    option: ContactOptionEnum,
  ): void => {
    sendRequest(toInputMessage(URL));
    subscribeResponse((event: Event) => handleResponseSubmitted(event, option));
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  const handleIconClicked = useSafeCallback(async (
    option: ContactOptionEnum,
  ): Promise<void> => {    
    switch (option) {
      case ContactOptionEnum.HOME:
        openFooterInputBox(user.homePage!, option);
        break;

      case ContactOptionEnum.TWITTER:
        openFooterInputBox(user.twitterId!, option);
        break;

      case ContactOptionEnum.INSTAGRAM:
        openFooterInputBox(user.instagramId!, option);
        break;

      case ContactOptionEnum.FACEBOOK:
        openFooterInputBox(user.facebookId!, option);
        break;
        
      default:
        throw new Error(`${option} is out of target.`);
    }
  }, [openFooterInputBox, user]);

  return (
    <Component className="edit-contact">
      <Container>
        <Content>
          <ClickableBox
            label="連絡先"
          >
            <ButtonWrapper>
              {CONTACTS.map((contact, index) =>
                <IconButton
                  key={index}
                  disableRipple
                  background={contact.background}
                  onClick={() => handleIconClicked(contact.id)}
                >
                  {contact.icon}
                </IconButton>            
              )}
            </ButtonWrapper>
          </ClickableBox>
        </Content>
      </Container>
    </Component>
  );
});

export default EditContact;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const IconButton = styled(Button)<{ background: string }>`
  width: 56px;
  min-width: 56px;
  height: 56px;
  color: ${theme.mixins.typography.fontColor.white};
  background: ${props => props.background} !important;
  border-radius: 50%;
  margin: ${theme.mixins.spacing / 2}px;
`;