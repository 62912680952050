import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import Screen from '../../components/screen/Screen';
import {EMAIL, LIFF_STATE, REDIRECT_PATH} from '../../constants/common-const';
import {ProviderIdEnum} from '../../enums/common-enum';
import firebase, {analytics, auth} from '../../firebase';
import {getLiff} from '../../line';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import AuthService from '../../services/auth-service';
import {isEmpty} from '../../utils/common-util';
import {decode, getQueryParams} from '../../utils/path-util';

interface P extends RouteComponentProps {}

const LiffScreen: React.FC<P> = React.memo(() => {
  const { queryParams, openPath } = usePath();
  const { initUser, existUser } = useUser();

  const redirectPath = useSafeCallback((): void => {
    const redirectPath = !!queryParams[LIFF_STATE]
      ? getQueryParams(decode(queryParams[LIFF_STATE]))[REDIRECT_PATH]
      : decode(queryParams[REDIRECT_PATH]);
    openPath(!!redirectPath ? redirectPath : Path.QUESTIONS);
  }, [openPath]);

  const succeededToSignIn = useSafeCallback(async (res: firebase.auth.UserCredential): Promise<void> => {
    if (!res.user) {
      openPath(Path.SIGN_IN);
      return;
    }

    const email = !!res.additionalUserInfo && !!res.additionalUserInfo.profile
      ? res.additionalUserInfo.profile[EMAIL]
      : res.user.email;

    if (isEmpty(email)) {
      openPath(Path.SIGN_IN);
      return;
    }

    await initUser(res.user, email);
    redirectPath();
  }, [openPath, initUser, redirectPath]);

  const failedToSignIn = useSafeCallback((): void => {
    openPath(Path.SIGN_IN);
  }, [openPath]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const isSignedIn = await existUser();

    if (isSignedIn) {
      redirectPath();
      return;
    }

    const liff = await getLiff();

    if (!liff.isLoggedIn()) {
      liff.login();
      return;
    }

    const idToken = await liff.getIDToken();
    
    if (!idToken) {
      openPath(Path.SIGN_IN);
      return;
    }

    const customToken = await AuthService
      .verifyUser(idToken, ProviderIdEnum.LINE);

    auth.signInWithCustomToken(customToken)
      .then(succeededToSignIn)
      .catch(failedToSignIn);
  }, [existUser, redirectPath, openPath]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("LIFF画面");
  }, [initialize]);

  return (
    <Screen
      loading={true}
      className="liff-screen"
    />
  );
});

export default LiffScreen;