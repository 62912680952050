import {Button as MuiButton, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import {toConfirmMessage} from '../../../../converters/message-converter';
import {ConfirmMessage} from '../../../../entities/message-entity';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../../styles/responsive';
import {theme} from '../../../../styles/theme';

interface P {
  confirm: ConfirmMessage;
}

const FooterConfirmation: React.FC<P> = React.memo(props => {
  const { confirm } = props;
  const { sendResponse } = useMessage();

  const proceed = useSafeCallback((): void => {
    sendResponse(toConfirmMessage(confirm.value));
  }, [sendResponse, confirm.value]);

  const calcel = useSafeCallback((): void => {
    sendResponse(toConfirmMessage(EMPTY));
  }, [sendResponse]);

  return (
    <Component className="footer-confirmation">
      <Container>
        <Content>
            <UpperBox>
              <Description>
                {confirm.description}
              </Description>
              <ProceedButton
                disableRipple
                onClick={proceed}
              >
                {confirm.value}
              </ProceedButton>
            </UpperBox>

            <LowerBox>
              <CalcelButton
                disableRipple
                onClick={calcel}
              >
                キャンセル
              </CalcelButton>
            </LowerBox>
        </Content>
      </Container>
    </Component>
  );
});

export default FooterConfirmation;

const Container = styled.div`
  width: 100%;
  height: 168px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  &::before {
    width: 100vw;
    height: 100vh;
    content: '';
    position: absolute;
    left: 0px;  
    bottom: 0px;
    background: ${theme.mixins.background.black};
    opacity: 0.4;
    z-index: 2000;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: 168px;
  z-index: 2100;
`;

const UpperBox = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 96px;
  background: ${theme.mixins.background.gray8};
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: ${theme.mixins.spacing}px;
`;

const Description = styled(Typography)`
  width: 240px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray3};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ProceedButton = styled(MuiButton)`
  width: 100%;
  height: 48px;
  border-radius: 16px;
  background: ${theme.mixins.background.gray8} !important;

  .MuiButton-label {
    color: ${theme.mixins.typography.fontColor.pink};
    font-size: ${theme.mixins.typography.fontSize.fourteen}px;
    font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
    font-family: ${theme.mixins.typography.fontFamily};  
  }
`;

const LowerBox = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 48px;
  background: ${theme.mixins.background.white};
  border-radius: 8px;
  margin: ${theme.mixins.spacing}px;
`;

const CalcelButton = styled(MuiButton)`
  width: 100%;
  height: 48px;
  border-radius: 16px;
  background: ${theme.mixins.background.white} !important;

  .MuiButton-label {
    color: ${theme.mixins.typography.fontColor.blue};
    font-size: ${theme.mixins.typography.fontSize.sixteen}px;
    font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
    font-family: ${theme.mixins.typography.fontFamily};  
  }
`;