import {Answer} from '../entities/answer-entity';
import {Reply, ReplyId} from '../entities/reply-entity';
import {User} from '../entities/user-entity';
import {Content, URL} from '../vo/common-vo';

export const toReply = (
  replyId: ReplyId,
  answer: Answer,
  content: Content,
  photoURL: URL,
  user: User,
): Reply => {
  return {
    replyId,
    answer,
    content,
    photoURL,
    user,
  };
};