import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../components/box/EditableBox';
import Component from '../../../components/component/Component';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import {Name} from '../../../vo/common-vo';

interface P {
  user: User;
  onChange(user: User): void;
}

const EditFamilyName: React.FC<P> = React.memo(props => {
  const { user, onChange } = props;
  const unmountRef = useUnmountRef();
  const [familyName, setFamilyName] = useSafeState<Name>(unmountRef, user.familyName);

  const handleFamilyNameChanged = useSafeCallback((familyName: Name): void => {
    setFamilyName(familyName);
    onChange({ ...user, familyName });
  }, [setFamilyName, onChange, user]);

  return (
    <Component className="edit-family-name">
      <Container>
        <Content>
          <EditableBox
            editable
            maxLength={15}
            type="text"
            title="姓"
            value={familyName}
            onChange={handleFamilyNameChanged}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default EditFamilyName;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;