import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {Connection} from '../../entities/connection-entity';
import {User} from '../../entities/user-entity';
import {ConnectionSortEnum} from '../../enums/connection-enum';
import {analytics} from '../../firebase';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import ConnectionService from '../../services/connection-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import ConnectionCards from './connection-card/ConnectionCards';

interface P extends RouteComponentProps {}

const ConnectionsScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<Connection[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    const connections = await ConnectionService.fetchConnections(
      user.userId,
      ConnectionSortEnum.NAME_ASC,
    );
    
    setConnections(connections);
    setLoaded(true);
  }, [getUser, setConnections, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("つながり一覧画面");
  }, [initialize]);

  return (
    <Screen
      loading={!loaded}
      className="connections-screen"
    >
      <Container>
        <Content>
          <ConnectionCards
            connections={connections}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default ConnectionsScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px;
`;