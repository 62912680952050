import {Typography} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import Component from '../../../../components/component/Component';
import InputBox from '../../../../components/input/InputBox';
import {EMPTY} from '../../../../constants/common-const';
import env from '../../../../env/env';
import firebase, {auth} from '../../../../firebase';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import {theme} from '../../../../styles/theme';
import {isEmpty} from '../../../../utils/common-util';
import {DOMAINS} from '../../../../utils/path-util';
import {Email} from '../../../../vo/common-vo';
import miccca from './../../../../assets/miccca.png';

interface P {
  onSendEmail(email: Email): void;
}

const InputEmail: React.FC<P> = React.memo(props => {
  const { onSendEmail } = props;
  const unmountRef = useUnmountRef();
  const [disabled, setDisabled] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabled(isEmpty(email));
  }, [setDisabled, email]);

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    setDisabled(true);

    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: `https://${DOMAINS[env]}`,
    };

    auth.sendPasswordResetEmail(email, settings)
      .then(() => onSendEmail(email));
  }, [setDisabled, email, onSendEmail]);

  return (
    <Component className="input-email">
      <Container>
        <Content>
          <LogoWrapper>
            <Logo
              src={miccca}
            />

            <CatchCopy>
              ミッカでやってみっか
            </CatchCopy>
          </LogoWrapper>
          
          <InputWrapper>
            <InputBox
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
            />
          </InputWrapper>
            
          <ButtonWrapper>
            <Button
              type="primary"
              disabled={disabled}
              icon={<MailIcon fontSize='small' />}
              label="再設定のURLを送る"
              onClick={signInWithEmailAndPassword}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  )
});

export default InputEmail;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.mixins.spacing * 6}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 4}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const CatchCopy = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;