import React, {useEffect} from 'react';
import {scrollTop} from '../../utils/common-util';
import Component from '../component/Component';

interface P {}

const ScrollTop: React.FC<P> = React.memo(() => {

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Component className="scroll-top" />
  );
});

export default ScrollTop;