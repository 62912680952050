import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {User} from '../../entities/user-entity';
import {NotificationCategory} from '../../enums/notification-enum';
import {analytics} from '../../firebase';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import NotificationList from './notification-list/NotificationList';

interface P extends RouteComponentProps {}

const NotificationsAnnounceScreen: React.FC<P> = React.memo(() => {
  const { getUser } = useUser();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initilize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    setUser(user);
    setLoaded(true);
  }, [getUser, setUser, setLoaded]);

  useEffect(() => {
    initilize();
    analytics.setCurrentScreen("通知画面");
  }, [initilize]);

  return (
    <Screen
      loading={!loaded}
      className="notifications-announce-screen"
    >
      <Container>
        <Content>
          <NotificationList
            user={user}
            category={NotificationCategory.ANNOUNCE}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default NotificationsAnnounceScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px;
`;