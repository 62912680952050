import {CommunityMemberRole, CommunityMemberStatus} from "../enums/community-member-enum";
import {Message} from "../vo/common-vo";
import {Community} from "./community-entity";
import {User} from "./user-entity";

export type CommunityMemberId = string;

export class CommunityMember {
  communityMemberId: CommunityMemberId;
  community?: Community;
  isPinned: boolean;
  status: CommunityMemberStatus;
  role: CommunityMemberRole;
  message?: Message;
  user: User;
  createdAt?: Date;
  updatedAt?: Date;
}