import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {CommunityMember} from '../../../entities/community-member-entity';
import XSwipableCard, {CardOptions, CardRef} from '../../common/swipable-card/XSwipableCard';
import SwipableMemberCard from './SwipableMemberCard';

interface P {
  ref: React.Ref<CardRef>;
  options: CardOptions;
  members: CommunityMember[];
  onClickRight(member: CommunityMember): void;
  onClickLeft(member: CommunityMember): void;
}

const SwipableMemberCards: React.ForwardRefExoticComponent<P & React.RefAttributes<CardRef>> = React.forwardRef<CardRef, P>((props, ref) => {
  const {
    options,
    members,
    onClickRight,
    onClickLeft,
  } = props;

  return (
    <Component className="swipable-member-cards">
      <Container>
        <Content>
          {members.map((member, index) =>
            <CardWrapper
              key={index}
            >
              <XSwipableCard
                ref={ref}
                cardOptions={options}
                onClickRight={() => onClickRight(member)}
                onClickLeft={() => onClickLeft(member)}
              >
                <SwipableMemberCard
                  key={index}
                  member={member}
                />
              </XSwipableCard>
            </CardWrapper>
          )}
        </Content>
      </Container>
    </Component>
  );
});

export default SwipableMemberCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const CardWrapper =styled.div`
  width: 100%;
  height: 88px;
`;