import {Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {SUCCESS, UPDATE_PROFILE} from '../../../constants/snackbar-const';
import {toInputMessage, toTabsMessage} from '../../../converters/message-converter';
import {Idea} from '../../../entities/idea-entity';
import {InputMessage} from '../../../entities/message-entity';
import useMessage from '../../../redux/hooks/useMessage';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import IdeaService from '../../../services/idea-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {Content} from '../../../vo/common-vo';
import XSwipableCard, {CardOptions, CardRef, PositionEnum} from '../../common/swipable-card/XSwipableCard';
import loading from './../../../assets/icon/icon_loading2_white.png';
import adjust from './../../../assets/icon/icon_pen_white.png';

const CARD_OPTIONS: CardOptions = {
  rightButton: {
    color: theme.mixins.typography.fontColor.white,
    label: '編集する',
    icon: <Icon size="large" src={adjust} />,
    fontSize: theme.mixins.typography.fontSize.fourteen,
    background: theme.mixins.background.blue,
  },
  leftButton: {
    color: theme.mixins.typography.fontColor.white,
    label: '削除する',
    icon: <Icon size="large" src={loading} />,
    fontSize: theme.mixins.typography.fontSize.fourteen,
    background: theme.mixins.background.gray2,
  },
};

interface P {
  idea: Idea,
  deleteIdea(idea: Idea): void;
}

const EditTopic: React.ForwardRefExoticComponent<P & React.RefAttributes<CardRef>> = React.forwardRef<CardRef, P>((props, ref) => {
  const { idea: initIdea, deleteIdea } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const { sendRequest, subscribeResponse, unsubscribeResponse } = useMessage();
  const [idea, setIdea] = useSafeState<Idea>(unmountRef, initIdea);

  const saveIdea = useSafeCallback(async(content: Content): Promise<void> => {
    (ref as React.RefObject<CardRef>).current
      && (ref as React.RefObject<CardRef>).current!.moveCard(PositionEnum.CENTER);
    const newIdea = { ...idea, content };
    setIdea(newIdea);
    await IdeaService.saveIdea(newIdea);
    enqueueSnackbar(UPDATE_PROFILE, { variant: SUCCESS });
  }, [ref, idea, setIdea, enqueueSnackbar]);

  const handleResponseSubmitted = useSafeCallback(async (
    event: Event,
  ): Promise<void> => {
    const input: InputMessage = event['detail'].input!;
    sendRequest(toTabsMessage());
    unsubscribeResponse();
    await saveIdea(input.text);
  }, [saveIdea, sendRequest, unsubscribeResponse]);

  const openFooterInputBox = useSafeCallback((): void => {
    sendRequest(toInputMessage(idea.content));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, idea.content, subscribeResponse, handleResponseSubmitted]);

  return (
    <Component className="edit-topic">
      <Container>
        <StyledContent>
          <Topic>
            Q : {idea.topic.content}
          </Topic>

          <XSwipableCard
            ref={ref}
            cardOptions={CARD_OPTIONS}
            onClickRight={openFooterInputBox}
            onClickLeft={() => deleteIdea(idea)}
          >
            <Box>
              <StyledAnswer>
                {idea.content}
              </StyledAnswer>
            </Box>
          </XSwipableCard>
        </StyledContent>
      </Container>
    </Component>
  );
});

export default EditTopic;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px 0px;
`;

const StyledContent = styled.div`
  width: 100%;
  height: auto;
`;

const Topic = styled(Typography)`
  width: calc(100% - 32px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};  
  background: ${theme.mixins.background.orange} !important;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  margin: 0px ${theme.mixins.spacing * 2}px;
`;

const Box = styled.div`
  width: calc(100vw - ${theme.mixins.spacing * 4}px);
  max-width: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  height: auto;
  min-height: 96px;
  border-radius: 16px;
  background: ${theme.mixins.background.white};
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const StyledAnswer = styled(Typography)`
  ${theme.mixins.underline};
`;