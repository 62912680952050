import React, {useMemo} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import SubTabs, {SubTab} from '../../../components/tab/SubTabs';
import {fromSignUpInTab, toSignUpInTab} from '../../../converters/path-converter';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Index} from '../../../vo/common-vo';

export enum SignUpInTabEnum {
  SIGN_IN,
  SIGN_UP,
  RESET_PASSWORD,
  OUT_OF_TARGET,
}

const tabs: SubTab[] = [
  {
    id: SignUpInTabEnum.SIGN_IN,
    label: 'ログイン',
  },
  {
    id: SignUpInTabEnum.SIGN_UP,
    label: '新規登録',
  },
];

interface P {}

const SignUpInTabs: React.FC<P> = React.memo(() => {
  const { path, replacePath } = usePath();
  const index = useMemo<Index>(() => toSignUpInTab(path), [path]);

  const handleTabChanged = useSafeCallback((
    tab: SubTab,
  ): void => {
    replacePath(fromSignUpInTab(tab.id));
  }, [replacePath]);

  return (
    <Component
      loadable={index !== SignUpInTabEnum.OUT_OF_TARGET}
      className="sign-up-in-tabs"
    >
      <Container>
        <Content>
          <SubTabs
            selected={tabs[index]}
            tabs={tabs}
            onChange={handleTabChanged}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default SignUpInTabs;

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 288px;
  height: auto;
`;
