import {Button as MuiButton, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Background, Color, ID, Label} from '../../vo/common-vo';
import Component from '../component/Component';

export interface ButtonOption {
  id: ID;
  primary: boolean;
  color: Color;
  background: Background;
  label: Label;
}

interface P {
  disabled?: boolean;
  options: ButtonOption[];
  onClick(option: ButtonOption): void;
}

const MultiButtons: React.FC<P> = React.memo(props => {
  const { disabled = false, options, onClick } = props;

  return (
    <Component className="multi-buttons">
      <Container>
        <Content>
          {options.map((option, index) =>
            <Button
              key={index}
              disableRipple
              disabled={option.primary && disabled}
              number={options.length}
              bcolor={option.color}
              background={option.background}
              onClick={(e) => {
                e.stopPropagation();
                onClick(option);
              }}
            >
              <StyledLabel>
                {option.label}
              </StyledLabel>
            </Button>
          )}
        </Content>
      </Container>
    </Component>
  );
});

export default MultiButtons;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(MuiButton)<{
  number: number,
  bcolor: Color,
  background: Background
}>`
  width: 100%;
  height: 48px;
  border-radius: 24px;
  color: ${props => props.bcolor};
  background: ${props => props.background} !important;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 4px;
  margin: 0px ${theme.mixins.spacing / 2}px;
`;

const StyledLabel = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;