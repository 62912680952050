import {useSnackbar} from 'notistack';
import React from 'react';
import styled from 'styled-components';
import CardWithIcons, {IconData} from '../../../../components/card/CardWithIcons';
import Component from '../../../../components/component/Component';
import {EMPTY} from '../../../../constants/common-const';
import {COMMUNITY_ID} from '../../../../constants/id-const';
import {COPY_LINK, SUCCESS} from '../../../../constants/snackbar-const';
import {Community} from '../../../../entities/community-entity';
import usePath from '../../../../redux/hooks/usePath';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {Path} from '../../../../router/Routes';
import {copyURL, embedIdInPath} from '../../../../utils/path-util';

export enum CommunityPhotoIconEnum {
  ASK = 'ask',
  SETTING = 'setting',
  SHARE = 'share'
}

interface P {
  icons: IconData[];
  community: Community;
}

const CommunityPhoto: React.FC<P> = React.memo(props => {
  const { icons, community } = props;
  const { openPath } = usePath();
  const { enqueueSnackbar } = useSnackbar();

  const openAskScreen = useSafeCallback((): void => {
    openPath(`${Path.ASK_TO}?${COMMUNITY_ID}=${community.communityId}` as Path);
  }, [openPath, community.communityId]);

  const openCommunityEditScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.COMMUNITY_EDIT, [community.communityId]));
  }, [openPath, community.communityId]);

  const copyCommunityInvitationURL = useSafeCallback((): void => {
    copyURL(embedIdInPath(Path.COMMUNITY_DETAILS, [community.communityId]));
    enqueueSnackbar(COPY_LINK, { variant: SUCCESS });
  }, [community.communityId, enqueueSnackbar]);

  const handleIconClicked = useSafeCallback((icon: IconData): void => {
    switch(icon.id) {
      case CommunityPhotoIconEnum.ASK:
        openAskScreen();
        break;
        
      case CommunityPhotoIconEnum.SETTING:
        openCommunityEditScreen();
        break;

      case CommunityPhotoIconEnum.SHARE:
        copyCommunityInvitationURL();
        break;

      default:
        throw new Error(`${icon.id} is out of target.`);
    }
  }, [openAskScreen, openCommunityEditScreen, copyCommunityInvitationURL]);

  return (
    <Component className="community-photo">
      <Container>
        <Content>
          <CardWithIcons
            photoURL={community.photoURL}
            label={!!community.name
              ? community.name : EMPTY}
            icons={icons}
            onClick={handleIconClicked}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityPhoto;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;