export const ASIA_NORTHEAST1 = 'asia-northeast1';

export const EMAIL = 'email';

export const ID_CONST = 'id';

export const CREATED_AT_CONST = 'createdAt';

export const UPDATED_AT_CONST = 'updatedAt';

export const ASC_CONST = 'asc';

export const DESC_CONST = 'desc';

export const IN_CONST = 'in';

export const EMPTY = '';

export const ZERO = 0;

export const ONE = 1;

export const MINUS_ONE = -1;

export const DUE = 'due';

export const CREATED_AT = 'createdAt';

export const UPDATED_AT = 'updatedAt';

export const MAX_LENGTH = 255;

export const LIFF_STATE = 'liff.state';

export const REDIRECT_PATH = 'redirectPath';