import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import CommentBox from '../../components/box/CommentBox';
import Button from '../../components/button/Button';
import Screen from '../../components/screen/Screen';
import {EMPTY, MINUS_ONE} from '../../constants/common-const';
import {Affiliation} from '../../entities/affiliation-entity';
import {User} from '../../entities/user-entity';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useReservedURL from '../../redux/hooks/useReservedURL';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {noop} from '../../utils/common-util';
import {getUserPhoto, toFullName} from '../../utils/user-util';
import {SelfIntroduction as SelfIntroductionVo, URL} from '../../vo/common-vo';
import mojaco from './../../assets/mojaco/mojaco_greeting.png';
import Affiliations from './input-introduction/Affiliations';
import ProfilePhoto from './input-introduction/ProfilePhoto';
import SelfIntroduction from './input-introduction/SelfIntroduction';

interface P {}

const RegisterIntroScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const { getUser, updateUser } = useUser();
  const { hasReservedURL, openReservedURL } = useReservedURL();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [photoURL, setPhotoURL] = useSafeState<URL>(unmountRef);
  const [selfIntroduction, setSelfIntroduction] = useSafeState<SelfIntroductionVo>(unmountRef, EMPTY);
  const [affiliations, setAffiliations] = useSafeState<Affiliation[]>(unmountRef, []);
  const [user, setUser] = useSafeState<User>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    setUser(user);
    setPhotoURL(user.photoURL ? user.photoURL : EMPTY);
    setLoaded(true);
  }, [getUser, openPath, setUser, setPhotoURL, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("プロフィール登録画面");
  }, [initialize]);

  const handleAffiliationChanged = useSafeCallback((newAffiliation: Affiliation): void => {
    setAffiliations(affiliations => {
      const index = affiliations.findIndex(a => a.affiliationId === newAffiliation.affiliationId);
      index === MINUS_ONE
        ? affiliations.push(newAffiliation)
        : affiliations[index] = newAffiliation;
      return affiliations;
    });
  }, [setAffiliations]);

  const saveUserInfo = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const updatedUser: User = {
      ...user,
      photoURL: !!photoURL ? photoURL : getUserPhoto(),
      selfIntroduction,
      affiliations,
    };

    await updateUser(updatedUser);

    hasReservedURL()
      ? openReservedURL()
      : openPath(Path.QUESTIONS);
  }, [setSaving, user, photoURL, selfIntroduction, affiliations, updateUser, hasReservedURL, openReservedURL, openPath]);

  return (
    <Screen
      loading={!loaded || saving}
      className="register-intro-screen"
    >
      <Container>
        <Content>
          <CommentWrapper>
            <CommentBox
              circlePhoto={mojaco}
              onClickCirclePhoto={noop}
            >
              <Greeting>
                {`${toFullName(user)}さん、はじめにあなたのことを教えて下さい。`}
              </Greeting>
            </CommentBox>

            <Complement>
              プロフィールは後から変更・追加も可能です。
            </Complement>
          </CommentWrapper>

          <BoxWrapper>
            <ProfilePhoto
              user={user}
              onChange={setPhotoURL}
            />
          </BoxWrapper>

          <SelfIntroduction
            onChange={setSelfIntroduction}
          />

          <Affiliations
            user={user}
            onChange={handleAffiliationChanged}
          />

          <ButtonWrapper>
            <Button
              type="primary"
              label="完了！"
              onClick={saveUserInfo}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    </Screen>
  )
});

export default RegisterIntroScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
`;

const CommentWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray7};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const Complement = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.ten}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin-top: ${theme.mixins.spacing}px;
  margin-left: ${theme.mixins.spacing * 9}px;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;