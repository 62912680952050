import {Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import MultiButtons, {ButtonOption} from '../../../components/button/MultiButtons';
import Component from '../../../components/component/Component';
import Tips from '../../../components/typography/Tips';
import {COPY_LINK, SUCCESS} from '../../../constants/snackbar-const';
import {Community} from '../../../entities/community-entity';
import {Question} from '../../../entities/question-entity';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import {theme} from '../../../styles/theme';
import {noop} from '../../../utils/common-util';
import {copyURL, embedIdInPath} from '../../../utils/path-util';
import QuestionHeaderCard from '../../questions/question-card/parts/QuestionHeaderCard';
import mojaco from './../../../assets/mojaco/mojaco_bow.png';

enum ButtonType {
  ASK_MORE = 'ask_more',
  OPEN_QUESTION = 'open_question',
}

const QUESTION_CREATED_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.ASK_MORE,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: 'もっと相談する',
  },
  {
    id: ButtonType.OPEN_QUESTION,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '相談をみる',
  },
];

interface P {
  question: Question;
}

const QuestionCreated: React.FC<P> = React.memo(props => {
  const { question } = props;
  const { openPath } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const community = useMemo<Community>(() => question.communities[0], [question.communities]);

  const copyCommunityQuestionDetailsURL = useSafeCallback((): void => {
    copyURL(embedIdInPath(Path.COMMUNITY_QUESTION_DETAILS, [community.communityId, question.questionId]));
    enqueueSnackbar(COPY_LINK, { variant: SUCCESS });
  }, [enqueueSnackbar, community.communityId, question.questionId]);

  const handleFooterButtonClicked = useSafeCallback((option: ButtonOption): void => {
    switch (option.id) {
      case ButtonType.ASK_MORE:
        openPath(Path.ASK_TO);
        break;

      case ButtonType.OPEN_QUESTION:
        openPath(embedIdInPath(Path.QUESTION_DETAILS, [question.questionId]));
        break;

      default:
        throw new Error(`${option.id} is out of target.`);
    }
  }, [openPath, question]);

  return (
    <Component className="question-created">
      <Container>
        <Content>
          <MessageWrapper>
            <Message>
              相談できました。
            </Message>
            <Message>
              返信をしばし待ちましょう。
            </Message>
          </MessageWrapper>

          <Mojaco
            src={mojaco}
          />

          <Tips
            message="コミュニティやトークにいない人に回答してもらいたいときは、相談への招待リンクを送りましょう。"
          />

          <CardWrapper>
            <QuestionHeaderCard
              community={community}
              question={question}
              onClickCommunity={noop}
              onClickReply={noop}
              onClickAccount={noop}
              onClickCopy={noop}
            />

            <CircleWrapper
              onClick={copyCommunityQuestionDetailsURL}
            >
              <Circle />
              <Label>ここ</Label>
            </CircleWrapper>
          </CardWrapper>

          <FooterArea>
            <ButtonWrapper>
              <MultiButtons
                options={QUESTION_CREATED_BUTTONS}
                onClick={handleFooterButtonClicked}
              />
            </ButtonWrapper>
          </FooterArea>
        </Content>
      </Container>
    </Component>
  );
});

export default QuestionCreated;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing}px;
`;

const CardWrapper =styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: ${theme.mixins.spacing * 2}px;
`;

const CircleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: relative;
  top: -48px;
  left: calc(((100% - 160px) / 6 + 10px) * -1);
`;

const Circle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 5px solid ${theme.mixins.palette.pink};
`;

const Label = styled(Typography)`
  width: 56px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  left: 0px;
  bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  width: 280px;
  height: 48px;
`;