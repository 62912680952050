import {ZERO} from '../constants/common-const';
import {COUNT_NOTIFICATIONS, FETCH_NOTIFICATIONS, SAVE_NOTIFICATION} from '../constants/request-const';
import {convertMulti, toCountNotificationsRequest, toFetchNotificationsRequest, toSaveNotificationRequest} from '../converters/request-converter';
import {Notification} from '../entities/notification-entity';
import {UserId} from '../entities/user-entity';
import {ErrorCodeEnum, ErrorItemEnum} from '../enums/error-message-enum';
import {NotificationCategory, NotificationSortEnum} from '../enums/notification-enum';
import {functions} from '../firebase';
import {isDefAndNotNull} from '../utils/common-util';
import {Count, Limit, Offset} from '../vo/common-vo';
import {CountNotificationsRequest, FetchNotificationsRequest, SaveNotificationRequest} from '../vo/request-vo';

export default class NotificationService {
  public static saveNotification = async (
    notification: Notification,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
      const request: SaveNotificationRequest =
        toSaveNotificationRequest(notification);
    
      const func = functions.httpsCallable(SAVE_NOTIFICATION);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
        .catch((error) => reject(error));
    });
  };

  public static fetchNotifications = async (
    userId: UserId,
    type: NotificationCategory,
    sort: NotificationSortEnum,
    limit?: Limit,
    offset?: Offset,
  ): Promise<Notification[]> => {
    return await new Promise((resolve, reject) => {
      const request: FetchNotificationsRequest =
        toFetchNotificationsRequest(userId, type, sort, limit, offset);
    
      const func = functions.httpsCallable(FETCH_NOTIFICATIONS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? convertMulti(res.data) : []))
        .catch((error) => reject(error));
    });
  };

  public static countUnconfirmedNotifications = async (
    userId: UserId,
    category: NotificationCategory,
  ): Promise<Count> => {
    return await new Promise((resolve, reject) => {
      const request: CountNotificationsRequest =
        toCountNotificationsRequest(userId, category);
    
      const func = functions.httpsCallable(COUNT_NOTIFICATIONS);
      func(request)
        .then((res): void => resolve(isDefAndNotNull(res) ? res.data : ZERO))
        .catch((error) => reject(error));
    });
  };
}
