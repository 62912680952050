import React, {useEffect, useRef} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Icon from '../../components/icon/Icon';
import Screen from '../../components/screen/Screen';
import {EMPTY} from '../../constants/common-const';
import {toAnswer} from '../../converters/answer-converter';
import {Question, QuestionId} from '../../entities/question-entity';
import {User} from '../../entities/user-entity';
import {AnswerStatus} from '../../enums/answer-enum';
import {QuestionSortEnum} from '../../enums/question-enum';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import AnswerService from '../../services/answer-service';
import QuestionService from '../../services/question-service';
import {theme} from '../../styles/theme';
import {CardOptions} from '../common/swipable-card/XYSwipableCard';
import {CardDeckRef, PositionEnum} from '../common/swipable-card/XYSwipableDeck';
import balloon from './../../assets/icon/icon_balloon_white.png';
import QuestionCards from './question-card/QuestionCards';

const CARD_OPTIONS: CardOptions = {
  rightButton: {
    color: theme.mixins.typography.fontColor.white,
    label: '回答する',
    icon: <Icon size="large" src={balloon} />,
    fontSize: theme.mixins.typography.fontSize.twenty,
    background: theme.mixins.background.pink,
  },
};

interface P extends RouteComponentProps {}

const CommunityQuestionsScreen: React.FC<P> = React.memo(() => {
  const ref = useRef<CardDeckRef>(null);
  const unmountRef = useUnmountRef();
  const { params } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [questions, setQuestions] = useSafeState<Question[]>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();

    const questions = await QuestionService.fetchQuestionsByCommunityId(
      params.communityId!,
      user.userId,
      QuestionSortEnum.CREATED_AT_DESC,
    );
    
    setUser(user);
    setQuestions(questions);
    setLoaded(true);
  }, [getUser, params.communityId, setUser, setQuestions, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("コミュニティ毎相談一覧画面");
  }, [initialize]);

  const moveCardToTop = useSafeCallback((position: PositionEnum): void => {
    if (position !== PositionEnum.LEFT) return;
    setTimeout(() => {
      ref.current && ref.current.onFocus();
      ref.current && ref.current.moveCard(PositionEnum.TOP);  
    }, 400)
  }, []);

  const removeQuestion = useSafeCallback((questionId: QuestionId): void => {
    ref.current && ref.current.removeCard(questionId);
    const question = questions.find(q => q.questionId === questionId)!;

    const answer = toAnswer(
      AnswerService.getAnswerId(),
      question,
      AnswerStatus.SKIPPED,
      EMPTY,
      EMPTY,
      user,
    );
    
    AnswerService.saveAnswer(answer);
  }, [questions, user]);

  return (
    <Screen
      loading={!loaded}
      className="community-questions-screen"
    >
      <Container>
        <Content>
          <QuestionCards
            ref={ref}
            showComplete={false}
            user={user}
            options={CARD_OPTIONS}
            questions={questions}
            onMove={moveCardToTop}
            onClickLeft={removeQuestion}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default CommunityQuestionsScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
`;

const Content = styled.div`
  width: 100vw;
  height: auto;
`;