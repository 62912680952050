export enum ErrorItemEnum {
  EMAIL = 'email',
  PASSWORD = 'password',
  FAMILY_NAME = 'family_name',
  FIRST_NAME = 'first_name',
}

export enum ErrorCodeEnum {
  INVALID_EMAIL = 'invalid-email',
  EMAIL_ALREADY_IN_USE = 'email-already-in-use',
  OPERATION_NOT_ALLOWED = 'operation-not-allowed',
  WEAK_PASSWORD = 'weak-password',
  USER_DISABLED = 'user-disabled',
  USER_NOT_FOUND = 'user-not-found',
  WRONG_PASSWORD = 'wrong-password',
}