import {Checkbox as MuiCheckBox, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  label: Label;
  checked: boolean;
  onChange(): void;
}

const CheckBox: React.FC<P> = React.memo(props => {
  const { label, checked, onChange } = props;

  return (
    <Component className="check-box">
      <Container>
        <Content
          onClick={onChange}
        >
          <MuiCheckBox
            disableRipple
            checked={checked}
          />

          <CheckBoxLabel>
            {label}
          </CheckBoxLabel>
        </Content>
      </Container>
    </Component>
  );
});

export default CheckBox;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

const CheckBoxLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;