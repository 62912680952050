import {Typography} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import ImageWithSkeleton from '../../../components/image/ImageWithSkeleton';
import {EMPTY, MINUS_ONE, ZERO} from '../../../constants/common-const';
import {Community} from '../../../entities/community-entity';
import {CommunityMember} from '../../../entities/community-member-entity';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import QuestionService from '../../../services/question-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {hasLength, isZero} from '../../../utils/common-util';
import {toMemberNames} from '../../../utils/community-member-util';
import {toDayDiff} from '../../../utils/date-util';
import {Count} from '../../../vo/common-vo';
import pin from './../../../assets/icon/icon_pin_white.png';

const IMG_SIZE = 132;

interface P {
  user: User;
  community: Community;
  members: CommunityMember[];
  onClick(community: Community): void;
}

const CommunityCard: React.FC<P> = React.memo(props => {
  const { user, community, members, onClick } = props;
  const unmountRef = useUnmountRef();
  const [count, setCount] = useSafeState<Count>(unmountRef, ZERO);

  const remainingDays = useMemo<number | null>(() => {
    return !!community.due ? toDayDiff(community.due) * MINUS_ONE : null;
  }, [community.due]);

  const memberNames = useMemo<string>(() => {
    return hasLength(members) ? toMemberNames(members) : EMPTY;
  }, [members]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const count = await QuestionService.countUnconfirmedQuestions(
      user.userId,
      community.communityId,
    );
    setCount(count);
  }, [user.userId, community.communityId, setCount]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className="community-card">
      <Container>
        <Content
          onClick={() => onClick(community)}
        >
          {community.isPinned &&
            <IconWrapper>
              <Icon
                size="small"
                src={pin}
              />
            </IconWrapper>
          }

          <PhotoWrapper
            isPinned={community.isPinned}
          >
            <ImageWithSkeleton
              animation="wave"
              variant='rect'
              style={styleForCommunityPhoto}
              src={community.photoURL}
            />
          </PhotoWrapper>
          
          <CommunityBox>
            <TitleWrapper>
              <Title>
                {community.name}
              </Title>

              {!isZero(count) &&
                <UnconfirmedCount>
                  {count}
                </UnconfirmedCount>            
              }
            </TitleWrapper>

            <MemberNames>
              {memberNames}
            </MemberNames>

            <Wrapper>
              <MembersWrapper>
                {hasLength(members) &&
                  <AvatarWrapper>
                    <Avatar
                      isHead
                      src={members[0].user.photoURL}
                    />
                  </AvatarWrapper>
                }
                {members.length > 1 &&
                  <AvatarWrapper>
                    <Avatar
                      isHead={false}
                      src={members[1].user.photoURL}
                    />
                  </AvatarWrapper>
                }
                {members.length > 2 &&
                  <AvatarWrapper>
                    <Avatar
                      isHead={false}
                      src={members[2].user.photoURL}
                    />
                  </AvatarWrapper>
                }
                {members.length > 3 &&
                  <AvatarWrapper>
                    <Avatar
                      isHead={false}
                      src={members[3].user.photoURL}
                    />
                  </AvatarWrapper>
                }
              </MembersWrapper>
              
              {remainingDays !== null &&
                <DaysWrapper
                  limited={remainingDays < 2}
                >
                  <Border>
                    <Remaining>
                      あと
                    </Remaining>
                    <Days>
                      {remainingDays}
                    </Days>
                    <Remaining>
                      日
                    </Remaining>
                  </Border>
                </DaysWrapper>
              }
            </Wrapper>
          </CommunityBox>
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityCard;

const Container = styled.div`
  width: calc(100vw - ${theme.mixins.spacing * 4}px);
  max-width: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  height: ${IMG_SIZE}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
`;

const IconWrapper = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  background: linear-gradient(45deg, ${theme.mixins.background.blue} 0%, ${theme.mixins.background.blue} 50%, transparent 50%, transparent 100%);
  border-radius: 0px 0px 0px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 20;
  padding ${theme.mixins.spacing / 4}px;
`;

const PhotoWrapper = styled.div<{ isPinned: boolean }>`
  margin-left: ${props => props.isPinned ? -48 : 0}px;
`;

const styleForCommunityPhoto: CSSProperties = {
  width: IMG_SIZE,
  minWidth: IMG_SIZE,
  height: IMG_SIZE,
  borderRadius: 8,
  objectFit: 'cover',
  zIndex: 10,
};

const CommunityBox = styled.div`
  width: calc(100% - ${IMG_SIZE}px);
  height: ${IMG_SIZE}px;
  border-radius: 8px;
  background: ${theme.mixins.background.white};
`;

const TitleWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 32px;
  border-bottom: solid 1px ${theme.mixins.palette.gray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.mixins.spacing}px;
`;

const Title = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select:none;
`;

const UnconfirmedCount = styled(Typography)`
  width: 28px;
  height: 28px;
  color: ${theme.mixins.typography.fontColor.white};
  background: ${theme.mixins.background.pink};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -16px;
  right: -14px;
  user-select: none;
`;

const MemberNames = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 24px;
  color: ${theme.mixins.typography.fontColor.gray2};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select:none;
  margin: ${theme.mixins.spacing}px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MembersWrapper = styled.div`
  max-width: calc(100% - 300px);
  display: flex;
  margin: 0px ${theme.mixins.spacing}px;
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

const Avatar = styled.img<{ isHead: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 20;
  object-fit: cover;
  user-select: none;
  margin-left: ${props => props.isHead ? 0 : theme.mixins.spacing * -1}px;
`;

const DaysWrapper = styled.div<{ limited: boolean }>`
  width: 96px;
  height: 44px;
  background: ${props => props.limited
    ? theme.mixins.background.pink
    : theme.mixins.background.gray6};
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Border = styled.div`
  width: 94px;
  height: 40px;
  border-radius: 4px 0px 0px 4px;
  border-top: solid 2px ${theme.mixins.palette.white};
  border-left: solid 2px ${theme.mixins.palette.white};
  border-bottom: solid 2px ${theme.mixins.palette.white};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const Remaining = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select:none;
`;

const Days = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.eighteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select:none;
  margin: 0px ${theme.mixins.spacing / 2}px;
`;
