import {ReservedURLAction} from '../../entities/action-entity';
import {ReservedURLActionEnum} from '../../enums/action-enum';
import {URL} from '../../vo/common-vo';

export const toReservedURLAction = (
  reservedURL: URL,
): ReservedURLAction => {
  return {
    type: ReservedURLActionEnum.SET,
    reservedURL,
  };
};
