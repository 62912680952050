import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../components/box/EditableBox';
import Component from '../../../components/component/Component';
import {User} from '../../../entities/user-entity';
import {theme} from '../../../styles/theme';

interface P {
  user: User;
}

const SelfIntroduction: React.FC<P> = React.memo(props => {
  const { user } = props;

  return (
    <Component
      loadable={!!user.selfIntroduction}
      className="self-introduction"
    >
      <Container>
        <Content>
          <EditableBox
            type="text"
            title="自己紹介"
            value={user.selfIntroduction!}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default SelfIntroduction;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;