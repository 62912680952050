import {ProcessAction} from '../../entities/action-entity';
import {Process, ProcessActionEnum} from '../../enums/action-enum';

const processReducer = (process: Process, action: ProcessAction): Process => {
  if (process === undefined) return Process.INITIAL;

  switch (action.type) {
    case ProcessActionEnum.SET:
      return action.process;

    case ProcessActionEnum.PURGE:
      return Process.INITIAL;
      
    default:
      return process;
  }
};

export default processReducer;
