import React, {useEffect} from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {EMAIL} from '../../constants/common-const';
import {User} from '../../entities/user-entity';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {embedIdInPath} from '../../utils/path-util';
import AccountCreated from './create-account/AccountCreated';
import InputAccount from './create-account/InputAccount';

export enum RegisterStatus {
  INPUT,
  FINISH,
}

interface P {}

const RegisterNewScreen: React.FC<P> = React.memo(() => {
  const { queryParams, openPath } = usePath();
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [status, setStatus] = useSafeState<RegisterStatus>(unmountRef, RegisterStatus.INPUT);
  const [user, setUser] = useSafeState<User | null>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();

    if (!!user && user.isActivated) {
      openPath(Path.QUESTIONS);
      return;
    }

    setUser(user);
    setLoaded(true);
  }, [getUser, openPath, setUser, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("基本情報登録画面");
  }, [initialize]);

  const openRegisterIntroScreen = useSafeCallback((user: User): void => {
    setStatus(RegisterStatus.FINISH);
    setTimeout(() => openPath(embedIdInPath(Path.REGISTER_INTRO, [user.userId])), 3000);
  }, [setStatus, openPath]);

  return (
    <Screen
      loading={!loaded}
      className="register-new-screen"
    >
      <Container>
        <Content>
          {status === RegisterStatus.INPUT &&
            <InputAccount
              email={queryParams[EMAIL]!}
              user={user}
              onFinish={openRegisterIntroScreen}
            />        
          }

          {status === RegisterStatus.FINISH &&
            <AccountCreated />     
          }
        </Content>
      </Container>
    </Screen>
  )
});

export default RegisterNewScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
`;