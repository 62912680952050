import {Affiliation, AffiliationId} from '../entities/affiliation-entity';
import {Name} from '../vo/common-vo';

export const toAffiliation = (
  affiliationId: AffiliationId,
  name: Name,
): Affiliation => {
  return {
    affiliationId,
    name,
  };
};