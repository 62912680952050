import React, {useMemo} from 'react';
import styled from 'styled-components';
import EditableBox from '../../../components/box/EditableBox';
import MultiButtons, {ButtonOption} from '../../../components/button/MultiButtons';
import Component from '../../../components/component/Component';
import Slider from '../../../components/slider/Slider';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {theme} from '../../../styles/theme';
import {isEmpty} from '../../../utils/common-util';
import {Content, Level, Title, URL} from '../../../vo/common-vo';

const TITLE_PLACEHOLDER =  '家計簿アプリってなに使われてますか？';

const CONTENT_PLACEHOLDER =  '最近、お金の管理をちゃんとしないとなぁと思ってて、家計簿アプリを使ってみようと思っています。色々あってどれが良いか悩んでまして、ぜひ皆さんの使ってらっしゃるもの聞いてみたいです！';

enum ButtonType {
  SELECT_COMMUNITIES = 'select_communities',
  CREATE_QUESTION = 'create_question',
}

const QUESTION_INPUT_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.SELECT_COMMUNITIES,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: 'もどる',
  },
  {
    id: ButtonType.CREATE_QUESTION,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '相談する',
  },
];

interface P {
  level: Level;
  title: Title;
  content: Content;
  photoURL: URL;
  onChangeLevel(level: Level): void;
  onChangeTitle(title: Title): void;
  onChangeContent(content: Content): void;
  onChangePhotoURL(photoURL: URL): void;
  goNext(): void;
  goBack(): void;
}

const InputQuestion: React.FC<P> = React.memo(props => {
  const {
    level,
    title,
    content,
    photoURL,
    onChangeLevel,
    onChangeTitle,
    onChangeContent,
    onChangePhotoURL,
    goNext,
    goBack,
  } = props;

  const disabled = useMemo<boolean>(() => {
    return isEmpty(title);
  }, [title]);

  const onClickFooterButton = useSafeCallback((option: ButtonOption): void => {
    switch (option.id) {
      case ButtonType.SELECT_COMMUNITIES:
        goBack();
        break;

      case ButtonType.CREATE_QUESTION:
        goNext();
        break;

      default:
        throw new Error(`${option.id} is out of target.`);
    }
  }, [goBack, goNext]);

  return (
    <Component className="input-question">
      <Container>
        <StyledContent>
          <Wrapper>
            <EditableBox
              editable
              maxLength={30}
              type="text"
              title="タイトル"
              placeholder={TITLE_PLACEHOLDER}
              value={title}
              onChange={onChangeTitle}
            />
          </Wrapper>

          <Wrapper>
            <EditableBox
              multiline
              editable
              type="text"
              title="相談の内容"
              placeholder={CONTENT_PLACEHOLDER}
              value={content}
              onChange={onChangeContent}
            />
          </Wrapper>

          <Wrapper>
            <EditableBox
              editable
              type="image"
              title="写真"
              value={photoURL}
              onChange={onChangePhotoURL}
            />
          </Wrapper>

          <Wrapper>
            <Slider
              rightLabel="ガチめ"
              leftLabel="ユルめ"
              value={level}
              onChange={onChangeLevel}
            />
          </Wrapper>
        </StyledContent>

        <FooterArea>
          <ButtonWrapper>
            <MultiButtons
              disabled={disabled}
              options={QUESTION_INPUT_BUTTONS}
              onClick={onClickFooterButton}
            />
          </ButtonWrapper>
        </FooterArea>
      </Container>
    </Component>
  );
});

export default InputQuestion;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const StyledContent = styled.div`
  width: 100%;
  height: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  left: 0px;
  bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  width: 280px;
  height: 48px;
`;