import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  label: Label;
  children: React.ReactNode;
  onClick?(): void;
}

const ClickableBox: React.FC<P> = React.memo(props => {
  const { label, children, onClick } = props;

  return (
    <Component className="clickable-box">
      <Container>
        <Content
          onClick={() => !!onClick && onClick()}
        >
          <TitleWrapper>
            <Title>
              {label}
            </Title>
          </TitleWrapper>

          <Wrapper>
            {children}
          </Wrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default ClickableBox;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${theme.mixins.background.white};
  border-radius: 16px;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 1px solid ${theme.mixins.palette.gray2};
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Typography)`
  width: 100%;
  height: 40px;
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing}px 0px;
`;