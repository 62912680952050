import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ButtonOption} from '../../../../components/button/MultiButtons';
import Component from '../../../../components/component/Component';
import {EMPTY, MINUS_ONE} from '../../../../constants/common-const';
import {toMember} from '../../../../converters/community-member-converter';
import {toButtonsMessage, toTabsMessage} from '../../../../converters/message-converter';
import {Community} from '../../../../entities/community-entity';
import {CommunityMember} from '../../../../entities/community-member-entity';
import {Connection} from '../../../../entities/connection-entity';
import {ButtonMessage} from '../../../../entities/message-entity';
import {User} from '../../../../entities/user-entity';
import {CommunityMemberRole, CommunityMemberStatus} from '../../../../enums/community-member-enum';
import {ConnectionSortEnum} from '../../../../enums/connection-enum';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../../redux/hooks/useUnmountRef';
import useUser from '../../../../redux/hooks/useUser';
import CommunityMemberService from '../../../../services/community-member-service';
import ConnectionService from '../../../../services/connection-service';
import {theme} from '../../../../styles/theme';
import {scrollTop} from '../../../../utils/common-util';
import SelectableMemberCards from '../../member-card/SelectableMemberCards';

enum ButtonType {
  ADD_NEW_MEMBERS = 'add_new_members',
  GO_BACK = 'go_back',
}

const EDIT_COMMUNITY_MEMBERS_BUTTON: ButtonOption[] = [
  {
    id: ButtonType.GO_BACK,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: '戻る',
  },
  {
    id: ButtonType.ADD_NEW_MEMBERS,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '追加する',
  },
];

interface P {
  community: Community;
  members: CommunityMember[];
  onClickAdd(selected: CommunityMember[]): void;
  goBack(): void;
}

const EditCommunityMembers: React.FC<P> = React.memo(props => {
  const { community, members, onClickAdd, goBack } = props;
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const { sendRequest, subscribeResponse } = useMessage();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<Connection[]>(unmountRef, []);
  const [selected, setSelected] = useSafeState<CommunityMember[]>(unmountRef, []);

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const button: ButtonMessage = event['detail'].buttons[0];
    sendRequest(toTabsMessage());

    switch (button.id) {
      case ButtonType.ADD_NEW_MEMBERS:
        onClickAdd(selected);
        break;
      
      case ButtonType.GO_BACK:
        goBack();
        break;
        
      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [sendRequest, onClickAdd, selected, goBack]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    const connections = await ConnectionService.fetchConnections(
      user.userId,
      ConnectionSortEnum.NAME_ASC,
    );
    
    setConnections(connections.filter(c =>
      members.findIndex(m => m.user.userId === c.connecting.userId) === MINUS_ONE));
    setLoaded(true);
  }, [getUser, setConnections, members, setLoaded]);

  useEffect(() => {
    scrollTop();
    initialize();
  }, [initialize]);

  const setupMessage = useSafeCallback(async (): Promise<void> => {
    sendRequest(toButtonsMessage(EDIT_COMMUNITY_MEMBERS_BUTTON));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
  }, [setupMessage]);

  const updateSelectedMembers = useSafeCallback((connection: Connection): void => {
    setSelected(selected => {
      const isSelected = selected.findIndex(s =>
        s.user.userId === connection.connecting.userId) !== MINUS_ONE;

      const newMember = toMember(
        CommunityMemberService.getCommunityMemberId(),
        community,
        false,
        CommunityMemberStatus.INVITED,
        CommunityMemberRole.MEMBER,
        EMPTY,
        connection.connecting,
      );

      return isSelected
        ? selected.filter(s => s.user.userId !== connection.connecting.userId)
        : [ ...selected, newMember ];
    });
  }, [setSelected, community]);

  return (
    <Component
      loading={!loaded}
      className="edit-community-members"
    >
      <Container>
        <Content>
          <SelectableMemberCards
            members={connections}
            onSelect={updateSelectedMembers}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default EditCommunityMembers;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;