import moment from "moment";
import {MINUS_ONE} from "../constants/common-const";
import {DateTime, Day, Hour, MilliSecond, Minute} from "../vo/common-vo";

export const ONE_HOUR: MilliSecond = 1000 * 60 * 60;

export const ONE_DAY: MilliSecond = ONE_HOUR * 24;

export const ONE_WEEK: MilliSecond = ONE_DAY * 7;

export const now = (): MilliSecond => moment().unix() * 1000;

export const toDayDiff = (date: Date): Day =>
  moment.unix(date.getTime() / 1000).diff(moment(), 'days') * MINUS_ONE;

export const toHourDiff = (date: Date): Hour =>
  moment.unix(date.getTime() / 1000).diff(moment(), 'hours') * MINUS_ONE;

export const toMinDiff = (date: Date): Minute =>
  moment.unix(date.getTime() / 1000).diff(moment(), 'minutes') * MINUS_ONE;

export const toDateTime = (date: Date): DateTime => {
  const ms = date.getTime();
  const diff = now() - ms;

  if (diff < ONE_HOUR) {
    return `${toMinDiff(date)}分前`;
  }

  if (diff < ONE_DAY) {
    return `${toHourDiff(date)}時間前`;
  }

  if (diff < ONE_WEEK) {
    return `${toDayDiff(date)}日前`;
  }

  return moment.unix(ms / 1000).format('YYYY年MM月DD日');
}

export const getDate = (numberOfDays: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + numberOfDays);
  return date;
}

export const isToday = (date: Date): boolean => {
  const target = new Date(date);
  const today = new Date();
  return target.getDate() === today.getDate();
}