import React from 'react';
import styled from 'styled-components';
import Box from '../../../../components/box/Box';
import PhotoBox from '../../../../components/box/PhotoBox';
import Component from '../../../../components/component/Component';
import {URL} from '../../../../vo/common-vo';

interface P {
  photoURL: URL;
}

const QuestionPhotoCard: React.FC<P> = React.memo(props => {
  const { photoURL } = props;

  return (
    <Component className="question-photo-card">
      <Container>
        <Content>
          <Box>
            <PhotoBox
              src={photoURL}
            />
          </Box>
        </Content>
      </Container>
    </Component>
  );
});

export default QuestionPhotoCard;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;