import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {Community} from '../../entities/community-entity';
import {User} from '../../entities/user-entity';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import CommunityService from '../../services/community-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import EditCommunity from './edit-community/EditCommunity';

interface P extends RouteComponentProps {}

const CommunityEditScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params, openPath } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [community, setCommunity] = useSafeState<Community>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const [user, community] = await Promise.all([
      getUser() as Promise<User>,
      CommunityService.fetchCommunity(params.communityId!)
    ]);

    if (!community) {
      openPath(Path.QUESTIONS);
      return;
    }

    setUser(user);
    setCommunity(community);
    setLoaded(true);
  }, [getUser, params.communityId, openPath, setUser, setCommunity, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("コミュニティ編集画面");
  }, [initialize]);

  return (
    <Screen
      loading={!loaded}
      className="community-edit-screen"
    >
      <Container>
        <Content>
          <EditCommunity
            user={user}
            community={community}
          />
        </Content>
      </Container>
    </Screen>
  );
});

export default CommunityEditScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 10}px;
`;