import {Badge, createStyles, Tab as MuiTab, Tabs as MuiTabs, Theme, withStyles} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ID_CONST} from '../../constants/common-const';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {findIndex} from '../../utils/common-util';
import {Index, Label} from '../../vo/common-vo';
import Component from '../component/Component';

const constructBadge = (label: Label): React.ReactNode => {
  return (
    <Badge color="primary" variant='dot'>
      {label}
    </Badge>
  );
}

export interface MainTab {
  id: number;
  showBadge?: boolean;
  label: Label;
}

interface P {
  selected: MainTab;
  tabs: MainTab[];
  onChange(tab: MainTab): void;
}

const MainTabs: React.FC<P> = React.memo(props => {
  const { selected, tabs, onChange } = props;
  const unmountRef = useUnmountRef();
  const [tabIndex, setTabIndex] = useSafeState<Index>(unmountRef, findIndex<MainTab>(tabs, ID_CONST, selected.id));

  useEffect(() => {
    setTabIndex(findIndex<MainTab>(tabs, ID_CONST, selected.id));
  }, [setTabIndex, tabs, selected.id]);

  const handleTabChange = useSafeCallback((
    event: React.ChangeEvent<{}>,
    tabIndex: Index,
  ) => {
    setTabIndex(tabIndex);
    onChange(tabs[tabIndex]);
  }, [setTabIndex, onChange, tabs]);

  return (
    <Component className="main-tabs">
      <Container>
        <Content>
          <Tabs
            variant="fullWidth"
            value={tabIndex}
            onChange={handleTabChange}
          >
            {tabs.map((tab, index) =>
              <Tab
                key={index}
                disableRipple
                style={{
                  width: `calc((100% - ${theme.mixins.spacing * 4}px) / ${tabs.length})`,
                  maxWidth: `${(MOBILE_MAX_WIDTH - theme.mixins.spacing * 4) / tabs.length}px`,
                  minWidth: `${(MOBILE_MIN_WIDTH - theme.mixins.spacing * 4) / tabs.length}px`,
                }}
                label={
                  tab.showBadge
                    ? constructBadge(tab.label)
                    : tab.label
                }
              />)
            }
          </Tabs>
        </Content>
      </Container>
    </Component>
  );
});

export default MainTabs;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

interface TabsProps {
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  value: Index;
  onChange(event: React.ChangeEvent<{}>, tabIndex: Index);
}

const Tabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `100%`,
      maxWidth: `${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px`,
      minWidth: `${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px`,
      backgroundColor: theme.mixins.background.white,
    },
    indicator: {
      border: `solid 3px ${theme.mixins.background.pink}`,
      borderRadius: 3,
    },
  }),
)((props: TabsProps) => <MuiTabs {...props} />);

interface TabProps {
  disableRipple: boolean;
  style: CSSProperties;
  label: React.ReactNode;
}

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.mixins.typography.fontColor.gray5,
      fontSize: theme.mixins.typography.fontSize.fourteen,
      fontWeight: theme.mixins.typography.fontWeight.nineHundreds as number,
      fontFamily: theme.mixins.typography.fontFamily,
      whiteSpace: 'nowrap',
      userSelect: 'none',
      paddingBottom: theme.mixins.spacing * 3 / 2,
      
      '&:focus': {
        color: theme.mixins.typography.fontColor.pink,
      },
    },
    selected: {
      color: theme.mixins.typography.fontColor.pink,
    },
  }),
)((props: TabProps) => <MuiTab {...props} />);