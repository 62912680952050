import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Email} from '../../../vo/common-vo';
import {EMPTY} from './../../../constants/common-const';
import EmailSent from './parts/EmailSent';
import SignUpOptions from './parts/SignUpOptions';

export enum SignUpStatus {
  SELECT_SIGN_UP_OPTION,
  EMAIL_SENT,
}

interface P {}

const SignUp: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const [status, setStatus] = useSafeState<SignUpStatus>(unmountRef, SignUpStatus.SELECT_SIGN_UP_OPTION);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);

  const showEmailSentMessage = useSafeCallback((email: Email): void => {
    setEmail(email);
    setStatus(SignUpStatus.EMAIL_SENT);
  }, [setEmail, setStatus]); 

  return (
    <Component className="sign-up">
      <Container>
        <Content>
          {status === SignUpStatus.SELECT_SIGN_UP_OPTION &&
            <SignUpOptions
              onSendEmail={showEmailSentMessage}
            />
          }
            
          {status === SignUpStatus.EMAIL_SENT &&
            <EmailSent
              email={email}
            />        
          }
        </Content>
      </Container>
    </Component>
  )
});

export default SignUp;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;