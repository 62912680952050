import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../components/box/EditableBox';
import Component from '../../../components/component/Component';
import {EMPTY} from '../../../constants/common-const';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {theme} from '../../../styles/theme';
import {SelfIntroduction as SelfIntroductionVo} from '../../../vo/common-vo';

interface P {
  onChange(selfIntroduction: SelfIntroductionVo): void;
}

const SelfIntroduction: React.FC<P> = React.memo(props => {
  const { onChange } = props;
  const unmountRef = useUnmountRef();
  const [selfIntroduction, setSelfIntroduction] = useSafeState<SelfIntroductionVo>(unmountRef, EMPTY);

  const updateSelfIntroduction = useSafeCallback((
    selfIntroduction: SelfIntroductionVo,
  ): void => {
    setSelfIntroduction(selfIntroduction);
    onChange(selfIntroduction);
  }, [setSelfIntroduction, onChange]);

  return (
    <Component className="self-introduction">
      <Container>
        <Content>
          <EditableBox
            multiline
            editable
            type="text"
            placeholder="ヒトコトの自己紹介"
            value={selfIntroduction}
            onChange={updateSelfIntroduction}
          />
        </Content>
      </Container>
    </Component>
  )
});

export default SelfIntroduction;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;