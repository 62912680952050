import firebase from 'firebase';
import {USER_PHOTOS} from "../constants/user-const";
import {User} from "../entities/user-entity";
import {ProviderIdEnum} from "../enums/common-enum";
import {Name, URL} from "../vo/common-vo";
import {getRandomInt, isDefAndNotNull} from "./common-util";

export const toFullName = (user: User): Name =>
  isDefAndNotNull(user) ? `${user.familyName} ${user.firstName}` : '';

export const getUserPhoto = (): URL => {
  const index = getRandomInt(3);
  return USER_PHOTOS[index];
}

export const getProviderId = (fbUser: firebase.User): ProviderIdEnum => {
  const providerId = fbUser.displayName;

  switch (providerId) {
    case ProviderIdEnum.LINE:
      return ProviderIdEnum.LINE;

    default:
      return ProviderIdEnum.GOOGLE;
  }
}