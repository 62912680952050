import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import {CommunityMember} from '../../../entities/community-member-entity';
import {Connection} from '../../../entities/connection-entity';
import SelectableMemberCard from './SelectableMemberCard';

interface P {
  selected?: CommunityMember[] | Connection[];
  members: CommunityMember[] | Connection[];
  onSelect(member: CommunityMember | Connection): void;
}

const SelectableMemberCards: React.FC<P> = React.memo(props => {
  const { selected = [], members, onSelect } = props;

  return (
    <Component className="selectable-member-cards">
      <Container>
        <Content>
          {members.map((member, index) =>
            <SelectableMemberCard
              key={index}
              selected={selected}
              member={member}
              onSelect={onSelect}
            />)}
        </Content>
      </Container>
    </Component>
  );
});

export default SelectableMemberCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;