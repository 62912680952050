import {Answer, AnswerId} from '../entities/answer-entity';
import {ButtonMessage, Message, ReplyMessage} from '../entities/message-entity';
import {Reply} from '../entities/reply-entity';
import {MessageType} from '../enums/message-enum';
import {toFullName} from '../utils/user-util';
import {Description, Text, URL, Value} from '../vo/common-vo';

export const toTabsMessage = (): Message => {
  return {
    type: MessageType.TABS,
  };
}

export const toReplyMessage = (
  id: AnswerId,
  content: Answer | Reply,
): ReplyMessage => {
  return {
    id,
    title: toFullName(content.user),
    details: content.content,
    photoURL: content.user.photoURL!,
  };
}

export const toInputMessage = (
  text: Text,
  photoURL?: URL,
  reply?: ReplyMessage,
  initFocus: boolean = false,
  usePhoto: boolean = false,
): Message => {
  return {
    type: MessageType.INPUT,
    input: {
      initFocus,
      usePhoto,
      reply,
      text,
      photoURL,
    }
  };
}

export const toButtonsMessage = (
  buttons: ButtonMessage[],
  disabled?: boolean,
): Message => {
  return {
    type: MessageType.BUTTONS,
    disabled: !!disabled,
    buttons,
  };
}

export const toConfirmMessage = (
  value: Value,
  description?: Description,
): Message => {
  return {
    type: MessageType.CONFIRM,
    confirm: {
      description,
      value,
    }
  };
}