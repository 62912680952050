import {Typography} from '@material-ui/core';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import {CSSProperties} from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import ImageWithSkeleton from '../../../components/image/ImageWithSkeleton';
import {User} from '../../../entities/user-entity';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import PhotoService from '../../../services/photo-service';
import {theme} from '../../../styles/theme';
import {isEmpty, isNotEmpty} from '../../../utils/common-util';
import {Text, URL} from '../../../vo/common-vo';

interface P {
  user: User;
  onChange(photoURL: URL): void;
}

const ProfilePhoto: React.FC<P> = React.memo(props => {
  const { user, onChange } = props;
  const unmountRef = useUnmountRef();
  const [uploading, setUploading] = useSafeState<boolean>(unmountRef, false);
  const [photoURL, setPhotoURL] = useSafeState<Text>(unmountRef, user.photoURL);

  const uploadProfilePhoto = useSafeCallback(async (image): Promise<void> => {
    setUploading(true);
    const photoURL = await PhotoService.upload(image, user.userId);
    setPhotoURL(photoURL);
    onChange(photoURL);
    setUploading(false);
  }, [setUploading, user.userId, setPhotoURL, onChange]);

  return (
    <Component
      loading={uploading}
      className="profile-photo"
    >
      <Container>
        <Content>
            <UploadArea>
              <UploadPhoto
                type="file"
                accept="image/*"
                onChange={(e) => uploadProfilePhoto(e.target.files[0])}
              />

              <Body>
                <PhotoWrapper>
                  {isEmpty(photoURL) &&
                    <NoPhoto>
                      <AddAPhotoOutlinedIcon fontSize="large" />
                    </NoPhoto>            
                  }

                  {isNotEmpty(photoURL) &&
                    <ImageWithSkeleton
                      animation="wave"
                      variant='circle'
                      style={styleForProfilePhoto}
                      src={photoURL}
                    />        
                  }
                </PhotoWrapper>

                <Label>
                  プロフィール画像を追加しよう
                </Label>
              </Body>
            </UploadArea>
        </Content>
      </Container>
    </Component>
  );
});

export default ProfilePhoto;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  border-radius: 16px;
`;

const UploadArea = styled.div`
  width: 100%;
  height: auto;
`;

const UploadPhoto = styled.input`
  width: calc(100% - ${theme.mixins.spacing * 2}px);
  height: 196px;
  opacity: 0;
  appearance: none;
  position: absolute;
`;

const Body = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const PhotoWrapper = styled.div`
  width: 240px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${theme.mixins.palette.gray2};
`;

const NoPhoto = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${theme.mixins.background.gray5};
`;

const styleForProfilePhoto: CSSProperties = {
  width: 120,
  height: 120,
  borderRadius: '50%',
  objectFit: 'cover',
};

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-top: ${theme.mixins.spacing}px;
`;