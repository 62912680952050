import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {analytics} from '../../firebase';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import CommunityList from './community-list/CommunityList';

interface P extends RouteComponentProps {}

const CommunitiesScreen: React.FC<P> = React.memo(() => {

  useEffect(() => {
    analytics.setCurrentScreen("コミュニティ一覧画面");
  }, []);

  return (
    <Screen className="communities-screen">
      <Container>
        <Content>
          <CommunityList />
        </Content>
      </Container>
    </Screen>
  );
});

export default CommunitiesScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 10}px;
`;