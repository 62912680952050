import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import EditableBox from '../../../../components/box/EditableBox';
import {ButtonOption} from '../../../../components/button/MultiButtons';
import Component from '../../../../components/component/Component';
import Tips from '../../../../components/typography/Tips';
import {toButtonsMessage, toTabsMessage} from '../../../../converters/message-converter';
import {ButtonMessage} from '../../../../entities/message-entity';
import useMessage from '../../../../redux/hooks/useMessage';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import {isEmpty} from '../../../../utils/common-util';
import {Description, Name, URL} from '../../../../vo/common-vo';

enum ButtonType {
  SELECT_COMMUNITY_MEMBERS = 'select_community_members',
  CREATE_NEW_COMMUNITY = 'create_new_community',
}

export const INPUT_COMMUNITY_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.SELECT_COMMUNITY_MEMBERS,
    primary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray4,
    label: '戻る',
  },
  {
    id: ButtonType.CREATE_NEW_COMMUNITY,
    primary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '作成する',
  },
];

interface P {
  name: Name;
  description: Description;
  photoURL: URL;
  onChangeName(name: Name): void;
  onChangeDescription(description: Description): void;
  onChangePhotoURL(photoURL: URL): void;
  goNext(): void;
  goBack(): void;
}

const InputCommunity: React.FC<P> = React.memo((props: any) => {
  const {
    name,
    description,
    photoURL,
    onChangeName,
    onChangeDescription,
    onChangePhotoURL,
    goNext,
    goBack,
  } = props;
  const { sendRequest, subscribeResponse } = useMessage();

  const handleResponseSubmitted = useSafeCallback((event: Event): void => {
    const button: ButtonMessage = event['detail'].buttons[0];

    switch (button.id) {
      case ButtonType.SELECT_COMMUNITY_MEMBERS:
        goBack();
        break;

      case ButtonType.CREATE_NEW_COMMUNITY:
        goNext();
        break;
        
      default:
        throw new Error(`${button.id} is out of target.`);
    }
  }, [goBack, goNext]);

  const setupMessage = useSafeCallback((): void => {
    sendRequest(toButtonsMessage(INPUT_COMMUNITY_BUTTONS, isEmpty(name)));
    subscribeResponse(handleResponseSubmitted);
  }, [sendRequest, name, subscribeResponse, handleResponseSubmitted]);

  useEffect(() => {
    setupMessage();
    
    return () => {
      sendRequest(toTabsMessage());
    };
  }, [setupMessage, sendRequest]);

  return (
    <Component className="input-community">
      <Container>
        <Content>
          <SupplementWrapper>
            <Supplement>
              コミュニティに名前と写真、そして目的をつけよう
            </Supplement>
          </SupplementWrapper>

          <CommunityItemBoxWrapper>
            <EditableBox
              editable
              maxLength={30}
              type="text"
              title="コミュニティの名前"
              placeholder='第77階 アトミカ祭実行委員会'
              value={name}
              onChange={onChangeName}
            />
          </CommunityItemBoxWrapper>

          <CommunityItemBoxWrapper>
            <EditableBox
              editable
              type="image"
              title='コミュニティの写真'
              value={photoURL}
              onChange={onChangePhotoURL}
            />
          </CommunityItemBoxWrapper>

          <CommunityItemBoxWrapper>
            <EditableBox
              multiline
              editable
              type="text"
              title="コミュニティの説明"
              placeholder="2021年8月のアトミカ祭実行メンバー。先輩方にも入ってもらいつつフランクに相談出し合っていきましょ〜"
              value={description}
              onChange={onChangeDescription}
            />
          </CommunityItemBoxWrapper>

          <Tips
            message="コミュニティの名前や写真は共通で表示されます。 変更はオーナーだけが可能で、後から変更することも可能です。"
          />
        </Content>
      </Container>
    </Component>
  );
});

export default InputCommunity;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: ${window.innerHeight - 56}px;
  padding-bottom: ${theme.mixins.spacing * 10}px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SupplementWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Supplement = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration-line: underline;
  user-select: none;
`;

const CommunityItemBoxWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
`;