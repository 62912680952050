
import React, {useRef} from 'react';
import styled from 'styled-components';
import Component from '../../../components/component/Component';
import Icon from '../../../components/icon/Icon';
import {Community} from '../../../entities/community-entity';
import {User} from '../../../entities/user-entity';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {Path} from '../../../router/Routes';
import CommunityMemberService from '../../../services/community-member-service';
import {theme} from '../../../styles/theme';
import {embedIdInPath} from '../../../utils/path-util';
import XSwipableCard, {CardOptions, CardRef, PositionEnum} from '../../common/swipable-card/XSwipableCard';
import ear from './../../../assets/icon/icon_ear_white.png';
import pin from './../../../assets/icon/icon_pin_white.png';
import CommunityCard from './CommunityCard';

const CARD_OPTIONS: CardOptions = {
  rightButton: {
    color: theme.mixins.typography.fontColor.white,
    label: '相談を\nみる',
    icon: <Icon size="medium" src={ear} />,
    fontSize: theme.mixins.typography.fontSize.twenty,
    background: theme.mixins.background.pink,
  },
  leftButton: {
    color: theme.mixins.typography.fontColor.white,
    label: 'ピン',
    icon: <Icon size="medium" src={pin} />,
    fontSize: theme.mixins.typography.fontSize.twenty,
    background: theme.mixins.background.blue,
  },
};

interface P {
  loaded?: boolean;
  user: User;
  communities: Community[];
  onClick(community: Community): void;
}

const CommunityCards: React.FC<P> = React.memo(props => {
  const { loaded = true, user, communities: initCommunities, onClick } = props;
  const ref = useRef<CardRef>(null);
  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const [communities, setCommunities] = useSafeState<Community[]>(unmountRef, initCommunities);

  const openCommunityQuestionsScreen = useSafeCallback((community: Community): void => {
    openPath(embedIdInPath(Path.COMMUNITY_QUESTIONS, [community.communityId]));
  }, [openPath]);

  const toggleCommunityPin = useSafeCallback((community: Community): void => {
    const isPinned = !community.isPinned;
    const member = community.members!.find(m => m.user.userId === user.userId)!;
    CommunityMemberService.saveCommunityMembers(community.communityId, [{ ...member, isPinned }], user);

    setCommunities(communities => {
      const targetCommunity = communities.find(c => c.communityId === community.communityId)!;
      const filteredCommunity = communities.filter(c => c.communityId !== community.communityId);
      const pinnedCommunities = filteredCommunity.filter(c => c.isPinned);
      const unpinnedCommunities = filteredCommunity.filter(c => !c.isPinned);   
      return [ ...pinnedCommunities, { ...targetCommunity, isPinned }, ...unpinnedCommunities ];
    });
    ref.current && ref.current.moveCard(PositionEnum.CENTER);
  }, [user, setCommunities]);

  return (
    <Component
      loading={!loaded}
      className="community-cards"
    >
      <Container>
        <Content>
          {communities.map((community, index) =>
            <CardWrapper
              key={index}
            >
              <XSwipableCard
                ref={ref}
                cardOptions={CARD_OPTIONS}
                onClickRight={() => openCommunityQuestionsScreen(community)}
                onClickLeft={() => toggleCommunityPin(community)}
              >
                <CommunityCard
                  user={user}
                  community={community}
                  members={community.members!}
                  onClick={onClick}
                />
              </XSwipableCard>
            </CardWrapper>
          )}
        </Content>
      </Container>
    </Component>
  );
});

export default CommunityCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const CardWrapper =styled.div`
  width: 100%;
  height: 148px;
`;