import React from 'react';
import styled from 'styled-components';
import EditableBox from '../../../../components/box/EditableBox';
import Component from '../../../../components/component/Component';
import {Community} from '../../../../entities/community-entity';
import useSafeCallback from '../../../../redux/hooks/useSafeCallback';
import {theme} from '../../../../styles/theme';
import {URL} from '../../../../vo/common-vo';

interface P {
  community: Community;
  onChange(community: Community): void;
}

const EditCommunityPhoto: React.FC<P> = React.memo(props => {
  const { community, onChange } = props;

  const updatePhotoURL = useSafeCallback((photoURL: URL): void => {
    onChange({ ...community, photoURL });
  }, [onChange, community]);

  return (
    <Component className="edit-community-photo">
      <Container>
        <Content>
          <EditableBox
            editable
            type="image"
            title="プロフィール写真"
            value={community.photoURL}
            onChange={updatePhotoURL}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default EditCommunityPhoto;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;