import {useSprings} from '@react-spring/core';
import React, {useImperativeHandle, useRef} from 'react';
import {useGesture} from 'react-use-gesture';
import {FullGestureState} from 'react-use-gesture/dist/types';
import styled from 'styled-components';
import {ID_CONST} from '../../../constants/common-const';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import useSafeState from '../../../redux/hooks/useSafeState';
import useUnmountRef from '../../../redux/hooks/useUnmountRef';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../../styles/responsive';
import {theme} from '../../../styles/theme';
import {findIn, hasLength, isDefAndNotNull, isZero, removeFrom} from '../../../utils/common-util';
import {ID, Index} from '../../../vo/common-vo';
import XYSwipableCard, {CardData, CardOptions, CardProps, PROPS_INDEX} from './XYSwipableCard';

type Axis = 'x' | 'y' | undefined;

enum DirectionEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum PositionEnum {
  CENTER = 'center',
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum DeckActionEnum {
  MOVE_TOP_AND_FOCUS_ON = 'move_top_and_focus_on',
  MOVE_CENTER = 'move_center',
}

export const DECK_SETTINGS: { [key: string]: number } = {
  /** position */
  DEFAULT_X_POSITION: 0,
  DEFAULT_Y_POSITION: window.innerWidth > MOBILE_MAX_WIDTH
    ? window.innerHeight - MOBILE_MAX_WIDTH - 136
    : window.innerWidth < MOBILE_MIN_WIDTH
      ? window.innerHeight - MOBILE_MIN_WIDTH - 136
      : window.innerHeight - window.innerWidth - 136,
  TOP_Y_POSITION: 0,

  /** distance */
  MOVING_DISTANCE: 100,

  /** opacity */
  VISIBLE: 1,
  NOT_VISIBLE: 0,

  /** scale */
  DEFAULT_SCALE: 1,
  LARGE_SCALE: 1.05,

  /** delay */
  TRANSITION_DELAY: 40,
}

const toId = (id: ID): Partial<CardProps> => {
  return { id };
}

const to = (p: PositionEnum, t: number, o: number, v: boolean): Partial<CardProps> => {
  return {
    x: p === PositionEnum.RIGHT
      ? DECK_SETTINGS.DEFAULT_X_POSITION + DECK_SETTINGS.MOVING_DISTANCE
      : p === PositionEnum.LEFT
        ? DECK_SETTINGS.DEFAULT_X_POSITION - DECK_SETTINGS.MOVING_DISTANCE
        : DECK_SETTINGS.DEFAULT_X_POSITION,
    y: p === PositionEnum.TOP || t === 1
      ? DECK_SETTINGS.TOP_Y_POSITION
      : p === PositionEnum.BOTTOM
        ? DECK_SETTINGS.DEFAULT_Y_POSITION + DECK_SETTINGS.MOVING_DISTANCE
        : Math.floor(DECK_SETTINGS.DEFAULT_Y_POSITION / (t - 1) * (t - o)),
    zIndex: (t - o) * 10,
    opacity: v ? DECK_SETTINGS.VISIBLE : DECK_SETTINGS.NOT_VISIBLE,
    scale: DECK_SETTINGS.DEFAULT_SCALE,
    delay: DECK_SETTINGS.TRANSITION_DELAY,
  }
};

const toX = (p: PositionEnum, t: number, o: number): Partial<CardProps> => {
  return {
    x: p === PositionEnum.RIGHT
      ? DECK_SETTINGS.DEFAULT_X_POSITION + DECK_SETTINGS.MOVING_DISTANCE
      : DECK_SETTINGS.DEFAULT_X_POSITION - DECK_SETTINGS.MOVING_DISTANCE,
    y: t === 1
      ? DECK_SETTINGS.TOP_Y_POSITION
      : Math.floor(DECK_SETTINGS.DEFAULT_Y_POSITION / (t - 1) * (t - o)),
    delay: DECK_SETTINGS.TRANSITION_DELAY,
  }
};

const toY = (p: PositionEnum): Partial<CardProps> => {
  return {
    x: DECK_SETTINGS.DEFAULT_X_POSITION,
    y: p === PositionEnum.TOP
      ? DECK_SETTINGS.TOP_Y_POSITION
      : DECK_SETTINGS.DEFAULT_Y_POSITION + DECK_SETTINGS.MOVING_DISTANCE,
    delay: DECK_SETTINGS.TRANSITION_DELAY,
  }
};

const toHidden = (): Partial<CardProps> => {
  return {
    opacity: DECK_SETTINGS.NOT_VISIBLE,
    zIndex: -1,
  }
};

const from = (): Partial<CardProps> => {
  return {
    y: DECK_SETTINGS.TOP_Y_POSITION,
    opacity: DECK_SETTINGS.NOT_VISIBLE,
  }
};

const empty = (): Partial<CardProps> => {
  return {};
};

const toDefaultCardProps = (
  nextPosition: PositionEnum,
  cardLength: number,
): Partial<CardProps> => {
  const order = 1;

  switch (nextPosition) {
    case PositionEnum.CENTER:
      return to(PositionEnum.CENTER, cardLength, order, true);

    case PositionEnum.TOP:
      return toY(PositionEnum.TOP);

    case PositionEnum.BOTTOM:
      return {
        ...toY(PositionEnum.BOTTOM),
        ...toHidden(),
      };

    case PositionEnum.RIGHT:
      return toX(PositionEnum.RIGHT, cardLength, order);

    case PositionEnum.LEFT:
      return toX(PositionEnum.LEFT, cardLength, order);
      
    default:
      throw new Error(`${nextPosition} is out of target.`);
  }
};

const isTargetGesture = (
  state: Omit<FullGestureState<"drag">, "event">,
): boolean => {
  const isDefinedDirection = isDefAndNotNull(state.axis);
  const finishedDragging = !state.dragging;
  return isDefinedDirection
    && finishedDragging;
}

const isFrontCard = (
  id: ID,
  cardDataList: CardData[],
): boolean => {
  return id === cardDataList[0].id;
}

const toDirection = (
  axis: Axis,
  xDir: number,
  yDir: number,
): DirectionEnum => {
  switch (axis) {
    case 'x':
      return xDir > 0 ? DirectionEnum.RIGHT : DirectionEnum.LEFT;

    case 'y':
      return yDir > 0 ? DirectionEnum.BOTTOM : DirectionEnum.TOP;
      
    default:
      throw new Error(`${axis} is out of target.`);
  }
}

const toNextPosition = (
  position: PositionEnum,
  direction: DirectionEnum,
  options?: CardOptions,
): PositionEnum => {
  switch (position) {
    case PositionEnum.TOP:
      return direction === DirectionEnum.BOTTOM
        ? PositionEnum.CENTER : PositionEnum.TOP;

    case PositionEnum.BOTTOM:
      return direction === DirectionEnum.TOP
        ? PositionEnum.CENTER : PositionEnum.BOTTOM;

    case PositionEnum.RIGHT:
      return direction === DirectionEnum.LEFT
        ? PositionEnum.CENTER : PositionEnum.RIGHT;

    case PositionEnum.LEFT:
      return direction === DirectionEnum.RIGHT
        ? PositionEnum.CENTER : PositionEnum.LEFT;
  }

  switch (direction) {
    case DirectionEnum.TOP:
      return PositionEnum.TOP;

    case DirectionEnum.BOTTOM:
      return PositionEnum.BOTTOM;

    case DirectionEnum.RIGHT:
      return !!options && !!options.leftButton
        ? PositionEnum.RIGHT
        : PositionEnum.CENTER;

    case DirectionEnum.LEFT:
      return !!options && !!options.rightButton
        ? PositionEnum.LEFT
        : PositionEnum.CENTER;

    default:
      throw new Error(`${direction} is out of target`);
  }
}

const toDefaultCardDataList = (cardDataList: CardData[]): CardData[] => {
  return cardDataList.map(cardData => {
    return { ...cardData, visible: true, position: PositionEnum.CENTER };
  });
}

const showFrontCardOnly = (cardDataList: CardData[]): CardData[] => {
  return cardDataList.map((cardData, index) => {
    return { ...cardData, visible: isZero(index), position: PositionEnum.TOP };
  });
}

const hideFrontCard = (cardDataList: CardData[]): CardData[] => {
  if (!hasLength(cardDataList)) return [];
  const card = cardDataList[0];
  const newCardDataList = removeFrom<CardData>(cardDataList, ID_CONST, card.id);
  newCardDataList.push({ ...card, visible: false, position: PositionEnum.BOTTOM });
  return newCardDataList;
}

const updatePosition = (cardDataList: CardData[], id: ID, position: PositionEnum): CardData[] =>
  cardDataList.map(cardData => cardData.id === id ? { ...cardData, position } : cardData);

export interface CardDeckRef {
  onFocus(): void;
  moveCard(position: PositionEnum): void;
  removeCard(id: ID): void;
}

interface P {
  cardOptions?: CardOptions;
  cardDataList: CardData[];
  onMove?(position: PositionEnum): void;
  onClickRight?(id: ID): void;
  onClickLeft?(id: ID): void;
  onReachEnd?(): void;
}

const XYSwipableDeck: React.ForwardRefExoticComponent<P & React.RefAttributes<CardDeckRef>> = React.forwardRef<CardDeckRef, P>((props, ref) => {
  const {
    cardOptions,
    cardDataList: initCardDataList,
    onMove,
    onClickRight,
    onClickLeft,
    onReachEnd,
  } = props;

  const unmountRef = useUnmountRef();
  const isFocus = useRef<boolean>(false);
  const [cardDataList, setCardDataList] = useSafeState<CardData[]>(unmountRef, initCardDataList);

  const [cardPropsList, setCardPropsList] = useSprings<CardProps>(initCardDataList.length, (i: number) => {
    const cardData = findIn<CardData>(initCardDataList, PROPS_INDEX, i)!;

    const cardProps = {
      ...toId(cardData.id),
      ...to(cardData.position, initCardDataList.length, i + 1, cardData.visible),
      from: from(),
    } as CardProps;
    
    return cardProps;
  });

  const updateCardProps = useSafeCallback((targetIndex: Index, props: Partial<CardProps> | undefined): void => {
    setCardPropsList((index: Index) => index === targetIndex ? props : empty());
  }, [setCardPropsList]);

  const reconstructCardPropsList = useSafeCallback((cardDataList: CardData[]): void => {
    cardDataList.forEach((cardData: CardData, index: Index) => {
      const { propsIndex, position, visible } = cardData;
      const props = to(position, cardDataList.length, index + 1, visible);
      updateCardProps(propsIndex, props);
    });
  }, [updateCardProps]);

  const reconstructCardDataList = useSafeCallback((): void => {
    setCardDataList((cardDataList: CardData[]) => {
      const defaultCardDataList = toDefaultCardDataList(cardDataList); 
      reconstructCardPropsList(defaultCardDataList);
      return defaultCardDataList;
    });
  }, [setCardDataList, reconstructCardPropsList]);

  const moveCardToLast = useSafeCallback((cardDataList: CardData[]): CardData[] => {
    const newCardDataList = hideFrontCard(cardDataList);
    setTimeout(() => reconstructCardDataList(), 200);
    return newCardDataList;
  }, [reconstructCardDataList]);

  const updateCardDataList = useSafeCallback((
    prevPosition: PositionEnum,
    nextPosition: PositionEnum,
    cardDataList: CardData[],
    id: ID,
  ): CardData[] => {
    if (prevPosition === PositionEnum.TOP &&
        nextPosition === PositionEnum.CENTER) {
      const center = toDefaultCardDataList(cardDataList);
      reconstructCardPropsList(center);
      return center;
    }
  
    switch (nextPosition) {
      case PositionEnum.TOP:
        const top = showFrontCardOnly(cardDataList);
        reconstructCardPropsList(top);
        return top;
  
      case PositionEnum.BOTTOM:
        const bottom = moveCardToLast(cardDataList);
        reconstructCardPropsList(bottom);
        return bottom;
  
      case PositionEnum.RIGHT:  
      case PositionEnum.LEFT:
      case PositionEnum.CENTER:
        return updatePosition(cardDataList, id, nextPosition);
  
      default:
        throw new Error(`${nextPosition} is out of target`);
    }
  }, [moveCardToLast, reconstructCardPropsList]);

  const handleCardPositionChanged = useSafeCallback((
    prevPosition: PositionEnum,
    nextPosition: PositionEnum,
    cardDataList: CardData[],
  ): CardData[] => {
    const { id, propsIndex } = cardDataList[0];
    const props = toDefaultCardProps(nextPosition, cardDataList.length);
    updateCardProps(propsIndex, props);
    return updateCardDataList(prevPosition, nextPosition, cardDataList, id);
  }, [updateCardProps, updateCardDataList]);

  const handleDragGestureChanged = useSafeCallback((state: Omit<FullGestureState<"drag">, "event">): void => {
     if (!isTargetGesture(state)) return;

    setCardDataList(cardDataList => {
      if (!isFrontCard(state.args[0], cardDataList)) return cardDataList;
      isFocus.current = false;
      const { position: prevPosition } = cardDataList[0];
      const { axis, direction: [xDir, yDir] } = state;
      const direction = toDirection(axis, xDir, yDir);
      const nextPosition = toNextPosition(prevPosition, direction, cardOptions);
      !!onMove && onMove(nextPosition);
      return handleCardPositionChanged(prevPosition, nextPosition, cardDataList);
    });
  }, [setCardDataList, cardOptions, onMove, handleCardPositionChanged])

  const bind = useGesture({
      onDrag: handleDragGestureChanged,
  });

  const moveCard = useSafeCallback((nextPosition: PositionEnum): void => {
    setCardDataList(cardDataList => {
      const { position: prevPosition } = cardDataList[0];
      if (prevPosition === nextPosition) return cardDataList;
      return handleCardPositionChanged(prevPosition, nextPosition, cardDataList);
    });
  }, [setCardDataList, handleCardPositionChanged]);

  const hideCard = useSafeCallback((id: ID): boolean => {
    const cardData = findIn<CardData>(cardDataList, ID_CONST, id)!;
    updateCardProps(cardData.propsIndex, toHidden());
    return true;
  }, [cardDataList, updateCardProps]);

  const removeCard = useSafeCallback((id: ID): void => {
    setCardDataList(cardDataList => {
      const newCardDataList = removeFrom<CardData>(cardDataList, ID_CONST, id);
      reconstructCardPropsList(newCardDataList);
      !hasLength(newCardDataList) && !!onReachEnd && onReachEnd();
      return newCardDataList;
    });
  }, [setCardDataList, reconstructCardPropsList, onReachEnd]);

  useImperativeHandle(ref, () => ({
    onFocus: () => isFocus.current = true,
    moveCard: (position: PositionEnum) => moveCard(position),
    removeCard: (id: ID) => hideCard(id) && removeCard(id),
  }));

  const handleActionChanged = useSafeCallback((action: DeckActionEnum): void => {
    switch (action) {
      case DeckActionEnum.MOVE_TOP_AND_FOCUS_ON:
        isFocus.current = true;
        moveCard(PositionEnum.TOP);
        break;

      case DeckActionEnum.MOVE_CENTER:
        moveCard(PositionEnum.CENTER);
        break;
        
      default:
        throw new Error(`${action} is out of target.`);
    }
  }, [moveCard]);

  return (
    <Container>
      <Content>
        {cardDataList.map((card: CardData, index: Index) => (
          <XYSwipableCard
            key={card.propsIndex}
            isFocus={isFocus.current}
            isFront={isZero(index)}
            cardOptions={cardOptions}
            cardProps={cardPropsList[card.propsIndex]}
            cardData={card}
            bind={bind}
            onClickRight={(id: ID) => !!onClickRight && onClickRight(id)}
            onClickLeft={(id: ID) => !!onClickLeft && onClickLeft(id)}
            onAction={handleActionChanged}
          />
        ))}
      </Content>
    </Container>
  );
});

export default XYSwipableDeck;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  padding-top: ${theme.mixins.spacing * 2}px;
`;