import {default as React, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import EditableBox from '../../components/box/EditableBox';
import Button from '../../components/button/Button';
import Screen from '../../components/screen/Screen';
import {EMPTY} from '../../constants/common-const';
import {toIdea} from '../../converters/idea-converter';
import {Idea} from '../../entities/idea-entity';
import {Topic} from '../../entities/topic-entity';
import {User} from '../../entities/user-entity';
import {IdeaStatus} from '../../enums/idea-enum';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import useUser from '../../redux/hooks/useUser';
import {Path} from '../../router/Routes';
import IdeaService from '../../services/idea-service';
import TopicService from '../../services/topic-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';
import {hasSize} from '../../utils/common-util';
import {embedIdInPath} from '../../utils/path-util';
import {Content} from '../../vo/common-vo';

interface P extends RouteComponentProps {}

const TopicNewScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params, openPath } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [topic, setTopic] = useSafeState<Topic>(unmountRef);
  const [prevIdea, setPrevIdea] = useSafeState<Idea | undefined>(unmountRef);
  const [content, setContent] = useSafeState<Content>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser() as User;
    const topic = await TopicService.fetchTopic(params.topicId!, user.userId);

    if (!topic) {
      openPath(Path.QUESTIONS);
      return;
    }

    const idea = !!topic.ideas && !!topic.ideas.length
      ? topic.ideas[0] : undefined;

    setUser(user);
    setTopic(topic);
    setPrevIdea(idea);
    setContent(!!idea ? idea.content : EMPTY);
    setLoaded(true);
  }, [getUser, params.topicId, openPath, setUser, setTopic, setPrevIdea, setContent, setLoaded]);

  useEffect(() => {
    initialize()
    analytics.setCurrentScreen("一問一答回答画面");
  }, [initialize]);

  const saveIdea = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    
    const idea = toIdea(
      !!prevIdea ? prevIdea.ideaId : IdeaService.getIdeaId(),
      topic,
      IdeaStatus.ACTIVE,
      content,
      user,
    );

    const errors = await IdeaService.saveIdea(idea);
    if (!hasSize(errors)) openPath(embedIdInPath(Path.ACCOUNT_TOPICS, [user.userId]));
  }, [setSaving, prevIdea, topic, content, user, openPath]);

  return (
    <Screen
      loading={!loaded || saving}
      className="topic-new-screen"
    >
      <Container>
        <StyledContent>
          <Wrapper>
            <EditableBox
              type="text"
              title="届いた質問"
              value={!!topic ? topic.content : EMPTY}
            />
          </Wrapper>

          <Wrapper>
            <EditableBox
              editable
              multiline
              type="text"
              title="回答"
              value={content}
              onChange={setContent}
            />
          </Wrapper>

          <ButtonWrapper>
            <Button
              type="primary"
              label="回答する"
              onClick={saveIdea}
            />
          </ButtonWrapper>
        </StyledContent>
      </Container>
    </Screen>
  );
});

export default TopicNewScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const StyledContent = styled.div`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 16}px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;