import {Idea, IdeaId} from '../entities/idea-entity';
import {Topic} from '../entities/topic-entity';
import {User} from '../entities/user-entity';
import {IdeaStatus} from '../enums/idea-enum';
import {Content} from '../vo/common-vo';

export const toIdea = (
  ideaId: IdeaId,
  topic: Topic,
  status: IdeaStatus,
  content: Content,
  user: User
): Idea => {
  return {
    ideaId,
    topic: { ...topic, ideas: [] },
    status,
    content,
    user,
  };
};