import compress from 'browser-image-compression';
import {v4 as uuid} from 'uuid';
import {UserId} from '../entities/user-entity';
import {storage} from '../firebase';
import {URL} from '../vo/common-vo';

const OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 1080,
}

const COLLECTION_NAME_PHOTOS = 'photos';

export default class PhotoService {
  public static upload = async (
    image: File,
    userId: UserId,
  ): Promise<URL> => {
    try {
      const compressedImage = await compress(image, OPTIONS);
      const ref = storage.ref(`/${COLLECTION_NAME_PHOTOS}/${userId}/${uuid()}`);
      const snapshot = await ref.put(compressedImage);
      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      throw Error(JSON.stringify(e));
    }
  };
}
