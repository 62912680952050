import axios from 'axios';
import qs from 'qs';
import {EMPTY} from "../constants/common-const";
import {VERIFY_USER} from "../constants/request-const";
import {toVerifyUserRequest} from "../converters/request-converter";
import {ProviderIdEnum} from "../enums/common-enum";
import {functions} from "../firebase";
import {getLineTokenBody} from '../line';
import {LINE_TOKEN_CONFIG, LINE_TOKEN_URL} from "../line/config";
import {isDefAndNotNull} from "../utils/common-util";
import {Code, CustomToken, IdToken} from "../vo/common-vo";
import {VerifyUserRequest} from "../vo/request-vo";

export default class AuthService {
  public static fetchIdToken = async (
    code: Code,
  ): Promise<CustomToken> => {
    const res = await axios.post(
      LINE_TOKEN_URL,
      qs.stringify(getLineTokenBody(code)),
      LINE_TOKEN_CONFIG,
    );

    return res.data.id_token;
  };

  public static verifyUser = async (
    idToken: IdToken,
    providerId: ProviderIdEnum,
  ): Promise<CustomToken> => {
    return await new Promise((resolve, reject) => {
    const request: VerifyUserRequest =
      toVerifyUserRequest(idToken, providerId);
  
    const func = functions.httpsCallable(VERIFY_USER);
    func(request)
      .then((res): void => resolve(isDefAndNotNull(res) ? res.data : EMPTY))
      .catch((error) => reject(error));
    });
  };
}
