export enum NotificationSortEnum {
  CREATED_AT_ASC,
  CREATED_AT_DESC,
}

export enum NotificationCategory {
  ANNOUNCE = 'announce',
  COMMUNITY = 'community',
  ACTIVITY = 'activity',
}

export enum NotificationType {
  ANNOUNCE = 'announce',
  INVITE_MEMBER = 'invite_member',
  REQUEST_MEMBER = 'request_member',
  REQUEST_CONNECTION = 'request_connection',
  RECEIVE_ANSWER = 'receive_answer',
  RECEIVE_REPLY = 'receive_reply',
  ADD_IDEA = 'add_idea',
}

export enum NotificationStatus {
  UNCONFIRMED = 'unconfirmed',
  CONFIRMED = 'confirmed',
}