import {useSnackbar} from 'notistack';
import React from 'react';
import styled from 'styled-components';
import CardWithIcons, {IconData} from '../../../components/card/CardWithIcons';
import Component from '../../../components/component/Component';
import {COPY_LINK, SUCCESS} from '../../../constants/snackbar-const';
import {User} from '../../../entities/user-entity';
import usePath from '../../../redux/hooks/usePath';
import useSafeCallback from '../../../redux/hooks/useSafeCallback';
import {Path} from '../../../router/Routes';
import {copyURL, embedIdInPath} from '../../../utils/path-util';
import {toFullName} from '../../../utils/user-util';
import setting from './../../../assets/icon/icon_setting_white.png';
import share from './../../../assets/icon/icon_share_white.png';

enum ProfilePhotoIconEnum {
  EDIT = 'edit',
  SHARE = 'share',
}

const getIcons = (isMyAccount: boolean): IconData[] => [
  {
    id: ProfilePhotoIconEnum.EDIT,
    disabled: !isMyAccount,
    src: setting,
  },
  {
    id: ProfilePhotoIconEnum.SHARE,
    disabled: false,
    src: share,
  }
];

interface P {
  isMyAccount: boolean;
  user: User;
}

const ProfilePhoto: React.FC<P> = React.memo(props => {
  const { isMyAccount, user } = props;
  const { openPath } = usePath();
  const { enqueueSnackbar } = useSnackbar();

  const openAccountEditScreen = useSafeCallback(() => {
    openPath(embedIdInPath(Path.ACCOUNT_EDIT, [user.userId]));
  }, [openPath, user]);

  const copyAccountURL = useSafeCallback(() => {
    copyURL(embedIdInPath(Path.ACCOUNT, [user.userId]));
    enqueueSnackbar(COPY_LINK, { variant: SUCCESS });
  }, [user, enqueueSnackbar]);

  const onClickIcon = useSafeCallback((icon: IconData) => {
    switch(icon.id) {
      case ProfilePhotoIconEnum.EDIT:
        openAccountEditScreen();
        break;

      case ProfilePhotoIconEnum.SHARE:
        copyAccountURL();
        break;

      default:
        throw new Error(`${icon.id} is out of target.`);
    }
  }, [openAccountEditScreen, copyAccountURL]);

  return (
    <Component className="profile-photo">
      <Container>
        <Content>
          <CardWithIcons
            photoURL={user.photoURL}
            label={toFullName(user)}
            icons={getIcons(isMyAccount)}
            onClick={onClickIcon}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default ProfilePhoto;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;