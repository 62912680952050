import {ProcessAction} from '../../entities/action-entity';
import {Process, ProcessActionEnum} from '../../enums/action-enum';

export const toProcessAction = (
  process: Process,
): ProcessAction => {
  return {
    type: ProcessActionEnum.SET,
    process,
  };
};
