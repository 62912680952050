import {EMPTY} from '../../constants/common-const';
import {ReservedURLAction} from '../../entities/action-entity';
import {ReservedURLActionEnum} from '../../enums/action-enum';
import {URL} from '../../vo/common-vo';

const reservedURLReducer = (reservedURL: URL, action: ReservedURLAction): URL => {
  if (reservedURL === undefined) return EMPTY;

  switch (action.type) {
    case ReservedURLActionEnum.SET:
      return action.reservedURL;

    case ReservedURLActionEnum.PURGE:
      return EMPTY;
      
    default:
      return reservedURL;
  }
};

export default reservedURLReducer;
