import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import Box from '../../components/box/Box';
import Screen from '../../components/screen/Screen';
import {Idea} from '../../entities/idea-entity';
import {IdeaSortEnum} from '../../enums/idea-enum';
import {analytics} from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import useSafeState from '../../redux/hooks/useSafeState';
import useUnmountRef from '../../redux/hooks/useUnmountRef';
import IdeaService from '../../services/idea-service';
import {MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH} from '../../styles/responsive';
import {theme} from '../../styles/theme';

interface P extends RouteComponentProps {}

const AccountTopicsScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params } = usePath();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);  
  const [ideas, setIdeas] = useSafeState<Idea[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const ideas = await IdeaService.fetchIdeas(
      params.userId!,
      IdeaSortEnum.CREATED_AT_DESC,  
    );
    
    setIdeas(ideas);
    setLoaded(true);
  }, [params.userId, setIdeas, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen("一問一答一覧画面");
  }, [initialize]);

  return (
    <Screen
      loading={!loaded}
      className="account-topics-screen"
    >
      <Container>
        <Content>
          {ideas.map((idea, index) =>
            <TopicWrapper
              key={index}            
            >
              <Topic>
                Q : {idea.topic.content}
              </Topic>

              <Box>
                <Answer>
                  {idea.content}
                </Answer>
              </Box>
            </TopicWrapper>
          )}
        </Content>
      </Container>
    </Screen>
  );
});

export default AccountTopicsScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: auto;
  padding: ${theme.mixins.spacing * 8}px 0px ${theme.mixins.spacing * 9}px;
`;

const TopicWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Topic = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};  
  background: ${theme.mixins.background.orange} !important;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px;
  margin-bottom: ${theme.mixins.spacing / 2}px;
`;

const Answer = styled(Typography)`
  ${theme.mixins.underline};
`;