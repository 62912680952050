import QRCodeReact from 'qrcode.react';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import {REDIRECT_PATH} from '../../constants/common-const';
import env from '../../env/env';
import {Path} from '../../router/Routes';
import {theme} from '../../styles/theme';
import {LIFF_URLS} from '../../utils/path-util';
import {Message, URL} from '../../vo/common-vo';
import Component from '../component/Component';
import Tips from '../typography/Tips';

interface P {
  url: URL;
  path: Path;
  message: Message;
}

const QRCode: React.FC<P> = React.memo(props => {
  const { path, message } = props;

  const lineURL = useMemo(() => 
    `${LIFF_URLS[env]}?${REDIRECT_PATH}=${encodeURIComponent(path)}`
  , [path]);

  return (
    <Component className="qr-code">
      <Container>
        <Content>
          <QRCodeWrapper>
            <QRCodeReact
              size={160}
              bgColor={theme.mixins.background.yellow}
              value={lineURL}
            />
          </QRCodeWrapper>

          <Tips
            message={message}
          />
        </Content>
      </Container>
    </Component>
  );
});

export default QRCode;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px 0px ;
`;