import {v4 as uuid} from 'uuid';
import {SAVE_ANSWER} from "../constants/request-const";
import {toSaveAnswerRequest} from "../converters/request-converter";
import {Answer, AnswerId} from "../entities/answer-entity";
import {ErrorCodeEnum, ErrorItemEnum} from "../enums/error-message-enum";
import {functions} from "../firebase";
import {isDefAndNotNull} from "../utils/common-util";
import {SaveAnswerRequest} from "../vo/request-vo";

export default class AnswerService {
  public static getAnswerId = (): AnswerId => uuid();

  public static saveAnswer = async (
    answer: Answer,
  ): Promise<Map<ErrorItemEnum, ErrorCodeEnum>> => {
    return await new Promise((resolve, reject) => {
    const request: SaveAnswerRequest =
      toSaveAnswerRequest(answer);
  
    const func = functions.httpsCallable(SAVE_ANSWER);
    func(request)
      .then((res): void => resolve(isDefAndNotNull(res) ? res.data : new Map()))
      .catch((error) => reject(error));
    });
  };
}
