import {Slider as MuiSlider, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import useSafeCallback from '../../redux/hooks/useSafeCallback';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  disabled?: boolean;
  rightLabel: Label;
  leftLabel: Label;
  value: number;
  onChange?(value: number): void;
}

const Slider: React.FC<P> = React.memo(props => {
  const {
    disabled = false,
    rightLabel,
    leftLabel,
    value,
    onChange,
  } = props;

  const handleLevelChanged = useSafeCallback((
    event: React.ChangeEvent<{}>,
    newValue: number | number[],
  ) => {
    !!onChange && onChange(newValue as number);
  }, [onChange]);

  return (
    <Component className="slider">
      <Container>
        <Content>
          <LabelWrapper>
            {leftLabel}
          </LabelWrapper>

          <StyledSlider
            disabled={disabled}
            value={value}
            onChange={handleLevelChanged}
          />

          <LabelWrapper>
            {rightLabel}
          </LabelWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default Slider;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

const StyledSlider = styled(MuiSlider)`
  .MuiSlider-thumb {
    width: 32px;
    height: 32px;
    margin-top: -16px;
    margin-left: -16px;
    background: ${theme.mixins.background.white};
    border: 0.5px solid ${theme.mixins.palette.gray2};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const LabelWrapper = styled(Typography)`
  min-width: 80px;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray5};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  user-select: none;
`;