import {Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {theme} from '../../styles/theme';
import {Label} from '../../vo/common-vo';
import Component from '../component/Component';

interface P {
  label: Label;
}

const Bar: React.FC<P> = React.memo(props => {
  const { label } = props;

  return (
    <Component className="bar">
      <Container>
        <Content>
          <StyledLabel>
            {label}
          </StyledLabel>
        </Content>
      </Container>
    </Component>
  );
});

export default Bar;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray5};
  font-size: ${theme.mixins.typography.fontSize.fourteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;